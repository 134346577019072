import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { InputComponent, SelectComponent } from ".";
import { deleteDiv, deleteDivDept, deleteRole, getAllDepartments, getAllDivisionsByDept, getAllPermissons, getDivisionById, saveDivisionDept } from "../../../services/AdminSecurityServices";
import ToastMessage from "../ToastMessage";
import { Delete } from "@mui/icons-material";
import { Autocomplete, TableSortLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../ConfirmDialog";
import { getDepartmentsByOrgId } from "../../../services/WorkOrderServices";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const divisionTableColumns = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "division",
    headerName: "Division Name",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "description",
    headerName: "Description",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "super-app-theme--header",
    width: 100,
    sortable: false,
  },
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}

          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, setSelectedRole, loading, setLoading,
    selectedStie, save, setSave, allDepartments, setOpenConfirm, openConfirm, user, replaceConfirm,
    setReplaceConfirm, existingDepartment, setExistingDepartment, selectedRow, setSelectedRow } = props;
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(0);
  const [departmentValues, setDepartmentValues] = useState([]);
  const [remainingDepts, setRemainingDepts] = useState([]);
  const [input, setInput] = useState('');
  const [selectedDept, setSelectedDept] = useState({});
  const [selectedDeptDelete, setSelectedDeptDelete] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disableDelete, setDisableDelete] = useState(true);
  const [deptDesc, setDeptDesc] = useState('');
  const [departments, setDepartments] = useState([
    {
      "id": 0,
      "organizationId": 0,
      "divisionId": 0,
      "departmentId": 0,
      "departmentCode": "string",
      "description": "string",
      "createdDate": "2024-11-12T12:20:36.797Z",
      "lastUpdatedDate": "2024-11-12T12:20:36.797Z",
      "createdBy": 0,
      "lastUpdatedBy": 0
    }
  ])

  const tableContainerRef = useRef(null);

  const handleAdd = () => {
    setDepartments([{
      "id": 0,
      "organizationId": 0,
      "divisionId": 0,
      "departmentId": 0,
      "departmentCode": "string",
      "description": "string",
      "createdDate": new Date().toISOString(),
      "lastUpdatedDate": new Date().toISOString(),
      "createdBy": 0,
      "lastUpdatedBy": 0
    }, ...departments]);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }

  const handleCancel = () => {
    setSelectedDept({});
    const filteredData = departments.filter(item => item.id !== 0);
    setDepartments(filteredData);
  }

  const fetchData = async () => {
    setLoading(true);
    getAllDivisionsByDept(row?.id, page, rowsPerPage).then(response => {
      if (response?.statusCode === "200") {
        setLoading(true);
        response?.data?.content.forEach(x => {
          x.value = x.departmentCode;
        })
        const filteredData = allDepartments.filter(item1 =>
          !response?.data.content.some(item2 => item1.departmentId === item2.departmentId)
        );
        setRemainingDepts(filteredData);
        setDisableDelete(response?.data?.content?.length !== 0 ? true : false);
        setDepartments(response?.data.content?.length == 0 ? [
          {
            "id": 0,
            "organizationId": 0,
            "divisionId": 0,
            "departmentId": 0,
            "departmentCode": "string",
            "description": "string",
            "createdDate": "2024-11-12T12:20:36.797Z",
            "lastUpdatedDate": "2024-11-12T12:20:36.797Z",
            "createdBy": 0,
            "lastUpdatedBy": 0
          }
        ] : response?.data.content);
        setTotalElements(response.data.totalElements);
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }



  const deleteDept = async (_id) => {
    setLoading(true);
    deleteDivDept(_id).then(response => {
      if (response?.statusCode === "200") {
        setLoading(false);
        const toastProps = { open: true, type: "success", message: "Department deleted succesfully", onClose: () => setToast({}) };
        setToast(toastProps);
        fetchData();
      }
      else {
        const toastProps = { open: true, type: "error", message: response.message, onClose: () => setToast({}) };
        setToast(toastProps);
      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }

  const saveDepartment = async () => {
    const departmentValuesPayload = [{
      "id": 0,
      "organizationId": row?.organizationId,
      "divisionId": row?.id,
      "departmentId": selectedDept?.departmentId,
      "departmentCode": selectedDept?.departmentCode,
      "description": deptDesc,
      "createdDate": new Date().toISOString(),
      "lastUpdatedDate": new Date().toISOString(),
      "createdBy": user?.id,
      "lastUpdatedBy": user?.id
    }]
    saveDivisionDept(departmentValuesPayload)
      .then(response => {
        if (response.statusCode == 200) {
          const toastProps = { open: true, type: "success", message: "Department saved succesfully", onClose: () => setToast({}) };
          setToast(toastProps);
          fetchData();
          setLoading(false);
          setDeptDesc('');
          handleCancel();
        }
        else {
          const toastProps = { open: true, type: "error", message: response.message, onClose: () => setToast({}) };
          setToast(toastProps);
          setLoading(false);
          handleCancel()
        }
      })
      .catch(err => console.log(err));
  }

  const handleReplaceandSaveDept = (_id) => {
    setLoading(true);
    deleteDivDept(_id).then(response => {
      if (response?.statusCode === "200") {
        saveDepartment();
      }
      else {
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    if (save && row?.id === selectedRow) {
      setSave(false)
      handleReplaceandSaveDept(existingDepartment?.id);
    }
  }, [save])

  const handleValidate = async () => {
    setLoading(true);
    getAllDepartments(null, 0, 50).then(response => {
      if (response?.statusCode === "200") {
        const findExistingDepartment = response?.data?.content?.find(item => item?.departmentId === selectedDept?.departmentId);
        setExistingDepartment(findExistingDepartment);
        if (findExistingDepartment) {
          setReplaceConfirm(true);
        }
        else {
          saveDepartment();
        }

      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open])




  return (
    <React.Fragment>
      <ConfirmDialog
        openConfirm={confirmDelete}
        hidebuttons={false}
        msg={`Do you want to delete ${selectedDeptDelete?.departmentCode}?`}
        accept={() => {
          deleteDept(selectedDeptDelete?.id)
          setConfirmDelete(false);
        }}
        reject={() => {
          setConfirmDelete(false);
        }}
      />
      <TableRow
        className='admin-security-roles-table-row'
        sx={{
          "& > *": { borderBottom: open ? "inherit" : "unset" }, background: open ? "#F3F4F5" : "white", borderBottom: open ? "1px solid rgba(224, 224, 224, 1)" : "none",
        }}
      >
        <TableCell component='th' scope='row' sx={{
          borderLeft: '5px solid #006CEC !important', borderBottom: '0', borderBottomLeftRadius: '5px',
          borderTopLeftRadius: '5px'
        }}>
          {row.id}
        </TableCell>
        <TableCell align='right'>{row.division}</TableCell>
        <TableCell align='right'>{row.description}</TableCell>
        <TableCell sx={{ display: "flex" }} >
          <IconButton disabled={loading} aria-label='expand row' size='small'  onClick={() => {
            setSelectedRole(row); setOpenConfirm(true);
          }}>
            <Delete />
          </IconButton>
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => { setOpen(!open); setSelectedRow(row?.id) }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, background: open ? "#F3F4F5" : "white", borderTop: open ? '1px solid rgba(224, 224, 224, 1)' : 'none' }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box className='collapsable-table-box-main'>
              <Box className='collapsable-table-box-sub'>
                <Box className='roles-grid-main' sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <TableContainer ref={tableContainerRef} sx={{ maxWidth: '800px', maxHeight: '400px' }}>
                    <Table stickyHeader className='admin-security-roles-table' size='small' aria-label='purchases'>
                      <TableHead>
                        <TableRow>
                          <TableCell>{'Departments'}</TableCell>
                          <TableCell>{'Description'}</TableCell>
                          <TableCell >{'Actions'}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {departments?.map((rowPer, index) => (
                          <TableRow key={rowPer?.id}>
                            <TableCell sx={{ padding: '10px 16px !important' }}
                              className='table-cell-with-dropdown'
                            >
                              {rowPer?.id !== 0 ? <SelectComponent
                                label=''
                                values={[rowPer]}
                                selectedvalue={rowPer.id}
                                disabled={false}
                                width='100%'
                                parentselectcallback={null}
                              ></SelectComponent> :
                                <Autocomplete
                                  value={selectedDept || {}}
                                  onChange={(event, newValue) => {
                                    setSelectedDept(newValue);
                                    setDeptDesc(newValue?.description)
                                  }}
                                  inputValue={input || ''}
                                  onInputChange={(event, newInputValue) => {
                                    setInput(newInputValue || '');
                                  }}
                                  onOpen={() => setSelectedRow(row?.id)}
                                  getOptionLabel={(option) => option.departmentCode || ''}
                                  options={remainingDepts}
                                  sx={{ width: '100%' }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Select Department"
                                      fullWidth
                                    />
                                  )}
                                  disabled={false}
                                />
                              }
                            </TableCell>
                            <TableCell className="action-button-cell" >
                              {rowPer?.id !== 0 ? (rowPer?.description || '-') : <InputComponent
                                label={""}
                                value={deptDesc}
                                disabled={true}
                                width={"100%"}
                                onChange={(e) => setDeptDesc(e.target.value)}
                              ></InputComponent>}
                            </TableCell>
                            <TableCell className="action-button-cell" align='start'>
                              {
                                rowPer?.id !== 0 ?
                                  <Box className='action-buttons' >
                                    <IconButton disabled={loading} onClick={() => handleAdd()}>
                                      <AddBoxIcon color="primary" />
                                    </IconButton>
                                    <IconButton disabled={loading} onClick={() => { setSelectedDeptDelete(rowPer); setConfirmDelete(true) }}>
                                      <IndeterminateCheckBoxIcon color="error" />
                                    </IconButton>
                                  </Box>
                                  :
                                  <Box className='action-buttons' >
                                    <IconButton disabled={loading} onClick={() => handleValidate()}>
                                      <SaveIcon color="success" />
                                    </IconButton>
                                    <IconButton disabled={loading || departments?.length == 1} onClick={() => handleCancel()}>
                                      <CancelIcon color="error" />
                                    </IconButton>
                                  </Box>
                              }

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ToastMessage toast={toast} />
    </React.Fragment >
  );
}

export default function AxSecurityDivisionsTableComponent(props) {
  const { divisionsData, showNewRole, totalElements, selectedSite, saveData, user, sendData, setSendData, fetchData } = props;

  const [allDepartments, setAllDepartments] = useState<any>([]);
  const [selectedDepartments, setSelectedPermission] = useState<any>([]);
  const [descriptionError, setDescriptionError] = useState("");
  const [divisionNameError, setDivisionNameError] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [description, setDescription] = useState("");
  const [editedRoleId, setEditedRoleId] = useState("");
  const [replaceConfirm, setReplaceConfirm] = useState(false);
  const [save, setSave] = useState(false);
  const [existingDepartment, setExistingDepartment] = useState({});
  const [selectedRole, setSelectedRole] = useState({
    "id": 0,
    "organizationId": 0,
    "divisionId": 0,
    "departmentId": 0,
    "departmentCode": "string",
    "description": "string",
    "createdDate": "2024-11-12T12:20:36.797Z",
    "lastUpdatedDate": "2024-11-12T12:20:36.797Z",
    "createdBy": 0,
    "lastUpdatedBy": 0
  });
  const [addDepartments, setAddDepartments] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const [toast, setToast] = useState({});

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('userId');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(divisionsData || [], getComparator(order, orderBy)),
    [divisionsData, order, orderBy, page, rowsPerPage]
  );

  const fetchDivisions = async (divisionId) => {
    setLoading(true);
    try {
      const response = await getDivisionById(divisionId);
      if (!response || !response.data) {
        console.error("Unexpected response structure:", response);
        return;
      }
    } catch (error) {
      console.error("Error fetching division:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (divisionsData?.length > 0) {
      const divisionId = divisionsData[0]?.id;
      if (divisionId) {
        fetchDivisions(divisionId);
      }
    }
  }, [divisionsData]);

  const fetchDepartments = async () => {
    setLoading(true);
    const response = await getDepartmentsByOrgId("", selectedSite?.organizationId, user?.id,);
    setLoading(false);
    if (response?.statusCode === '200') {
      setAllDepartments(response?.data)
    }
  }

  useEffect(() => {
    if (selectedSite?.organizationId) {
      fetchDepartments();
    }

  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  };

  const deleteDivision = (_id) => {
    setLoading(true);
    deleteDiv(_id).then(response => {
      if (response?.statusCode === "200") {
        fetchData();
        setOpenConfirm(false);
        const toastProps = { open: true, type: "success", message: "Division deleted succesfully", onClose: () => setToast({}) };
        setToast(toastProps);
        setLoading(false);
      }
      else {
        const toastProps = { open: true, type: "error", message: response.message, onClose: () => setToast({}) };
        setToast(toastProps);
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }

  const validateFields = () => {
    let isValid = true;

    if (description == "") {
      setDescriptionError('Division Name is Required')
      isValid = false;
    } else {
      setDescriptionError('')
    }

    if (divisionName == "") {
      setDivisionNameError('Description is Required')
      isValid = false;
    } else {
      setDivisionNameError('')
    }

    return isValid;
  };


  useEffect(() => {
    if (sendData) {
      if (validateFields()) {
        saveData([
          {
            "id": 0,
            "organizationId": selectedSite?.organizationId,
            "division": divisionName,
            "description": description,
            "createdDate": new Date().toISOString(),
            "lastUpdatedDate": new Date().toISOString(),
            "createdBy": user?.id,
            "lastUpdatedBy": user?.id,
          }
        ])
      }
    }
  })




  return (
    <Box>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      <ConfirmDialog
        openConfirm={replaceConfirm}
        hidebuttons={true}
        msg={`The department "${existingDepartment?.departmentCode}" already exists in the division "${visibleRows?.find(item => item.id == existingDepartment?.divisionId)?.division}". Do you want to continue?`}
        accept={() => {
          setSave(true);
          setReplaceConfirm(false);
        }}
        reject={() => {
          setReplaceConfirm(false);
        }}
      />
      <ToastMessage toast={toast} />
      <TableContainer className="table-container-roles">

        <Table stickyHeader className='admin-roles-main-table'
          sx={{
            "& th": { padding: "0px 16px", textAlign: "left" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          aria-label='collapsible table'>

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={divisionTableColumns}
          />
          <TableBody>
            {showNewRole && (
              <>
                <TableRow key={"addnewuser"} sx={{ '& .MuiTableCell-root': { padding: '10px 16px' } }}>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <InputComponent
                      label={""}
                      value={divisionName}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => setDivisionName(e.target.value)}
                      error={divisionNameError}
                    ></InputComponent>
                  </TableCell>
                  <TableCell>
                    <InputComponent
                      label={""}
                      value={description}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => setDescription(e.target.value)}
                      error={descriptionError}
                    ></InputComponent>
                  </TableCell>
                  <TableCell align='center' className='table-cell-with-dropdown'></TableCell>
                </TableRow>
              </>
            )}
            {visibleRows?.map((row, index) => (
              <Row
                key={index}
                row={row}
                selectedSite={selectedSite}
                allDepartments={allDepartments}
                setSelectedRole={(e) => setSelectedRole(e)}
                loading={loading}
                user={user}
                setSelectedRow={setSelectedRow}
                selectedRow={selectedRow}
                setLoading={setLoading}
                replaceConfirm={replaceConfirm}
                setReplaceConfirm={setReplaceConfirm}
                save={save}
                setOpenConfirm={setOpenConfirm}
                openConfirm={openConfirm}
                setSave={setSave}
                existingDepartment={existingDepartment}
                setExistingDepartment={setExistingDepartment}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
      <ConfirmDialog openConfirm={openConfirm}
        hidebuttons={false}
        msg={`Do you want to delete ${selectedRole?.description}?`}
        accept={() => {
          deleteDivision(selectedRole?.id);
        }}
        reject={() => { setOpenConfirm(false); }}
      />

    </Box>

  );
}