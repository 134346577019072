import { Box, Dialog, DialogTitle, } from "@mui/material";
import { ButtonComponent } from "./common-field-components";
import { useTranslation } from "react-i18next";

export const InfoDialog = (props) => {
    const { openInfo, okAction, msg} = props;

    const { t } = useTranslation();

    return (
        <Dialog className="wo-confirmation-popup"
            open={openInfo}
        >
            <Box className="conformation-popup-content">
                <DialogTitle align="center">
                    {msg}
                </DialogTitle>
                <Box className="conformation-popup-buttons">
                    <Box className="no-button">
                        <ButtonComponent icon={false} label={t("OK")} disabled={false} buttoncallBack={okAction} />
                    </Box>
                   
                </Box>
            </Box>
        </Dialog >
    )
}