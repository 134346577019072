export const APIENPOINTS = {
    ADMIN: {
        SITES: {
            GET_ALL_SITES: '/admin/sites',
            PUT_UPDATE_SITES: '/admin/sites',
            EXPORT_TO_EXCEL: '/excelExport/sites'
        },
        DEVICES: {
            ADD_DEVICE: "/admin/device",
            GET_ALL_DEVICES: "/admin/devices",
            PUT_UPDATE_DEVICES: "/admin/devices",
            DELETE_DEVICE: "/admin/device/",
            SEARCH_DEVICES: "/admin/devicesSearch",
            RECOLONIZE_DEVICES: "/admin/recolonizeDevice",
            DECOLONIZE_DEVICES: "/admin/decolonizeDevice",
            GET_DEVICES_BY_ORG: "/admin/devicesByOrgCode"
        },
        PERMISSIONS: {
            GET_ALL_PERMISSION_MENUS: '/admin/permissionMenus',
            POST_ADD_PERMISSION: '/admin/permission',
            DELETE_PERMISSION: '/admin/permission',
            GET_ALL_PERMISSIONS: '/admin/permissions',
            GET_ALL_MENUS: '/admin/menus',
        },
        USERS: {
            GET_ALL_USERS: '/admin/users',
            POST_ADD_USER: '/admin/createUser',
            POST_UPDATE_USER: '/admin/updateUser',
            DELETE_USER: '/admin/deleteUser',
            GET_USER_BY_EMAIL: '/admin/getUserByEmailOrUserNameOrPhone?email=',
            SEARCH_USERS: '/admin/searchUsers'
        },
        ROLES: {
            GET_ALL_ROLES: '/admin/roles',
            POST_ADD_ROLE: '/admin/role',
            DELETE_ROLE: '/admin/role',
            GET_ROLE_PERMISSIONS: '/admin/rolePermissions',
            PUT_ROLE_PERMISSIONS: '/admin/rolePermissions'
        },
        LOCATIONS: {
            GET_ALL_LOCATIONS: '/admin/locations',
        },
        DIVISION: {
            GET_DIVISION_BY_DEPT_ID: '/admin/getDivisionDeptById',
            SAVE_DIVISION: '/admin/saveDivisions',
            SAVE_DIVISION_BY_DEPT: '/admin/saveDivisionDepts',
            DELETE_DIVISION_DEPT: '/admin/deleteDivisionDept',
            DELETE_DIVISION: '/admin/deleteDivision',
            GET_ALL_DEPARTMENTS: '/admin/getAllDepartments'
        },
        GET_ALL_MENUS: '/menus',
    },
    ASSETS: {
        GET_ASSETS_BY_SEARCH: '/assets/getAssetsBySearch',
        GET_ASSETS_BY_SEARCH_SORTING: '/assets/getAssetsBySearch',
        GET_ASSET_DETAILS_ASSET_NUMBER: '/assets/getAssetsByAssetNumber',
        GET_ALL_ASSETS: '/assets/getAllAssets',
        GET_ASSET_HIERARCHY: '/assets/getAssetHierarchyByAsset?assetNumber=',
        GET_ASSET_CoNFIG: '/assets/getAssetConfigByAssetNumber?assetNumber=',
        UPDATE_ASSET_CONFIG: '/assets/updateAssetConfig',
        GET_ASSET_ATTACHMENTS: '/assets/getAssetAttachmentsByAssetNumber',
        DOWNLOAD_ASSET_ATTACHMENT: '/assets/downloadAssetDocumentAttachment',
        TASK_VISUAL_DICTIONALY: '/assets/taskVisualDictionary',
        GET_ASSETS_BY_PARENT: '/assets/getAssetsByParent',
        UPDATE_RFID_BY_ASSETNUMBER: '/assets/updateRFIDByAssetNumber',
        DOWNLOAD_ASSETS_EXCEL: '/excelExport/assets',
    },
    MASTER: {
        GET_ASSET_CONFIGURATIONS: '/lookups/getAssetConfigurationsByAttributeAndAssetGroup',
        GET_USER_SITES: '/lookups/getUserSites?userId=',
        GET_WO_FAILURE_CODES: '/lookups/getFailureCodes?assetGroup=',
        GET_WO_CAUSE_CODES: 'lookups/getCauseCodes?failureCode=',
        GET_WO_RESOLUTION_CODE: 'lookups/getResolutionCodes?failureCode=',
        GET_WO_OPERATION_DEPARTMENTS: 'lookups/getDeptsByOrgId',
        GET_SEARCH_ASSET_GROUPS: 'lookups/searchAssetGroups',
        GET_WO_RESOURCES: 'lookups/getResourcesByOrgId',
        GET_WO_LABOR_RESOURCES: 'lookups/getLaborResourcesByOrgId',
        GET_EMPLOYEES: 'lookups/getEmployeesByResourceId',
        GET_MATERIALS: '/workorders/getWOMaterials',
        GET_ALL_DIVISIONS: '/admin/getAllDivisions'
    },
    WORKORDERS: {
        CREATE_WORKORDER: '/workorders/createWorkOrder',
        GET_WORKORDER_DETAILS_BY_WORKORDER_ID_WORKORDER: '/workorders/getWorkOrderDetailsByWorkOrderId?workOrderId=',
        GET_WORKORDERS: '/workorders/getAllWorkOrders?',
        GET_WORKORDERS_BY_SEARCH: '/workorders/getWorkOrdersBySearch?',
        GET_WORKORDERS_BY_SEARCH_SORTING: 'workorders/getWorkOrdersBySearch?',
        GET_WORKORDER_STATUS: '/lookups/getWOStatus',
        GET_WO_PROROTIES: '/lookups/getWOPriority',
        GET_WO_CLASS_CODE: '/lookups/getClassCodesByOrgId?organizationId=',
        GET_SOURCE_ASSETS: '/lookups/getSourceAssetsByOrgId',
        GET_SOURCE_ASSETS_BY_FILTER: '/lookups/getAssetsByOrgIdAndFilter',
        GET_PARENT_ASSETS_BY_FILTER: '/lookups/getParentAssetsByOrgIdAndFilter',
        GET_DOCUMENTS: '/workorders/documents?',
        GET_DOCUMENTS_BY_WORKORDERID: '/workorders/documentsByWorkOrderId?',
        DOWNLOAD_WO_DOCUMENT: '/workorders/downloadDocumentAttachment?attachmentName=',
        UPDATE_WO_STATUS: '/workorders/updateWorkOrderStatus/',
        GET_WORKING_RIGS: '/workorders/getWorkingRigs',
        DOWNLOAD_WO_EXCEL: '/excelExport/exportWorkOrders?',
        OPERATIONS: {
            GET: '/workorders/operations?',
            SAVE: '/workorders/operation',
            UPDATE: '/workorders/updateOperation',
            UPDATE_ACTUAL_FINISH: '/workorders/operation/updateActualFinish',
            DELETE: '/workorders/deleteOperationById',
            UPDATE_CONDITIONAL_VALUE: '/workorders/updateOperationConditionalValue'
        },
        LABOR: {
            GET: '/workorders/getLaborsByWipEntityId?pageNo=',
            SAVE: '/workorders/labor',
            UPDATE: '/workorders/updateLabor',
            DELETE: '/workorders/deleteLaborById?laborId=',
            GET_TASKS: '/workorders/getOperationTasksByWipentityId?wipEntityId=',
        },
        METER_READING: {
            GET: '/workorders/getMeterReadingByAssetNumberAndAssetGroupId?assetNumber=',
            UPDATE: '/workorders/updateMeterReadingValueByWorkOrderId',
        },
        FAILURE: {
            GET: '/workorders/getFailuresByWorkOrderId?workOrderId=',
            SAVE: '/workorders/saveFailure',
            DELETE: '/workorders/deleteFailureById?workOrderId=',
            UPDATE: '/workorders/updateFailure'
        },
        MATERIALS: {
            GET_MATERIALS: '/workorders/getWOMaterials',
            GET_MATERIALS_ITEM: '/lookups/getMaterialLookups',
            SAVE: '/workorders/addMaterial',
            UPDATE: '/workorders/updateMaterial',
            DELETE: '/workorders/deleteMaterial'
        },
        SUMMARY: {
            GET_WOATTRIBUTES: '/workorders/getWOAttributes',
            GET_WOLOVALUES: '/lookups/getWOLOVValues',
            GET_MFG_LOOKUPS: '/lookups/getMfgLookups',
            SAVE: '/workorders/addWoAttributes',
        }
    },
    BATCHPROCESS: {
        GENERATE_INVENTORIES: {
            GET_INVENTORY_ORGS_BY_OPERATINGUNIT: '/batchprocess/getInventoryOrgsByOperatingUnit?',
            GET_INVENTORY_ORGS_SEARCH: '/batchprocess/getInventoryOrgsSearch?',
            GET_SUB_INVENTORIES_BY_ORGS: '/batchprocess/getSubInventoriesByOrgs?',
            GET_SUB_INVENTORIES_BY_SEARCH: '/batchprocess/getSubInventoriesBySearch?',
            GET_LOCATORS_BY_ORGS_AND_SUBINVENTORIES: 'batchprocess/getLocatorsByOrgsAndSubInventories?',
            GET_LOCATORS_BY_SEARCH: '/batchprocess/getLocatorsBySearch?',
            UPDATE_INVENTORY_STATUS: '/batchprocess/updateInventoryStatus?',
            GET_GENERATE_INVENTORIES: 'batchprocess/getGenerateInventories',
            ASSIGNMENTS: {
                GET_INVENTORY_ASSIGNMENTS: 'batchprocess/getInventoryAssignments?',
                DELETE_INVENTORY_ASSIGNMENTS: 'batchprocess/deleteInventoryAssignment?',
                ADD_INVENTORY_ASSIGNMENTS: 'batchprocess/addInventoryAssignment',
                SEARCH_BY_EMPLOYEE: 'batchprocess/searchByEmployee'
            }
        },
        PROCESSMONITOR: {
            GET_BATCH_PROCESSES: '/batchprocess/getBatchProcesses?',
            GET_PROCESS_MONITOR: '/batchprocess/getProcessMonitor?'
        },
        SCHEDULE_BATCH_PROCESS: {
            GET_SCHEDULE_BATCH_PROCESSES: 'batchprocess/getScheduleBatchProcesses?',
            DELETE_SCHEDULE_BATCH_PROCESSES: 'batchprocess/deleteScheduleBatchProcesses?',
            ADD_SCHEDULE_BATCH_PROCESSES: 'batchprocess/saveScheduleBatchProcesses',
            UPDATE_SCHEDULE_BATCH_PROCESSES: 'batchprocess/updateScheduleBatchProcesses',
            SAVE_OR_UPDATE_SCHEDULE_PARAMS_PROCESS: 'batchprocess/saveOrUpdateScheduleParamsProcesses',
            DELETE_SCHEDULE_PARAMS_PROCESSES: 'batchprocess/deleteScheduleBatchParamsProcesses',
            RUN_NOW: 'batchprocess/runNow'
        },
        THIRD_PARTY_LOCATORS: {
            SAVE_PHYSICAL_LOCATION: 'batchprocess/save3pPhysicalLocation',
            SAVE_PHYSICAL_LOCATOR: 'batchprocess/save3pPhysicalLocator',
            GET_LOCATORS_BY_SEARCH: 'batchprocess/getLocatorsBySearch',
            GET_THIRDPARTY_PHYSICAL_LOCATORS: 'batchprocess/get3pPhysicalLocators',
            GET_THIRDPARTY_PHYSICAL_LOCATORS_DATA: 'batchprocess/get3pPhysicalLocatorsData',
            GET_THIRDPARTY_PHYSICAL_LOCATIONS: 'batchprocess/get3pPhysicalLocations',
            DELETE_THIRDPARTY_PHYSICAL_LOCATOR: 'batchprocess/delete3pPhysicalLocator'
        }
    },
    PLANNEDASSETINVENTORY: {
        GET_PAI_GENERATEINVENTORIES: '/pai/getPAIGenerateInventories',
        GET_PAI_ASSETDETAILS: '/pai/getPAIAssetDetails?',
        GET_PAI_ASSET_ASSIGNMENTS_SUMMARY: '/pai/getPAIAssetAndAssignmentsSummary?',
        GET_ASSET_DETAILS: '/pai/getAssetDetails/export/excel?',
        DELETE_PAI_GENERATEINVENTORIES: '/pai/deletePAIGenerateInventories',
        GET_EXECUTIVE_INVENTORY: '/pai/getAssetsForExecuteInventoriesServices',
        ADD_ASSET_TO_INVENTORY: '/pai/addAssetToInventory',
        UPDATE_PAI_ASSET_DETAILS: '/pai/updatePaiAssetsDetails',
        CHECK_PAI_ASSET_RFID: '/pai/decompositionRFID',
        VERIFY_BY_SCAN: '/pai/verifyByScan'
    },
    REPORTS: {
        LABORHOURS: {
            GET_LABORHOURS: '/reports/getLaborHours',
            FETCH_FILENAME: '/mtf/fetchFileFromS3'
        },
        GET_WO_REPORTS: '/reports/getWOHistoryReport?',
        GET_ASSET_DETAILS_REPORT: '/reports/getAssetListingReport',
        DOWNLOAD_REPORTS_EXCEL: '/excelExport/exportLaborHoursReport',
        DOWNLOAD_CONDITIONAL_HISTORY_REPORT: '/excelExport/exportConditionalHistoryHoursReport',
        CONDITIONBASEDHISTORY: {
            GET_CONDITION_BASED_HISTORY: '/reports/getConditionalHistoryHours',
            FETCH_FILENAME: '/mtf/fetchFileFromS3'
        },
        INVENTORY_REPORTS: '/reports/getInventoryReport',
        DOWNLOAD_INVENTORY_REPORTS: '/excelExport/exportInventoryReport',
        FAILURE_ANALISIS: '/reports/getFailureAnalysisReport'
    },
    WORKORDERACCESSLOGS: {
        GET_WOACCESSLOGS: '/batchprocess/getWorkOrderAccessLogs?'
    },
    EMAIL: {
        SEND_EMAIL: '/email/sendEmail'
    },
    DASHBOARD: {
        GET_DASHBOARD_DETAILS: '/dashboard/getDashboardDetails/',
        GET_DASHBOARD_DUE_WO: '/dashboard/getDashboardDueWo'
    }


}