import React, { useContext, useState, useEffect } from "react";
import {
  Box, Stack, IconButton, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Card, CardContent,
  Autocomplete,
  TextField,
  Typography,
  colors
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { DatePickerComponent, InputComponent, SelectComponent } from ".";
import { t } from "i18next";
import { tableCellClasses } from '@mui/material/TableCell';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { UserContext } from "../../../store/UserContext";
import { addMaterial, updateMaterial, getItems, deleteMaterial } from "../../../services/WorkOrderServices";
import { ConfirmDialog } from '../ConfirmDialog';
import '../index.css'
import ToastMessage from "../ToastMessage";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    minWidth: '70px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    BorderBottom: 0,
  },
  "& td:first-of-type, & th:first-of-type": {
    borderLeft: '5px solid #006CEC !important',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  },
}));

function formatDate(timestamp) {
  if (timestamp === null || timestamp === 0) {
    return "-";
  } else {
    const date = new Date(timestamp);
    const year = String(date.getUTCFullYear()); // Get the last two digits of the year
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, UTC
    const day = String(date.getUTCDate()).padStart(2, '0'); // UTC day
    return `${month}-${day}-${year}`;
  }
}
function formatEditDate(timestamp) {
  if (timestamp === null || timestamp === 0) {
    return "-";
  } else {
    const date = new Date(timestamp);
    const year = String(date.getUTCFullYear()); // Get the last two digits of the year
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, UTC
    const day = String(date.getUTCDate()).padStart(2, '0'); // UTC day
    return `${year}-${month}-${day}`;
  }
}

export default function WorkordersMaterialTableComponent(props) {
  const {
    setAddRecord, tasks, addRecord,
    materialData, clickedWorkOrder, setPage, page, rowsPerPage,
    setRowsPerPage, loading, setLoading, fetchItems, getUpdatedMaterialData
  } = props;

  const { user, screenSize, appConstants } = useContext(UserContext);
  const [deleteId, setDeleteId] = useState();
  const [toast, setToast] = useState({});
  const [editRecord, setEditRecord] = useState(false);
  const [editData, setEditData] = useState({});
  const [itemDropdown, setItemDropdown] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [itemInput, setitemInput] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);

  const validationSchema = yup.object({
    task: yup.string().trim().required("Required"),
    department: yup.string(),
    item: yup.string().trim().required("Required"),
    quantity: yup.number().required("Required").min(1, "Quantity should not be 0"),
    dateRequired: yup.date().required("Required"),
  });
  const initialDate = new Date();
  const formik = useFormik({
    initialValues: {
      id: '',
      task: '',
      departmentId: '',
      department: '',
      item: '0',
      segment1: '0',
      itemDescription: '',
      inventoryItemId: '',
      quantity: "",
      dateRequired: moment(initialDate).format("YYYY-MM-DD"),
      supplyType: '1',
      itemType: '0',
      uom: '',
      createdBy: user?.id,
      lastUpdatedBy: user?.id
    },
    onSubmit: (values) => {
      // Submission handled manually
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const items = await getItems(0, 50, clickedWorkOrder.organizationId, null);
      setItemDropdown(items);
    };

    fetchData();
  }, [clickedWorkOrder.organizationId]);

  const handleTaskChange = (e) => {
    formik.setFieldValue('task', e, false);
    const task = tasks?.find(item => item.value === e);
    formik.setFieldValue('department', task?.departmentCode || '', false);
  };


  const handleItemChange = (_item) => {
    setSelectedItem(_item);
    if (_item) {
      formik.setFieldValue('itemDescription', _item.description || '', false);
      formik.setFieldValue('segment1', _item.segment1 || '', false);
      formik.setFieldValue('itemType', _item.itemType || '', false);
      formik.setFieldValue('inventoryItemId', _item.inventoryItemId || '', false);
      formik.setFieldValue('item', _item.id || '');
    } else {
      formik.setFieldValue('itemDescription', '', false);
      formik.setFieldValue('segment1', '', false);
      formik.setFieldValue('itemType', '', false);
      formik.setFieldValue('inventoryItemId', '', false);
      formik.setFieldValue('item', '0');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 1, headerName: "Task" },
    { id: 2, headerName: "Department" },
    { id: 3, headerName: "Item" },
    { id: 4, headerName: "Item Description" },
    { id: 5, headerName: "Quantity/Amount" },
    { id: 6, headerName: "Date Required" },
    { id: 7, headerName: "Actions" },
  ];

  const deleteRecord = (id) => {
    setDeleteId(id);
    setOpenConfirm(true);
  };



  const validateFields = () => {
    let isValid = true;

    if (!formik.values.task || formik.values.task === '0' || formik.values.task === '--Select Task--') {
      formik.setFieldError('task', 'Select Task');
      isValid = false;
    } else {
      formik.setFieldError('task', '');
    }

    if (!formik.values.department) {
      formik.setFieldError('department', 'Department should not be empty');
      isValid = false;
    } else {
      formik.setFieldError('department', '');
    }

    if (!formik.values.item || formik.values.item === '0') {
      formik.setFieldError('item', 'Item should not be empty');
      isValid = false;
    } else {
      formik.setFieldError('item', '');
    }

    if (!formik.values.quantity || formik.values.quantity === '') {
      formik.setFieldError('quantity', 'Quantity should not be 0');
      isValid = false;
    } else {
      formik.setFieldError('quantity', '');
    }

    if (!formik.values.dateRequired) {
      formik.setFieldError('dateRequired', 'Date Required should not be empty');
      isValid = false;
    } else {
      formik.setFieldError('dateRequired', '');
    }

    return isValid;
  };


  const handleSaveMaterialRecord = async (_data) => {
    setLoading(true);
    try {
      const res = await addMaterial(user.token, _data);
      if (res?.statusCode === "200") {
        setToast({ open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) });
        formik.resetForm();
        onClose();
        handleClose();
        setTimeout(() => {
          getUpdatedMaterialData(); // Fetch data immediately
          setLoading(false)
        }, 3000);
      } else {
        setLoading(false);
        const errorMessage = res?.message || "";
        if (res?.statusCode === "500" && errorMessage.includes("Transaction Date is not in Open Accounting Period")) {
          const accountingDate = errorMessage.match(/:([0-9]{2}-[A-Z]{3}-[0-9]{2})/)[1]; // Extract date
          setToast({
            open: true,
            type: "error",
            message: `The transaction date must be in an open accounting period. Please choose a date within the allowed period, after ${accountingDate}.`,
            onClose: () => setToast({}),
          });
        } else if (errorMessage.includes('exists')) {
          setToast({ open: true, type: "error", message: "Material entry already exists", onClose: () => setToast({}) });
        } else {
          setToast({ open: true, type: "error", message: errorMessage || "Failed to save", onClose: () => setToast({}) });
        }
      }
    } catch (error) {
      setLoading(false);
      setToast({ open: true, type: "error", message: error?.message || "Failed to save", onClose: () => setToast({}) });
    }
  };

  const addRecordData = async () => {
    const taskValue = tasks.find(item => item?.id === formik.values.task);
    const dateRequired = moment(formik.values.dateRequired).format("YYYY-MM-DD");
    const data = {
      inventoryItemId: formik.values.inventoryItemId,
      wipEntityId: clickedWorkOrder?.wipEntityId,
      departmentId: taskValue?.departmentId,
      itemType: formik.values.itemType,
      supplyType: 1,
      opeSeqId: taskValue?.task,
      itemDescription: formik.values.itemDescription,
      orgId: clickedWorkOrder?.organizationId,
      item: formik.values.segment1,
      quantity: parseInt(formik.values.quantity, 10),
      createdBy: user?.id,
      updatedBy: user?.id,
      dateRequired: dateRequired,
      uom: ''
    };
    handleSaveMaterialRecord(data);
    getUpdatedMaterialData(data);
  };


  const updateRecordData = async () => {
    const taskValue = tasks.find(item => item.id === formik.values.task);
    const dateRequired = moment(formik.values.dateRequired).format("YYYY-MM-DD");

    const data = {
      id: formik.values.id,
      inventoryItemId: formik.values.inventoryItemId,
      wipEntityId: clickedWorkOrder?.wipEntityId,
      departmentId: taskValue?.departmentId,
      itemType: formik.values.itemType,
      supplyType: parseInt(formik.values.supplyType, 10) || 1,
      opeSeqId: taskValue?.task,
      itemDescription: formik.values.itemDescription,
      orgId: clickedWorkOrder?.organizationId,
      item: formik.values.segment1 || formik.values.item,
      quantity: parseInt(formik.values.quantity, 10),
      uom: formik.values.uom,
      updatedBy: user?.id,
      dateRequired: dateRequired,
      createdBy: user?.id,
    };

    updateMaterialRecord(data);
  };

  const updateMaterialRecord = async (_data) => {
    setLoading(true);
    try {
      const _id = _data.id;
      const res = await updateMaterial(user.token, _id, _data);
      if (res?.statusCode === "200") {
        setToast({ open: true, type: "success", message: "Updated successfully", onClose: () => setToast({}) });
        formik.resetForm();
        setTimeout(() => {
          getUpdatedMaterialData(); // Fetch data immediately
          setLoading(false)
        }, 3000);
        onClose();
      } else {
        setLoading(false);
        setToast({ open: true, type: "error", message: res?.message || "Failed to update", onClose: () => setToast({}) });
      }
    } catch (errors) {
      setLoading(false);
      setToast({ open: true, type: "error", message: errors?.message || "Failed to update", onClose: () => setToast({}) });
    }
  };
  // const deleteMaterialRecord = async (id) => {
  //   setLoading(true);
  //   try {
  //     const res = await deleteMaterial(user.token, id);
  //     if (res?.statusCode === "200") {
  //       setToast({ open: true, type: "success", message: "Deleted successfully", onClose: () => setToast({}) });
  //       await getUpdatedMaterialData();
  //     } else {
  //       setToast({ open: true, type: "error", message: res?.message || "Failed to delete", onClose: () => setToast({}) });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setToast({ open: true, type: "error", message: error?.message || "Failed to delete", onClose: () => setToast({}) });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const deleteMaterialRecord = async (id) => {
    setLoading(true);
    try {
      const res = await deleteMaterial(user.token, id);

      if (res?.statusCode === "200") {
        setToast({ open: true, type: "success", message: "Deleted successfully", onClose: () => setToast({}) });
        await getUpdatedMaterialData();
      } else {
        setToast({ open: true, type: "error", message: res?.message || "Failed to delete", onClose: () => setToast({}) });
      }
    } catch (error) {
      console.error('Error deleting material:', error); // Debugging log
      setLoading(false);
      setToast({ open: true, type: "error", message: error?.message || "Failed to delete", onClose: () => setToast({}) });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAddRecord(false);
    setEditRecord(false);
    formik.resetForm();
    setEditData({});
    setSelectedItem({});

  };

  const onClose = () => {
    setAddRecord(false);
    setEditRecord(false);
    formik.resetForm();
    setEditData({});
    setSelectedItem({});
  };

  const handleEditClick = (row) => {
    const task = tasks?.find(item => item.value === row?.operationSeqNum);

    // Create item directly from row
    const item = {
      id: row?.inventoryItemId,
      segment1: row?.concatenatedSegments,
      description: row?.itemDescription,
      itemType: row?.itemType,
      inventoryItemId: row?.inventoryItemId,
    };

    formik.setValues({
      id: row?.id || '',
      task: task?.id || '',
      department: row?.departmentCode || '',
      item: row?.inventoryItemId || '',
      segment1: row?.concatenatedSegments || '',
      itemDescription: row?.itemDescription || '',
      inventoryItemId: row?.inventoryItemId || '',
      quantity: row?.requiredQuantity || '',
      dateRequired: formatEditDate(row?.dateRequired) || '',
      supplyType: row?.supplyType || '1',
      itemType: row?.itemType || '0',
      uom: row?.uom || '',
      createdBy: user?.id,
      lastUpdatedBy: user?.id,
    });

    setSelectedItem(item); // Set the selected item for Autocomplete
    setEditRecord(true);
    setEditData(row);
    if (screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION) {
      setAddRecord(true);
    }
  };



  return (
    <Box >

      {/* Mobile Dialog for Add Record */}
      {screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION && (
        <Dialog
          open={addRecord}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" Add Material "}
          </DialogTitle>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className='mobile-card-content-main'>
              <Box className='mobile-cards-content'>
                <Box className='card-content-wo'>
                  <Card variant="outlined">
                    <CardContent>
                      {/* Using Flexbox for Each Field */}
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {/* Task Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Task
                          </Box>
                          <SelectComponent
                            values={tasks}
                            name={"task"}
                            placeholder={"Add Task"}
                            selectedvalue={formik.values?.task}
                            width={"100%"}
                            error={formik?.errors?.task}
                            parentselectcallback={handleTaskChange}
                            disabled={editRecord} // Disable when editing
                          />
                        </Box>

                        {/* Department Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Department
                          </Box>
                          <InputComponent
                            name="department"
                            value={formik.values.department}
                            disabled={true} // Always disabled
                          />
                        </Box>

                        {/* Item Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Item
                          </Box>
                          <Autocomplete
                            disabled={true} // Disable when editing
                            value={selectedItem || null} // Set the selected item object
                            onChange={(event, newValue) => {
                              handleItemChange(newValue);
                            }}
                            inputValue={itemInput || ""}
                            onInputChange={(event, newInputValue) => {
                              setitemInput(newInputValue);
                              if (newInputValue?.length > 3) {
                                fetchItems(newInputValue);
                              } else if (newInputValue?.length === 0) {
                                fetchItems(null);
                              }
                            }}
                            loading={loading}
                            getOptionLabel={(option) => {
                              const segment1 = option?.segment1 || "";
                              const description = option?.description || "";
                              return segment1 && description
                                ? `${segment1} - ${description}`
                                : `${segment1}${description}`.trim();
                            }}
                            options={props.itemData}
                            sx={{ minWidth: "200px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={"Search Items"}
                                //disabled={editRecord}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    backgroundColor: editRecord ? '#E2E4E8' : 'white',
                                  },
                                  //disabled: editRecord
                                }}
                              />
                            )}
                          />
                          {formik.touched.item && formik.errors.item && (
                            <Typography variant="caption" color="error">
                              {formik.errors.item}
                            </Typography>
                          )}
                        </Box>

                        {/* Item Description */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Item Description
                          </Box>
                          <InputComponent
                            name={"itemDescription"}
                            disabled={true} // Always disabled
                            value={formik.values?.itemDescription}
                            maxLength={"50"}
                          />
                        </Box>

                        {/* Quantity Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Quantity
                          </Box>
                          <InputComponent
                            name="quantity"
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            error={formik?.errors?.quantity}
                            disabled={false}
                          />
                        </Box>

                        {/* Date Required Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ fontWeight: 'bold' }}>
                            Date
                          </Box>
                          <DatePickerComponent
                            name={"dateRequired"}
                            onDateSelect={(e) => formik.setFieldValue('dateRequired', e, true)}
                            value={formik.values?.dateRequired}
                            maxLength={"50"}
                            error={formik.errors?.dateRequired}
                            disabled={false} // Always enabled in edit mode
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
          </DialogContent>


          <DialogActions>
            <Button variant='contained' color="error" onClick={handleClose} autoFocus sx={{ color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button variant='contained' color="success"
              onClick={() => {
                if (validateFields()) {
                  addRecordData();
                }
              }} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Main Table */}
      <TableContainer sx={{ transition: "height 100ms ease-in-out", maxHeight: window.innerHeight - 288, width: "100%" }} className='wo-operations-table-container'>
        <Table
          sx={{
            minWidth: 700,
            '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
            '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important", height: '20px !important' },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black",
              fontWeight: 600
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black",
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          stickyHeader
          size="small"
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow style={{ height: 10 }}>
              {/* Confirm Delete Dialog */}
              <ConfirmDialog
                openConfirm={openConfirm}
                msg={'Do you want to delete the Material entry?'}
                accept={() => {
                  deleteMaterialRecord(deleteId);
                  setOpenConfirm(false);
                }}
                reject={() => setOpenConfirm(false)}
              />
              {/* Render Column Headers */}
              {columns?.map((column, index) => (
                <StyledTableCell key={index}>{t(column.headerName)}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Editable Row for Add/Edit */}
            {(addRecord || editRecord) && screenSize?.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION && (
              <StyledTableRow>
                {/* Task Field */}
                <StyledTableCell>
                  <SelectComponent
                    values={tasks}
                    name={"task"}
                    selectedvalue={formik.values?.task}
                    width={"100px"}
                    error={formik?.errors?.task}
                    parentselectcallback={handleTaskChange}
                    disabled={editRecord} // Disable when editing
                  />
                </StyledTableCell>

                {/* Department Field */}
                <StyledTableCell>
                  <InputComponent
                    name="department"
                    value={formik.values.department}
                    disabled={true} // Always disabled
                  />
                </StyledTableCell>

                {/* Item Field */}
                <StyledTableCell>
                  <Autocomplete
                    disabled={editRecord}
                    value={selectedItem || null}
                    onChange={(event, newValue) => {
                      handleItemChange(newValue);
                      formik.setFieldValue('item', newValue ? newValue.id : '', true);
                      // formik.setFieldTouched('item', true, true);
                    }}
                    inputValue={itemInput || ""}
                    onInputChange={(event, newInputValue) => {
                      setitemInput(newInputValue);
                      if (newInputValue?.length > 3) {
                        fetchItems(newInputValue);
                      } else if (newInputValue?.length === 0) {
                        fetchItems(null);
                      }
                    }}
                    loading={loading}
                    getOptionLabel={(option) => {
                      const segment1 = option?.segment1 || "";
                      const description = option?.description || "";
                      return segment1 && description
                        ? `${segment1} - ${description}`
                        : `${segment1}${description}`.trim();
                    }}
                    options={props.itemData}
                    sx={{ minWidth: "300px", marginTop: '8px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Search Items"}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            backgroundColor: editRecord ? '#E2E4E8' : 'white',
                          },
                        }}
                      />
                    )}

                  />

                  {formik.errors.item && (
                    <Box mt={0.5}>
                      <Typography color="red" fontSize={'13px'}>
                        {formik.errors.item}
                      </Typography>
                    </Box>
                  )}


                </StyledTableCell>

                {/* Item Description Field */}
                <StyledTableCell>
                  <InputComponent
                    name={"itemDescription"}
                    width={"50"}
                    disabled={true} // Always disabled
                    value={formik.values?.itemDescription}
                    maxLength={"50"}
                  />
                </StyledTableCell>

                {/* Quantity Field */}
                <StyledTableCell>
                  <InputComponent
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={formik?.errors?.quantity}
                    disabled={false}
                  />
                </StyledTableCell>

                {/* Date Required Field */}
                <StyledTableCell>
                  <DatePickerComponent
                    name={"dateRequired"}
                    onDateSelect={(e) => formik.setFieldValue('dateRequired', e, true)}
                    value={formik.values?.dateRequired}
                    maxLength={"50"}
                    error={formik.errors?.dateRequired}
                    disabled={false} // Always enabled in edit mode
                  />


                </StyledTableCell>

                {/* Actions Field */}
                <StyledTableCell className="action-button-cell" align='center'>
                  <Box className='action-buttons'>
                    <Stack direction={"row"} gap={'10px'}>
                      <SaveIcon
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                        onClick={() => {
                          if (validateFields()) {
                            if (editRecord) {
                              updateRecordData();
                            } else {
                              addRecordData();
                            }
                          }
                        }}
                      />
                      <CancelIcon
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                        onClick={() => {
                          onClose()
                        }}
                      />
                    </Stack>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}

            {/* Render Material Data Rows */}
            {materialData && materialData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                editData && editData?.id !== row?.id && (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row?.operationSeqNum}</StyledTableCell>
                    <StyledTableCell>{row?.departmentCode}</StyledTableCell>
                    <StyledTableCell sx={{ '&.MuiTableCell-root': { padding: "6px 12px" } }} >{row?.concatenatedSegments}</StyledTableCell>
                    <StyledTableCell>{row?.itemDescription}</StyledTableCell>
                    <StyledTableCell>{row?.requiredQuantity}</StyledTableCell>
                    <StyledTableCell>{formatDate(row?.dateRequired)}</StyledTableCell>
                    <StyledTableCell>
                      <Stack direction={"row"} gap={'1px'}>
                        <IconButton
                          disabled={(clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6)}
                          onClick={() => handleEditClick(row)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          disabled={(clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6)}
                          onClick={() => {
                            deleteRecord(row?.id);
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>


                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              ))
            }
          </TableBody>
        </Table>

      </TableContainer>
      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={materialData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
      <ToastMessage toast={toast} />

    </Box>
  );
}
