import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { Grid, TableSortLabel, TextField } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InputComponent, SelectComponent } from ".";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { t } from "i18next";
import { UserContext } from "../../../store/UserContext";
import { useTranslation } from "react-i18next";

export const userTableColumns = [
  {
    field: "collapse",
    headerName: "",
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "templateName",
    headerName: "TEMPLATE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "description",
    headerName: "DESCRIPTION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "sender",
    headerName: "SENDER",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "emailAddress",
    headerName: "EMAIL_ADDRESS",
    width: 100,
    headerClassName: "super-app-theme--header",
  }
];


const priorityDropdownValues = [
  { id: 1, value: "Low" },
  { id: 2, value: "Medium" },
  { id: 3, value: "High" },
  { id: 4, value: "Critical" },
];

const validationSchema = yup.object({
  templateName: yup.string().required("Required"),
  description: yup.string().required("Required"),
  priority: yup.string().required("Required"),
  instructionalText: yup
    .string()
    .required('Required'),
  sender: yup.string().email('Not a valid email').nullable(),
  emailAddress: yup.string().email('Not a valid email').nullable(),
  subject: yup.string().required("Required"),
  messageText: yup.string().required("Required"),
});


function ExpandCollapseTableCell({ handleChange, errors, disabled, row = { priority: null, subject: null, instructionalText: null, messageText: null } }) {
  const isValueMatched: any = row.priority && priorityDropdownValues.filter(dropdown => dropdown.value === row.priority)
  const priorityValue = (isValueMatched && isValueMatched?.length > 0) ? isValueMatched[0]?.id : null
  const [priority, setPriority] = useState(priorityValue);

  return (
    <Box className='collapsable-table-box-main'>
      <Box className='collapsable-table-box-sub'>
        <Box className='Notify-grid-main-pc'>
          <Grid item xs={12} sm={6} className='notify-table'>
            <TableContainer>
              <Table
                sx={{
                  "& .MuiTableHead-root": {
                    color: "#053ead",
                  },
                  "& .MuiTableCell-head ": {
                    color: "#053ead",
                    borderBottom: "none",
                    padding: "10px",
                    backgroundColor: "white !important",
                  },
                  "& .MuiTableCell-body ": { padding: "10px 16px", verticalAlign: "baseline" },
                  "& .css-34nofg-MuiTableRow-root:last-child th ": {
                  },
                  "& .css-34nofg-MuiTableRow-root td": { backgroundColor: "#F3F4F5" },
                }}
              >
                <TableBody>
                  <TableRow>
                    <Box width="100%" className="notification-collapsible-tablerow-box-main">
                      <Box className="notification-collapsible-tablerow-box-sub">
                        <TableCell component='th' scope='column'>
                          {t("PRIORITY")}
                        </TableCell>
                        <TableCell scope='column'>
                          <SelectComponent
                            values={priorityDropdownValues}
                            name={"priority"}
                            selectedvalue={priority}
                            disabled={disabled}
                            width={"100%"}
                            error={errors?.priority}
                            parentselectcallback={(e) => {
                              setPriority(e);
                              handleChange(e);
                            }}
                          />
                        </TableCell>
                      </Box>
                      <Box className="notification-collapsible-tablerow-box-sub">
                        <TableCell component='th' scope='column'>
                          {t("SUBJECT")}
                        </TableCell>
                        <TableCell align='left'>
                          <InputComponent
                            disabled={disabled}
                            width={"100%"}
                            name={"subject"}
                            value={row.subject}
                            onChange={handleChange}
                            error={errors?.subject}
                          />
                        </TableCell>
                      </Box>
                    </Box>
                  </TableRow>
                  <TableRow>
                    <Box className="notification-collapsible-tablerow-box-main">
                      <Box className="notification-collapsible-tablerow-box-sub">
                        <TableCell component="th" className='admin-notify-tablecell' align='left'>
                          {t("INSTRUCTIONAL_TEXT")}
                        </TableCell>
                        <TableCell className='admin-notify-tablecell' align='left'>
                          <Box className='admin-notify-tablecel'>
                            <TextField
                              id="outlined-multiline-static"
                              name="instructionalText"
                              multiline
                              rows={6}
                              defaultValue={row.instructionalText}
                              variant="outlined"
                              onChange={handleChange}
                              helperText={errors?.instructionalText}
                              disabled={disabled}
                            />
                          </Box>
                        </TableCell>
                      </Box>
                      <Box className="notification-collapsible-tablerow-box-sub">
                        <TableCell component='th' scope='column'>
                          {t("MESSAGE_TEXT")}
                        </TableCell>
                        <TableCell align='left'>
                          <Box className='admin-notify-tablecel'>
                            <TextField
                              id="outlined-multiline-static"
                              name="messageText"
                              multiline
                              rows={6}
                              defaultValue={row.messageText}
                              variant="outlined"
                              onChange={handleChange}
                              helperText={errors?.messageText}
                              disabled={disabled}
                            />
                          </Box>
                        </TableCell>
                      </Box>
                    </Box>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

function InputFormRow({ submit, setSubmit, handleSave }) {
  const { user } = useContext(UserContext);

  const [errors, setErrors] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      templateName: null,
      description: null,
      priority: null,
      instructionalText: null,
      sender: null,
      emailAddress: null,
      subject: null,
      messageText: null,
      createdBy: user.name,
      lastUpdatedBy: user.name
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  const handleChange = (e) => {
    if (typeof e === 'number') {
      const selectedvalue = priorityDropdownValues.find(dropdown => dropdown.id === e);
      formik.setFieldValue("priority", selectedvalue?.value, false);
    } else {
      formik.setFieldValue(e.target.name, e.target.value, false);
    }
  }

  useEffect(() => {
    if (submit) {
      formik.validateForm().then((result) => {
        setErrors({ ...result })
        setSubmit(!submit)
        if (Object.keys(result).length === 0) {
          handleSave(formik.values)
        }
      })
    }
  }, [submit])


  return (
    <React.Fragment>
      <TableRow className="notification-template-table-add-row">
        <TableCell className='Notify-collapsible-button-cell'>
          {""}
        </TableCell>
        <TableCell sx={{ minWidth: "210px" }}>
          <InputComponent name={"templateName"} disabled={false} width={"100%"}
            onChange={handleChange} error={errors?.templateName} />
        </TableCell>
        <TableCell sx={{ minWidth: "210px" }}>
          <InputComponent name={"description"} disabled={false} width={"100%"}
            onChange={handleChange} error={errors?.description} />
        </TableCell>
        <TableCell sx={{ minWidth: "210px" }}>
          <InputComponent name={"sender"} disabled={false} width={"100%"}
            onChange={handleChange} error={errors?.sender} />
        </TableCell>
        <TableCell sx={{ minWidth: "210px" }}>
          <InputComponent name={"emailAddress"} disabled={false} width={"100%"}
            onChange={handleChange} error={errors?.emailAddress} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <ExpandCollapseTableCell handleChange={handleChange} errors={errors} disabled={false} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function DisplayRow({ row, submit, handleSave, setExistingRecord }) {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const formik = useFormik({
    initialValues: {
      notificationTemplatesId: row.notificationTemplatesId,
      templateName: row.templateName,
      description: row.description,
      priority: row.priority,
      instructionalText: row.instructionalText,
      sender: row.sender,
      emailAddress: row.emailAddress,
      subject: row.subject,
      messageText: row.messageText,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  const handleChange = (e) => {
    if (typeof e === 'number') {
      const selectedvalue = priorityDropdownValues.find(dropdown => dropdown.id === e);
      formik.setFieldValue("priority", selectedvalue?.value, false);
    } else {
      formik.setFieldValue(e.target.name, e.target.value, false);
    }
  }

  useEffect(() => {
    if (submit && open) {
      formik.validateForm().then((result) => {
        setErrors({ ...result })
        if (Object.keys(result).length === 0) {
          handleSave(formik.values)
          setOpen(!open)
        }
      })
    }
  }, [submit])



  return (
    <React.Fragment>
      <TableRow className='admin-notfiy-table-row' >
        <TableCell className='Notify-collapsible-button-cell' >
          <IconButton aria-label='expand row' size='small' onClick={() => {
            setOpen(!open)
            setExistingRecord(true)
          }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {t(row.templateName)}
        </TableCell>
        <TableCell align='right'>{row.description}</TableCell>
        <TableCell align='right'>{row.sender}</TableCell>
        <TableCell align='right'>{row.emailAddress}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <ExpandCollapseTableCell row={row} handleChange={handleChange} errors={errors} disabled={true} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AxNotificationsTempTableComponent(props: any) {
  const { showNewTemplate, submit, setSubmit, handleSave, setExistingRecord,
    page, setPage, rowsPerPage, setRowsPerPage, data } = props;

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('userId');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data?.content || [], getComparator(order, orderBy)),
    [data, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <TableContainer className='admin-notify-main-table'>
        <Table stickyHeader aria-label='collapsible table'
          sx={{
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "white"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "white"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "white"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "white", opacity: 100
            }
          }}
        >
         
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={userTableColumns}
          />
          <TableBody>

            {showNewTemplate &&
              <InputFormRow
                submit={submit}
                setSubmit={setSubmit}
                handleSave={handleSave}
              />
            }

            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
              <DisplayRow key={row.notificationTemplatesId}
                row={row}
                submit={submit}
                handleSave={handleSave}
                setExistingRecord={setExistingRecord} />
            ))}

          </TableBody>
        </Table>
      </TableContainer >
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

  );
}
