import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { SelectComponent } from ".";
import { Box, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import '../index.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    whiteSpace:'noWrap',
    

  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  "&:last-child td, &:last-child th": {
    BorderBottom: 0,

  },
  "& td:first-of-type, & th:first-of-type": {
    borderLeft: '5px solid #006CEC !important',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  },
}));



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead >
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <StyledTableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function AxSiteTableComponent(props) {
  const { columns, clearDataToSave, data, page, setPage, rowsPerPage, setRowsPerPage, setClearDataToSave } = props;

  const [editingSiteIds, setEditingSiteIds] = useState<any>([]);
  const [dataTosave, setDataToSave] = useState<any>([]);


  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('orgCode');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data?.content || [], getComparator(order, orderBy)),
    [data?.content, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/


  useEffect(() => {
    if (!clearDataToSave) {
      setEditingSiteIds([]);
    }
  }, [clearDataToSave])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const captureUpdatedValues = (id, fieldName, value) => {
    let currentRowData = dataTosave.find(x => x.siteId === id);
    if (currentRowData) {
      currentRowData[fieldName] = value;
      setDataToSave([...dataTosave.filter(x => x.siteId !== id), currentRowData]);
    } else {
      currentRowData = data.content.find(x => x.siteId === id);
      currentRowData[fieldName] = value;
      setDataToSave([...dataTosave.concat([currentRowData])]);
    }
  }

  const getTableCells = (row) => {
    return <>
      <StyledTableCell sx={{ borderBottomLeftRadius: '5px' }}>{row.orgCode}</StyledTableCell>
      <StyledTableCell>{row.operatingUnit}</StyledTableCell>
      <StyledTableCell>{row.orgName}</StyledTableCell>
      <StyledTableCell>{row.location}</StyledTableCell>
      {editingSiteIds.includes(row.siteId) ?
        <>
          <StyledTableCell>
            <SelectComponent
              values={[{ id: 1, value: "Yes" }, { id: 2, value: "No" }]}
              selectedvalue={row.isOperatingUnit === "No" ? 2 : 1}
              disabled={false}
              width={"100%"}
              parentselectcallback={(_val) => {
                _val = _val === 1 ? "Yes" : "No";
                captureUpdatedValues(row.siteId, "isOperatingUnit", _val);
              }}
            />
          </StyledTableCell>
          <StyledTableCell>
            <SelectComponent
              values={[{ id: 1, value: "Yes" }, { id: 2, value: "No" }]}
              selectedvalue={row.isInventoryOrg === "No" ? 2 : 1}
              disabled={false}
              width={"100%"}
              parentselectcallback={(_val) => {
                _val = _val === 1 ? "Yes" : "No";
                captureUpdatedValues(row.siteId, "isInventoryOrg", _val);
              }}
            />
          </StyledTableCell>
          <StyledTableCell>
            <SelectComponent
              values={[{ id: 1, value: "True" }, { id: 2, value: "False" }]}
              selectedvalue={row.active ? 1 : 2}
              disabled={false}
              width={"100%"}
              parentselectcallback={(_val) => {
                _val = _val === 1 ? "True" : "False";
                captureUpdatedValues(row.siteId, "active", _val);
              }}
            />
          </StyledTableCell>
        </>
        :
        <>
          <StyledTableCell>{row.isOperatingUnit}</StyledTableCell>
          <StyledTableCell>{row.isInventoryOrg}</StyledTableCell>
          <StyledTableCell>{row.active ? 'True' : 'False'}</StyledTableCell>
        </>
      }
    </>
  }


  useEffect(() => {
    props.setDataToSave(dataTosave);
  }, [dataTosave])

  return (
    <Box>
      <TableContainer className='table-container-sites'>
        <Table
          sx={{
            minWidth: 700,
            "& .MuiTableCell-head": {
              backgroundColor: "#E6E6E6 !important", color: "black !important"
            },
            "& .css-cokgfw-MuiTableCell-root.MuiTableCell-head": { width: "50px !important" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          stickyHeader
          size='small'
          aria-label='sticky table'
        >

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          <TableBody>
            {visibleRows?.map((row, index) => (
              <StyledTableRow key={index} >
                <StyledTableCell align='center' className="edit-icon-cell">
                  <ModeEditOutlineOutlinedIcon fontSize="medium"
                    onClick={() => {
                      props.showSave();
                      setEditingSiteIds([...editingSiteIds, row.siteId]);
                      setClearDataToSave(!clearDataToSave);
                    }}>
                  </ModeEditOutlineOutlinedIcon>
                </StyledTableCell>
                {getTableCells(row)}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'

      />
    </Box>

  );
}
