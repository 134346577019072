import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box, TableSortLabel,  } from '@mui/material';
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
             color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',       
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        {headCell.sortable ?
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {t(headCell.headerName)}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel> : (t(headCell.headerName))
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function AxInventoryReports(props) {

    const { t } = useTranslation();
    const { columns, data, page, setPage } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(25);



    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('assetNumber');
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [data, order, orderBy, page, rowsPerPage]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box className="wo-pc-tablet-tabs" >
                <TableContainer className='inv-reports-table'>
                    <Table sx={{
                        minWidth: 700,
                        '& .MuiTableCell-head': { backgroundColor: "#2A3547 !important", color: "white !important" },

                        "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                            color: "white"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                            color: "white"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                            color: "white"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                            color: "white", opacity: 100
                        }
                    }} stickyHeader size="small" aria-label="sticky table">
                       
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={columns}
                        />
                        <TableBody>
                            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <TableRow key={index} >
                                    <StyledTableCell>{row?.rig}</StyledTableCell>
                                    <StyledTableCell>{row?.paiName}</StyledTableCell>
                                    <StyledTableCell>{row?.asset}</StyledTableCell>
                                    <StyledTableCell>{row?.description}</StyledTableCell>
                                    <StyledTableCell>{row?.manufacturer}</StyledTableCell>
                                    <StyledTableCell>{row?.modelNumber}</StyledTableCell>
                                    <StyledTableCell>{row?.manufacturerSerial}</StyledTableCell>
                                    <StyledTableCell>{row?.creationDate}</StyledTableCell>
                                    <StyledTableCell>{row?.status == "Incomplete" ? "Force Complete" : row?.status}</StyledTableCell>
                                    <StyledTableCell>{row?.handhledScan}</StyledTableCell>
                                    <StyledTableCell>{row?.verifiedLocation}</StyledTableCell>
                                    <StyledTableCell>{row?.inventoryId}</StyledTableCell>
                                    <StyledTableCell>{row?.verifiedDate}</StyledTableCell>
                                    <StyledTableCell>{row?.locator}</StyledTableCell>
                                    <StyledTableCell>{row?.rfidTag}</StyledTableCell>
                                    <StyledTableCell>{row?.dateOfLastScan}</StyledTableCell>
                                    <StyledTableCell>{row?.scannedBy}</StyledTableCell>
                                    <StyledTableCell>{row?.notes}</StyledTableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>

                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={props?.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>



        </Box>
    );
}