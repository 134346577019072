export const workOrderGridColumns = [
  {
    field: "workOrderId",
    headerName: "WORKORDER",
    width: 120,
    headerClassName: "super-app-theme--header MuiDataGrid-pinnedColumns--left-header",
  },
  {
    field: "briefDescription",
    headerName: "DESCRIPTION",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  { field: "instanceNumber", headerName: "Asset", width: 75, headerClassName: "super-app-theme--header" },
  {
    field: "assetDescription",
    headerName: "ASSET_DESCRIPTION",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "statusType",
    headerName: "STATUS",
    width: 75,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "priority",
    headerName: "PRIORITY",
    width: 75,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "classCode",
    headerName: "Class Code",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "woTypeDesc",
    headerName: "WorkOrder Type",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "releaseDate",
    headerName: "RELEASE_DATE",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "scheduledComplete",
    headerName: "Scheduled Finish",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "createdDate",
    headerName: "CREATE_DATE",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "actualStart",
    headerName: "ACTUAL_START",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "actualFinish",
    headerName: "ACTUAL_FINISH",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  
];
