import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box, TableSortLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import { UserContext } from '../../../store/UserContext';
import '../index.css'




export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        {headCell.field !== "buttons" &&
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {t(headCell.headerName)}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export default function WoMetersTableComponent(props) {

    const { t } = useTranslation();
    const { columns, data } = props;
    const { screenSize, appConstants } = useContext(UserContext);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('task');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Box>
            <TableContainer className='wo-meters-table-container' sx={{ width: screenSize?.dynamicWidth < appConstants.MOBILE_RESOLUTION ? window.innerWidth : "100%" }}>
                <Table sx={{
                    minWidth: 700,
                    '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                    '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                        color: "white"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                        color: "white"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                        color: "white"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                        color: "white", opacity: 100
                    }
                }} stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow style={{ height: 10 }}>
                            {columns.map((column, index) => {
                                return <StyledTableCell key={index}>
                                    {t(column.headerName)}
                                </StyledTableCell>
                            })}
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {data && data.length > 0 ? (
                            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{row?.requirement}</StyledTableCell>
                                    <StyledTableCell>{row?.measurement}</StyledTableCell>
                                    <StyledTableCell>{row?.newValue}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>




                </Table>

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='customTablePagination'

            />
        </Box>
    );
}