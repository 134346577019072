import { Box, Button, Drawer, IconButton, Stack, Toolbar, Typography, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { EAMAssetListingTableComponent, } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { Autocomplete, Checkbox } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { getAssetListingReport } from "../../services/ReportServices";
import { getDepartmentsByOrgId } from "../../services/WorkOrderServices";
import { getsearchAssetGroups } from "../../services/WorkOrderServices";
import { exportToExcel } from "../../services/ExcelExportService";
import IosShareIcon from '@mui/icons-material/IosShare';
const eamAssetListingColumns = [
    {
        field: "assetNumber",
        headerName: "Asset Number",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "category",
        headerName: "Category Code",
        width: 120,
        headerClassName: "super-app-theme--header MuiDataGrid-pinnedColumns--left-header",
    },
    {
        field: "parentInstanceNumber",
        headerName: "Parent Asset Number",
        width: 200,
        headerClassName: "super-app-theme--header",
    },

    {
        field: "assetGroupDescription",
        headerName: "Asset Group Description",
        width: 75,
        headerClassName: "super-app-theme--header"
    },
    {
        field: "desription",
        headerName: "Description",
        width: 150,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "manufacturer",
        headerName: "Manufacturer",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "model",
        headerName: "Model Number",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "manufacturerSerialNumber",
        headerName: "Serial Number",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "subInventory",
        headerName: "Department",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "locator",
        headerName: "Location",
        width: 75,
        headerClassName: "super-app-theme--header",
    },


];


export default function EAMAssetListing() {
    const { userSitesData, selectedSite, oktaAuth, smallScreens, largeScreens, user } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const [searchFilter, setSearchFilter] = useState({ organizationIds: '', department: '', departmentCodeInput: '', assetGroup: '', assetGroupsInput: '', userId: '', sortBy: '', sortParameter: '' })
    const [showFilterFields, setShowFilterFields] = useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [assetGroups, setAssetGroups] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const getSelectedOrg = userSitesData?.find(item => item?.orgId === selectedSite?.organizationId);
    const [selectedOrg, setSelectedOrg] = useState(getSelectedOrg || []);
    const [selectedAssetGroup, setSelectedAssetGroup] = useState([]);

    const excelExport = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('organizationIds', selectedSite?.organizationId);
        const departmentParams = selectedDepartment?.map(item => item?.departmentId) || ''
        const assetGroupParams = selectedAssetGroup?.map(item => item?.assetGroup) || ''
        if (Array.isArray(departmentParams)) {
            departmentParams?.forEach(value => {
                urlParams.append('departmentIds', value);
            });
        } else if (departmentParams !== '') {
            urlParams.append('assetGroup', departmentParams);
        }
        if (Array.isArray(assetGroupParams)) {
            assetGroupParams?.forEach(value => {
                urlParams.append('assetGroup', value);
            });
        } else if (assetGroupParams !== '') {
            urlParams.append('departmentIds', assetGroupParams);
        }
        if (user?.id) {
            urlParams.append('userId', user?.id);
        }
        if (searchFilter?.sort && searchFilter?.sort !== "") {
            urlParams.append('sort', searchFilter?.sort);
        }
        if (searchFilter?.parameter && searchFilter?.parameter !== "") {
            urlParams.append('parameter', searchFilter?.parameter);
        }

        const url = `/excelExport/getAssetListingReport?${urlParams.toString()}`;
        exportToExcel(url);
    };

    const isClear = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchAssetData = async () => {
        setLoading(true);
        setTableData([]);
        const params = {
            page: page,
            size: rowsPerPage,
            organizationIds: selectedOrg?.orgId || selectedSite?.organizationId,
            department: (selectedDepartment?.map(item => item?.departmentId))?.toString() || '',
            assetGroup: (selectedAssetGroup?.map(item => item?.assetGroup))?.toString() || '',
            userId: user?.id,
            sort: searchFilter?.sort || '',
            parameter: searchFilter?.parameter || ''
        };

        // Convert arrays to comma-separated strings
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) {
                params[key] = params[key].join(',');
            }
        });

        Object.keys(params).forEach(key => {
            if (params[key] === '' || params[key] === ',') {
                delete params[key];
            }
        });
        const response = await getAssetListingReport(params);
        setLoading(false);
        if (response?.statusCode === '200') {
            setTableData(response?.data?.content);
            setTotalElements(response?.data?.totalElements);
        }
    }
    const fetchDepartments = async () => {
        setLoading(true);
        const response = await getDepartmentsByOrgId("", selectedSite?.organizationId, user?.id);
        setLoading(false);
        if (response?.statusCode === '200') {
            setDepartments(response?.data)
        }
    }

    const fetchsearchAssetGroups = async (_division, _keyword) => {
        setLoading(true);
        const params = {
            orgId: selectedOrg?.orgId || selectedSite?.organizationId,
            userId: user?.id,
            division: '',
            keyword: _keyword
        };

        // Convert arrays to comma-separated strings
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) {
                params[key] = params[key].join(',');
            }
        });

        Object.keys(params).forEach(key => {
            if (params[key] === '' || params[key] === ',') {
                delete params[key];
            }
        });
        try {
            const response = await getsearchAssetGroups(params);
            setLoading(false);
            if (response && response?.statusCode === '200') {
                setAssetGroups(response?.data || []);
            }
        } catch (error) {
            console.log("Error fetching asset groups:", error);
            setLoading(false);
        }
    };

    const clearFilters = async () => {
        isClear.current = true;
        setSearch(false);
        setSearchFilter({ organizationIds: '', department: '', departmentCodeInput: '', assetGroup: '', assetGroupsInput: '', userId: '', sortBy: '', sortParameter: '' });
        setSelectedDepartment([]);
        setSelectedAssetGroup([]);
        setSelectedOrg(getSelectedOrg || []);
        setTableData([]);
        setPage(0);
        setRowsPerPage(25);
    }

    useEffect(() => {
        if (isClear.current === true) {
            setSearch(false);
            setShowSideBar(!showSideBar)
            isClear.current = false;

        }
    }, [searchFilter])

    useEffect(() => {
        if (selectedSite && searchFilter && search) {
            setSearch(false);
            fetchAssetData();
        }
    }, [page, rowsPerPage, searchFilter.sortBy, searchFilter.parameter]);

    useEffect(() => {
        if (selectedSite?.organizationId) {
            fetchDepartments()
            fetchsearchAssetGroups()
        }
    }, [])

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                <Box className={largeScreens ? 'homePageContentWrapper' : 'mobileHomePageContentWrapper'}>
                    <Box className='homePageHeader'>
                        <Typography variant='h5' flexGrow={1}>
                            Asset Details
                        </Typography>
                        <Stack direction={'row'}>
                            <IconButton onClick={() => largeScreens ? setShowFilterFields(!showFilterFields) : setShowSideBar(!showSideBar)}>
                                {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                            </IconButton>

                            <IconButton onClick={excelExport}>
                                <IosShareIcon style={{ fontSize: "20" }} />
                            </IconButton>

                        </Stack>
                    </Box>
                    {
                        smallScreens ?
                            <Drawer
                                anchor="right"
                                open={showSideBar}
                                onClose={() => setShowSideBar(!showSideBar)}
                                PaperProps={{ style: { width: '300px' } }} >
                                <Box className='drawerContent' padding={'10px 15px'}>
                                    <Toolbar />
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                        <Typography variant='boldBody' color={'black !important'}>Filters</Typography>
                                        <IconButton sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }} onClick={() => setShowSideBar(!showSideBar)}>
                                            <FilterAltOffOutlinedIcon />
                                        </IconButton>
                                        <IconButton onClick={excelExport}>
                                            <IosShareIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack direction={'column'} gap={'10px'}>
                                        {userSitesData && userSitesData.length > 0 && (
                                            <Stack width="100%">
                                                <Typography>
                                                    {'Org'}
                                                </Typography>
                                                <Autocomplete
                                                    value={selectedOrg || []}
                                                    onChange={(event, newValue) => {
                                                        setSelectedOrg(newValue);
                                                    }}
                                                    inputValue={searchFilter?.OrgInput || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setSearchFilter({ ...searchFilter, OrgInput: newInputValue });
                                                    }}
                                                    limitTags={1}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.orgName || ''}
                                                    options={
                                                        userSitesData
                                                            .filter(item => item.id !== 0)
                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                    }
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disabled={false}
                                                />
                                            </Stack>
                                        )}
                                        <Stack width="100%">
                                            <Typography>
                                                {'Department'}
                                            </Typography>
                                            <Autocomplete
                                                value={selectedDepartment || []}
                                                onChange={(event, newValue) => {
                                                    setSelectedDepartment(newValue);
                                                }}
                                                multiple
                                                inputValue={searchFilter?.departmentCodeInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, departmentCodeInput: newInputValue });
                                                }}
                                                limitTags={5}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.departmentCode || ''}
                                                options={
                                                    departments?.sort((a, b) => a.departmentCode.localeCompare(b.departmentCode))
                                                }
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.departmentCode}
                                                    </li>
                                                )}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography>
                                                {'Asset Group Description'}
                                            </Typography>
                                            <Autocomplete
                                                value={selectedAssetGroup || []}
                                                onChange={(event, newValue) => {
                                                    setSelectedAssetGroup(newValue);
                                                }}
                                                multiple
                                                inputValue={searchFilter?.assetGroupsInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, assetGroupsInput: newInputValue });
                                                    if (newInputValue?.length > 3) {
                                                        fetchsearchAssetGroups("", newInputValue);
                                                    }
                                                }}
                                                limitTags={5}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.assetGroupDescription || ''}
                                                options={
                                                    assetGroups?.sort((a, b) => a.assetGroup.localeCompare(b.assetGroup))
                                                }
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.assetGroupDescription}
                                                    </li>
                                                )}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        <Button variant='contained' onClick={() => { fetchAssetData(); setShowSideBar(!showSideBar) }}>Search</Button>
                                        <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                    </Stack>
                                </Box>
                            </Drawer>
                            :
                            <Box className='homePageFilters'>
                                {showFilterFields && (
                                    <Box className='filters-lg'>
                                        {userSitesData && userSitesData.length > 0 && (
                                            <Stack width="100%">
                                                <Typography>
                                                    {'Org'}
                                                </Typography>
                                                <Autocomplete
                                                    value={selectedOrg || []}
                                                    onChange={(event, newValue) => {
                                                        setSelectedOrg(newValue);
                                                    }}
                                                    inputValue={searchFilter?.OrgInput || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setSearchFilter({ ...searchFilter, OrgInput: newInputValue });
                                                    }}
                                                    limitTags={1}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.orgName || ''}
                                                    options={
                                                        userSitesData
                                                            .filter(item => item.id !== 0)
                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                    }
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disabled={false}
                                                />
                                            </Stack>
                                        )}
                                        <Stack width="100%">
                                            <Typography>
                                                {'Department'}
                                            </Typography>
                                            <Autocomplete
                                                value={selectedDepartment || []}
                                                onChange={(event, newValue) => {
                                                    setSelectedDepartment(newValue);
                                                }}
                                                multiple
                                                inputValue={searchFilter?.departmentCodeInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, departmentCodeInput: newInputValue });
                                                }}
                                                limitTags={5}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.departmentCode || ''}
                                                options={
                                                    departments?.sort((a, b) => a.departmentCode.localeCompare(b.departmentCode))
                                                }
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.departmentCode}
                                                    </li>
                                                )}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography>
                                                {'Asset Group Description'}
                                            </Typography>
                                            <Autocomplete
                                                value={selectedAssetGroup || []}
                                                onChange={(event, newValue) => {
                                                    setSelectedAssetGroup(newValue);
                                                }}
                                                multiple
                                                inputValue={searchFilter?.assetGroupsInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, assetGroupsInput: newInputValue });
                                                    if (newInputValue?.length > 3) {
                                                        fetchsearchAssetGroups("", newInputValue);
                                                    }
                                                }}
                                                limitTags={5}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.assetGroupDescription || ''}
                                                options={
                                                    assetGroups?.sort((a, b) => a.assetGroup.localeCompare(b.assetGroup))
                                                }
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.assetGroupDescription}
                                                    </li>
                                                )}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                            <IconButton
                                                aria-label="search"
                                                onClick={fetchAssetData}
                                                edge="start"
                                            >
                                                <SearchIcon />
                                            </IconButton>

                                            <IconButton onClick={clearFilters}
                                                aria-label="search"
                                                edge="start"
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )}
                            </Box>
                    }

                    <Box className='homePageTable'>
                        {
                            tableData?.length !== 0 ?
                                <EAMAssetListingTableComponent
                                    rowCount={totalElements}
                                    columns={eamAssetListingColumns}
                                    page={page}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}
                                    showFilterFields={showFilterFields}
                                    setPage={(e) => setPage(e)}
                                    rowsPerPage={rowsPerPage}
                                    search={search}
                                    setSearch={setSearch}
                                    setRowsPerPage={(e) => setRowsPerPage(e)}
                                    data={tableData}
                                    fetchData={fetchAssetData}
                                    selectedSite={selectedSite}
                                    smallScreens={smallScreens}
                                ></EAMAssetListingTableComponent>
                                : (
                                    <Typography variant="h6" align="center" style={{ marginTop: '16px', display: 'block' }}>No data found</Typography>
                                )
                        }

                    </Box>
                </Box>
            }
        </Box>
    );
}