import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box, Button, TableSortLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material'
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { saveLabor, updateLabor, getOperationResourcesByOrgId } from '../../../services/WorkOrderServices';
import { DatePickerComponent, InputComponent, SelectComponent } from ".";
import ToastMessage from '../ToastMessage';
import {
    getEmployees
} from '../../../services/MasterServices';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ConfirmDialog } from '../ConfirmDialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from '../../../store/UserContext';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#1d66a2",
        },
        secondary: {
            main: '#f44336',
        },
    },
});




export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}



const validationSchema = yup.object({
    task: yup.string().trim().required("Required"),
    department: yup.string(),
    resourceCode: yup.string(),
    employee: yup.string().required("Required"),
    otherEmp: yup.string(),
    transactionDate: yup.date().required("Required"),
    start: yup.date(),
    finish: yup.date(),
    duration: yup.number(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        textOverflow: 'ellipsis',
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        height: '20px',

    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    },
    '& .MuiTableCell-root': {
    },
    '&:last-child td, &:last-child th': {
        borderBottom: 0,
    },
    "& td:first-of-type, & th:first-of-type": {
        borderLeft: '5px solid #006CEC !important',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px'
    },
}));


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        {headCell.field !== "buttons" &&
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {t(headCell.headerName)}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function WoLaborTableComponent(props) {
    const { laborData, addRecord, user, tasks, clickedWorkOrder, setSaveRecord, saveRecord } = props;
    const { screenSize, appConstants } = useContext(UserContext);
    const { t } = useTranslation();
    const { columns, data } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [toast, setToast] = useState({});
    const [empDropdown, setEmpDropdown] = useState([{ id: 0, value: '' }]);
    const [editRecord, setEditRecord] = useState(false);
    const [editData, setEditData] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [loading, setLoading] = useState(false);
    const [editDisabled, setEditDisabled] = React.useState(false);
    const [empOther, setEmpOther] = useState(false);
    const [resourceId, setResourceId] = useState('');
    const [resourceLaborData, setResourceLaborData] = useState();
    const [resourceLaborDropdown, setResourceLaborDropdown] = useState([{ id: 0, value: '--Select Resource--' }]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const d = new Date();
    const dateDefaultValue = dayjs(d, "DD-MM-YYYY HH:mm");
    const dateTimeDefaultValue = d.toISOString();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('task');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(laborData?.content, getComparator(order, orderBy)),
        [laborData?.content, order, orderBy, page, rowsPerPage]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    d.setMinutes(d.getMinutes() + 1);
    const dataFinish = d.toISOString();
    const formik = useFormik({
        initialValues: {
            task: '0',
            department: '',
            resourceCode: '',
            employee: '0',
            otherEmp: '',
            transactionDate: dateTimeDefaultValue,
            start: dateTimeDefaultValue,
            finish: dataFinish,
            duration: 0.1,
            createdBy: user.name,
            lastUpdatedBy: user.name
        },
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
        },
    });
    const getResources = async (orgId, owningDepartment) => {
        try {
            const response = await getOperationResourcesByOrgId(user?.token, orgId, owningDepartment);
            if (response && response?.statusCode === "200") {
                setResourceLaborData(response?.data);
                let resourceDropdown = [{
                    id: 0,
                    value: '--Select Resource--',
                }];
                response?.data?.forEach((resource) => {
                    resourceDropdown.push({
                        id: resource.resourceId,
                        value: resource.resourceCode
                    })
                });
                setResourceLaborDropdown(resourceDropdown)
            }
        } catch (err) { console.log(err) };
    }

    const formatDateTime = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('YYYY-MM-DD HH:mm:ss');
    }

    const formatDateForTDValidation = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('MM-DD-YYYY');
    }

    const getEmpByResource = async (organizationId, departmentId, resourceId) => {
        let empDropdownList = [{
            id: 0,
            value: '--Select Employee--',
        },
        ]
        try {

            const response = await getEmployees(user?.token, organizationId, departmentId, resourceId);
            if (response && response?.statusCode === "200") {
                await response?.data?.forEach((emp, index) => {
                    empDropdownList[index + 2] = ({
                        id: emp.employeeNumber,
                        value: emp.fullName
                    })
                });
                setEmpDropdown(empDropdownList);
            }
        } catch (err) { console.log(err) };
    }
    const handleTaskChange = (e) => {
        formik.setFieldValue('task', e, false);
        const task = tasks?.find(item => item.id === e);
        formik.setFieldValue('department', task?.departmentCode, false);
        getResources(props?.clickedWorkOrder?.organizationId, task?.departmentId);

    }
    const handleResourceCodesChange = (e) => {
        const personId = resourceLaborData?.find(x => x.resourceId === e);
        formik.setFieldValue('resourceCode', e, false);
        setResourceId(personId);
        const taskValue = tasks.find(item => item?.id === formik.values.task);
        getEmpByResource(props?.clickedWorkOrder?.organizationId, taskValue?.departmentId, personId?.resourceId)
    }
    const handleEmployeeChange = (e) => {
        formik.setFieldValue('employee', e, false);
        const filterLaborWithSameEmployee = visibleRows?.filter(item => item?.employeeId === e && (moment(item?.transactionDate).format("MM-DD-YYYY") == formik?.values?.transactionDate || (moment(item?.tempTransactionDate).format("MM-DD-YYYY") == formik?.values?.transactionDate)));
        if (filterLaborWithSameEmployee?.length !== 0) {
            const toastProps = { open: true, type: "error", message: 'Cannot add labor because the same employee is already assigned for the selected date', onClose: () => setToast({}) };
            setToast(toastProps);
        }
        if (e === 1) {
            setEmpOther(true)
        } else {
            setEmpOther(false)
        }
    }
    const deleteRecord = (laborId) => {
        setDeleteId(laborId);
        setOpenConfirm(true);
    }

    const validateFields = () => {
        var flag = false;
        if (formik.values.task === '0' || formik.values.task === '--Select Task--') {
            formik.setFieldError('task', 'Select Task');
            flag = true;
        } else {
            formik.setFieldError('task', '');
        }
        if (formik.values.department === null || formik.values.department === '') {
            formik.setFieldError('resourceCode', 'Department should not be empty');
            flag = true;
        } else {
            formik.setFieldError('resourceCode', '');
        }

        if (formik.values.resourceCode === null || formik.values.resourceCode === '') {
            formik.setFieldError('resourceCode', 'Resource should not be empty');
            flag = true;
        } else {
            formik.setFieldError('resourceCode', '');
        }
        if ((formik.values.employee === '0' || formik.values.employee === '--Select Employee--')) {
            formik.setFieldError('employee', 'Select Employee');
            flag = true;
        } else {
            formik.setFieldError('employee', '');
        }


        let release = formatDateForTDValidation(props?.clickedWorkOrder?.releaseDate);

        function validateEmployee() {
            const filterLaborWithSameEmployee = visibleRows?.filter(item => item?.employeeId === formik.values.employee && (moment(item?.transactionDate).format("MM-DD-YYYY") == formik?.values?.transactionDate || (moment(item?.tempTransactionDate).format("MM-DD-YYYY") == formik?.values?.transactionDate)));
            if (filterLaborWithSameEmployee?.length !== 0) {
                return true
            }
            else {
                return false
            }
        }


        if (release > formik?.values?.transactionDate) {
            formik.setFieldError('transactionDate', 'transaction date cant be before release date');

            flag = true;
        }
        else if (validateEmployee()) {
            const toastProps = { open: true, type: "error", message: 'Cannot add labor because the same employee is already assigned for the selected date', onClose: () => setToast({}) };
            setToast(toastProps);
            flag = true;
        }
        else {
            formik.setFieldError('transactionDate', '');
        }
        if (formik.values.duration <= 0 || formik.values.duration === null || formik.values.duration > 24) {
            formik.setFieldError('duration', 'Duration should be greater than 00:00 and below 24:00');
            flag = true;
        } else {
            formik.setFieldError('duration', '');
        }
        if (flag) {
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        if (props.saveRecord) {
            props.setSaveRecord(false);
            if (validateFields()) {
                if (editRecord && addRecord) {
                    updateRecordData();
                } else {
                    const resources = resourceLaborDropdown.find(item => item.id === parseInt(formik.values.resourceCode));
                    const taskValue = tasks.find(item => item?.id === formik.values.task);
                    const empData = empDropdown.find(item => item?.id == formik.values?.employee);
                    const startDate = moment(clickedWorkOrder?.scheduledStart).format("YYYY-MM-DD HH:mm:ss");
                    const start = moment(formik.values.transactionDate).format("YYYY-MM-DD");
                    const finish = moment(getFinishDate()).format("YYYY-MM-DD HH:mm:ss");
                    const data = {
                        workOrderId: props?.clickedWorkOrder?.workOrderId,
                        wipEntityId: props?.clickedWorkOrder?.wipEntityId,
                        organizationId: props?.clickedWorkOrder?.organizationId,
                        task: taskValue?.value,
                        resource: resources?.value,
                        resourceId: resources?.id,
                        empName: empData?.value,
                        employee: formik.values.employee,
                        otherEmp: formik.values.otherEmp,
                        transactionDate: start,
                        startDate: startDate,
                        finishDate: finish,
                        duration: formik.values.duration,
                        createdBy: user.name,
                    }
                    saveLaborRecord(data);
                }

            }
        }
    });

    const saveLaborRecord = async (data) => {
        setLoading(true);
        saveLabor(user?.token, data).then(res => {
            if (res?.statusCode === "200") {
                const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                formik.values.task = '0';
                formik.values.resourceCode = '';
                formik.values.department = '';
                formik.values.employee = '0';
                formik.values.otherEmp = '';
                formik.values.transactionDate = dateTimeDefaultValue;
                formik.values.start = dateTimeDefaultValue;
                formik.values.finish = dateTimeDefaultValue;
                formik.values.duration = 0.1;
                setTimeout(() => {
                    props?.getUpdatedLaborData();
                    setLoading(false);
                }, 3000);
                onClose();
            }
            else {
                setLoading(false);
                if (res?.message?.includes('exists')) {
                    const toastProps = { open: true, type: "error", message: "Task number already exists", onClose: () => setToast({}) };
                    setToast(toastProps);
                } else {
                    const toastProps = { open: true, type: "error", message: res?.message, onClose: () => setToast({}) };
                    setToast(toastProps);
                }
            }
        }).catch(errors => {
            const toastProps = { open: true, type: "error", message: errors?.message, onClose: () => setToast({}) };
            setToast(toastProps);
        });
    }
    const dateFormat = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('YYYY-MM-DD HH:mm');
    }

    const editRecordData = async (row) => {
        setEditRecord(true);
        setEditDisabled(true);
        setEditData(row);
        setResourceId(row);
        const personId = resourceLaborData?.find(x => x.resourceId === row.resourceId);
        const taskValue = tasks.find(item => item?.value === row.task);
        await getResources(props?.clickedWorkOrder?.organizationId, taskValue?.departmentId);
        getEmpByResource(props?.clickedWorkOrder?.organizationId, taskValue?.departmentId, row?.resourceId);

        const resources = resourceLaborDropdown.find(item => item?.value === row.resourceCode);
        formik.setFieldValue('task', taskValue?.id, false)
        formik.setFieldValue('resourceCode', row?.resourceId, false)
        formik.setFieldValue('department', taskValue?.departmentCode, false)
        formik.setFieldValue('employee', row.employeeId, false)
        formik.setFieldValue('otherEmp', row.otherEmp, false);
        formik.setFieldValue('transactionDate', row.start, false)
        formik.setFieldValue('start', row.start, false)
        formik.setFieldValue('finish', row.finish, false)
        formik.setFieldValue('duration', ((row.duration == 0 || row?.duration == null) ? row?.tempDuration : row?.duration), false);
        if (screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION) {
            props?.setAddRecord(true);
        }
    }

    const getFinishDate = () => {
        const scheduledStart = moment(clickedWorkOrder?.scheduledStart, 'YYYY-MM-DD HH:mm');

        // Convert the duration from hours to minutes
        const durationMin = parseFloat(formik?.values?.duration) * 60;

        // Add the duration to the scheduled start date
        const finishDate = scheduledStart.add(durationMin, 'minutes').toISOString();

        // Set the finish date in formik
        return finishDate
    }


    useEffect(() => {
        if (props?.addRecord) {
            const dinitial = new Date();
            const initialdateDefaultValue = dayjs(dinitial, "DD-MM-YYYY HH:mm");
            const initialdateTimeDefaultValue = dinitial.toISOString();
            dinitial.setMinutes(dinitial.getMinutes() + 1);
            const initialdataFinish = dinitial.toISOString();
            refreshFields();
            setEditData({});
            formik.setFieldValue('transactionDate', initialdateTimeDefaultValue, false);
            formik.setFieldValue('start', initialdateTimeDefaultValue, false);
            formik.setFieldValue('finish', initialdataFinish, false);
            setEditDisabled(!props?.addRecord);
        }
    }, [props?.addRecord])
    useEffect(() => {
        setEditData(editData);
    }, [editData])
    const updateRecordData = () => {
        if (validateFields()) {
            setLoading(true);
            const resources = resourceLaborDropdown.find(item => item?.id === parseInt(formik?.values?.resourceCode));
            const taskValue = tasks.find(item => item.id === formik.values.task);
            const start = moment(clickedWorkOrder?.scheduledStart).format("YYYY-MM-DD HH:mm:ss");
            const startDate = moment(formik.values.transactionDate).format("YYYY-MM-DD");
            const finishDate = moment(getFinishDate()).format("YYYY-MM-DD HH:mm:ss");

            const data = {
                laborId: editData?.laborId,
                workOrderId: props?.clickedWorkOrder?.workOrderId,
                wipEntityId: props?.clickedWorkOrder?.wipEntityId,
                organizationId: props?.clickedWorkOrder?.organizationId,
                task: taskValue?.value,
                resource: resources?.value,
                resourceId: resources?.id,
                empName: editData?.empName,
                employee: formik.values.employee === '--Select Employee--' ? 0 : formik.values.employee,
                otherEmp: formik.values.otherEmp,
                transactionDate: startDate, //formik.values.start,
                startDate: start,
                finishDate: finishDate, //formik.values.finish,
                duration: formik.values.duration,
                modifiedBy: user.id,
            }
            updateLaborRecord(data);
        }
    }
    const updateLaborRecord = async (data) => {
        setLoading(true);
        updateLabor(user?.token, data).then(res => {
            if (res?.statusCode === "200") {
                const toastProps = { open: true, type: "success", message: "Updated successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                refreshFields()
                setEditRecord(false);
                setEditData({});
                setTimeout(() => {
                    props?.getUpdatedLaborData();
                    setLoading(false);
                }, 3000);

                onClose();
            }
            else {
                setLoading(false);
                if (res?.message?.includes('exists')) {
                    const toastProps = { open: true, type: "error", message: "Task number already exists", onClose: () => setToast({}) };
                    setToast(toastProps);
                } else {
                    const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                    setToast(toastProps);
                }
            }
        });
    }
    const refreshFields = () => {
        formik.setFieldValue('task', '0', false)
        formik.setFieldValue('resourceCode', '', false)
        formik.setFieldValue('department', '', false)
        formik.setFieldValue('employee', '0', false)
        formik.setFieldValue('otherEmp', '', false);
        formik.setFieldValue('transactionDate', null, false)
        formik.setFieldValue('start', null, false)
        formik.setFieldValue('finish', null, false)
        formik.setFieldValue('duration', 0.1, false)
        formik.setFieldError('duration', '')
        formik.setFieldError('transactionDate', '')
        formik.setFieldError('start', '')
        formik.setFieldError('finish', '')
        formik.setFieldError('task', '')
        formik.setFieldError('employee', '')
        formik.setFieldError('resourceCode', '')
    }
    const isNumeric = (val: string): boolean => {
        return !isNaN(Number(val));
    }

    const enableFieldTechToast = () => {
        const toastProps = { open: true, type: "error", message: "Deletion of the Labor with Fieldtech resource is not allowed", onClose: () => setToast({}) };
        setToast(toastProps);
    }
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const onClose = () => {
        refreshFields();
        setEditRecord(false);
        props?.setAddRecord(false);
        setEditData({});
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box>
                {/* Web and Tablet UI */}
                <Box >
                    {loading &&
                        <div className="loading">Loading&#8230;</div>
                    }
                    <ConfirmDialog openConfirm={openConfirm} msg={'Do you want delete the labor entry?'} //"All tasks must be finished to complete the Work Order."
                        accept={() => {

                            props?.deleteLaborRecord(deleteId)
                            setOpenConfirm(false);
                        }}
                        reject={() => setOpenConfirm(false)}
                    />
                    <TableContainer sx={{ width: "100%", transition: "height 100ms ease-in-out", maxHeight: window.innerHeight - 288 }} className='wo-operations-table-container' >
                        <Table sx={{
                            minWidth: 700,
                            '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                            '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                                color: "black", opacity: 100
                            }

                        }} stickyHeader size="small" aria-label="sticky table">

                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={columns}
                            />
                            <TableBody>
                                {((addRecord || editRecord) && screenSize?.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION) && <TableRow className='add-labor-row add-row-wo'>
                                    <TableCell className='task-width' >
                                        <SelectComponent
                                            values={tasks}
                                            name={"task"}
                                            selectedvalue={formik.values?.task}
                                            disabled={editDisabled}
                                            width={"100%"}
                                            error={formik?.errors?.task}
                                            parentselectcallback={handleTaskChange}
                                        />
                                    </TableCell>
                                    <TableCell className='task-width'>
                                        <InputComponent
                                            name={"department"}
                                            width={"100%"}
                                            disabled={true}
                                            value={formik.values?.department}
                                            maxLength={"50"}
                                            error={formik?.errors?.department}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <SelectComponent
                                            values={resourceLaborDropdown}
                                            name={"resourceCode"}
                                            selectedvalue={formik.values?.resourceCode}
                                            disabled={editDisabled}
                                            width={"100%"}
                                            error={formik?.errors?.resourceCode}
                                            parentselectcallback={handleResourceCodesChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <SelectComponent
                                            values={empDropdown}
                                            name={"employee"}
                                            selectedvalue={formik.values?.employee}
                                            disabled={editDisabled}
                                            width={"100%"}
                                            error={formik?.errors?.employee}
                                            parentselectcallback={handleEmployeeChange}
                                        />
                                    </TableCell>

                                    <TableCell className='date-picker_labor'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                defaultValue={(dateDefaultValue)}
                                                className='labor-dates'
                                                inputFormat={"MM-DD-YYYY"}
                                                onAccept={(e) => {
                                                    var hourDiff = ((new Date(formik?.values?.finish).getTime() - new Date(formik?.values?.start).getTime()));
                                                    var minDiff = hourDiff / 60 / 1000; //in minutes

                                                }}
                                                onChange={(e) => {
                                                    try {
                                                        let date = new Date(e);
                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                        formik.setFieldValue("transactionDate", formattedDate, false);

                                                        if (e && formattedDate) {
                                                            const filterLaborWithSameEmployee = visibleRows?.filter(item => item?.employeeId === formik.values.employee && (moment(item?.transactionDate).format("MM-DD-YYYY") == formattedDate || (moment(item?.tempTransactionDate).format("MM-DD-YYYY") == formattedDate)));
                                                            if (filterLaborWithSameEmployee?.length !== 0) {
                                                                const toastProps = { open: true, type: "error", message: 'Cannot add labor because the same employee is already assigned for the selected date', onClose: () => setToast({}) };
                                                                setToast(toastProps);
                                                            }
                                                        }

                                                        if (formik?.values?.duration != 0) {
                                                            /*  const d = new Date(clickedWorkOrder?.scheduledStart);
                                                             console.log(d, clickedWorkOrder?.scheduledStart)
                                                             let durationMin = parseFloat(formik?.values?.duration.toString()) * 60;
                                                             console.log(d.getMinutes(), durationMin)
                                                             d.setMinutes(d.getMinutes() - durationMin);
                                                             const dataFinish = d.toISOString();
                                                             console.log(dataFinish)
                                                             formik.setFieldValue('start', dataFinish);
                                                             let release = formatDateTime(props?.clickedWorkOrder?.releaseDate); */
                                                            // Parse the scheduled start date using moment

                                                        }
                                                    } catch (e) {
                                                        let date = new Date(e);
                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                        formik.setFieldValue("transactionDate", formattedDate, false);
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Calendar />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            variant='outlined'
                                                            onKeyDown={onKeyDown}
                                                            size="small"
                                                            sx={{ fontSize: "14px !important", backgroundColor: "white", }}
                                                            {...params}
                                                        />

                                                    </>
                                                )} value={formik?.values.transactionDate} error={formik?.errors?.transactionDate}
                                                disableFuture={true} />
                                        </LocalizationProvider>
                                        {formik?.errors?.transactionDate && <Typography variant={'body2'} mt={'5px'} style={{ color: "red" }}>{formik?.errors?.transactionDate}</Typography>}
                                    </TableCell>

                                    <TableCell className='duration-feild' >
                                        <InputComponent
                                            name={"duration"}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(e.target.name, e.target.value, false);
                                                if (isNumeric(e.target.value) && parseInt(e.target.value) <= 24 && parseInt(e.target.value) > 0) {
                                                    formik.setFieldError(e.target.name, '');
                                                    /* const d = new Date(clickedWorkOrder?.scheduledStart);
                                                    const dateDefaultValue = dayjs(d, "DD-MM-YYYY HH:mm");
                                                    const dateTimeDefaultValue = d.toISOString();
                                                    let durationMin = parseFloat(e.target.value) * 60;
                                                    d.setMinutes(d.getMinutes() + durationMin);
                                                    const dataFinish = d.toISOString();
                                                    formik.setFieldValue('finish', dataFinish); */
                                                    let release = formatDateTime(props?.clickedWorkOrder?.releaseDate);
                                                } else if (!isNaN(value) && !isNaN(parseFloat(value))) {
                                                    formik.setFieldError('duration', ''); // Clear the error message
                                                    if (isNumeric(e.target.value) && parseInt(e.target.value) > 24) {
                                                        formik.setFieldError('duration', 'Duration should be below 24:00');
                                                    }
                                                    else if (isNumeric(e.target.value) && parseInt(e.target.value) <= 0) {
                                                        formik.setFieldError('duration', 'Duration should be greater than 00:00');
                                                    } else if (e.target.value === '') {
                                                        formik.setFieldError(e.target.name, 'Duration should not be empty. Please enter a valid duration.');
                                                    }
                                                    // Rest of the code
                                                } else {
                                                    if (isNumeric(e.target.value) && parseInt(e.target.value) > 24) {
                                                        formik.setFieldError('duration', 'Duration should be below 24:00');
                                                    }
                                                    else if (isNumeric(e.target.value) && parseInt(e.target.value) <= 0) {
                                                        formik.setFieldError('duration', 'Duration should be greater than 00:00');
                                                    } else {
                                                        formik.setFieldError(e.target.name, 'Duration should not be empty. Please enter a valid duration.');
                                                    }
                                                }
                                            }
                                            }
                                            maxLength={"200"}
                                            type={"number"}
                                            error={formik.errors?.duration}
                                            value={formik?.values.duration} />
                                    </TableCell>
                                    <TableCell>
                                        <Box className='action-buttons'>
                                            {(editRecord) &&
                                                <SaveIcon
                                                    color={props?.clickedWorkOrder?.statusType === 4 ? 'disabled' : 'success'}
                                                    sx={{
                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                        pointerEvents: props?.clickedWorkOrder?.statusType === 4 ? "none" : "pointer",
                                                        cursor: props?.clickedWorkOrder?.statusType === 4 ? "not-allowed" : "pointer"
                                                    }}
                                                    onClick={() => {
                                                        updateRecordData();
                                                    }}
                                                />
                                            }

                                            <CancelIcon
                                                sx={{
                                                    color: props?.clickedWorkOrder?.statusType === 4 ? 'disabled' : 'rgba(0, 0, 0, 0.54)',
                                                    pointerEvents: props?.clickedWorkOrder?.statusType === 4 ? "none" : "pointer",
                                                    cursor: props?.clickedWorkOrder?.statusType === 4 ? "not-allowed" : "pointer"
                                                }}
                                                onClick={() => {
                                                    refreshFields();
                                                    setEditRecord(false);
                                                    props?.setAddRecord(false);
                                                    setEditData({});
                                                }}
                                            />

                                        </Box>
                                    </TableCell>

                                </TableRow>
                                }
                                {visibleRows && visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    editData && editData?.laborId !== row?.laborId &&
                                    <StyledTableRow key={index}>
                                        <StyledTableCell className='wo-labour-task-cell' sx={{ borderLeft: '5px solid #006CEC' }}>{row.task}</StyledTableCell>
                                        <StyledTableCell>{tasks?.find(item => item.task === row.task)?.departmentCode}</StyledTableCell>
                                        <StyledTableCell>{row?.resourceCode || '-'}</StyledTableCell>
                                        <StyledTableCell>{row?.empName || '-'}</StyledTableCell>
                                        <StyledTableCell>{(row.transactionDate == '' || row?.transactionDate == null) ? row?.tempTransactionDate : row.transactionDate}</StyledTableCell>
                                        <StyledTableCell>{(row.duration == 0 || row?.duration == null) ? row?.tempDuration : row?.duration}</StyledTableCell>
                                        <StyledTableCell className="action-button-cell" align='center'>
                                            <Stack direction={'row'} gap={1} >
                                                <IconButton
                                                    onClick={() => {
                                                        props?.setAddRecord(!addRecord);
                                                    }}
                                                    color='rgba(0, 0, 0, 0.54)'
                                                    disabled={((clickedWorkOrder?.statusType === 4) || (clickedWorkOrder?.statusType === 6)) ? true : loading} >

                                                    <AddCircleOutlineIcon />

                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        editRecordData(row);
                                                    }}
                                                    color='rgba(0, 0, 0, 0.54)'
                                                    disabled={((clickedWorkOrder?.statusType === 4) || (clickedWorkOrder?.statusType === 6)) ? true : loading}
                                                >
                                                    <EditIcon />

                                                </IconButton>



                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={laborData?.content?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                {screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION &&
                    <Dialog
                        open={addRecord}
                        onClose={onClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Add Labor"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '0px' }}>
                            <Box className='mobile-card-content-main'>
                                <Box className='mobile-cards-content'>
                                    <Box className='card-content-wo'>
                                        <Card variant="outlined">
                                            <React.Fragment>
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_TASK')}
                                                            </Typography>
                                                            <SelectComponent
                                                                values={tasks}
                                                                name={"task"}
                                                                selectedvalue={formik.values?.task}
                                                                disabled={editDisabled}
                                                                width={"100%"}
                                                                error={formik?.errors?.task}
                                                                parentselectcallback={handleTaskChange}
                                                            />
                                                        </Box>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_DEPARTMENT')}
                                                            </Typography>
                                                            <InputComponent
                                                                name={"department"}
                                                                width={"100%"}
                                                                disabled={true}
                                                                value={formik.values?.department}
                                                                maxLength={"50"}
                                                                error={formik?.errors?.department}
                                                            />
                                                        </Box>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_RESOURCE')}
                                                            </Typography>
                                                            <SelectComponent
                                                                values={resourceLaborDropdown}
                                                                name={"resourceCode"}
                                                                selectedvalue={formik.values?.resourceCode}
                                                                disabled={editDisabled}
                                                                width={"100%"}
                                                                error={formik?.errors?.resourceCode}
                                                                parentselectcallback={handleResourceCodesChange}
                                                            />
                                                        </Box>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_EMPLOYEE')}
                                                            </Typography>
                                                            <SelectComponent
                                                                values={empDropdown}
                                                                name={"employee"}
                                                                selectedvalue={formik.values?.employee}
                                                                disabled={editDisabled}
                                                                width={"100%"}
                                                                error={formik?.errors?.employee}
                                                                parentselectcallback={handleEmployeeChange}
                                                            />
                                                        </Box>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_TRANSACTION_DATE')}
                                                            </Typography>
                                                            <DatePickerComponent
                                                                defaultValue={(dateDefaultValue)}
                                                                className='labor-dates'
                                                                inputFormat={"MM-DD-YYYY"}
                                                                disableFuture={true}
                                                                value={formik?.values.transactionDate}
                                                                onDateSelect={(e) => {
                                                                    try {
                                                                        let date = new Date(e);
                                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                                        formik.setFieldValue("transactionDate", formattedDate, false);
                                                                        if (e && formattedDate) {
                                                                            const filterLaborWithSameEmployee = visibleRows?.filter(item => item?.employeeId === formik.values.employee && (moment(item?.transactionDate).format("MM-DD-YYYY") == formattedDate || (moment(item?.tempTransactionDate).format("MM-DD-YYYY") == formattedDate)));
                                                                            if (filterLaborWithSameEmployee?.length !== 0) {
                                                                                const toastProps = { open: true, type: "error", message: 'Cannot add labor because the same employee is already assigned for the selected date', onClose: () => setToast({}) };
                                                                                setToast(toastProps);
                                                                            }
                                                                        }


                                                                    } catch (e) {
                                                                        formik.setFieldValue("transactionDate", "", false);
                                                                    }
                                                                }}
                                                                error={formik?.errors?.transactionDate}
                                                            />
                                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    defaultValue={(dateDefaultValue)}
                                                                    className='labor-dates'
                                                                    inputFormat={"MM-DD-YYYY"}
                                                                    value={formik?.values.transactionDate}
                                                                    onChange={(e) => {
                                                                        try {
                                                                            let date = new Date(e);
                                                                            const formattedDate = moment(date).format("MM-DD-YYYY");
                                                                            formik.setFieldValue("transactionDate", formattedDate, false);
                                                                            if (e && formattedDate) {
                                                                                const filterLaborWithSameEmployee = visibleRows?.filter(item => item?.employeeId === formik.values.employee && (moment(item?.transactionDate).format("MM-DD-YYYY") == formattedDate || (moment(item?.tempTransactionDate).format("MM-DD-YYYY") == formattedDate)));
                                                                                if (filterLaborWithSameEmployee?.length !== 0) {
                                                                                    const toastProps = { open: true, type: "error", message: 'Cannot add labor because the same employee is already assigned for the selected date', onClose: () => setToast({}) };
                                                                                    setToast(toastProps);
                                                                                }
                                                                            }


                                                                        } catch (e) {
                                                                            formik.setFieldValue("transactionDate", "", false);
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Calendar />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <>
                                                                            <TextField
                                                                                variant='outlined'
                                                                                size="small"
                                                                                sx={{ fontSize: "14px !important", backgroundColor: "white" }}
                                                                                {...params}
                                                                            />
                                                                            {formik?.errors?.transactionDate && <span style={{ color: "red" }}>{formik?.errors?.transactionDate}</span>}
                                                                        </>
                                                                    )}
                                                                    error={formik?.errors?.transactionDate}
                                                                    disableFuture={true}
                                                                />
                                                            </LocalizationProvider> */}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                {t('WO_LABOR_DURATION')}
                                                            </Typography>
                                                            <InputComponent
                                                                name={"duration"}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('duration', e.target.value, false);
                                                                }}
                                                                value={formik.values.duration}
                                                                width={"100%"}
                                                                maxLength={"200"}
                                                                type={"number"}
                                                                error={formik.errors?.duration}
                                                            />
                                                        </Box>

                                                    </Box>
                                                </CardContent>
                                            </React.Fragment>
                                        </Card>
                                    </Box>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <button
                                onClick={onClose}
                                disabled={loading}
                                style={{
                                    padding: "10px 20px",
                                    backgroundColor: "#c62828",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                }}
                            >
                                {t("CANCEL")}
                            </button>
                            <button
                                onClick={() => { setSaveRecord(!saveRecord); }}
                                disabled={loading}
                                style={{
                                    padding: "10px 20px",
                                    backgroundColor: "#1b5e20",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                }}
                            >
                                {t("SAVE")}
                            </button>
                        </DialogActions>
                    </Dialog>
                }
            </Box>
            <ToastMessage toast={toast} />
        </ThemeProvider>
    );
}