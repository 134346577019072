import React from 'react';
import { Grid, Box, Typography, } from '@mui/material';

import { InputComponent } from "../shared-components/common-field-components";


const AssetAssignmentForm = (props) => {
    const { formik, } = props;

    return (
        <Box sx={{
            padding: {
                xs: '4px',
                lg: '1px',
            }
        }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Employee Name</Typography>
                        <InputComponent
                            name="AssetAssignment.employeeName"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetAssignment?.employeeName && formik.errors.AssetAssignment?.employeeName}
                            value={formik.values.AssetAssignment?.employeeName || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Employee Number</Typography>
                        <InputComponent
                            name="AssetAssignment.employeeNumber"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetAssignment?.employeeNumber && formik.errors.AssetAssignment?.employeeNumber}
                            value={formik.values.AssetAssignment?.employeeNumber || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Location</Typography>
                        <InputComponent
                            name="AssetAssignment.location"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetAssignment?.location && formik.errors.AssetAssignment?.location}
                            value={formik.values.AssetAssignment?.location || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Expense Account</Typography>
                        <InputComponent
                            name="AssetAssignment.expenseAccount"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetAssignment?.expenseAccount && formik.errors.AssetAssignment?.expenseAccount}
                            value={formik.values.AssetAssignment?.expenseAccount || ''}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AssetAssignmentForm;
