export const deviceTableColumns = [
  {
    field: "checkbox",
    aligncontent: "center",

    headerClassName: "super-app-theme--header",
    sortable: false,
  },

  {
    field: "device",
    headerName: "DEVICE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "deviceDescription",
    headerName: "DESCRIPTION",
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "deviceRole",
    headerName: "ORG_ID",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "resetData",
    headerName: "RESET_DATE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "lastConnected",
    headerName: "LAST_CONNECTED",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pendingData",
    headerName: "PENDING_DATA",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pendingApplicatioon",
    headerName: "PENDING_APPLICATIONS",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "colonization",
    headerName: "COLONIZATION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "edit",
    headerName: "",
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
];


