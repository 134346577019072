import React from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import "./DatePicker.css";

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
}

export default function DatePickerComponent(props) {
  const { label, dateDefaultValue = null, disabled, disableFuture, disablePast, lebalTop, onDateSelect, error, value, maxDate, minDate, showTimestamp = false, timestamp } = props;

  return (
    <FormControl variant="standard" fullWidth={true} sx={{
      fontSize: '14px !important', marginBottom: "4px", marginTop: "8px",

      '& .MuiButtonBase-root': {
        padding: "0px",
        marginRight: "0px",
      }
      , "& .Mui-disabled": { backgroundColor: '#E2E4E8' }


    }} className={label === "" ? "ax-date-picker-component" : "ax-date-picker-component date-picker"}>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && <InputLabel sx={{ fontSize: "18px !important", marginTop: lebalTop ? lebalTop : '-25px' }}>{label}</InputLabel>}
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          views={["year", "month", "day"]}
          value={value || dateDefaultValue}
          onChange={(newValue) => {
            try {
              let date = new Date(newValue);
              let formattedDate = newValue ? convertUTCDateToLocalDate(date).toISOString().split('T')[0] : null;
              onDateSelect(formattedDate);
            } catch (e) {
              onDateSelect(newValue);
            }

          }}
          renderInput={(params) => (
            <>
              <TextField
                variant='outlined'
                fullWidth={true}
                size="small"
                sx={{ fontSize: "14px !important", backgroundColor: "white", }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  value: showTimestamp && timestamp
                    ? `${params.inputProps.value} ${timestamp}`
                    : params.inputProps.value,
                }}
              />
              {error && <span style={{ color: "red" }}>{error}</span>}
            </>
          )}
        />
      </LocalizationProvider>

    </FormControl>
  );
}
