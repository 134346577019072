import React, { useEffect } from "react";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import { AxSiteTableComponent, AxSiteMobileTableComponent, ButtonComponent } from "../shared-components/common-field-components";
import { siteTableColumns } from "../constants/SitesTable";
import "./admin.css";
import { useState, useContext } from "react";
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import MobileTableCardComponentSites from "./MobileTableCardComponentSites";
import { getAllSites, updateSites } from '../../services/AdminSitesServices';
import { UserContext } from "../../store/UserContext";
import ToastMessage from "../shared-components/ToastMessage";
import { t } from "i18next";


const Sites = () => {

  const [viewData, setViewData] = useState(false);
  const { oktaAuth, user } = useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();

  const [sitesData, setSitesData] = useState();
  const [showSave, setShowSave] = useState(false);
  const [clearDataToSave, setClearDataToSave] = useState(false);
  const [dataToSave, setDataToSave] = useState([]);
  const [toast, setToast] = useState({});
  const [currentSiteForMobile, setCurrentSiteForMobile] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);



  const sitesMobileHeaderData = [

    {
      field: "orgCode",
      headerName: "SITE",
      width: 100,
      headerClassName: "super-app-theme--header",
      sortable: true
    },
    {
      field: "operatingUnit",
      headerName: "OPERATING_UNIT",
      width: 100,
      headerClassName: "super-app-theme--header",
      sortable: true
    },
    {
      field: "orgName",
      headerName: "DESCRIPTION",
      width: 100,
      headerClassName: "super-app-theme--header",
      sortable: true
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 100,
      headerClassName: "super-app-theme--header",
      sortable: true
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "super-app-theme--header",
      sortable: false
    },

  ];

  const fetchData = () => {
    try {
      getAllSites(page, rowsPerPage).then(response => {
        setLoading(true);
        if (response?.statusCode === "200") {
          setSitesData(response?.data);
          setClearDataToSave(false);
          setShowSave(false);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log('ERROR >>> ' + error)
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const saveSites = () => {
    dataToSave.map((data, index) => {
      dataToSave[index].lastUpdatedBy = user?.name;
    });
    updateSites(accessToken, dataToSave).then(response => {
      setLoading(true);
      if (response.statusCode === '200') {
        const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
        setToast(toastProps);
        fetchData();
        setClearDataToSave(true);
        setLoading(false);
      }
      else {
        const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
        setToast(toastProps);
        setLoading(false);
      }
    }).catch(() => {
      const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
      setToast(toastProps);
      setLoading(false);
    });
  }

  return (
    <Box>

      <Box className="admin-sites-pc-tab-main">
        {loading &&
          <div className="loading">Loading&#8230;</div>
        }
        <Box className='admin-sites-main'>
          <Grid className='admin-content-page-header'>
            <Typography variant={'h5'} className='admin-content-header-tittle-text'>{t('SITES')}</Typography>
            <Box className="sites-table-action-buttons">

              {showSave ? <Box display={"flex"} flexDirection={"row"} gap={"10px"}><Box className="save-button">
                <Box className="sites-table-action-buttons">
                  <ButtonComponent label={t("SAVE")} color={'#2BAA4F'}
                    buttoncallBack={saveSites}
                  />
                </Box>
              </Box><ButtonComponent color={"#F30C0C"} buttoncallBack={() => { setClearDataToSave(!clearDataToSave); setShowSave(false); }} icon={false} label={t("CANCEL")} disabled={false} /></Box> : null}

            </Box>
          </Grid>
          <Box className='admin-sites-table'>
            {loading ? <CircularProgress /> :
              <AxSiteTableComponent
                columns={siteTableColumns}
                data={sitesData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setClearDataToSave={setClearDataToSave}
                showSave={() => setShowSave(true)}
                cancel={showSave}
                setDataToSave={(_dataToSave) => {
                  setDataToSave(_dataToSave);
                }}
                clearDataToSave={clearDataToSave}
              />
            }
          </Box>
        </Box>
      </Box>

      <Box className="admin-sites-mobile">
        <Box className="admin-sites-main-mobile">
          <Grid className='admin-content-page-header-mobile'>
            <Box className="header-sub-div">
              {viewData && (
                <ArrowCircleLeftOutlinedIcon onClick={() => setViewData(!viewData)} className="back-button-mobile-sites" fontSize="medium" />
              )}
              <Typography variant={'h5'} className='admin-content-header-tittle-text'>{t('SITES')}</Typography>
            </Box>
            {/* <Box className="export-button-box">
              <IosShareIcon />
            </Box> */}
          </Grid>
          <Box className='admin-sites-table'>
            {loading ? <CircularProgress /> :
              <AxSiteTableComponent
                columns={siteTableColumns}
                data={sitesData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setClearDataToSave={setClearDataToSave}
                showSave={() => setShowSave(true)}
                cancel={showSave}
                setDataToSave={(_dataToSave) => {
                  setDataToSave(_dataToSave);
                }}
                clearDataToSave={clearDataToSave}
              />
            }
          </Box>
        </Box>
      </Box>
      <ToastMessage toast={toast} />
    </Box>
  );
};


export default Sites;
