import * as React from "react";
import { Grid, Typography, Box,  } from '@mui/material';


import { InputComponent } from "../shared-components/common-field-components";

const AssetWarrantyForm = (props) => {

    const { formik,  } = props;

    return (
        <Box
            sx={{
                padding: {
                    xs: '4px',
                    lg: '1px',
                },
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Warranty Number</Typography>
                        <InputComponent
                            name="AssetWarranty.warrantyNumber"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}                          
                            error={formik.touched.AssetWarranty?.warrantyNumber &&formik.errors.AssetWarranty?.warrantyNumber}
                            value={formik.values.AssetWarranty.warrantyNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Description</Typography>
                        <InputComponent
                            name="AssetWarranty.description"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.description &&formik.errors.AssetWarranty?.description}
                            value={formik.values.AssetWarranty.description}
                           
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Type</Typography>
                        <InputComponent
                            name="AssetWarranty.type"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.type &&formik.errors.AssetWarranty?.type}
                            value={formik.values.AssetWarranty.type}
 
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Start Date</Typography>
                        <InputComponent
                            name="AssetWarranty.startDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onChange={formik.handleChange}
                            onDateSelect={(date) => formik.setFieldValue("AssetWarranty.startDate", date, true)}
                            onBlur={formik.handleBlur}
                            value={formik.values.AssetWarranty.startDate}
                            error={formik.touched.AssetWarranty?.startDate &&formik.errors.AssetWarranty?.startDate}
                        />
                    </Grid>

                
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>End Date</Typography>
                        <InputComponent
                            name="AssetWarranty.endDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onDateSelect={(date) => formik.setFieldValue("AssetWarranty.endDate", date, true)}
                            error={formik.touched.AssetWarranty?.endDate &&formik.errors.AssetWarranty?.endDate}
                            value={formik.values.AssetWarranty.endDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Cost</Typography>
                        <InputComponent
                            name="AssetWarranty.cost"
                            width="100%"
                            type="number"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.cost &&formik.errors.AssetWarranty?.cost}
                            value={formik.values.AssetWarranty.cost}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Renewable</Typography>
                        <InputComponent
                            name="AssetWarranty.renewable"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.renewable &&formik.errors.AssetWarranty?.renewable}
                            value={formik.values.AssetWarranty.renewable}
                          
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Coverage</Typography>
                        <InputComponent
                            name="AssetWarranty.coverage"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.coverage &&formik.errors.AssetWarranty?.coverage}
                            value={formik.values.AssetWarranty.coverage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Mileage Limit</Typography>
                        <InputComponent
                            name="AssetWarranty.mileageLimit"
                            width="100%"
                            type="number"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.mileageLimit &&formik.errors.AssetWarranty?.mileageLimit}
                            value={formik.values.AssetWarranty.mileageLimit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Warranty Expiration</Typography>
                        <InputComponent
                            name="AssetWarranty.warrantyExpiration"
                            width="100%"
                            type="date"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetWarranty?.warrantyExpiration &&formik.errors.AssetWarranty?.warrantyExpiration}
                            value={formik.values.AssetWarranty.warrantyExpiration}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Installation Date</Typography>
                        <InputComponent
                            name="AssetWarranty.installationDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}                           
                            error={formik.touched.AssetWarranty?.installationDate &&formik.errors.AssetWarranty?.installationDate}
                            value={formik.values.AssetWarranty.installationDate}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AssetWarrantyForm;
