import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';

export const getDashboadData = async (params) => {
    try {
        let fetchData = [];
        if (params?.userId) {
            fetchData = await axios.get(APIENPOINTS.DASHBOARD.GET_DASHBOARD_DETAILS, { params }).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getDashboardDueWo = async (params) => {
    try {
        let fetchData = [];
        if (params?.userId) {
            fetchData = await axios.get(APIENPOINTS.DASHBOARD.GET_DASHBOARD_DUE_WO, { params }).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}