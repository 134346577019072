import axios from "axios";

// console.log(process.env)
const token = localStorage.getItem('authToken');
axios.defaults.baseURL = process.env. REACT_APP_AX_SERVICES_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;


/* const instance = axios.create({
  baseURL: 'http://localhost:8080/pten',
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
}); */
// Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = token;
//return instance;
// }

//export default instance;