import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import './services/global';
import './components/constants/APIEndpoints';
import './App.css'

const App = () => (
  <div className="app">
    <Router>
      <Routes />
    </Router>
  </div>
);
export default App;