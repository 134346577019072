import { createTheme } from '@mui/material/styles';

// Set the default font size to 0.875rem
const defaultFontSizeRem = 0.9;

const theme = createTheme({
  typography: {
    // Base font family and font size
    fontFamily: 'Verdana Pro, Arial, sans-serif',
    fontSize: defaultFontSizeRem,
    // Customizing each variant
    h1: {
      fontSize: `${defaultFontSizeRem * 2.5}rem`, // 2.5rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    h2: {
      fontSize: `${defaultFontSizeRem * 2}rem`, // 2rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    h3: {
      fontSize: `${defaultFontSizeRem * 1.75}rem`, // 1.75rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    h4: {
      fontSize: `${defaultFontSizeRem * 1.5}rem`, // 1.5rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    h5: {
      fontSize: `${defaultFontSizeRem * 1.25}rem`, // 1.25rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    h6: {
      fontSize: `${defaultFontSizeRem * 1.125}rem`, // 1.125rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    subtitle1: {
      fontSize: `${defaultFontSizeRem * 1}rem`, // 1rem
      fontWeight: 400,
      wordBreak:'break-word'
    },
    subtitle2: {
      fontSize: `${defaultFontSizeRem * 0.875}rem`, // 0.875rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    subtitle3: {
      fontSize: `${defaultFontSizeRem * 0.9}rem`, // 0.875rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    body1: {
      fontSize: `${defaultFontSizeRem * 1}rem`, // 1rem
      fontWeight: 400,
      wordBreak:'break-word'
    },
    body2: {
      fontSize: `${defaultFontSizeRem * 0.875}rem`, // 0.875rem
      fontWeight: 400,
      wordBreak:'break-word'
    },
    boldBody: {
      fontSize: `${defaultFontSizeRem * 1}rem`, // 1rem
      fontWeight: 600,
      wordBreak:'break-word'
    },
    button: {
      fontSize: `${defaultFontSizeRem * 0.875}rem !important`, // 0.875rem
      fontWeight: 500,
      textTransform: 'capitalize',
      whiteSpace:'noWrap'
    },
    caption: {
      fontSize: `${defaultFontSizeRem * 0.75}rem`, // 0.75rem
      fontWeight: 400,
      wordBreak:'break-word'
    },
    overline: {
      fontSize: `${defaultFontSizeRem * 0.75}rem`, // 0.75rem
      fontWeight: 400,
      textTransform: 'uppercase',
      wordBreak:'break-word'
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem', // Adjust this value as needed
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: `${defaultFontSizeRem}rem`, // Set label font size to 1rem
          color: 'rgba(0, 0, 0, 0.6)', // Example color
          '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.8)', // Adjust focused state color if needed
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: `${defaultFontSizeRem}rem !important`, // Adjust this value as needed
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600, // Apply 600 font weight to table headers
          '& span': {
            fontSize: `${defaultFontSizeRem / 1.14}rem !important`, // Target <span> elements specifically
          },
        },
      },
    },
  },
});

export default theme;
