import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableSortLabel, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { red } from "@mui/material/colors";
import { t } from "i18next";
import { options } from "joi";
import React, { useContext, useEffect, useState } from "react";
import { InputComponent, SelectComponent } from ".";
import {
  validateEmail,
  validateIsNumber
} from "../../../helpers/validations";
import { getAllLocations, getAllRoles, getSearchUsers, deleteUser } from "../../../services/AdminSecurityServices";
import { getAllSites } from "../../../services/AdminSitesServices";
import { addSubInventories } from "../../../services/GenerateInventoriesServices";
import { UserContext } from "../../../store/UserContext";
import ToastMessage from "../ToastMessage";
import { useTranslation } from "react-i18next";
import { userTableColumns } from "../../constants/UserTableColumns";
import { Delete } from "@mui/icons-material";
import { ConfirmDialog } from "../ConfirmDialog";
import '../index.css'


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, setSelectedUser, setOpenConfirm, loading } = props;
  const [open, setOpen] = useState(false);
  const [currentRoles, setCurrentRoles] = useState<any>([...row.roles]);
  const [currentSites, setCurrentSites] = useState<any>([...row.sites]);
  const [currentLocations, setCurrentLocations] = useState<any>([...row.locations]);
  const [currentSubInventories, setCurrentSubInventories] = useState<any>([...row.userSubInventoriesResponses]);
  const [defaultSite, setDefaultSite] = useState(row.defaultSiteId);
  const [toast, setToast] = useState({});
  const [subInventories, setSubInventories] = useState<any[]>([]);
  const [subInventorySearchString, setSubInventorySearchString] = useState('');


  useEffect(() => {
    if (row?.sites?.length > 0) {
      findSubInventories();
    }
    setOpen(false);
    if (row.roles.length === 0)
      setCurrentRoles([{ roleId: props.rolesData[0].id }]);
    else
      setCurrentRoles([...row.roles]);
    if (row.sites.length === 0)
      setCurrentSites([{ siteId: props.sitesData[0].id }]);
    else
      setCurrentSites([...row.sites]);
    if (row.locations.length === 0)
      setCurrentLocations([{ locId: props.locationsData[0].id }]);
    else
      setCurrentLocations([...row.locations]);

    if (row.userSubInventoriesResponses.length === 0)
      setCurrentSubInventories([{ organizationId: 0 }]);
    else
      setCurrentSubInventories([...row.userSubInventoriesResponses]);

    setDefaultSite(row.defaultSiteId);
    setToast({});
  }, [row.userId, props.refresh])
  useEffect(() => {
    if (open)
      props.setEditedUserId(row.userId);
  }, [open]);


  const findSubInventories = async () => {
    const filteredData = props?.subInventories.filter((item) =>
      currentSubInventories.some(
        (response) =>
          response.organizationId === item.organizationId &&
          response.subInventory === item.secondaryInventoryName
      )
    );
    setSubInventories(filteredData);

  }

  useEffect(() => {
    if (row?.sites?.length > 0 && open == true) {
      findSubInventories();
    }
  }, [open])
  useEffect(() => {
  }, [row.userId])
  const validate = () => {
    let tempCurrentRoles = currentRoles.filter(x => x.roleId !== 0);
    return tempCurrentRoles.length > 0;
  }
  useEffect(() => {
    if (props.sendEditedData && props.editedUserId === row.userId) {
      if (validate()) {
        let tempRoles = currentRoles.map(x => x.roleId);
        let tempLocations = currentLocations.map(x => x.locId);
        let tempSites = currentSites.map(x => x.siteId);
        let dataToSave = {
          userId: row.userId,
          userName: row.name,
          email: row.email,
          phone: row.phone,
          supervisor: 'AX_ADMIN',
          defaultSiteId: defaultSite,
          roleIds: tempRoles.filter((x, ind) => x !== 0 && tempRoles.indexOf(x) === ind),
          siteIds: tempSites.filter((x, ind) => x !== 0 && tempSites.indexOf(x) === ind),
          locationIds: tempLocations.filter((x, ind) => x !== 0 && tempLocations.indexOf(x) === ind),
          subInventories: [...currentSubInventories],
          updatedBy: 'AX_ADMIN',
        }
        props.saveEditedData(dataToSave);
        props.setSendEditedData(false);
        props.setRefresh(!props.refresh);
        setToast({});
        setOpen(false);
      }
      else {
        props.setSendEditedData(false);
        const toastProps = { open: true, type: "error", message: "Please select atleast one role", onClose: () => setToast({}) };
        setToast({ ...toastProps });
      }
    }
  }, [props.sendEditedData])

  const handleRoleChange = (_roleId, ind) => {
    let tempCurrentRoles = currentRoles;
    tempCurrentRoles[ind].roleId = _roleId;
    tempCurrentRoles[ind].roleDesc = props.rolesData.find(x => x.id === _roleId).description;
    setCurrentRoles([...tempCurrentRoles]);
  }
  const addRole = () => {
    let tempCurrentRoles = currentRoles;
    tempCurrentRoles.unshift({ roleId: 0 });
    setCurrentRoles([...tempCurrentRoles]);
  }
  const handleSiteChange = (_siteId, ind) => {
    let tempCurrentSites = currentSites;
    tempCurrentSites[ind].siteId = _siteId;
    tempCurrentSites[ind].ouName = props.sitesData.find(x => x.id === _siteId).ouName;
    setCurrentSites([...tempCurrentSites]);
  }
  const addSite = () => {
    let tempCurrentSites = currentSites;
    tempCurrentSites.unshift({ siteId: 0 });
    setCurrentSites([...tempCurrentSites]);
  }
  const handleLocationChange = (_selectedSubInventory, ind) => {
    let tempCurrentLocations = [...currentSubInventories];
    tempCurrentLocations[ind].organizationId = _selectedSubInventory?.organizationId;
    tempCurrentLocations[ind].subInventory = _selectedSubInventory?.secondaryInventoryName;
    setCurrentSubInventories([...tempCurrentLocations]);
  }
  const addLocation = () => {
    let tempCurrentLocations = [...currentSubInventories];
    tempCurrentLocations.unshift({ organizationId: 0 });
    setCurrentSubInventories([...tempCurrentLocations]);
  }
  const findSelectedSubInventory = (row) => {
    const selected = props.subInventories?.find(item => item.organizationId == row.organizationId && item.secondaryInventoryName == row.subInventory);
    return selected;
  }
  return (
    <React.Fragment>
      <ToastMessage toast={toast} />
      <TableRow
        className='admin-security-users-table-row'
        sx={{
          "& > *": { borderBottom: open ? "inherit" : "unset" }, background: open ? "#F3F4F5" : "white", borderBottom: open ? "1px solid rgba(224, 224, 224, 1)" : "none"
        }}
      >
        <TableCell component='th' scope='row' sx={{
          borderLeft: '5px solid #006CEC !important', borderBottom: '0', borderBottomLeftRadius: '5px',
          borderTopLeftRadius: '5px',
        }}>
          {row.userId}
        </TableCell>
        <TableCell align='right'>{row.name}</TableCell>
        <TableCell align='right'>{row.email}</TableCell>
        <TableCell align='right'>{row.phone}</TableCell>

        <TableCell className='table-cell-with-dropdown' align='right'>
          {open ? <SelectComponent
            label=''
            values={[{ id: 0, value: "Select Current Site" }, ...props.sitesData.filter(x => x.Id === 0 || currentSites.map(cs => cs.siteId).includes(x.siteId))]}
            selectedvalue={defaultSite}
            disabled={false}
            width='100%'
            parentselectcallback={(selectedSite) => setDefaultSite(selectedSite)}
          ></SelectComponent> : row.currentSite}
        </TableCell>

        <TableCell sx={{ display: "flex" }} className='collapsible-button-cell' >
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => {
            setSelectedUser(row); setOpenConfirm(true);
          }}>
            <Delete />
          </IconButton>
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => {
            setOpen(!open)
            props.setChange(!open);
          }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

      </TableRow>

      <TableRow>
        <TableCell sx={{ padding: 0, background: open ? "#F3F4F5" : "white", }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box className='collapsable-table-box-main'>
              <Box className='collapsable-table-box-sub'>
                <Box className='roles-grid-main'>
                  <Table
                    className='admin-security-users-roles-data-main'
                    size='small'
                    aria-label='purchases'
                  >
                    <TableHead >
                      <TableRow>
                        <TableCell>{t('ROLES')}</TableCell>
                        <TableCell>{t('DESCRIPTION')}</TableCell>
                        <TableCell align='right'>{t('ACTIONS')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRoles.map((rolesRow, index) => (
                        <TableRow key={"roles-" + index}>
                          <TableCell
                            component='th'
                            scope='row'
                            className='table-cell-with-dropdown'
                          >
                            <SelectComponent
                              label=''
                              values={props.rolesData}
                              selectedvalue={rolesRow.roleId}
                              disabled={false}
                              width='100%'
                              parentselectcallback={(_roleId) => handleRoleChange(_roleId, index)}
                            ></SelectComponent>
                          </TableCell>
                          <TableCell>{rolesRow.roleDesc}</TableCell>
                          <TableCell align='right' className='action-button-cell'>
                            <Box className='action-buttons'>
                              <IconButton disabled={loading || rolesRow.roleId === 0} onClick={addRole}>
                                <AddBoxIcon

                                  color={rolesRow.roleId !== 0 ? 'primary' : 'disabled'}
                                ></AddBoxIcon>
                              </IconButton>
                              <IconButton disabled={loading || currentRoles.length === 1} onClick={() => {
                                let tempCurrentRoles = currentRoles;
                                tempCurrentRoles.splice(index, 1);
                                setCurrentRoles([...tempCurrentRoles])
                              }
                              }>
                                <IndeterminateCheckBoxIcon
                                  sx={currentRoles.length !== 1 ? { color: red[500] } : null}>
                                </IndeterminateCheckBoxIcon>
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box className='sites-loactions-parent-box'>
                  <Box className='sites-grid-main'>
                    <Table
                      className='admin-security-users-sites'
                      size='small'
                      aria-label='purchases'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('SITES')}</TableCell>
                          <TableCell>{t('DESCRIPTION')}</TableCell>
                          <TableCell align='right'>{t('ACTIONS')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentSites.map((sitesRow, index) => (
                          <TableRow key={"sites-" + index}>
                            <TableCell
                              component='th'
                              scope='row'
                              className='table-cell-with-dropdown'
                            >
                              <SelectComponent
                                label=''
                                values={props.sitesData}
                                selectedvalue={sitesRow.siteId}
                                disabled={false}
                                width='100%'
                                parentselectcallback={(_siteId) => handleSiteChange(_siteId, index)}
                              ></SelectComponent>
                            </TableCell>
                            <TableCell>{sitesRow.ouName}</TableCell>
                            <TableCell align='center' className='action-button-cell'>
                              <Box className='action-buttons'>
                                <IconButton disabled={ loading || sitesRow.siteId === 0} onClick={addSite}>
                                  <AddBoxIcon
                                    color={sitesRow.siteId !== 0 ? 'primary' : 'disabled'}
                                  >
                                  </AddBoxIcon>
                                </IconButton>
                                <IconButton disabled={loading || currentSites.length === 1} onClick={() => {
                                  let tempCurrentSites = currentSites;
                                  tempCurrentSites.splice(index, 1);
                                  setCurrentSites([...tempCurrentSites])
                                }
                                }>
                                  <IndeterminateCheckBoxIcon
                                    sx={currentSites.length !== 1 ? { color: red[500] } : null}
                                  >
                                  </IndeterminateCheckBoxIcon>
                                </IconButton>
                              </Box>

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box className='location-grid-main'>
                    <Table
                      className='admin-security-users-location'
                      size='small'
                      aria-label='purchases'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('DIVISION')}</TableCell>

                          <TableCell align='right'>{t('ACTIONS')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentSubInventories.map((locationRow, index) => (
                          <TableRow key={"location-" + index}>
                            <TableCell
                              component='th'
                              scope='row'
                              className='table-cell-with-dropdown'
                            >
                              <div className='wo-create-wo-desc asset-rig-dropdown'>

                                <Autocomplete
                                  value={findSelectedSubInventory(locationRow) || null}
                                  onChange={(event, newValue) => handleLocationChange(newValue, index)}
                                  inputValue={locationRow.organizationName}
                                  onInputChange={(event, newInputValue) => {
                                    let tempCurrentLocations = currentSubInventories;

                                    setCurrentSubInventories([...tempCurrentLocations]);
                                  }}
                                  getOptionLabel={options === null ? "" : (option) => `${option?.organizationName} - ${option?.secondaryInventoryName}`}
                                  id="controllable-states-demo"
                                  options={props?.subInventories}
                                  sx={{ width: "100%" }}

                                  getOptionSelected={(option, value) => `${option?.organizationName}-${option?.secondaryInventoryName}` === `${value?.organizationName}-${value?.secondaryInventoryName}`}
                                  renderInput={(params) => <TextField {...params} placeholder={"Search " + t('DIVISION')} />}
                                />
                              </div>

                            </TableCell>

                            <TableCell align='center' className='action-button-cell'>
                              <Box className='action-buttons'>
                                <IconButton disabled={loading || locationRow?.organizationId === 0} onClick={addLocation}>
                                  <AddBoxIcon

                                    color={locationRow.organizationId !== 0 ? 'primary' : 'disabled'}
                                  ></AddBoxIcon>
                                </IconButton>
                                <IconButton disabled={loading || (currentSubInventories?.length >= 1 && locationRow?.organizationId === 0)} onClick={() => {
                                  let tempCurrentSubInventories = currentSubInventories;
                                  tempCurrentSubInventories.splice(index, 1);
                                  setCurrentSubInventories([...tempCurrentSubInventories])
                                }
                                }>
                                  <IndeterminateCheckBoxIcon
                                    sx={(currentSubInventories.length >= 1 && locationRow.organizationId != 0) ? { color: red[500] } : null}
                                  ></IndeterminateCheckBoxIcon>
                                </IconButton>
                              </Box>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

export default function AxSecurityUsersTableComponent(props) {
  const { showNewUser } = props;

  const { user } = useContext<any>(UserContext);

  const [usersData, setUsersData] = useState<any>([]);
  const [totalElements, setTotalElements] = useState(1);
  const [rolesData, setRolesData] = useState<any>([{ id: 0, value: 'Select Role' }]);
  const [sitesData, setSitesData] = useState<any>([{ id: 0, value: 'Select Site' }]);
  const [locationsData, setLocationsData] = useState<any>([{ id: 0, value: 'Select Location' }]);
  const [subInventories, setSubInventories] = useState<any[]>([{ id: 0, value: 'Select Sub Inventory' }]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  //Add new user code start
  const [userName, setUserName] = useState("");
  const [emailAddress, setUserEmailAddress] = useState("");
  const [phoneNumber, setUserPhoneNumber] = useState("");
  const [currentSite, setUserCurrentSte] = useState(0);
  const [userNameError, setUserNameError] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [editedUserId, setEditedUserId] = useState("");
  const [siteError, setSiteError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const findSubInventories = async (_sites) => {
    const ordIds = _sites.map(item => item.orgId);
    try {
      const response = await addSubInventories('', 0, 10000, ordIds, user.id);
      if (response && response.statusCode === '200') {
        const subInventoriesSource = response.data.content.map(x => ({
          ...x,
        }));
        setSubInventories([...subInventoriesSource]);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching sub inventories:", error);
    }
  };


  const deleteSelectedUser = (_user) => {
    deleteUser(_user)
      .then(res => {
        if (res.statusCode == 200) {
          setOpenConfirm(false);
          setRefresh(!refresh);
          const toastProps = { open: true, type: "success", message: res.message, onClose: () => setToast({}) };
        }
        else {
          const toastProps = { open: true, type: "error", message: res.message, onClose: () => setToast({}) };
          setTimeout(() => setToast(toastProps));
        }
      }).catch((err) => {
        const toastProps = { open: true, type: "error", message: "Some error occured. Please contact admin.", onClose: () => setToast({}) };
        setTimeout(() => setToast(toastProps));
      })
  }

  useEffect(() => {
    const responses = Promise.all([getAllRoles(), getAllSites(0, 1000), getAllLocations()]);
    responses.then(async (res) => {
      res[0].data.content.forEach(x => {
        x.value = x.name
      });
      res[1].data.content.forEach(x => {
        x.id = x.siteId;
        x.value = x.orgName;
      });
      res[2].data.content.forEach(x => {
        x.id = x.locId;
        x.value = x.locCode;
      });
      await findSubInventories([...res[1].data.content]);
      setRolesData([...rolesData, ...res[0].data.content]);
      setSitesData([...sitesData, ...res[1].data.content]);
      setLocationsData([...locationsData, ...res[2].data.content]);
    });
  }, [])

  const axInputUserName = (e) => {
    setUserName(e);

    setUserNameError("");
  };
  const axInputUserEmailAddress = (e) => {
    setUserEmailAddress(e);
    if (!validateEmail(e) && e) {
      setEmailAddressError("You have entered an invalid email address!");
    } else {
      setEmailAddressError("");
    }
  };
  const axInputUserPhoneNumber = (e) => {
    setUserPhoneNumber(e);
    setPhoneNumberError("");
  };
  const axSelectUserCurrentSite = (e) => {
    setUserCurrentSte(e);
    if (!validateIsNumber(e) && e) {
      setSiteError("Please select the site");
    }
    else {
      setSiteError("");
    }
  };
  //Add new user code end

  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('userId');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(usersData || [], getComparator(order, orderBy)),
    [usersData, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData = () => {
    setLoading(true);
    getSearchUsers(0, 10000, props.keyword !== "" ? props.keyword : null)
      .then(async (response) => {
        if (response.statusCode == 200) {
          setPage(0);
          await response?.data?.content?.forEach((x, index) => {
            x.currentSite = x.sites.find(item => item.siteId === x.defaultSiteId)?.orgName;
          })
          setUsersData([...response.data.content]);
          setTotalElements(response.data.totalElements);
          setLoading(false);
        }
      })
  }

  useEffect(() => {
    if (props.filter) {
      props.setFilter(false);
      fetchData();
    }
  }, [props.filter])

  useEffect(() => {

    fetchData();
  }, [props.reload, refresh]);

  useEffect(() => {
    if (props.flagToSaveNewUser) {
      let returnWithoutSave = false;
      if (userName === "") {
        setUserNameError("This is required field");
        returnWithoutSave = true;
      }
      if (emailAddress === "") {
        setEmailAddressError("This is required field");
        returnWithoutSave = true;
      }
      if (phoneNumber === "") {
        setPhoneNumberError("This is required field");
        returnWithoutSave = true;
      }
      if (sitesData === "") {
        setSiteError("This is required field");
        returnWithoutSave = true;
      }
      if (!returnWithoutSave) {
        let data = {
          userName: userName,
          email: emailAddress,
          phone: phoneNumber,
          supervisor: null,
          defaultSiteId: currentSite,
          siteIds: [],
          roleIds: [],
          locationIds: [],
          createdBy: "",
          updatedBy: ""
        }
        props.saveNewUser(data);

      }
    }
    props.setSaveNewUser(false);
    setUserName('');
    setUserEmailAddress('');
    setUserPhoneNumber('');
    setUserCurrentSte(0);
  }, [props.flagToSaveNewUser])

  return (
    <Box>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      <TableContainer className="table-container-users">
        <Table stickyHeader
          sx={{
            "& th": { padding: "0px 16px", textAlign: "left" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          className='admin-users-main-table'
          aria-label='collapsible table'
        >

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={userTableColumns}
          />
          <TableBody>
            {showNewUser && (
              <TableRow className="adduser-data" key={"addnewuser"}>
                <TableCell>
                </TableCell>
                <TableCell>
                  <InputComponent
                    label={""}
                    value={userName}
                    disabled={false}
                    width={"100%"}
                    onChange={(e) => axInputUserName(e.target.value)}
                    error={userNameError}
                  ></InputComponent>
                </TableCell>
                <TableCell>
                  <InputComponent
                    label={""}
                    value={emailAddress}
                    disabled={false}
                    width={"100%"}
                    onChange={(e) => axInputUserEmailAddress(e.target.value)}
                    error={emailAddressError}
                  ></InputComponent>
                </TableCell>
                <TableCell>
                  <InputComponent
                    label={""}
                    value={phoneNumber}
                    disabled={false}
                    width={"100%"}
                    onChange={(e) => axInputUserPhoneNumber(e.target.value)}
                    error={phoneNumberError}
                  ></InputComponent>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>

                <TableCell align='center' className='table-cell-with-dropdown'></TableCell>
              </TableRow>
            )}
            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <Row
                key={row.userid || index}
                row={row}
                loading={loading}
                setChange={props.setChange}
                setOpenConfirm={(e) => setOpenConfirm(e)}
                setSelectedUser={(e) => setSelectedUser(e)}
                rolesData={rolesData}
                sitesData={sitesData}
                locationsData={locationsData}
                subInventories={[...subInventories.filter(y => row.sites.map(x => x.orgId).includes(y.organizationId))]}
                setEditedUserId={setEditedUserId}
                editedUserId={editedUserId}
                sendEditedData={props.sendEditedData}
                setSendEditedData={props.setSendEditedData}
                saveEditedData={props.saveEditedData}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
      <ConfirmDialog openConfirm={openConfirm}
        hidebuttons={false}
        msg={`Do you want to delete  user ${selectedUser?.name}?`}
        accept={() => {
          deleteSelectedUser(selectedUser?.userId);
        }}
        reject={() => { setOpenConfirm(false); }}
      />
    </Box>

  );
}
