import { Box, Button, Drawer, Grid, IconButton, Paper, Stack, TablePagination, Toolbar, Typography, } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import { WorkOrderHomePageTableComponent, InputComponent, SelectComponent } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import { workOrderGridColumns } from "../constants/workOrders";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkOrderStatus, getWorkOrdersBySearchAndSorting } from "../../services/WorkOrderServices";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { exportToExcel } from "../../services/ExcelExportService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function WorkOrdersHomePage() {
    const { user, selectedSite, oktaAuth, smallScreens, largeScreens } = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const dashboardPrams = location?.state || JSON.parse(localStorage.getItem('dashParams'));
    const priorities = JSON.parse(localStorage.getItem('woPriorities'))?.map((row) => ({ id: row?.value == 'Please Select' ? 'All' : row?.value, value: row?.value == 'Please Select' ? 'All' : row?.value }));
    const localFetchParams = JSON.parse(localStorage?.getItem('woLocalFetchParams'));
    const [searchFilter, setSearchFilter] = useState(
        {
            workOrderId: localFetchParams?.searchFilters?.workOrderId || '',
            assetNumber: localFetchParams?.searchFilters?.assetNumber || '',
            rig: localFetchParams?.searchFilters?.rig || '',
            status: dashboardPrams?.status || localFetchParams?.searchFilters?.status || 3,
            priority: dashboardPrams?.priority || localFetchParams?.searchFilters?.priority || 'All',
            sortBy: localFetchParams?.searchFilters?.sortBy || '',
            parameter: localFetchParams?.searchFilters?.parameter || ''
        }
    )
    const [showFilterFields, setShowFilterFields] = useState(location?.state || dashboardPrams || false);
    const [workordersData, setWorkordersData] = React.useState();
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(localFetchParams?.page || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localFetchParams?.rowsPerPage || 25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [workOrderStatus, setWorkOrderStatus] = useState([]);
    const [doSearch, setDoSearch] = useState(false);
    const isClear = useRef(false);
    const path = location.pathname;

    const fetchWorkOrdersByFilterData = () => {
        try {
            setLoading(true);
            const localSetSearchFilters = { ...localFetchParams, searchFilters: searchFilter };
            localStorage.setItem('woLocalFetchParams', JSON.stringify(localSetSearchFilters));

            const getFilteredPriorities = () => {
                const currentPriority = dashboardPrams?.priority || searchFilter?.priority;

                if (currentPriority === 'All') {
                    return priorities.filter(item => item.value !== 'All').map(item => item.value);
                }

                return [currentPriority];
            };

            const filteredPriority = getFilteredPriorities();

            getWorkOrdersBySearchAndSorting(
                page,
                rowsPerPage,
                selectedSite?.organizationId,
                JSON.parse(searchFilter?.status || '[]'),
                searchFilter?.workOrderId,
                searchFilter?.assetNumber,
                searchFilter?.rig,
                user?.id,
                searchFilter?.sortBy,
                searchFilter?.parameter,
                filteredPriority,
                dashboardPrams?.woDueType || '',
                dashboardPrams?.woType || '',
                dashboardPrams?.divisions?.map((item) => item?.id) ||
                JSON.parse(localStorage.getItem("divisions"))?.map((item) => item?.id) || ''
            )
                .then(response => {
                    if (response?.statusCode === "200") {
                        setWorkordersData(response.data?.content);
                        setTotalElements(response.data?.totalElements);
                    } else {
                        console.warn("Failed to fetch data: ", response?.message || "Unknown error");
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error in fetching work orders: ", error);
                    setLoading(false);
                });
        } catch (err) {
            console.error("Unexpected error: ", err);
            setLoading(false);
        }
    };


    const fetchWorkOrderStatus = () => {
        getWorkOrderStatus()
            .then((response, i) => {
                if (response?.statusCode === "200") {
                    const data = response.data.map((res) => {
                        return { id: res.statusId, value: res.workOrderStatus }
                    })
                    const ids = data
                        .filter(item => item.value === 'On Hold' || item.value === 'Released')
                        .map(item => item.id);

                    data?.unshift({ id: "[3, 4, 6]", value: "All" });
                    setWorkOrderStatus(data);
                }
            })
            .catch((err) => console.log(err));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        const savedParams = JSON.parse(localStorage.getItem('woLocalFetchParams'));
        const savePage = { ...savedParams, page: newPage };
        localStorage.setItem('woLocalFetchParams', JSON.stringify(savePage));
    };
    /*********************************************************/

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        const savedParams = JSON.parse(localStorage.getItem('woLocalFetchParams'));
        const savePage = { ...savedParams, rowsPerPage: +event.target.value };
        localStorage.setItem('woLocalFetchParams', JSON.stringify(savePage));
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchWorkOrdersByFilterData()
        }
    };

    const clearFilters = () => {
        fetchWorkOrderStatus();
        isClear.current = true;
        setPage(0);
        setRowsPerPage(25);
        setSearchFilter({ workOrderId: '', assetNumber: '', rig: '', status: 3, priority: 'All', sortBy: "", parameter: "" });

    }

    useEffect(() => {
        localStorage.setItem('dashParams', JSON.stringify(dashboardPrams))
    }, [])

    useEffect(() => {
        if (isClear.current === true) {
            fetchWorkOrdersByFilterData();
            setShowSideBar(!showSideBar)
            isClear.current = false;
        }
    }, [searchFilter])

    useEffect(() => {
        fetchWorkOrderStatus();
    }, [selectedSite])

    useEffect(() => {
        if (selectedSite)
            fetchWorkOrdersByFilterData();
    }, [page, rowsPerPage, selectedSite, path, searchFilter.sortBy, searchFilter.parameter]);

    const findStatus = (status) => {
        return workOrderStatus?.filter(function (val) { return val.id === status })[0]?.value;
    }

    const excelExport = () => {
        let query = new URLSearchParams();
        if (selectedSite?.organizationId) {
            query += 'organizationId=' + selectedSite?.organizationId
        }
        const statusParams = JSON.parse(searchFilter?.status || '[]')
        if (Array.isArray(statusParams)) {
            statusParams?.forEach(value => {
                query += '&status=' + value;
            });
        } else if (searchFilter?.status !== '') {
            query += '&status=' + searchFilter?.status;
        }
        if (searchFilter?.workOrderId !== '') {
            query += '&workOrderId=' + searchFilter?.workOrderId;
        }
        if (searchFilter?.assetNumber !== '') {
            query += '&assetNumber=' + searchFilter?.assetNumber;
        }
        if (searchFilter?.rig !== '') {
            query += '&rig=' + searchFilter?.rig;
        }
        if (user.id) {
            query += '&userId=' + user?.id;
        }
        const prioritiesParams = dashboardPrams?.priority == 'All' ?
            priorities?.filter(item => item.value !== 'All')?.map(item => item.value)
            :
            dashboardPrams?.priority
                ||
                searchFilter?.priority == 'All' ?
                priorities?.filter(item => item.value !== 'All')?.map(item => item.value)
                :
                searchFilter?.priority
        if (Array.isArray(prioritiesParams)) {
            prioritiesParams.forEach(value => {
                query += '&priority=' + value;
            });
        } else if (prioritiesParams !== '') {
            query += '&priority=' + prioritiesParams;
        }
        if (dashboardPrams?.woDueType && dashboardPrams?.woDueType !== '') {
            query += '&woDueType=' + dashboardPrams?.woDueType;
        }
        if (dashboardPrams?.woType && dashboardPrams?.woType !== '') {
            query += '&woType=' + dashboardPrams?.woType;
        }
        if (searchFilter?.sortBy !== '') {
            query += '&woType=' + searchFilter?.sortBy;
        }
        if (searchFilter?.parameter !== '') {
            query += '&woType=' + searchFilter?.parameter;
        }
        const divisionIdParams = dashboardPrams?.divisions?.map((item) => item?.id) ||
            JSON.parse(localStorage.getItem("divisions"))?.map((item) => item?.id) || ''
        if (Array.isArray(divisionIdParams)) {
            divisionIdParams.forEach(value => {
                query += '&divisionIds=' + value;
            });
        } else if (divisionIdParams !== '') {
            query += '&divisionIds=' + divisionIdParams;
        }
        const url = `/excelExport/exportWorkOrders?${query.toString()}`;
        exportToExcel(url);
    };

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                (largeScreens) && (
                    <Box className='homePageContentWrapper'>
                        <Box className='homePageHeader'>
                            {
                                dashboardPrams &&
                                <IconButton sx={{ marginLeft: '-10px' }} onClick={() => window.history.back()}>
                                    <ArrowBackIcon />
                                </IconButton>
                            }
                            <Typography variant='h5' flexGrow={1}>
                                Work Orders
                            </Typography>
                            <Stack direction={'row'}>
                                <IconButton onClick={() => navigate('/workorders/createWorkOrder')}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => setShowFilterFields(!showFilterFields)}>
                                    {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                                </IconButton>
                                <IconButton onClick={excelExport}>
                                    <IosShareIcon style={{ fontSize: "20" }} />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Box className='homePageFilters'>
                            {showFilterFields && (
                                <Box className='filters-lg'>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Work Order'}
                                        </Typography>
                                        <InputComponent

                                            width="100%"
                                            name="workorderId"
                                            value={searchFilter?.workOrderId}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Asset'}
                                        </Typography>
                                        <InputComponent

                                            name="assetNumber"
                                            width="100%"
                                            value={searchFilter?.assetNumber}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%" >
                                        <Typography >
                                            {'Parent Asset'}
                                        </Typography>
                                        <InputComponent

                                            width="100%"
                                            value={searchFilter?.rig}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['rig']: e.target.value });
                                            }}
                                            disabled={false}
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Status'}
                                        </Typography>
                                        <SelectComponent
                                            label={'Status'}
                                            values={workOrderStatus}
                                            onKeyPress={handleInputKeyPress}
                                            selectedvalue={searchFilter?.status}
                                            disabled={false}
                                            width='100%'
                                            parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['status']: e })}
                                        ></SelectComponent>

                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Priority'}
                                        </Typography>
                                        <SelectComponent
                                            name={"priority"}
                                            values={priorities}
                                            selectedvalue={searchFilter.priority}
                                            disabled={false}
                                            width='100%'
                                            parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['priority']: e })}
                                        />
                                    </Stack>
                                    <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => { fetchWorkOrdersByFilterData(); setPage(0); }}
                                            edge="start"
                                        >
                                            <SearchIcon />
                                        </IconButton>

                                        <IconButton onClick={clearFilters}
                                            aria-label="search"
                                            edge="start"
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                        <Box className='homePageTable'>
                            {
                                workordersData && (
                                    <WorkOrderHomePageTableComponent
                                        rowCount={totalElements}
                                        columns={workOrderGridColumns}
                                        page={page}
                                        setSearchFilter={setSearchFilter}
                                        searchFilter={searchFilter}
                                        showFilterFields={showFilterFields}
                                        setPage={(e) => setPage(e)}
                                        rowsPerPage={rowsPerPage}
                                        workOrderStatus={workOrderStatus}
                                        setRowsPerPage={(e) => setRowsPerPage(e)}
                                        data={workordersData}
                                        doSearch={doSearch}
                                        setDoSearch={setDoSearch}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        fetchData={fetchWorkOrdersByFilterData}
                                        selectedSite={selectedSite}
                                    ></WorkOrderHomePageTableComponent>
                                )
                            }
                        </Box>
                    </Box>
                )
            }
            {//mobile resolution
                smallScreens && (
                    <Box className='mobileHomePageContentWrapper'>
                        <Drawer
                            anchor="right"
                            open={showSideBar}
                            onClose={() => setShowSideBar(!showSideBar)}
                            PaperProps={{ style: { width: '300px' } }} >
                            <Box className='drawerContent' padding={'10px 15px'}>
                                <Toolbar />
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                    <Typography variant='boldBody' color={'black !important'}>Filters</Typography>
                                    <IconButton sx={{ marginRight: '-10px' }} onClick={() => setShowSideBar(!showSideBar)}>
                                        <FilterAltOffOutlinedIcon />
                                    </IconButton>
                                </Stack>
                                <Stack direction={'column'} gap={'10px'}>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Work Order'}
                                        </Typography>
                                        <InputComponent

                                            width="100%"
                                            name="workorderId"
                                            value={searchFilter?.workOrderId}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Asset'}
                                        </Typography>
                                        <InputComponent

                                            name="assetNumber"
                                            width="100%"
                                            value={searchFilter?.assetNumber}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%" >
                                        <Typography >
                                            {'Parent Asset'}
                                        </Typography>
                                        <InputComponent

                                            width="100%"
                                            value={searchFilter?.rig}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['rig']: e.target.value });
                                            }}
                                            disabled={false}
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Status'}
                                        </Typography>
                                        <SelectComponent
                                            label={'Status'}
                                            values={workOrderStatus}
                                            onKeyPress={handleInputKeyPress}
                                            selectedvalue={searchFilter?.status}
                                            disabled={false}
                                            width='100%'
                                            parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['status']: e })}
                                        ></SelectComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Priority'}
                                        </Typography>
                                        <SelectComponent
                                            name={"priority"}
                                            values={priorities}
                                            selectedvalue={searchFilter.priority}
                                            disabled={false}
                                            width='100%'
                                            parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['priority']: e })}
                                        />
                                    </Stack>

                                    <Button variant='contained' onClick={() => {setPage(0); fetchWorkOrdersByFilterData(); setShowSideBar(!showSideBar) }}>Search</Button>
                                    <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                </Stack>
                            </Box>
                        </Drawer>
                        <Box className='homePageHeader'>
                            <Typography variant='boldBody' flexGrow={1}>
                                Workorders
                            </Typography>
                            <Stack direction={'row'} >
                                <IconButton onClick={() => navigate('/workorders/createWorkOrder')}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                                    {showSideBar ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                                </IconButton>
                                <IconButton onClick={excelExport}>
                                    <IosShareIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Box className='homePagePagination'>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component='div'
                                count={totalElements || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}

                            />
                        </Box>
                        <Box className='homePageMobileTableContent' sx={{ height: window.innerHeight - 166 }}>
                            {workordersData?.length === 0 && <Typography variant="h6">No data found</Typography>}
                            {workordersData && workordersData?.map((row, index) => (
                                <Grid key={index} onClick={() => { navigate('/workorders/details', { state: row?.workOrderId }); localStorage.setItem('woNumber', row?.workOrderId) }} className="mobileHomePageListCard">
                                    <Paper elevation={0} >
                                        <Box alignItems='start' flex={1}>
                                            <Typography variant="boldBody">{row?.workOrderId}</Typography>
                                            <Typography ><Typography variant="boldBody" component={'span'}>Status: </Typography>{row?.statusType && findStatus(row?.statusType)}</Typography>
                                            <Typography ><Typography variant="boldBody" component={'span'}>Asset: </Typography>{row?.assetNumber ? row?.assetNumber : "-    "}</Typography>
                                            <Typography ><Typography variant="boldBody" component={'span'}>Cls Code: </Typography>{row?.classCode ? row?.classCode : '-'}</Typography>


                                        </Box>
                                        <Box textAlign='end' flex={1}>
                                            <Typography ><Typography variant="boldBody" component={'span'} >WO Type: </Typography>{row.woTypeDesc ? row.woTypeDesc : '-'}</Typography>
                                            <Typography ><Typography variant="boldBody" component={'span'} >Priority: </Typography>{row.priority ? row.priority?.slice(2) : '-'}</Typography>
                                            <Typography ><Typography variant="boldBody" component={'span'} >
                                                {window.innerWidth < 700 ? "Sch St: " : "Scheduled Start: "}</Typography>{row.scheduledStart ? row.scheduledStart : '-'}</Typography>
                                            < Typography > <Typography variant="boldBody" component={'span'} >{window.innerWidth < 700 ? "Sch Fi: " : "Scheduled Finish: "}</Typography>{row.scheduledComplete ? row.scheduledComplete : '-'}</Typography>

                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}