import React, { useState, useEffect, useContext } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { AxBroadcastTableComponent, DatePickerComponent, InputComponent, ButtonComponent } from "../shared-components/common-field-components";
import { BroadcastTableColumns } from "../constants/BroadcastTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { t } from "i18next";
import "./admin.css";
import { deleteBroadcastMessage, getBroadcastMessages, saveBroadcastMessage } from "../../services/BroadcastMessagesServices";
import ToastMessage from "../shared-components/ToastMessage";
import * as yup from 'yup';
import { useFormik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
import { UserContext } from "../../store/UserContext";


const validationSchema = yup.object({
  message: yup.string().trim().required("Required"),
  startDate: yup.string().required("Required"),
  expirationsDate: yup.string().required("Required"),
});

const Broadcast = () => {
  const [broadcastData, setBroadcastData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [toast, setToast] = useState({});
  const [addRecord, setAddRecord] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const d = new Date();
  const dateDefaultValue = dayjs(d, "DD-MM-YYYY");

  async function fetchData() {
    setLoading(true);
    const templates = await getBroadcastMessages(0, 10000);
    setBroadcastData(templates.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [errors, setErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      message: null,
      startDate: null,
      expirationsDate: null,
      createdBy: user.name,
      lastUpdatedBy: user.name
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
    },
  });

  const saveRecord = () => {
    setLoading(true);
    formik.validateForm().then(async (err) => {
      if (Object.keys(err).length) {
        setErrors({ ...err });
      } else {
        const reqBody = { ...formik.values };
        const response = await saveBroadcastMessage(reqBody)
        if (response.statusCode === "200") {
          const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
          setToast(toastProps);
          setErrors({});
          formik.resetForm();
          setAddRecord(false);
          fetchData();
          setLoading(false);
        } else {
          const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
          setToast(toastProps);
          setLoading(false);
        }
      }
    }).catch((err) => {
      console.log("error", err);
      setLoading(false);
    })
  }

  const deleteRecord = async (id) => {
    const response = await deleteBroadcastMessage(id);
    setLoading(true);
    if (response?.statusCode === "200") {
      const toastProps = { open: true, type: "success", message: "Deleted successfully", onClose: () => setToast({}) };
      setToast(toastProps);
      fetchData();
      setLoading(false);
    } else {
      const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
      setToast(toastProps);
      setLoading(false);
    }
  }

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  return (
    <Box className='admin-broadcast-main-notification'>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      {/*--------------------------------------------------------- pc layout start here -----------------------------------------------------------------------*/}
      {screenSize?.dynamicWidth > 599 &&
        <Box className='admin-broadcast-table'>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} mb={"10px"}>
            <Box className="add-button-pc">
              {!addRecord && broadcastData?.content?.length === 0 && <ButtonComponent icon label={t("ADD_BROADCAST_MESSAGE")} className='add-roles-button'
                buttoncallBack={() => {
                  setAddRecord(true);
                }}>
              </ButtonComponent>}
              {addRecord && broadcastData?.content?.length === 0 && <Box className="save-button">
                <ButtonComponent label={t("SAVE")} color={'#2BAA4F'}
                  buttoncallBack={() => saveRecord()}>
                </ButtonComponent>
              </Box>}
              {addRecord && broadcastData?.content?.length === 0 && <ButtonComponent label={t("CANCEL")} color={'#ff0500'}
                buttoncallBack={() => {
                  setAddRecord(false);
                }}>
              </ButtonComponent>}
            </Box>
          </Box>
          <AxBroadcastTableComponent
            columns={BroadcastTableColumns}
            data={broadcastData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            saveRecord={saveRecord}
            deleteRecord={deleteRecord}
            formik={formik}
            errors={errors}
            setErrors={setErrors}
            addRecord={addRecord}
            setAddRecord={setAddRecord}
            dateDefaultValue={dateDefaultValue} />
        </Box>
      }
      {/*--------------------------------------------------------- mobile layout start here -----------------------------------------------------------------------*/}
      {
        screenSize?.dynamicWidth < 599 &&
        <Box className="expanddata-notifications">
          <Box justifyContent="right" className="admin-security-content-page-header">
            <Typography variant={'h5'} paddingLeft="10px">
              Notifications
            </Typography>
            <Box className="add-button-mobile">
              {!addRecord && (
                <Box className="add-icon-notification">
                  <IconButton onClick={() => setAddRecord(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
              {addRecord && (
                <Box className="save-button-mobile-icon">
                  <IconButton
                    onClick={saveRecord}>
                    <SaveIcon sx={{ color: '#2BAA4F' }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ minWidth: 275 }} className="broadcast-table" >
            <Box className="table-data-broadcast-mobile">
              {addRecord &&
                <Card variant="outlined">
                  <React.Fragment>
                    <CardContent>
                      <TableContainer>
                        <Table
                          aria-label='simple table'
                        >
                          <TableBody>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('MESSAGE')}
                              </TableCell>
                              <TableCell align='left'>
                                <InputComponent
                                  name={"message"}
                                  onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                  width={"100%"}
                                  maxLength={"250"}
                                  error={errors?.message} />
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('START_DATE')}
                              </TableCell>
                              <TableCell align='left'>
                                <DatePickerComponent
                                  name={"startDate"}
                                  dateDefaultValue={dateDefaultValue}
                                  onDateSelect={(date) => formik.setFieldValue("startDate", date, false)}
                                  error={errors?.startDate}
                                  defaultValue={dateDefaultValue}
                                  value={formik?.values.startDate} />
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('EXPIRATION_DATE')}
                              </TableCell>
                              <TableCell align='left'>
                                <DatePickerComponent
                                  name={"expirationsDate"}
                                  dateDefaultValue={dateDefaultValue}
                                  onDateSelect={(date) => formik.setFieldValue("expirationsDate", date, false)}
                                  error={errors?.expirationsDate}
                                  value={formik?.values.expirationsDate} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </React.Fragment>
                  <Box className="delete-icon">
                    <IconButton onClick={() => setAddRecord(false)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Card>}
              {broadcastData?.content?.map((row, index) => (
                <Card key={row.broadcastMessagesId} variant="outlined">
                  <React.Fragment>
                    <CardContent>
                      <TableContainer>
                        <Table
                          aria-label='simple table'
                        >
                          <TableBody key={index}>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('MESSAGE')}
                              </TableCell>
                              <TableCell align='left'>{row.message}</TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('START_DATE')}
                              </TableCell>
                              <TableCell align="left">
                                {row.startDate
                                  ? new Date(row.startDate).toLocaleDateString() // Format to date only
                                  : "N/A" // Fallback for null/undefined
                                }
                              </TableCell>

                            </TableRow>
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                {t('EXPIRATION_DATE')}
                              </TableCell>
                              <TableCell align="left">
                                {row.expirationsDate
                                  ? new Date(row.expirationsDate).toLocaleDateString() // Parse and format the date
                                  : "N/A" // Handle null/undefined gracefully
                                }
                              </TableCell>


                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </React.Fragment>
                  <Box className="delete-icon">
                    <IconButton onClick={() => deleteRecord(row.broadcastMessagesId)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Card>
              ))}
              {broadcastData.totalElements >= rowsPerPage && <span onClick={() => setRowsPerPage(prevState => prevState + 10)}>Load more</span>}
            </Box>
          </Box>
        </Box>
      }
      {/*--------------------------------------------------------- mobile layout end here -----------------------------------------------------------------------*/}
      <ToastMessage toast={toast} />
    </Box >
  );
};

export default Broadcast;
