import { Typography, IconButton, Grid, Button, TextField, Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import '../styles/homepage.css';

import { InputComponent, SelectComponent, DatePickerComponent } from "../shared-components/common-field-components";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { createWorkOrder, getSourceAssetsByOrgId, getWorkOrderPriorities, getWorkOrderClassCode, getSourceAssetsByOrgIdandFilter, getParentAssetsByOrgIdandFilter } from "../../services/WorkOrderServices";
import { UserContext } from "../../store/UserContext";
import { useState } from "react";
import ToastMessage from "../shared-components/ToastMessage";
import { ConfirmDialog } from "../shared-components/ConfirmDialog";
import Autocomplete from '@mui/material/Autocomplete';

function CreateWorkOrder() {
    const { t } = useTranslation();
    const d = new Date();

    const { oktaAuth, selectedSite, user, permissions, recievedPermisions, Category_Values } = useContext(UserContext);
    const accessToken = oktaAuth?.oktaAuth?.getAccessToken();
    const priorityValues = JSON.parse(localStorage.getItem('woPriorities')) || []
    const [toast, setToast] = useState({});
    const [priorities, setPriorities] = useState(priorityValues);
    const [sourceAssetsDropdown, setSourceAssetsDropdown] = useState([]);
    const [classCode, setClassCode] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [dateDefaultValue1, setDateDefaultValue1] = useState("");
    const [dpvalue, setDpValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [parentDpvalue, setParentDpValue] = useState(null);
    const [parentInputValue, setParentInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [assetOptions, setAssetOptions] = useState([])
    const [disableSubmit, setDiasableSubmit] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [dpLoading, setDpLoading] = useState(false);

    // Handling permissions
    useEffect(() => {
        if (!permissions.workOrders && recievedPermisions) {
            navigate('/dashboard');
        }
    }, [])

    const fetchClassCodes = async (orgId) => {
        setLoading(true);
        try {
            const res = await getWorkOrderClassCode(orgId);
            if (res && res.data && res.data.length > 0) {
                const formattedData = [
                    { id: 0, value: "Please Select" },
                    ...res.data.map(x => ({
                        id: x.classCode,
                        value: x.classCode,
                        description: x.description
                    }))
                ];
                setClassCode(formattedData);
            } else {
                console.warn('No class codes found for the provided organization ID.');
                setClassCode([]);
            }
        } catch (error) {
            console.error('Error fetching class codes:', error);
        } finally {
            setLoading(false);
        }
    };

    const searchParentAsset = async (_filter = '') => {
        setDpLoading(true)
        getParentAssetsByOrgIdandFilter(page, rowsPerPage, selectedSite?.organizationId, user?.id, _filter).then(res => {
            setDpLoading(false)
            if (res && res?.statusCode === "200") {
                setSourceAssetsDropdown(res?.data?.content);
                setOptions(res?.data?.content);
            }
        });
    }

    const searchSourceAsset = async (_filter = '', _parent = '') => {
        setDpLoading(true);
        getSourceAssetsByOrgIdandFilter(page, rowsPerPage, selectedSite?.organizationId, user?.id, _filter, _parent).then(res => {
            setDpLoading(false)
            if (res && res?.statusCode === "200") {
                setAssetOptions(res?.data?.content);
            }
        });

    }

    useEffect(() => {
        searchParentAsset();
        searchSourceAsset();
        fetchClassCodes(selectedSite?.organizationId);
    }, [selectedSite])

    function isValidClassCode(message) {
        return this.test("isValidClassCode", message, function (value) {
            const { path, createError } = this;

            if (!value || value < 2) {
                return createError({ path, message: message });
            }

            return true;
        });
    }
    yup.addMethod(yup.string, "isValidClassCode", isValidClassCode);

    function isValidPriority(message) {
        return this.test("isValidPriority", message, function (value) {
            const { path, createError } = this;

            if (!value || value < 1) {
                return createError({ path, message: message });
            }

            return true;
        });
    }

    yup.addMethod(yup.string, "isValidPriority", isValidPriority);

    const validationSchema = yup.object().shape({
        category: yup.string().required('Category is required'),
        classCode: yup.string().required("This is required field"),
        scheduledStartDate: yup.date().typeError("This is required field").required('This is required field'),
        scheduledFinishDate: yup
            .date()
            .typeError('This is required field')
            .test(
                'is-not-before-start',
                'Finish date must be the same as or later than the start date',
                function (value) {
                    const { scheduledStartDate } = this.parent;
                    const startDate = dayjs(scheduledStartDate);
                    const finishDate = dayjs(value);
                    return finishDate.isSame(startDate) || finishDate.isAfter(startDate);
                }
            )
            .required('This is required field'),
        sourceAsset: yup.string().required("This is required field"),
        priority: yup.string().isValidPriority('This is required field'),
        briefDesc: yup.string().max(240, 'Description should not exceed 240 characters').required("This is required field"),
    });

    useEffect(() => {
        setDiasableSubmit(disableSubmit);
    }, [disableSubmit])

    return (
        <Box width={'100%'} height={'100%'} >
            {loading &&

                <div class="loading">Loading&#8230;</div>
            }

            <ConfirmDialog openConfirm={openConfirm} msg={"Are you sure?"}
                accept={() => {
                    setOpenConfirm(false); navigate("/workorders");
                }}
                reject={() => setOpenConfirm(false)}
            />
            <Box sx={{ width: "100%", display: 'flex', padding: window.innerWidth > 599 ? '10px 24px' : '10px 16px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='boldBody' fontWeight={600} >
                    {t('WO_CREATE_WORKORDER')}
                </Typography>
                <IconButton onClick={() => setOpenConfirm(true)} sx={{ padding: window.innerWidth < 599 ? '0px' : 'auto' }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box className="cwo-overflow" sx={{ height: window.innerHeight - 124, width: '100%', padding: window.innerWidth > 599 ? '0px 24px' : '0px 16px 10px 16px', overflow: 'auto' }} >
                <Box className='cwo-container'>
                    <Formik validationSchema={validationSchema}
                        initialValues={{
                            category: 1,
                            classCode: 0,
                            scheduledStartDate: null,
                            scheduledFinishDate: null,
                            sourceAsset: '',
                            priority: 0,
                            assetGroup: '',
                            department: '',
                            briefDesc: '',
                            employeeInitials: '',
                            moveToRig: '',
                            organizationId: '',
                            organizationCode: '',
                        }}
                        validateOnChange={false}
                        onSubmit={(values, formik) => {

                            values = {
                                category: Category_Values.find(x => x.id === values.category)?.value,
                                classCode: values.classCode,
                                scheduledStartDate: values.scheduledStartDate,
                                scheduledFinishDate: values.scheduledFinishDate,
                                sourceAsset: values.sourceAsset,
                                assetGroup: values.assetGroup,
                                priority: values.priority !== '' && priorities.find(x => x.id === values.priority)?.id,
                                briefDesc: `${Category_Values.find(x => x.id === values.category)?.value} - ${values.briefDesc}`,
                                additionalDesc: values.additionalDesc,
                                employeeInitials: values.employeeInitials,
                                moveToRig: values.moveToRig,
                                organizationId: selectedSite?.organizationId,
                                organizationCode: selectedSite?.organizationCode,
                                createdBy: user?.id
                            }
                            setLoading(true);
                            setDiasableSubmit(true);
                            createWorkOrder(accessToken, JSON.stringify(values))
                                .then(response => {
                                    if (response?.statusCode === '200') {
                                        const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                                        setToast(toastProps);
                                        setTimeout(() => {
                                            setLoading(false);
                                            navigate("/workorders");
                                        }, 10000);
                                    }
                                    else {
                                        const toastProps = { open: true, type: "error", message: response?.message, onClose: () => setToast({}) };
                                        setToast(toastProps);
                                        setLoading(false);
                                        setDiasableSubmit(false);
                                    }

                                }).catch((error) => {
                                    const toastProps = { open: true, type: "error", message: error?.message, onClose: () => setToast({}) };
                                    setToast(toastProps);
                                    setLoading(false);
                                    setDiasableSubmit(false);
                                });
                        }}
                    >
                        {(formik) => {
                            const { errors, values } = formik;

                            return (
                                <Form>
                                    <Container maxWidth="lg" >
                                        <Box className="cwo-form" sx={{ padding: window.innerWidth > 600 && '20px', border: window.innerWidth > 600 && '1px solid #c8cbd2', borderRadius: window.innerWidth > 600 && '4px' }}>
                                            <Grid container spacing={3} className="dropdownspace" >
                                                <Grid item xs={12} sm={6} className="categoryDropdown" >
                                                    <Typography>{t("WO_CREATE_WO_DROPDOWN_CATEGORY")}</Typography>
                                                    <SelectComponent
                                                        name={'category'}
                                                        label={t("WO_CREATE_WO_DROPDOWN_CATEGORY")}
                                                        values={Category_Values}
                                                        selectedvalue={values.category}
                                                        disabled={false}
                                                        width='100%'
                                                        parentselectcallback={(val) => {
                                                            formik.setFieldValue('category', val)
                                                                .then(() => formik.validateField('category'));
                                                        }}
                                                        error={errors.category}
                                                        placeholder="Please Select Category"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {
                                                        <div className='wo-create-wo-desc asset-rig-dropdown'>
                                                            <Typography>{t("PARENT_ASSET")}</Typography>
                                                            <Autocomplete
                                                                value={parentDpvalue || null}
                                                                onChange={(event: any, newValue: string | null) => {
                                                                    setParentDpValue(newValue);
                                                                    searchSourceAsset('', newValue?.parentInstanceNumber);
                                                                }}
                                                                inputValue={parentInputValue}
                                                                onInputChange={(event, newInputValue) => {
                                                                    setParentInputValue(newInputValue);
                                                                    searchParentAsset(newInputValue)

                                                                }}
                                                                loading={dpLoading}
                                                                getOptionLabel={(option) => option?.parentInstanceNumber || ""}
                                                                id="controllable-states-demo"
                                                                options={options}
                                                                sx={{ width: "100%", marginTop: '8px' }}
                                                                renderInput={(params) => <TextField {...params} placeholder={"Search " + t("PARENT_ASSET")} />}
                                                            />
                                                        </div>
                                                    }


                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className='wo-create-wo-desc asset-rig-dropdown'>
                                                        <Typography>{'Source Asset'}</Typography>
                                                        <Autocomplete
                                                            value={dpvalue || null}
                                                            onChange={(event: any, newValue: string | null) => {
                                                                if (newValue !== null) {
                                                                    setDpValue(newValue);
                                                                    formik.setFieldValue('sourceAsset', newValue.assetNumber).then(() => formik.validateField('sourceAsset'));
                                                                    formik.setFieldValue('assetGroup', newValue.type);
                                                                    formik.setFieldValue('classCode', newValue.accountingClassCode);
                                                                    formik.setFieldValue('department', newValue?.departmentCode)
                                                                }
                                                                else {
                                                                    setDpValue(null);
                                                                    formik.setFieldValue('sourceAsset', '').then(() => formik.validateField('sourceAsset'));
                                                                    formik.setFieldValue('assetGroup', '');
                                                                    formik.setFieldValue('classCode', '');
                                                                }

                                                            }}
                                                            inputValue={inputValue}
                                                            loading={dpLoading}
                                                            onInputChange={(event, newInputValue) => {
                                                                setInputValue(newInputValue);
                                                                searchSourceAsset(newInputValue, parentDpvalue?.parentInstanceNumber)
                                                            }}
                                                            disabled={options === null || options.length === 0}
                                                            getOptionLabel={((option) => option?.assetNumber ? `${option?.assetNumber} - ${option?.description}` : '')}
                                                            id="controllable-states-demo"
                                                            options={assetOptions}
                                                            sx={{ width: "100%", marginTop: '8px' }}
                                                            renderInput={(params) => <TextField {...params} placeholder={"Search " + 'Source Asset'} />}
                                                        />
                                                        {formik?.values.sourceAsset === "" && <span style={{ color: "red", fontSize: "14px" }}>{formik?.errors?.sourceAsset}</span>}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{'Asset Group'}</Typography>
                                                    <InputComponent
                                                        name={'assetGroup'}
                                                        value={values.assetGroup}
                                                        disabled={true}
                                                        width='100%'
                                                        error={errors.assertGroup}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{'Department'}</Typography>
                                                    <InputComponent
                                                        name={'department'}
                                                        value={values.department}
                                                        disabled={true}
                                                        width='100%'
                                                        error={errors.department}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} className="categoryDropdown">
                                                    <Typography>{t("WO_CREATE_WO_DROPDOWN_CLASS_CODES")}</Typography>
                                                    <SelectComponent
                                                        name="classCode"
                                                        label={t("WO_CREATE_WO_DROPDOWN_CLASS_CODES")}
                                                        values={[{ label: "Please Select", value: "" }, ...classCode]} // Add placeholder option
                                                        selectedvalue={formik.values.classCode}
                                                        disabled={false}
                                                        width="100%"
                                                        parentselectcallback={(val) => {
                                                            formik.setFieldValue("classCode", val);
                                                        }}
                                                        error={formik.errors.classCode}
                                                    />

                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{t("WO_CREATE_WO_DROPDOWN_PRIORITY")}</Typography>
                                                    <SelectComponent
                                                        name={"priority"}
                                                        label={t("WO_CREATE_WO_DROPDOWN_PRIORITY")}
                                                        values={priorities}
                                                        selectedvalue={values.priority}
                                                        disabled={false}
                                                        width='100%'
                                                        parentselectcallback={(val) => {
                                                            formik.setFieldValue('priority', val)
                                                                .then(() => formik.validateField('priority'));
                                                        }}
                                                        error={errors.priority}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{t("WO_CREATE_WO_DATEPICKER_SCHEDULED_START")}</Typography>
                                                    <DatePickerComponent
                                                        name="scheduledStartDate"
                                                        label={t("WO_CREATE_WO_DATEPICKER_SCHEDULED_START")}
                                                        dateDefaultValue={values.scheduledStartDate}
                                                        disabled={false}
                                                        onDateSelect={(val) => {
                                                            formik.setFieldValue('scheduledStartDate', val)
                                                                .then(() => formik.validateField('scheduledStartDate'));
                                                        }}
                                                        error={errors.scheduledStartDate}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{t("WO_CREATE_WO_DATEPICKER_SCHEDULED_FINISH")}</Typography>
                                                    <DatePickerComponent
                                                        name="scheduledFinishDate"
                                                        minDate={formik.values.scheduledStartDate}
                                                        label={t("WO_CREATE_WO_DATEPICKER_SCHEDULED_FINISH")}
                                                        dateDefaultValue={values.scheduledFinishDate}
                                                        disabled={false}
                                                        onDateSelect={(val) => {
                                                            formik.setFieldValue('scheduledFinishDate', val)
                                                                .then(() => formik.validateField('scheduledFinishDate'));
                                                        }}
                                                        error={errors.scheduledFinishDate}
                                                    />
                                                </Grid>



                                                {values.category === 2 || values.category === 6 ?
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography>{t("WO_CREATE_WO_INPUTLABEL_EMPLOYEE_INITIALS")}</Typography>
                                                        <InputComponent
                                                            name={'employeeInitials'}
                                                            value={values.employeeInitials}
                                                            disabled={false}
                                                            width='100%'
                                                            onChange={(e) => formik.setFieldValue('employeeInitials', e.target.value)}
                                                            error={errors.employeeInitials}
                                                            placeholder="Enter Employee Initials"
                                                        />
                                                    </Grid> : null
                                                }

                                                {values.category === 5 ?
                                                    <><Grid item xs={12} sm={6}>
                                                        <Typography>{t("WO_CREATE_WO_INPUTLABEL_MOVED_TO_RIG")}</Typography>
                                                        <InputComponent
                                                            name={'moveToRig'}
                                                            value={values.moveToRig}
                                                            disabled={false}
                                                            width='100%'
                                                            onChange={(e) => formik.setFieldValue('moveToRig', e.target.value)}
                                                            error={errors.moveToRig}
                                                            placeholder="Enter Move To Rig"
                                                        />
                                                    </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography>{t("WO_CREATE_WO_DATEPICKER_DATE_OF_REMOVAL")}</Typography>
                                                            <DatePickerComponent
                                                                dateDefaultValue={dateDefaultValue1}
                                                                disabled={false}
                                                                onDateSelect={(e) => { setDateDefaultValue1(e) }}
                                                            />
                                                        </Grid></> : null
                                                }


                                            </Grid>

                                            <Grid container spacing={3} paddingTop={3} >
                                                <Grid item xs={12} sm={12} className='wo-create-wo-desc'>
                                                    <Typography>Description</Typography>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        name="briefDesc"
                                                        multiline
                                                        rows={6}
                                                        disabled={false}
                                                        sx={{ marginTop: '8px', width: '100%' }}
                                                        value={formik.values.briefDesc} // Binding field value
                                                        onChange={formik.handleChange} // Using Formik's change handler
                                                        variant="outlined"
                                                        error={formik.touched.briefDesc && formik.errors.briefDesc ? true : false}
                                                        helperText={
                                                            formik.touched.briefDesc && formik.errors.briefDesc ? (
                                                                <span style={{ color: 'red', fontSize: '14px' }}>
                                                                    {formik.errors.briefDesc}
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                        placeholder="Enter a brief description..."
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Box>
                                        <Grid container spacing={3} justifyContent={"flex-end"} marginTop={0.1} >
                                            <Grid item className='cancel-button-hover'>
                                                <Button
                                                    color={'error'}
                                                    icon={false}
                                                    variant="contained"
                                                    disabled={loading}
                                                    onClick={() => setOpenConfirm(true)}
                                                >{t("CANCEL")}</Button>
                                            </Grid>
                                            <Grid item className='wo-create-wo-button-ok'>
                                                <Button variant="contained" icon={false} disabled={disableSubmit} type={'submit'} >
                                                    {t("OK")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>

                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>

            <ToastMessage toast={toast} />
        </Box >
    );
}

export default CreateWorkOrder;
