import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import { Box, TableSortLabel } from "@mui/material";
import { t } from "i18next";
import dayjs from 'dayjs';
import { getWorkOrderDetailsByWorkOrderId } from '../../../services/WorkOrderServices';
import { UserContext } from '../../../store/UserContext';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {

        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function AxLogsWoTableComponent(props) {

    const { selectedSite } = React.useContext<any>(UserContext);
    const [viewWorkOrder, setViewWorkOrder] = useState(false);
    const { columns, data, page, setPage, rowsPerPage, setRowsPerPage,  } = props;
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('assetNumber');
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data?.content, getComparator(order, orderBy)),
        [data?.content, order, orderBy, page, rowsPerPage]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const fetchWorkOrderDetailsByWorkOrderIdData = async (workOrderId, orgId) => {
        const response = await getWorkOrderDetailsByWorkOrderId(workOrderId, orgId);
        if (response?.statusCode === "200") {
            props?.setClickedWorkOrder(response?.data);
        }
    };

    const showWorkorder = (workOrderId, orgId) => {
        setViewWorkOrder(!viewWorkOrder);
        fetchWorkOrderDetailsByWorkOrderIdData(workOrderId, orgId);
    }

    useEffect(() => {
        props.workOrderCallback(viewWorkOrder);
    }, [viewWorkOrder])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = visibleRows?.map((n) => n);
            props.setSelected(newSelected);
            return;
        }
        props.setSelected([]);
    };

    const dateFormat = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('MM-DD-YYYY');
    }

    const handleClick = (event, name) => {

        const selectedIndex = props.selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [name];
        }

        props.setSelected(newSelected);

    };
    const isSelected = (name) => props.selected?.indexOf(name) !== -1;
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string }
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort<T>(
        array: readonly T[],
        comparator: (a: T, b: T) => number
    ) {
        const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }


    interface EnhancedTableProps {
        onRequestSort: (
            event: React.MouseEvent<unknown>,
            property: string
        ) => void;
        order: Order;
        orderBy: string;
        headCells: any[],
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, onRequestSort, headCells, data, handleSelectAllClick } = props;

        const createSortHandler =
            (property: string) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow style={{ height: 10 }}>
                    <StyledTableCell align='center' className='checkbox-table-head'>{ }</StyledTableCell>
                    {headCells?.map((headCell) => (
                        <StyledTableCell
                            key={headCell.field}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.field ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {t(headCell.headerName)}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box>
            <TableContainer className='table-container-wo-logs'>
                <Table className='devices-table-main' sx={{
                    minWidth: 700,
                    '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                    '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "1px !important" },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                        color: "black"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                        color: "black"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                        color: "black"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                        color: "black", opacity: 100
                    }
                }} stickyHeader size="small" aria-label="sticky table">

                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        data={visibleRows}
                        selected={props.selected}
                        handleSelectAllClick={handleSelectAllClick}
                        headCells={columns}
                    />
                    <TableBody>
                        {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (

                                <StyledTableRow key={index}>
                                    <StyledTableCell align="center" className='checkbox-cell'> <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) => handleClick(event, row)}
                                        aria-checked={isItemSelected}
                                        selected={isItemSelected}
                                        inputProps={{
                                            "aria-labelledby": labelId
                                        }} /></StyledTableCell>
                                    <StyledTableCell className='lwotablecell2 admin-link' onClick={() => { showWorkorder(row.workOrder, row.orgId); }}> {row.workOrder}</StyledTableCell>
                                    <StyledTableCell className='lwotablecell2' >{row.type}</StyledTableCell>
                                    <StyledTableCell className='lwotablecell2'>{row.processStatus}</StyledTableCell>
                                    <StyledTableCell className='lwotablecell2'>{dateFormat(row.processed)}</StyledTableCell>
                                    <StyledTableCell className='lwotablecell3'>{row.message}</StyledTableCell>
                                </StyledTableRow>
                            );

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={data?.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}