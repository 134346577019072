import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Typography, Link as MuiLink, Box, useMediaQuery, Grid, IconButton, useTheme, FormControl, Autocomplete, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WorkOrdersDasboardIcon from "../../assets/images/dashboard/Filter.svg";
import AssetsDasboardIcon from "../../assets/images/dashboard/Layers_fill.svg";
import PaiDasboardIcon from "../../assets/images/dashboard/Calendar.svg";
import MtDasboardIcon from "../../assets/images/dashboard/Subtract.svg";
import ReportsDasboardIcon from "../../assets/images/dashboard/Vector.svg";
import BpDasboardIcon from "../../assets/images/dashboard/QR.svg";
import AdminDasboardIcon from "../../assets/images/dashboard/User_fill.svg";
import { useTranslation } from "react-i18next";
import "./dashboard.css"
import { useContext } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { UserContext } from "../../store/UserContext";
import { InfoDialog } from "../shared-components/InfoDialog";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AxDashboardTable } from "../shared-components/common-field-components";
import Masonry from '@mui/lab/Masonry';
import { getDashboadData, getDashboardDueWo } from "../../services/DashboardServices";
import { getAllDivisions } from "../../services/AdminSecurityServices";
import ClearIcon from '@mui/icons-material/Clear';
import { getWorkOrderPriorities } from "../../services/WorkOrderServices";

export default function Dashboard(props) {
  const [error, setError] = useState({ ...props.error });
  const savedSelectedDivision = JSON.parse(localStorage.getItem('selectedDivisions'))
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions, oktaAuth, user, smallScreens, selectedSite, userProfile, largeScreens } = useContext(UserContext);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [hideContent, setHideContent] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const currentDateTime = new Date().toLocaleString();
  const [dashboardData, setDashboardData] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [divData, setDivData] = React.useState('');
  const [dueWoTableData, setDueWoTableData] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalElements, setTotalElements] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [cleared, setCleared] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [divInput, setDivInput] = useState('');
  const [selectedDivision, setSelectedDivision] = useState(savedSelectedDivision || []);
  const [currentWeekRange, setCurrentWeekRange] = useState({});
  const [currentMonthRange, setCurrentMonthRange] = useState({});
  const [nextMonthRange, setNextMonthRange] = useState({});
  const [previousMonthRange, setPreviousMonthRange] = useState({});
  const [priorities, setPriorities] = useState([]);


  const menusList = [
    { id: 1, name: t("MENU_WORK_ORDERS"), path: "/workorders/work-order-component", icon: WorkOrdersDasboardIcon, hasAccess: permissions.workOrders },
    { id: 2, name: t("menuassets"), path: "/assets", icon: AssetsDasboardIcon, hasAccess: permissions.assets },
    { id: 3, name: t("MENU_PLANNED_ASSET_INVENTORY"), path: "/planned-asset-inventory", icon: PaiDasboardIcon, hasAccess: permissions.plannedAssetInventory },
    { id: 5, name: t("MTF_MATERIAL_TRANSFER_FORM"), path: "/material-transfer-form", icon: MtDasboardIcon, hasAccess: permissions.materialTransferForm },
    { id: 6, name: t("MENU_REPORTS"), path: "/reports", icon: ReportsDasboardIcon, hasAccess: permissions.reports },
    { id: 7, name: t("MENU_BATCH_PROCESS"), path: "/batch-process", icon: BpDasboardIcon, hasAccess: permissions.batchProcess },
    { id: 8, name: t("menuadmin"), path: "/admin", icon: AdminDasboardIcon, hasAccess: permissions.admin },
  ];

  const Priority = [
    { label: 'Low priority', value: dashboardData?.lowPriorityWoCount || 0, color: '#04BFDA' },
    { label: 'High priority', value: dashboardData?.highPriorityWoCount || 0, color: '#FFA84A' },
    { label: 'Medium priority', value: dashboardData?.mediumPriorityWoCount || 0, color: '#9B88ED' },
    { label: 'Emergency priority', value: dashboardData?.emergencyPriorityWoCount || 0, color: '#FF0000' },
  ];

  const totalPriorityWoCount = Priority.reduce((total, priority) => total + priority.value, 0);

  const StyledContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  }));
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',

    fontSize: 15,
  }));

  function PieCenterLabel({ children, data }) {
    const { width, height, left, top } = useDrawingArea();
    return (

      <StyledText x={left + width / 2.1} y={top + height / 2}>
        {children}
      </StyledText>


    );
  }

  const Status = [
    { label: 'Released Work Orders', value: dashboardData?.releasedWoCount ?? 0, color: '#04BFDA' },
    { label: 'Completed Work Orders', value: dashboardData?.completedWoCount ?? 0, color: '#FFA84A' },
    { label: 'On Hold Work Orders', value: dashboardData?.onHoldWoCount ?? 0, color: '#9B88ED' },
  ];
  const totalWoCount = Status.reduce((total, status) => total + status.value, 0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => { setError({ ...props.error }) }, [props.error]);

  const getAllDiv = async () => {
    setLoading(true);
    const response = await getAllDivisions();
    if (response?.statusCode === "200") {
      const userDivisions = userProfile && userProfile?.userSubInventoriesResponses?.map((row) => (
        row.subInventory
      ))
      const filterDivision = response?.data?.content.filter(asset =>
        userDivisions?.includes(asset.division)
      );
      const divisionData = userProfile?.userSubInventoriesResponses.length == 0 ? response?.data?.content : filterDivision;
      setDivData(divisionData);
      filterDivision.length == 1 && setSelectedDivision(filterDivision);
      localStorage.setItem('divisions', JSON.stringify(divisionData));
      fetchDashboardData(selectedDivision?.length > 0 ? selectedDivision : divisionData);
    } else {
      console.warn("Failed to fetch divisions: ", response?.message || "Unknown error");
    }

    setLoading(false);
  };
  const formatDate = (date) => {
    if (!date) return '';
    const month = date.toLocaleString('default', { month: 'short' }); // Gets abbreviated month name
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const fetchPriorities = () => {
    getWorkOrderPriorities().then(res => {
      if (res && res?.statusCode === "200") {
        res?.data?.unshift({ priorityId: 0, priorityName: "Please Select" });
        res?.data?.forEach(x => {
          x.id = x.priorityId;
          x.value = x.priorityName
        })
        localStorage.setItem('woPriorities', JSON.stringify([...res?.data]))
        setPriorities([...res?.data]);
      }
    });
  }


  const fetchDashboardDueWo = (_divData, _keyword) => {
    setLoading(true);
    const params = {
      page: page,
      size: rowsPerPage,
      userId: user?.id,
      divisionIds: _divData?.map((item) => (item?.id)).toString(),
      organizationId: selectedSite?.organizationId,
      keyword: _keyword || '',
    }

    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
    });

    Object.keys(params).forEach(key => {
      if (params[key] === '' || params[key] === ',') {
        delete params[key];
      }
    });

    try {
      getDashboardDueWo(params).then(res => {
        if (res?.statusCode == "200") {
          setDueWoTableData(res?.data?.content);
          setTotalElements(res?.data?.totalElements);
        }
        setLoading(false)
      })
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDashboardDueWo(selectedDivision?.length > 0 ? selectedDivision : divData, keyword);
  };
  /*********************************************************/

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    fetchDashboardDueWo(selectedDivision?.length > 0 ? selectedDivision : divData, keyword);
  };

  const fetchDashboardData = (_divData) => {
    const params = {
      userId: user?.id,
      divisionIds: _divData?.map((item) => (item?.id)).toString(),
      organizationId: selectedSite?.organizationId
    }

    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
    });

    Object.keys(params).forEach(key => {
      if (params[key] === '' || params[key] === ',') {
        delete params[key];
      }
    });

    try {
      getDashboadData(params).then(res => {
        if (res?.statusCode == "200") {
          fetchDashboardDueWo(_divData);
          fetchPriorities();
          setDashboardData(res?.data);
        }
      })
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    // Check if there's any saved data in localStorage when the component mounts
    const savedDivisions = localStorage.getItem('selectedDivisions');
    if (savedDivisions) {
      setSelectedDivision(JSON.parse(savedDivisions));
    }
  }, []);

  const handleSelectionChange = (event, newValue) => {
    setSelectedDivision(newValue);
    fetchDashboardData(newValue?.length === 0 ? divData : newValue);

    // Save selected divisions to localStorage
    localStorage.setItem('selectedDivisions', JSON.stringify(newValue));
  };
  useEffect(() => {
    if (selectedSite && user && userProfile) {
      getAllDiv()
    }
  }, [user, selectedSite]);

  useEffect(() => {
    if (cleared) {
      setCleared(false);
      fetchDashboardDueWo(selectedDivision?.length > 0 ? selectedDivision : divData, keyword);
    }
  }, [cleared])

  let innerRadius = window?.innerWidth < 428 ? 4 : window?.innerWidth < 499 ? 7 : window.innerWidth < 700 ? 7 : window.innerWidth < 900 ? 9 : window.innerWidth > 900 && window.innerWidth < 1200 ? 12 : window.innerWidth < 1200 ? 8 : 7;
  let outerRadius = window?.innerWidth < 428 ? 3 : window?.innerWidth < 499 ? 5 : window.innerWidth < 700 ? 5 : window.innerWidth < 900 ? 7 : window.innerWidth > 900 && window.innerWidth < 1200 ? 9 : window.innerWidth < 1200 ? 6 : 5;
  let padding = window?.innerWidth < 599 ? 20 : 40;

  const gridRef = useRef(null);
  const dashMain = useRef(null)
  const [gridWidth, setGridWidth] = useState(0);

  useEffect(() => {
    if (gridRef.current) {
      setGridWidth(gridRef.current.offsetWidth);    }

    const handleResize = () => {
      if (gridRef.current) {
        setGridWidth(gridRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth, dashMain?.current?.offsetWidth]);

  const dashboardParams = {
    status: 3,
    priority: 'All',
    woDueType: null,
    woType: null,
    divisions: selectedDivision?.length > 0 ? selectedDivision : divData
  }

  useEffect(() => {
    const today = new Date();

    // Current Week Range
    const firstDayOfWeek = new Date(today);
    const dayOfWeek = firstDayOfWeek.getDay();
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() + diffToMonday);
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    setCurrentWeekRange({
      start: firstDayOfWeek,
      end: lastDayOfWeek,
    });

    // Current Month Range
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setCurrentMonthRange({
      start: firstDayOfMonth,
      end: lastDayOfMonth,
    });

    // Next Month Range
    const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const lastDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    setNextMonthRange({
      start: firstDayOfNextMonth,
      end: lastDayOfNextMonth,
    });

    // Previous Month Range
    const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    setPreviousMonthRange({
      start: firstDayOfPreviousMonth,
      end: lastDayOfPreviousMonth,
    });
  }, []);

  return (
    <Grid ref={dashMain} className="dashboard-main">
      {loading &&
        <div className="loading">
          Loading&#8230;</div>
      }
      {(error === null || Object.keys(error).length === 0) && !hideContent ?
        <>
          <Box className="dashboard-header" display="flex" justifyContent="space-between" alignItems="center">

            <Typography variant='h7' flexGrow={1}>{t('menudashboard')}</Typography>

            <Box sx={{ paddingRight: '4px' }}>
              <Autocomplete
                value={selectedDivision || []}
                onChange={(event, newValue) => {
                  setSelectedDivision(newValue);
                  fetchDashboardData(newValue?.length === 0 ? divData : newValue);
                  localStorage.setItem('selectedDivisions', JSON.stringify(newValue));
                }}
                multiple

                inputValue={divInput || ''}
                onInputChange={(event, newInputValue) => {
                  setDivInput(newInputValue);
                }}
                limitTags={2}
                disableCloseOnSelect
                getOptionLabel={(option) => option.division || ''}
                options={divData || []}
                renderOption={(props, option, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <li key={key} {...otherProps}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.division}
                    </li>
                  );
                }}
                sx={{ minWidth: window?.innerWidth > 599 ? '300px' : '250px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={selectedDivision?.length > 0 ? '' : 'All'}
                    fullWidth
                    disabled={true}
                  />
                )}
                disabled={divData && divData?.length == 1}
              />
            </Box>
          </Box>


          <Box className="dasboard-content">
            <Box className='due-data'>
              <Masonry columns={window.innerWidth > 1200 ? 3 : (window.innerWidth < 1200 && window.innerWidth > 600) ? 2 : 1}   >
                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3, woDueType: "DUE_PREVIOUS_MONTH_WO" } })}>
                  <Typography>Overdue Work Orders</Typography>
                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.woOverDueCount}</span>
                    {/*                     <span style={{ fontSize: 18 }}>Total</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3, woDueType: "DUE_THIS_MONTH_WO" } })}>
                  <Box className="due-format-dates">
                    <Typography>Work Orders Due This Month</Typography>
                    <Typography variant="body2">
                      ({formatDate(currentMonthRange.start)} - {formatDate(currentMonthRange.end)})
                    </Typography>
                  </Box>

                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.woDueMonthCount}</span>
                    {/*  <span style={{ fontSize: 18 }}>Total</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: '[3, 4, 6]', woType: "CWO" } })}>
                  <Typography>Corrective Work Orders</Typography>
                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.correctiveWoCount}</span>
                    {/*  <span style={{ fontSize: 18 }}>Total</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders',
                  { state: { ...dashboardParams, status: "3", woDueType: "DUE_THIS_WEEK_WO" } })}>

                  <Box className="due-format-dates">
                    <Typography>Work Orders Due This Week</Typography>
                    <Typography variant="body2">
                      ({formatDate(currentWeekRange.start)} - {formatDate(currentWeekRange.end)})
                    </Typography>

                  </Box>


                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.woDueWeekCount}</span>
                    {/* <span style={{ fontSize: 18 }}>Work Orders</span> */}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: "3", woDueType: "DUE_NEXT_MONTH_WO" } })}>
                  <Box className="due-format-dates">
                    <Typography>Work Orders Due Next Month</Typography>
                    <Typography variant="body2">
                      ({formatDate(nextMonthRange.start)} - {formatDate(nextMonthRange.end)})
                    </Typography>
                  </Box>
                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.woDueNextMonthCount}</span>
                    {/*  <span style={{ fontSize: 18 }}>Work Orders</span> */}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={1.5} className="due-info-child" onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: '[3, 4, 6]', woType: "PM" } })}>
                  <Typography>PM Work Orders</Typography>
                  <Typography>
                    <span style={{ fontSize: 36, marginRight: '6px' }}>{dashboardData?.pmWoCount}</span>
                    {/*     <span style={{ fontSize: 18 }}>Total</span> */}
                  </Typography>
                </Grid>
              </Masonry>
              {(largeScreens) && (
                <Masonry columns={window.innerWidth > 1200 ? 3 : (window.innerWidth < 1200 && window.innerWidth > 900) ? 1 : 1}>
                  <Grid item className="due-graph" sx={{ gap: '0px' }}>
                    <Box className="dashboard-table-title">
                      <Typography minWidth={'150px'} variant="boldBody">Work Orders Due Soon</Typography>
                      <FormControl sx={{
                        maxWidth: '250px',
                        '& .MuiInputBase-input': { padding: '10px 14px' },
                        '&.MuiResponsiveChart-container > svg': { width: " 62%" }
                      }} fullWidth>

                        <TextField
                          id="standard-basic"
                          placeholder="Keyword"
                          variant="standard"
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setKeyword("");
                                    setCleared(true);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    fetchDashboardDueWo(selectedDivision?.length > 0 ? selectedDivision : divData, keyword);
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>



                    </Box>
                    <Grid container >
                      <Grid item xs={12}>
                        <AxDashboardTable
                          divData={divData} data={dueWoTableData} handleChangeRowsPerPage={handleChangeRowsPerPage}
                          handleChangePage={handleChangePage} page={page} rowsPerPage={rowsPerPage} rowCount={totalElements}
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={1.5} className="due-graph">
                    <Box className="dashboard-table-title">
                      <Typography py={'8px'} variant="boldBody">Work Orders by Priority (Released)</Typography>
                    </Box>
                    <Box width={'100%'} height={250} position={'relative'}>
                      <PieChart
                        series={[
                          {
                            data: Priority,
                            cx: ((gridWidth - (padding + 10)) / 2),
                            cy: 125,
                            innerRadius: ((gridWidth - padding) / innerRadius),
                            outerRadius: ((gridWidth - padding) / outerRadius),
                          },
                        ]}
                        height={250}
                        sx={{ position: 'relative' }}
                        width={(gridWidth - padding)}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                      >

                      </PieChart>
                      <Typography
                        sx={{
                          position: 'relative',
                          textAnchor: 'middle',
                          dominantBaseline: 'middle',
                          bottom: '48%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          maxWidth: '131px',
                          textAlign: 'center'
                        }}
                      >
                        Total <span style={{ fontWeight: 'bold' }}>{dashboardData?.releasedWoCount}</span>
                      </Typography>
                    </Box>
                    <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                      <Box className="graph-title1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "3-Low" } })}>
                        {/* <CircularProgress variant="determinate" value={Priority[0]?.value} /> */}
                        <Typography>Low Priority Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "3-Low" } })}>< KeyboardArrowRightIcon /></IconButton>

                    </Box>

                    <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                      <Box className="graph-title2" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "2-Medium" } })}>
                        {/* <CircularProgress variant="determinate" value={Priority[1]?.value} /> */}
                        <Typography>Medium Priority Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "2-Medium" } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>

                    <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                      <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-High" } })}>
                        {/* <CircularProgress variant="determinate" value={Priority[2]?.value} /> */}
                        <Typography>High Priority Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-High" } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>

                    <Box className="graph-title" sx={{ borderBottom: 'none' }}>
                      <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => { navigate('/workorders', { state: { ...dashboardParams, priority: "1-Emergency" } }) }}>
                        {/* <CircularProgress variant="determinate" value={Priority[2]?.value} /> */}
                        <Typography>Emergency Priority Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-Emergency" } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>
                  </Grid>
                  <Grid ref={gridRef} item xs={12} md={1.5} className="due-graph">
                    <Box className="dashboard-table-title" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders')}>
                      <Typography py={'8px'} variant="boldBody">Work Orders by Status</Typography>
                    </Box>
                    <Box width={'100%'} height={250} position={'relative'}>
                      <PieChart
                        series={[
                          {
                            data: Status,
                            cx: ((gridWidth - (padding + 10)) / 2),
                            cy: 125,
                            innerRadius: ((gridWidth - padding) / innerRadius),
                            outerRadius: ((gridWidth - padding) / outerRadius),
                          },
                        ]}
                        height={250}
                        sx={{ position: 'relative' }}
                        width={(gridWidth - padding)}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                      >

                      </PieChart>
                      <Typography
                        sx={{
                          position: 'relative',
                          textAnchor: 'middle',
                          dominantBaseline: 'middle',
                          bottom: '48%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          maxWidth: '131px',
                          textAlign: 'center'
                        }}
                      >
                        Total <span style={{ fontWeight: 'bold' }}>{totalWoCount}</span>
                      </Typography>
                    </Box>
                    <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                      <Box className="graph-title1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3 } })}>
                        {/* <CircularProgress variant="determinate" value={Status[0]?.value} /> */}
                        <Typography>Released Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3 } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>

                    <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                      <Box className="graph-title2" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { status: 6 } })}>
                        {/* <CircularProgress variant="determinate" value={Status[1]?.value} /> */}
                        <Typography >On Hold Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 6 } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>

                    <Box className="graph-title" sx={{ borderBottom: 'none' }}>
                      <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { status: 4 } })}>
                        {/* <CircularProgress variant="determinate" value={Status[2]?.value} /> */}
                        <Typography >Completed Work Orders</Typography>
                      </Box>
                      <IconButton onClick={() => navigate('/workorders', { state: { status: 4 } })}>< KeyboardArrowRightIcon /></IconButton>
                    </Box>
                  </Grid>

                </Masonry>)
              }
              {
                smallScreens && (
                  <Box className='mobileHomePageContentWrapper'>
                    <Box sx={{ paddingBottom: 1 }}>
                      <Grid item className="due-graph" sx={{ gap: '0px' }}>
                        <Box className="dashboard-table-title">
                          <Typography minWidth={'150px'} variant="boldBody">Work Orders Due Soon</Typography>
                          <FormControl sx={{
                            maxWidth: '250px',
                            '& .MuiInputBase-input': { padding: '10px 14px' },
                            '&.MuiResponsiveChart-container > svg': { width: " 62%" }
                          }} fullWidth>

                            <TextField
                              id="standard-basic"
                              placeholder="Keyword"
                              variant="standard"
                              value={keyword}
                              onChange={(e) => {
                                setKeyword(e.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        setKeyword("");
                                        setCleared(true);
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        fetchDashboardDueWo(selectedDivision?.length > 0 ? selectedDivision : divData, keyword);
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>



                        </Box>
                        <Grid container >
                          <Grid item xs={12}>
                            <AxDashboardTable
                              divData={divData} data={dueWoTableData} handleChangeRowsPerPage={handleChangeRowsPerPage}
                              handleChangePage={handleChangePage} page={page} rowsPerPage={rowsPerPage} rowCount={totalElements}
                            />
                          </Grid>
                        </Grid>

                      </Grid> </Box>
                    <Masonry columns={window.innerWidth > 900 ? 2 : (window.innerWidth < 900 && window.innerWidth > 900) ? 2 : 1}>
                      <Grid className="due-graph">
                        <Box className="dashboard-table-title">
                          <Typography py={'8px'} variant="boldBody">Work Orders by Priority (Released)</Typography>
                        </Box>
                        <Box height={250} position={'relative'}>
                          <PieChart
                            series={[
                              {
                                data: Priority,
                                cx: ((gridWidth - (padding + 10)) / 2),
                                cy: 125,
                                innerRadius: ((gridWidth - padding) / innerRadius),
                                outerRadius: ((gridWidth - padding) / outerRadius),
                              },
                            ]}
                            height={250}
                            sx={{ position: 'relative' }}
                            width={(gridWidth - padding)}
                            slotProps={{
                              legend: { hidden: true },
                            }}
                          >

                          </PieChart>
                          <Typography
                            sx={{
                              position: 'relative',
                              textAnchor: 'middle',
                              dominantBaseline: 'middle',
                              bottom: '48%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              maxWidth: '90px',
                              textAlign: 'center'
                            }}
                          >
                            Total <span style={{ fontWeight: 'bold' }}>{dashboardData?.releasedWoCount}</span>
                          </Typography>
                        </Box>
                        <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                          <Box className="graph-title1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "3-Low" } })}>
                            {/* <CircularProgress variant="determinate" value={Priority[0]?.value} /> */}
                            <Typography>Low Priority Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "3-Low" } })}>< KeyboardArrowRightIcon /></IconButton>

                        </Box>

                        <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                          <Box className="graph-title2" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "2-Medium" } })}>
                            {/* <CircularProgress variant="determinate" value={Priority[1]?.value} /> */}
                            <Typography>Medium Priority Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "2-Medium" } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>

                        <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                          <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-High" } })}>
                            {/* <CircularProgress variant="determinate" value={Priority[2]?.value} /> */}
                            <Typography>High Priority Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-High" } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>

                        <Box className="graph-title" sx={{ borderBottom: 'none' }}>
                          <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-Emergency" } })}>
                            {/* <CircularProgress variant="determinate" value={Priority[2]?.value} /> */}
                            <Typography>Emergency Priority Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, priority: "1-Emergency" } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>
                      </Grid>
                      <Grid ref={gridRef} className="due-graph">
                        <Box className="dashboard-table-title" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders')}>
                          <Typography py={'8px'} variant="boldBody">Work Orders by Status</Typography>
                        </Box>
                        <Box height={250} position={'relative'}>
                          <PieChart
                            series={[
                              {
                                data: Status,
                                cx: ((gridWidth - (padding + 10)) / 2),
                                cy: 125,
                                innerRadius: ((gridWidth - padding) / innerRadius),
                                outerRadius: ((gridWidth - padding) / outerRadius),
                              },
                            ]}
                            height={250}
                            sx={{ position: 'relative' }}
                            width={(gridWidth - padding)}
                            slotProps={{
                              legend: { hidden: true },
                            }}
                          >

                          </PieChart>
                          <Typography
                            sx={{
                              position: 'relative',
                              textAnchor: 'middle',
                              dominantBaseline: 'middle',
                              bottom: '48%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              maxWidth: '131px',
                              textAlign: 'center'
                            }}
                          >
                            Total <span style={{ fontWeight: 'bold' }}>{totalWoCount}</span>
                          </Typography>
                        </Box>
                        <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                          <Box className="graph-title1" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3 } })}>
                            {/* <CircularProgress variant="determinate" value={Status[0]?.value} /> */}
                            <Typography>Released Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 3 } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>

                        <Box className="graph-title" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.30)' }}>
                          <Box className="graph-title2" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { status: 6 } })}>
                            {/* <CircularProgress variant="determinate" value={Status[1]?.value} /> */}
                            <Typography >On Hold Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { ...dashboardParams, status: 6 } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>

                        <Box className="graph-title" sx={{ borderBottom: 'none' }}>
                          <Box className="graph-title3" sx={{ cursor: 'pointer' }} onClick={() => navigate('/workorders', { state: { status: 4 } })}>
                            {/* <CircularProgress variant="determinate" value={Status[2]?.value} /> */}
                            <Typography >Completed Work Orders</Typography>
                          </Box>
                          <IconButton onClick={() => navigate('/workorders', { state: { status: 4 } })}>< KeyboardArrowRightIcon /></IconButton>
                        </Box>
                      </Grid>
                    </Masonry>
                  </Box>
                )}
            </Box>
          </Box>
        </>
        : <InfoDialog openInfo={true} msg={!hideContent ? error.message : popupMessage} okAction={async () => {
          await oktaAuth.signOut();
          navigate("/");
        }} />
      }
    </Grid >

  );
}
