import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { InputComponent } from ".";
import { deletePermission, getAllMenus } from "../../../services/AdminSecurityServices";
import ToastMessage from "../ToastMessage";
import { Delete } from "@mui/icons-material";
import { valid } from "joi";
import { useTranslation } from "react-i18next";
import { TableSortLabel } from "@mui/material";
import { ConfirmDialog } from "../ConfirmDialog";
import '../index.css'

const permissionTableColumns = [
  {
    field: "permName",
    headerName: "PERMISSION_LIST_ID",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "permDesc",
    headerName: "DESCRIPTION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "delete",
    headerName: "",
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">

                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const [row, setRow] = React.useState({ ...props.row });
  const [menus, setMenus] = useState([...props.menus]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = useState({});
  const { setSelectedPermission, setOpenConfirm, loading } = props;

  useEffect(() => {
    setRow({ ...props.row });
    setMenus([...props.menus]);
    setOpen(false);
  }, [props.row.permId])

  useEffect(() => {
    if (open) {
      props.setEditedPermId(row.permId);
      toggleSaveButton(menus);
    }
    else {
      props.setShowSaveButtonForEdit(false);
    }
  }, [open])

  useEffect(() => {
    if (props.sendEditedData && props.editedPermId === row.permId && validateMenuOptions()) {
      let dataToSave = {
        permId: row.permId,
        menus: [...menus]
      }
      props.saveEditedData(dataToSave);
      setOpen(false);
      props.setSendEditedData(false);
    }
  }, [props.sendEditedData])

  const validateMenuOptions = () => {
    const currentMenuOptions = menus.filter(x => x.accessType !== "" && x.accessType !== null);
    if (currentMenuOptions.length === 0) {
      const toastProps = { open: true, type: "error", message: "Select atleast one option from the menu", onClose: () => setToast({}) };
      setToast(toastProps);
      props.setSendEditedData(false);
      return false;
    }
    let valid = false;
    let tempPermissionsData = [...props.permissionsData];
    tempPermissionsData.forEach(x => {
      valid = false;
      let pastMenus = x.menus.filter(m => m.accessType !== '' && m.accessType !== null);
      let currMenus = currentMenuOptions.filter(m => m.accessType !== '' && m.accessType !== null);
      if (pastMenus.length === currMenus.length) {
        for (let i = 0; i < pastMenus.length; i++) {
          let option = currMenus.find(m => m.menuId === pastMenus[i].menuId
            && m.accessType === pastMenus[i].accessType);
          valid = option ? false : true;
          if (valid)
            break;
        }
        x.valid = valid;
      }
    });
    valid = tempPermissionsData.find(x => x.valid === false) ? false : true;
    if (!valid) {
      const toastProps = { open: true, type: "error", message: "Selected permissions already exists", onClose: () => setToast({}) };
      setToast(toastProps);
    }
    props.setSendEditedData(false);
    return valid;
  }

  const handleEdit = (menuOption, val) => {
    let _menus = [...menus], tempRow = row;
    let ind = _menus.findIndex(x => x.menuId === menuOption.menuId);
    if (_menus[ind].accessType === val) {
      menuOption.accessType = '';
    }
    else {
      menuOption.accessType = val;
    }
    _menus[ind] = menuOption;
    setMenus([..._menus]);
    toggleSaveButton(_menus);
  }

  const toggleSaveButton = (_menus) => {
    for (let i = 0; i < _menus.length; i++) {
      if (props.row.menus.find(y => y.menuId === _menus[i].menuId)?.accessType !== _menus[i].accessType) {
        props.setShowSaveButtonForEdit(true);
        return;
      }
      else {
        props.setShowSaveButtonForEdit(false);
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow
        className='admin-security-roles-table-row'
        sx={{
          "& > *": { borderBottom: open ? "inherit !important" : "unset" }, background: open ? "#F3F4F5" : "white", borderBottom: open ? "1px solid rgba(224, 224, 224, 1)" : "none"
        }}
      >

        <TableCell component='th' scope='row' sx={{
          borderLeft: '5px solid #006CEC !important', borderBottom: '0', borderBottomLeftRadius: '5px',
          borderTopLeftRadius: '5px'
        }}>
          {row.permName}
        </TableCell>
        <TableCell align='right'>{row.permDesc}</TableCell>

        <TableCell sx={{ display: "flex" }} className='collapsible-button-cell'>
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => {
            setSelectedPermission(row); setOpenConfirm(true);
          }}>
            <Delete />
          </IconButton>
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, background: open ? "#F3F4F5" : "white" }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box className='collapsable-table-box-main'>
              <Box className='collapsable-table-box-sub'>
                <Box className='roles-grid-main'>
                  <Table className='admin-security-roles-table' size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('MENU_NAME')}</TableCell>
                        <TableCell >{t('MENU_DESCRIPTION')}</TableCell>
                        <TableCell align='right'>{t('ACCESS_TYPE')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {menus?.map((permissionListOne, index) => (
                        <TableRow key={index}>
                          <TableCell component='th' scope='row'>
                            {permissionListOne.menuName}
                          </TableCell>
                          <TableCell>{permissionListOne.menuDescription}</TableCell>
                          <TableCell align='center'>
                            <Box className='checkbox-group-main'>
                              <FormGroup className='checkbox-group'>

                                <FormControlLabel
                                  control={
                                    <Checkbox checked={permissionListOne.accessType === "Edit"}
                                      onChange={() => {
                                        const temp = { ...permissionListOne };
                                        handleEdit(temp, "Edit")
                                      }}
                                    />
                                  }
                                  label={t('EDIT')}
                                />
                              </FormGroup>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ToastMessage toast={toast} />
    </React.Fragment>
  );
}

export default function AxSecurityPermissionListTableComponent(props) {
  const { permissionsData, showNewPermission, totalElements, loading } = props;
  const [currentPageData, setCurrentPageData] = useState([...permissionsData.slice(0, 10)]);
  const [permission, setPermission] = useState("");
  const [description, setDescription] = useState("");
  const [permissionsMenuList, setPermissionsMenuList] = useState<any>([]);
  const [permissionError, setPermissionError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [editedPermId, setEditedPermId] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [toast, setToast] = useState({});

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('permName');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(currentPageData || [], getComparator(order, orderBy)),
    [currentPageData, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/

  useEffect(() => {
    setCurrentPageData([...permissionsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [permissionsData])

  const fetchMenus = () => {
    getAllMenus().then(response => {
      if (response?.statusCode === "200") {
        response?.data.forEach(x => x.accessType = "");
        setPermissionsMenuList(response?.data);
      }
    })
      .catch(err => console.log(err));
  }

  const checkIsExist = (list, val) => {
    return list.find(x => x === val);
  }

  const validateData = () => {
    const regEx = /^[0-9a-zA-Z ]+$/;
    let valid = false;
    if (checkIsExist(permissionsData.map(x => x.permName), permission)) {
      setPermissionError("Entered permission name already exists");
      valid = false;
    }
    else {
      setPermissionError(null);
      valid = true;
    }

    if (checkIsExist(permissionsData.map(x => x.permDesc), description)) {
      setDescriptionError("Entered description already exists");
      valid = false;
    }
    else {
      setDescriptionError(null);
      valid = true;
    }
    props.setSendData(false);
    return valid;
  }

  const validateMenuOptions = () => {
    const currentMenuOptions = permissionsMenuList.filter(x => x.accessType !== "");
    if (currentMenuOptions.length === 0) {
      const toastProps = { open: true, type: "error", message: "Select atleast one option from the menu", onClose: () => setToast({}) };
      setToast(toastProps);
      return false;
    }
    else {
      valid = true;
    }

    return valid;
  }

  useEffect(() => {
    if (props.sendData) {
      if (validateData() && validateMenuOptions()) {
        let dataToSave = {
          permName: permission,
          permDescription: description,
          menus: [...permissionsMenuList.filter(x => {
            if (x.accessType !== "")
              return {
                menuId: x.menuId,
                accessType: x.accessType
              }
          })],
          createdBy: '',
          updatedBy: ''
        }
        props.saveData(dataToSave);
      }
    }
  }, [props.sendData])

  useEffect(() => {
    if (showNewPermission) {
      setPermission("");
      setDescription("");
      fetchMenus();
    }
  }, [showNewPermission])

  useEffect(() => {
    setCurrentPageData([...permissionsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [page, rowsPerPage])

  const axInputPermission = (e) => {
    setPermission(e.target.value);
  };

  const axInputPermissionDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangeNewAccessType = (e, menuId, type) => {
    let menuItem = permissionsMenuList.find(x => x.menuId === menuId);
    if (menuItem) {
      menuItem.accessType = menuItem.accessType === type ? "" : type;
    }
    let index = permissionsMenuList.findIndex(x => x.menuId === menuId);
    let tempPermissionsMenuList = permissionsMenuList;
    tempPermissionsMenuList[index] = menuItem;
    setPermissionsMenuList([...tempPermissionsMenuList]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  };

  const deleteSelectedPermission = (_permissions) => {
    deletePermission(_permissions)
      .then(res => {
        if (res.statusCode == 200) {
          setOpenConfirm(false);
          props.setRefresh(!props.refresh);
          const toastProps = { open: true, type: "success", message: res.message, onClose: () => setToast({}) };
          setToast(toastProps);
        }
        else {
          const toastProps = { open: true, type: "error", message: res.message, onClose: () => setToast({}) };
          setToast(toastProps);
        }
      }).catch((err) => {
        const toastProps = { open: true, type: "error", message: "Some error occured. Please contact admin.", onClose: () => setToast({}) };
        setToast(toastProps);
      })
  }

  return (
    <>
      <ToastMessage toast={toast} />
      <TableContainer className='table-container-permission-lists'>
        <Table stickyHeader className='admin-roles-main-table'
          sx={{
            "& th": { padding: "0px 16px", textAlign: "left" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          aria-label='collapsible table'>

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={permissionTableColumns}
          />
          <TableBody>
            {showNewPermission && (
              <>
                <TableRow key={"addnewuser"} sx={{ background: true ? "#F3F4F5" : "white", }}>

                  <TableCell>
                    <InputComponent
                      label={""}
                      value={permission}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => axInputPermission(e)}
                      error={permissionError}
                    ></InputComponent>
                  </TableCell>
                  <TableCell>
                    <InputComponent
                      label={""}
                      value={description}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => axInputPermissionDescription(e)}
                      error={descriptionError}
                    ></InputComponent>
                  </TableCell>
                  <TableCell align='center' className='table-cell-with-dropdown'></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: "0px", background: true ? "#F3F4F5" : "white", }} colSpan={12}>
                    <Collapse in={true} timeout='auto' unmountOnExit>
                      <Box className='collapsable-table-box-main'>
                        <Box className='collapsable-table-box-sub'>
                          <Box className='roles-grid-main'>
                            <Table
                              className='admin-security-roles-table'
                              size='small'
                              aria-label='purchases'
                            >
                              <TableHead sx={{ whiteSpace: 'nowrap' }}>
                                <TableRow>
                                  <TableCell>{t('MENU_NAME')}</TableCell>
                                  <TableCell>{t('MENU_DESCRIPTION')}</TableCell>
                                  <TableCell align='right'>{t('ACCESS_TYPE')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {permissionsMenuList.map((permissionListOne, menuIindex) => (
                                  <TableRow key={menuIindex}>
                                    <TableCell component='th' scope='row'>
                                      {t(permissionListOne.menuName)}
                                    </TableCell>
                                    <TableCell>{t(permissionListOne.menuDescription)}</TableCell>
                                    <TableCell align='center'>
                                      <Box className='checkbox-group-main'>
                                        <FormGroup className='checkbox-group'>

                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={permissionListOne.accessType === "Edit"}
                                                onChange={(e) =>
                                                  handleChangeNewAccessType(e, permissionListOne.menuId, "Edit")
                                                }
                                              />
                                            }
                                            label='Edit'
                                          />
                                        </FormGroup>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            )}
            {visibleRows.map((row, index) => (
              <Row key={index} row={row}
                menus={row.menus}
                loading={loading}
                setSelectedPermission={(e) => setSelectedPermission(e)}
                setOpenConfirm={(e) => setOpenConfirm(e)}
                setShowSaveButtonForEdit={props.setShowSaveButtonForEdit}
                sendEditedData={props.sendEditedData}
                setSendEditedData={props.setSendEditedData}
                saveEditedData={props.saveEditedData}
                setEditedPermId={setEditedPermId}
                editedPermId={editedPermId}
                refresh={props.refresh}
                setRefresh={props.setRefresh}
                permissionsData={permissionsData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
      <ConfirmDialog openConfirm={openConfirm}
        hidebuttons={true}
        msg={`Do you want to delete ${selectedPermission?.permName}?`}
        accept={() => {
          deleteSelectedPermission(selectedPermission?.permId);
        }}
        reject={() => { setOpenConfirm(false); }}
      />
    </>
  );
}
