import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormControl } from "@mui/material";
const BootstrapButton = styled(Button)({
  textTransform: "none",
  padding: "10px 32px",
  borderRadius: "5px",
  textDecoration: "none",
  height: '36px',
  lineHeight: '16px',
  backgroundColor: "#2F80ED",
  borderColor: "none",
  boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  "&:hover": {
    backgroundColor: 'none',
    borderColor: "#0062cc",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  "&:active": {
    boxShadow: "none",    
    borderColor: "none",
  },
  "&:focus": {
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  "&:disabled": {
    backgroundColor: "#E2E4E8",
    color: "#999EA8"
  },
  "& .MuiFormControl-marginDense": {
    margin: "0 !important",
  },

});

export default function ButtonComponent(props) {
  const { icon, label, disabled, color, type, fullwidth } = props;
  const buttoncallBack = () => {
    props.buttoncallBack();
  }
  return (
    <FormControl fullWidth={fullwidth} variant='standard' sx={{
      marginLeft: 0, marginBottom: 0, marginTop: 0

    }}>
      <BootstrapButton
        variant='contained'
        disableRipple
        disableFocusRipple={true}
        disabled={disabled}
        sx={{
          backgroundColor: color,
          "&.MuiButtonBase-root:hover": {
            backgroundColor: color,

          }
        }}
        startIcon={icon && <AddCircleOutlineIcon />}
        type={type}
        onClick={() => buttoncallBack()}>
        {label}
      </BootstrapButton>
    </FormControl>
  );
}
