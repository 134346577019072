import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { addNewPermission, getAllPermissonMenus, updatePermission } from "../../services/AdminSecurityServices";
import ToastMessage from "../shared-components/ToastMessage";
import { ButtonComponent, AxSecurityPermissionListTableComponent } from "../shared-components/common-field-components";
import "./admin.css";
import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const PermissionListAdmin = () => {
  const [permissionsData, setPermissionsData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [flagToGetChildData, setFlagToGetChildData] = React.useState(false);
  const [flagToGetUpdatedData, setFlagToGetUpdatedData] = React.useState(false);
  const [showSaveButtonForEdit, setShowSaveButtonForEdit] = React.useState(false);
  const [showNewPermission, setShowNewPermission] = React.useState(false);
  const [newPermission, setNewPermission] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = React.useState({});

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);

  const fetchData = () => {
    setLoading(true);
    getAllPermissonMenus(page, rowsPerPage).then(response => {
      if (response?.statusCode === "200") {
        setPermissionsData(response?.data.content);
        setTotalElements(response.data.totalElements);
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
  }

  React.useEffect(() => { fetchData() }, [refresh]);

  const addPermission = (permission) => {
    setNewPermission(permission);
  };

  return (
    <>
      <Box className='admin-security-permission-main'>
        {loading &&
          <div className="loading">Loading&#8230;</div>
        }
        <Grid className='admin-security-content-page-header'>
          <Typography variant={'h5'} className='admin-content-header-tittle-text'>{t('PERMISSION_LISTS')}</Typography>
          <Box >
            {showNewPermission ? (
              <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, }}>
                {window.innerWidth > 900 ? (
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, gap: 2 }}>
                    <ButtonComponent
                      label={t("CANCEL")}
                      color="red"
                      disabled={loading}
                      className="save-user-button"
                      buttoncallBack={() => setShowNewPermission(!showNewPermission)}
                    />
                    <ButtonComponent
                      label={t("SAVE")}
                      color="#2BAA4F"
                      disabled={loading}
                      buttoncallBack={() => setFlagToGetChildData(true)}
                    />
                  </Box>
                ) : (
                  <>
                    <IconButton disabled={loading} onClick={() => setShowNewPermission(!showNewPermission)} >
                      <Close />
                    </IconButton>

                    <IconButton disabled={loading} onClick={() => setFlagToGetChildData(true)}>
                      <SaveIcon />
                    </IconButton>

                  </>
                )}
              </Box>

            ) :
              <Box className="add-button-pc">
                <ButtonComponent
                  label={t("ADD_PERMISSION_LISTS")}
                  icon
                  disabled={loading}
                  className='add-permission-button'
                  buttoncallBack={(e) => setShowNewPermission(!showNewPermission)}
                >
                </ButtonComponent>
                {showSaveButtonForEdit ?
                  <Box className="save-button">
                    <ButtonComponent
                      label={t("SAVE")}
                      color={'#2BAA4F'}
                      disabled={loading}
                      buttoncallBack={() => { setFlagToGetUpdatedData(true) }}
                    >
                    </ButtonComponent>
                  </Box> : null}
              </Box>
            }
            <Box className="add-icon-mobile add-button-mobile">
              {showNewPermission ? (
                <Box className='buttons-users' >

                </Box>
              ) :
                <Box>
                  <IconButton disabled={loading} onClick={(e) => setShowNewPermission(!showNewPermission)}>
                    <AddIcon />
                  </IconButton>
                  {showSaveButtonForEdit ?
                    <Box className="save-button">
                      <ButtonComponent
                        label={t("SAVE")}
                        color={'#2BAA4F'}
                        disabled={loading}
                        buttoncallBack={() => { setFlagToGetUpdatedData(true) }}
                      >
                      </ButtonComponent>
                    </Box> : null}
                </Box>
              }
            </Box>
          </Box>
        </Grid>
        <Box className='admin-security-tables'>
          <AxSecurityPermissionListTableComponent
            permissionsData={permissionsData}
            showNewPermission={showNewPermission}
            sendData={flagToGetChildData}
            setSendData={setFlagToGetChildData}
            loading={loading}
            saveData={(_data) =>
              addNewPermission(_data).then(res => {
                if (res.statusCode == 200) {
                  const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                  setToast(toastProps);
                  setShowNewPermission(false);
                  fetchData();
                }
                else {
                  const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                  setToast(toastProps);
                }
              })
            }
            sendEditedData={flagToGetUpdatedData}
            setSendEditedData={setFlagToGetUpdatedData}
            saveEditedData={(_data) => {
              updatePermission(_data).then(res => {
                if (res.statusCode == 200) {
                  const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                  setToast(toastProps);
                  fetchData();
                }
                else {
                  const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                  setToast(toastProps);
                }
              })
              setFlagToGetUpdatedData(false);
            }
            }
            addPermissionCallback={(e) => addPermission(e)}
            setShowSaveButtonForEdit={setShowSaveButtonForEdit}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalElements={totalElements}
            refresh={refresh}
            setRefresh={setRefresh}
          ></AxSecurityPermissionListTableComponent>
        </Box>
      </Box>
      <ToastMessage toast={toast} />
    </>
  );
};

export default PermissionListAdmin;
