
export const validateTasks = (tasks) => {
    const response = tasks?.find((operation, index) => {
        return (operation?.actualFinish === null || operation?.actualFinish === '')
    });
    if (response) {
        return true;
    } else {
        return false;
    }
}
export const validateLabor = (labor) => {
    const durationSum = labor.map(item => item.duration).reduce((prev, next) => prev + next);
    return durationSum === 0 ? true : false;
}
export const validateDocuments = (documents, clickedWo, operations) => {
    const filteredRows = operations?.content?.filter(item =>
        item.description?.toLowerCase().includes("photos") || item.description?.toLowerCase().includes("photo") ||
        (item.description?.toLowerCase().includes("tech level") && item.description?.toLowerCase().includes("maintenance")) ||
        (item.description?.toLowerCase().includes("upload") || item.description?.toLowerCase().includes("attach"))
    );
    const lowercaseDescription = clickedWo?.briefDescription.toLowerCase();
    if (lowercaseDescription.includes("tech level") && lowercaseDescription.includes("preventive maintenance")) {
        return documents.length === 0;
    }
    if (filteredRows.length !== 0) {
        return documents.length === 0;
    }
    return false;
};
export const validateMeterReading = (tasks, currentReadingDate) => {
    const actualFinishList = tasks?.map(item => item.actualFinish);
    actualFinishList.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
    });
    const startDate = actualFinishList && actualFinishList !== null && actualFinishList?.length > 0 && formatDate(actualFinishList[0]);
    return startDate > currentReadingDate ? true : false;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        min = d.getMinutes(),
        sec = d.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    let date1 = [year, month, day].join('-');
    let time = [hours, min, sec].join(':');
    return [date1, time].join(' ');
}