import axios from "axios";

export const getBroadcastMessages = async (page = 0, size = 10) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AX_SERVICES_URL}/admin/broadcastMessages?page=${page}&size=${size}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const saveBroadcastMessage = async (_reqBody) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AX_SERVICES_URL}/admin/broadcastMessage`,
      _reqBody,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBroadcastMessage = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AX_SERVICES_URL}/admin/broadcastMessage?broadcastMessageId=${id}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
