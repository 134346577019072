export const siteTableColumns = [
  {
    field: "edit",
    headerName: "",
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "orgCode",
    headerName: "SITE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "operatingUnit",
    headerName: "OPERATING_UNIT",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "orgName",
    headerName: "DESCRIPTION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "location",
    headerName: "LOCATION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "isOperatingUnit",
    headerName: "IS_OPERATING_UNIT",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "isInventoryOrg",
    headerName: "IS_INVENTORY_ORG",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "active",
    headerName: "ACTIVE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
];
