import axios from "axios";
import { APIENPOINTS } from "../components/constants/APIEndpoints";

export const getAllDevices = async (page = 0, size = 5) => {
  try {
    const fetchData = await axios
      .get(`${APIENPOINTS.ADMIN.DEVICES.GET_ALL_DEVICES}?page=${page}&size=${size}`)
      .then((res) => {
        return res?.data;
      });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
};

export const addDevice = async (_reqBody) => {
  try {
    const fetchData = await axios
      .post(APIENPOINTS.ADMIN.DEVICES.ADD_DEVICE, _reqBody)
      .then((res) => {
        return res?.data;
      });
    return fetchData;
  } catch (error) {
    const resp = error.response.data;
    if(resp.statusCode === '404' && resp.message === "DUPLICATE KEY") {
      return error.response;
    }
    console.log(error);
  }
};

export const updateDevices = async (_reqBody) => {
  try {
    const fetchData = await axios
      .post(APIENPOINTS.ADMIN.DEVICES.PUT_UPDATE_DEVICES, _reqBody)
      .then((res) => {
        return res?.data;
      });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
};

export const deleteDevice = async (deviceId) => {
  try {
    const fetchData = await axios
      .get(APIENPOINTS.ADMIN.DEVICES.DELETE_DEVICE, { params: { deviceId } })
      .then((res) => {
        return res?.data;
      });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
};

export const searchDevices = async (_payload) => {
  try {
    const fetchData = await axios
      .get(APIENPOINTS.ADMIN.DEVICES.SEARCH_DEVICES, { params: _payload })
      .then((res) => {
        return res?.data;
      });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
};

export const recolonizeDevice = async(orgId, device) => {
  try {
    const fetchData = await axios
        .get(APIENPOINTS.ADMIN.DEVICES.RECOLONIZE_DEVICES, { params: {orgId : orgId, subInventoryId : device} })
        .then((res) => {
          return res?.data;
        });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
}

export const decolonizeDevice = async(device) => {
  try {
    const fetchData = await axios
        .get(APIENPOINTS.ADMIN.DEVICES.DECOLONIZE_DEVICES, { params: {subInventoryId : device} })
        .then((res) => {
          return res?.data;
        });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
}

export const getDevicesByOrg = async(orgCode) => {
  try {
    const fetchData = await axios
        .get(APIENPOINTS.ADMIN.DEVICES.GET_DEVICES_BY_ORG, { params: {orgCode : orgCode} })
        .then((res) => {
          return res?.data;
        });
    return fetchData;
  } catch (error) {
    console.log(error);
  }
}
