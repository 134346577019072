import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';
import { exportToExcel } from './ExcelExportService';

export const getWorkOrderPriorities = async (token) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WO_PROROTIES).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWorkOrderClassCode = async (orgId) => {
    try {
        const response = await axios.get(`${APIENPOINTS.WORKORDERS.GET_WO_CLASS_CODE}${orgId}`);
        return response?.data || [];
    } catch (error) {
        console.error('Error fetching work order class codes:', error);
        throw error;
    }
};


export const getSourceAssetsByOrgId = async (orgId, userId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_SOURCE_ASSETS,
            { params: { orgId: orgId, userId: userId } }).then(res => {
                return res?.data;
            });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getSourceAssetsByOrgIdandFilter = async (page, size, orgId, userId, assetFilter, parentAsset) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_SOURCE_ASSETS_BY_FILTER,
            { params: { page: page, size: size, orgId: orgId, userId: userId, assetFilter: assetFilter, parentAsset: parentAsset } }).then(res => {
                return res?.data;
            });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getParentAssetsByOrgIdandFilter = async (page, size, orgId, userId, assetFilter) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_PARENT_ASSETS_BY_FILTER,
            { params: { page: page, size: size, orgId: orgId, userId: userId, assetFilter: assetFilter } }).then(res => {
                return res?.data;
            });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const createWorkOrder = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.CREATE_WORKORDER, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;
    } //
}

// WO SEARCH SERVICES
export const getWorkOrderDetailsByWorkOrderId = async (id, _orgId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKORDER_DETAILS_BY_WORKORDER_ID_WORKORDER + id + '&organizationId=' + _orgId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWorkOrders = async (page, size, orgId) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKORDERS + 'page=' + page + '&size=' + size + '&organizationId=' + orgId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}




export const getWorkOrdersBySearch = async (page, size, orgId, status, workOrderId, assetNumber, rig, userId) => {
    try {
        let query = '';
        if (Array.isArray(status)) {
            status.forEach(value => {
                query += '&status=' + value;
            });
        } else if (status !== '') {
            query = '&status=' + status;
        }
        if (workOrderId !== '') {
            query += '&workOrderId=' + workOrderId;
        }
        if (assetNumber !== '') {
            query += '&assetNumber=' + assetNumber;
        }
        if (rig !== '') {
            query += '&rig=' + rig;
        }
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKORDERS_BY_SEARCH + 'page=' + page + '&size=' + size + '&organizationId=' + orgId + query + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWorkOrdersBySearchAndSorting = async (page, size, orgId, status, workOrderId, assetNumber, rig, userId, sortBy, parameter, priority, woDueType, woType, divisionIds) => {
    try {
        let query = '';
        if (Array.isArray(status)) {
            status.forEach(value => {
                query += '&status=' + value;
            });
        } else if (status !== '') {
            query += '&status=' + status;
        }
        if (workOrderId !== '') {
            query += '&workOrderId=' + workOrderId;
        }
        if (assetNumber !== '') {
            query += '&assetNumber=' + assetNumber;
        }
        if (rig !== '') {
            query += '&rig=' + rig;
        }
        if (priority !== '') {
            query += '&priority=' + priority;
        }
        if (woDueType !== '') {
            query += '&woDueType=' + woDueType;
        }
        if (woType !== '') {
            query += '&woType=' + woType;
        }
        if (Array.isArray(divisionIds)) {
            divisionIds.forEach(value => {
                query += '&divisionIds=' + value;
            });
        } else if (divisionIds !== '') {
            query += '&divisionIds=' + divisionIds;
        }
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKORDERS_BY_SEARCH_SORTING + 'page=' + page + '&size=' + size + '&organizationId=' + orgId + query + '&userId=' + userId + '&sort=' + sortBy + '&parameter=' + parameter).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getWorkOrderStatus = async () => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKORDER_STATUS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const updateWorkOrderStatus = async (createWorkOrderId, workOrderId, wipEntityId, userId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.UPDATE_WO_STATUS + "?createWorkOrderId=" + createWorkOrderId + "&workOrderId=" + workOrderId + "&wipEntityId=" + wipEntityId + "&userId=" + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}
// WO SEARCH SERVICES


// OPERATIONS SERVICES
export const getOperationsByWipEntityId = async (token, pageNo, pageSize, wipEntityId, assetActivity) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.OPERATIONS.GET + 'pageNo=' + pageNo + '&pageSize=' + pageSize + '&wipEntityId=' + wipEntityId + '&assetActivity=' + assetActivity).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getVisualDictionaryImage = async (image) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ASSETS.TASK_VISUAL_DICTIONALY + '?attachmentName=' + image).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getOperationResourcesByOrgId = async (token, orgId, deptId) => {
    try {
        deptId = deptId === null ? 0 : deptId;
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_RESOURCES, { params: { orgId: orgId, deptId: deptId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}



export const getOperationLaborResourcesByOrgId = async (page, size, orgId, filter) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_LABOR_RESOURCES, { params: { page: page, size: size, orgId: orgId, resourceCode: filter } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getDepartmentsByOrgId = async (token, orgId, userId) => {
    try {
        //deptId = deptId === null ? 0 : deptId;
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_OPERATION_DEPARTMENTS, { params: { orgId: orgId, userId: userId, } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getsearchAssetGroups = async (params) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_SEARCH_ASSET_GROUPS, {
            params
        }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveOperation = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.OPERATIONS.SAVE, _reqBody).then(res => {

            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;
    }
}

export const updateOperation = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.OPERATIONS.UPDATE, _reqBody).then(res => {

            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;
    }
}

export const updateActualFinish = async (token, _reqBody, userId) => { //?+'operationId='+_reqBody.operationId+'&completedDate='+_reqBody.?completedDate
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.OPERATIONS.UPDATE_ACTUAL_FINISH + '?operationId=' + _reqBody.operationId + '&completedDate=' + _reqBody?.completedDate + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateConditionalValue = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.OPERATIONS.UPDATE_CONDITIONAL_VALUE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteOperationById = async (token, operationId,) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.OPERATIONS.DELETE + '?operationId=' + operationId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
// OPERATIONS SERVICES

// LABOR SERVICES
export const getLaborsByWipEntityId = async (token, page, size, wipEntityId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.LABOR.GET + page + '&pageSize=' + size + '&wipEntityId=' + wipEntityId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveLabor = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.LABOR.SAVE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;;
    }
}

export const updateLabor = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.LABOR.UPDATE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
        return error?.response?.data;;
    }
}

export const deleteLaborById = async (token, laborId, userId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.LABOR.DELETE, { params: { laborId: laborId, userId: userId, } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getTasksByWipEntityId = async (wipEntityId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.LABOR.GET_TASKS + wipEntityId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

// LABOR SERVICES

// METER READING SERVICES
export const getMeterReadingsByAssetNumberAndAssetGroup = async (token, assetNumber, assetGroup) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.METER_READING.GET + assetNumber + '&assetGroupId=' + assetGroup).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateMeterReadingByWorkorderId = async (token, _data) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.METER_READING.UPDATE, _data).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        // console.log(error);
        return error?.response?.data;
    }
}
// METER READING SERVICES


// FAILURE SERVICES
export const getFailuresByWorkorderId = async (token, workorderId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.FAILURE.GET + workorderId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveFailure = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.FAILURE.SAVE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateFailure = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.FAILURE.UPDATE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteFailureById = async (token, workorderId, failureId, userId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.FAILURE.DELETE + workorderId + '&failureId=' + failureId + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getFailuresCodesByAssetGroup = async (token, assetGroup) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_FAILURE_CODES + assetGroup).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getCauseCodesByFailureCode = async (token, failureCode, setId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_CAUSE_CODES + failureCode + '&setId=' + setId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getResolutionCodesByFailureAndCauseAndSetId = async (token, failureCode, causeCode, setId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_WO_RESOLUTION_CODE + failureCode + '&causeCode=' + causeCode + '&setId=' + setId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWorkOrdersDocuments = async (pageNo, pageSize) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_DOCUMENTS + 'pageNo=' + pageNo + '&pageSize=' + pageSize).then(res => {
            return res?.data;
        });
        return fetchData;

    } catch (error) {
        console.log(error);
    }
}

export const getDocumentsByWorkOrderId = async (workOrderId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_DOCUMENTS_BY_WORKORDERID + 'workOrderId=' + workOrderId).then(res => {
            return res?.data;
        });
        return fetchData;

    } catch (error) {
        console.log(error);
    }
}

export const attachDocuments = async (id, files) => {
    try {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('attachments', file, file.name);
        });
        const { data } = await axios.post(
            `${process.env.REACT_APP_AX_SERVICES_URL}/workorders/attachDocuments?documentId=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const downloadDocumentRecord = async (name) => {
    exportToExcel('/workorders/downloadDocumentAttachment?attachmentName=' + name);
};

export const saveDocumentRecord = async (_reqBody, attachments) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_AX_SERVICES_URL}/workorders/document`,
            _reqBody,
        );
        if (response?.data?.statusCode === "200" && attachments.length > 0) {
            attachDocuments(response?.data?.data?.documentId, attachments);
        }
        return response?.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteDocumentRecord = async (id, workOrderId, userId) => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_AX_SERVICES_URL}/workorders/document?documentId=${id}&workOrder=${workOrderId}&userId=${userId}`,
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getWorkingRigs = async (token, page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.GET_WORKING_RIGS, { params: { page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

// FAILURE SERVICES

//Material Services//
export const getItems = async (page, size, organizationId, filter) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.MATERIALS.GET_MATERIALS_ITEM, { params: { page: page, size: size, organizationId: organizationId, filter: filter } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWOMaterials = async (orgId, wipEntityId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.MATERIALS.GET_MATERIALS, { params: { orgId: orgId, wipEntityId: wipEntityId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateMaterial = async (token, id, _reqBody) => {
    try {
        const fetchData = await axios.post(`${APIENPOINTS.WORKORDERS.MATERIALS.UPDATE}?id=${id}`, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
        return error?.response?.data;;
    }
}

export const addMaterial = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.MATERIALS.SAVE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;
    }
}
export const deleteMaterial = async (token, id) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.MATERIALS.DELETE, { params: { id: id } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}



export const getWOAttributes = async (token, orgId, wipEntityId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.SUMMARY.GET_WOATTRIBUTES, { params: { orgId: orgId, wipEntityId: wipEntityId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWoLovalues = async (page, size, config, filter) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.SUMMARY.GET_WOLOVALUES, { params: { page: page, size: size, config: config, filter: filter } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getMfgLookups = async (page, size, lookupType, filter) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.WORKORDERS.SUMMARY.GET_MFG_LOOKUPS, { params: { page: page, size: size, lookupType: lookupType, filter: filter } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addWoAttributes = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.WORKORDERS.SUMMARY.SAVE, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}


