import "./App.css";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import React, { useCallback, useEffect, useState } from "react";
import { UserContext } from "./store/UserContext";
import { AppConstants } from './AppConstants';
import { getUserByEmail } from "./services/AdminSecurityServices";
import MainLayout from "./components/layout/MainLayout";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri: process.env.REACT_APP_OKTA_BASE_REDIRECT_URI + "/login/callback",
});

function Routes() {
    const restoreOriginalUri = useCallback(
        async (_oktaAuth, originalUri) => {
            window.location.replace(
                toRelativeUrl(originalUri || "/", window.location.origin)
            );
        },
        []
    );
    const [userData, setUserData] = useState({
        email: '',
        email_verified: true,
        family_name: '',
        given_name: '',
        name: '',
        sub: '',
        token: '',
    });

    const [permissions, setPermissions] = useState({
        admin: null,
        assets: null,
        batchProcess: null,
        materialTransferForm: null,
        plannedAssetInventory: null,
        reports: null,
        workOrders: null
    });
    //Category Values dropdown static data in Create Work Order
    const Category_Values = [
        { id: 1, value: "Please Select" },
        /*  { id: 2, value: "CMI" }, */
        { id: 3, value: "CWO" },
        /*  { id: 4, value: "NPT" },
         { id: 5, value: "Tech" }, */
    ];

    const [error, setError] = useState(null);
    const [recievedPermisions, setRecievedPermissions] = useState(false);
    const [defaultSiteId, setDefaultSiteId] = useState();
    const [userSitesData, setUserSitesData] = useState(null);
    const [userProfileDetails, setUserProfileDetails] = useState()

    const dataFetchedRef = React.useRef(false);
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (oktaAuth && oktaAuth?.getAccessToken() && oktaAuth?.isAuthenticated()) {
            try {
                oktaAuth?.getUser()?.then(async (res) => {
                    const userDetails = await getUserByEmail(res?.email);
                    if (userDetails.statusCode == 200) {
                        setUserProfileDetails(userDetails?.data);
                        if (userDetails?.data?.permissionMenus.length === 0) {
                            setError({ message: "OOPS... You don't have any permissions assigned. Please contact admin." })
                        }
                        else {
                            setError(null);
                        }
                        const user = {
                            id: userDetails?.data?.userId,
                            email: res?.email,
                            email_verified: res?.email_verified,
                            family_name: res?.family_name,
                            given_name: res?.given_name,
                            name: res?.name,
                            sub: res?.sub,
                            token: oktaAuth?.getAccessToken()
                            // expired 
                        }
                        localStorage.setItem('authToken', user.token)
                        setUserData(user);
                        let tempUserSites = [...userDetails.data.sites];
                        tempUserSites.forEach(x => { // BINDING ID, VALUE TO EXISITNG LIST
                            x.id = x.siteId;
                            x.value = x.orgName == 'Seattle Maintenance' ? 'Jacksonville Maintenance' : x.orgName;
                        });
                        setUserSitesData([...tempUserSites]);

                        // Retain the current site even user refreshes the page
                        if (localStorage.getItem("currentSiteId") !== undefined && localStorage.getItem("currentSiteId") !== 'undefined' && localStorage.getItem("currentSiteId") !== null) {
                            setDefaultSiteId(localStorage.getItem("currentSiteId"));
                        }
                        else {
                            localStorage.setItem("currentSiteId", userDetails.data.defaultSiteId);
                            setDefaultSiteId(userDetails.data.defaultSiteId);
                        }
                        let consolidatedMenus = [...userDetails.data.permissionMenus.map(pm => [...pm.menus])].flat();
                        consolidatedMenus = consolidatedMenus.filter(m => m.accessType !== null && m.accessType !== "View" && m.accessType != "");
                        const menus = JSON.parse(process.env.REACT_APP_MENUS);
                        let currPermissions = { ...permissions };
                        consolidatedMenus.forEach(cm => {
                            switch (cm.menuName) {
                                case menus.admin:
                                    currPermissions.admin = true;
                                    break;
                                case menus.assets:
                                    currPermissions.assets = true;
                                    break;
                                case menus.batchProcess:
                                    currPermissions.batchProcess = true;
                                    break;
                                case menus.materialTransferForm:
                                    currPermissions.materialTransferForm = true;
                                    break;
                                case menus.plannedAssetInventory:
                                    currPermissions.plannedAssetInventory = true;
                                    break;
                                case menus.reports:
                                    currPermissions.reports = true;
                                    break;
                                case menus.workOrders:
                                    currPermissions.workOrders = true;
                                    break;
                            }
                        });
                        setPermissions({ ...currPermissions });
                        setRecievedPermissions(true);
                    }
                    else {
                        setError(userDetails);
                    }
                })
                    .catch(err => {
                        console.log(err);
                    });
            } catch (error) {
                console.log('error while trying to get user info...')
            }
        }
    }, [!userData]);

    // SCREEN RESIZE WIDTH AND HEIGHT WBASED ON RESOLUTION
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);
    // SCREEN RESIZE WIDTH AND HEIGHT WBASED ON RESOLUTION


    const [selectedSite, setSelectedSite] = React.useState();

    const updateSelectedSite = (selectedSite) => {
        setSelectedSite(selectedSite);
    };

    const [initialOrientation, setInitialOrientation] = useState(window.screen.orientation.type);

    useEffect(() => {
        const handleOrientationChange = () => {
            if (window.screen.orientation.type !== initialOrientation) {
                window.location.reload();
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [initialOrientation]); // Dependency on initialOrientation

    const largeScreens = screenSize?.dynamicWidth > AppConstants.TABLET_POTRAIT_RESOLUTION;
    const smallScreens = screenSize?.dynamicWidth < AppConstants.TABLET_POTRAIT_RESOLUTION;

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <UserContext.Provider value={{
                user: userData, oktaAuth: oktaAuth, screenSize: screenSize && screenSize,
                appConstants: AppConstants, selectedSite: selectedSite, permissions: permissions,
                recievedPermisions: recievedPermisions, updateSelectedSite: updateSelectedSite,
                defaultSiteId: defaultSiteId, userSitesData: userSitesData, largeScreens: largeScreens, smallScreens: smallScreens,
                Category_Values,userProfile: userProfileDetails
            }}
            >
                <ThemeProvider theme={theme}>
                    <MainLayout error={error} />
                </ThemeProvider>
            </UserContext.Provider>
        </Security>
    );
}

export default Routes;