import { Box, Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import {
    getWorkOrderDetailsByWorkOrderId,
    updateWorkOrderStatus, getLaborsByWipEntityId, getDocumentsByWorkOrderId, getMeterReadingsByAssetNumberAndAssetGroup,
    getOperationsByWipEntityId, getWOMaterials,
    getWorkOrderStatus,
    getWOAttributes,

} from "../../services/WorkOrderServices";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { ConfirmDialog } from "../shared-components/ConfirmDialog";
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import OperationsIcon from "../../assets/images/WorkOrders/operations.svg";
import LaborIcons from "../../assets/images/WorkOrders/labor.svg";
import DocumentsIcons from "../../assets/images/WorkOrders/documents.svg";
import MetersIcons from "../../assets/images/WorkOrders/meters.svg";
import FailureIcons from "../../assets/images/WorkOrders/failure.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import dayjs from 'dayjs';
import { InfoDialog } from "../shared-components/InfoDialog";
import Operations from "./Operations";
import ToastMessage from "../shared-components/ToastMessage";
import { validateTasks, validateLabor, validateMeterReading, validateDocuments } from '../../helpers/complete-work-order-validations';
import { lazy, Suspense } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const WoSummary = lazy(() => import('./WoSummary'));
const Materials = lazy(() => import('./Materials'));
const Labor = lazy(() => import('./Labor'));
const Documents = lazy(() => import('./Documents'));
const Meters = lazy(() => import('./Meters'));
const Failure = lazy(() => import('./Failure'));

export default function WorkOrderDetails() {

    const { selectedSite, oktaAuth, largeScreens, smallScreens, user } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const navigate = useNavigate();
    const location = useLocation();

    const workorderNumber = location.state || localStorage.getItem('woNumber');
    const [toast, setToast] = useState({});
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(smallScreens ? 1 : 0);
    const [previousTab, setPreviousTab] = React.useState(1);
    const [workorderDetails, setWorkorderDetails] = useState([]);
    const [openInfo, setOpenInfo] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('')
    const [targetTab, setTargetTab] = useState(0);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [workOrderStatus, setWorkOrderStatus] = React.useState([]);
    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const dashboardPrams = location?.state;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBackClick = () => {

        navigate('/workorders'); // Otherwise, navigate to the main workorders page

    };

    const tabsList = [
        {
            id: 0,
            label: 'Summary',
            icon: ""
        },
        {
            id: 1,
            label: 'Operations',
            icon: OperationsIcon
        },
        {
            id: 2,
            label: 'Materials',
            icon: LaborIcons
        },
        {
            id: 3,
            label: 'Labor',
            icon: LaborIcons
        },
        {
            id: 4,
            label: 'Documents',
            icon: DocumentsIcons
        },
        {
            id: 5,
            label: 'Meters',
            icon: MetersIcons
        },
        {
            id: 6,
            label: 'Failure',
            icon: FailureIcons
        },

    ]


    const isLaptopResolution = window.innerWidth >= 900;

    const dateTimeFormat = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('MM-DD-YYYY');
    }

    const fetchWorkOrderStatus = () => {
        getWorkOrderStatus()
            .then((response, i) => {
                if (response?.statusCode === "200") {
                    const data = response.data.map((res) => {
                        return { id: res.statusId, value: res.workOrderStatus }
                    })
                    const ids = data
                        .filter(item => item.value === 'On Hold' || item.value === 'Released')
                        .map(item => item.id);

                    data.unshift({ id: 0, value: "All" });
                    setWorkOrderStatus(data);
                }
            })
            .catch((err) => console.log(err));
    };

    const fetchWorkOrderDetailsByWorkOrderId = () => {
        setLoading(true);
        getWorkOrderDetailsByWorkOrderId(workorderNumber, selectedSite.organizationId)
            .then((response) => {
                if (response?.statusCode === "200") {
                    setLoading(false);
                    fetchWorkOrderStatus();
                    setWorkorderDetails(response?.data);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((err) => { console.log(err) });
    };

    useEffect(() => {
        if (workorderNumber && selectedSite) {
            fetchWorkOrderDetailsByWorkOrderId();
        }
    }, [workorderNumber, location, selectedSite]);

    const fetchOperation = (wipEntityId) => {
        return getOperationsByWipEntityId('', 0, 100, wipEntityId, workorderDetails?.assetActivity).then(res => {
            return res.data;
        });
    }
    const fetchLabors = (wipEntityId) => {
        return getLaborsByWipEntityId('', 0, 100, wipEntityId).then(res => {
            return res.data;
        });
    }
    const fetchDocumentsData = () => {
        return getDocumentsByWorkOrderId(workorderDetails?.workOrderId).then(res => {
            return res.data;
        })
    }
    const fetchMeters = () => {
        return getMeterReadingsByAssetNumberAndAssetGroup('', workorderDetails?.instanceNumber, workorderDetails?.assetGroup).then(res => {
            return res.data;
        });
    }
    const fetchWorkOrders = (workOrderId) => {
        return getWorkOrderDetailsByWorkOrderId(workOrderId, selectedSite.organizationId)
            .then((response) => {
                return response?.data;
            })

    }
    const fetchMaterials = (workOrderId) => {
        return getWOMaterials(selectedSite.organizationId, workOrderId)
            .then((response) => {
                return response?.data;
            })

    }

    const fetchAttributes = async () => {
        return getWOAttributes('', selectedSite.organizationId, workorderDetails?.wipEntityId)
            .then((response) => {
                return response?.data;
            })
    };

    const clickCompleteWOStatus = () => {
        if (workorderDetails?.statusType === 6) {
            woCompleteStatusUpdate(3);
        } else {
            const responses = Promise.all([
                fetchOperation(workorderDetails?.wipEntityId),
                fetchLabors(workorderDetails?.wipEntityId),
                fetchDocumentsData(),
                fetchMeters(),
                fetchWorkOrders(workorderDetails.workOrderId),
                fetchAttributes(),
                fetchMaterials(workorderDetails?.wipEntityId)
            ]);
            responses.then(res => {
                let currentReadingDate = dateTimeFormat(res[3]?.currentReadingDate);
                let msg = '';
                if ((res[5]?.attribute1)) {
                    if (res[0]?.content?.length > 0) {
                        // TASKS VALIDATION
                        if (validateTasks(res[0]?.content)) {
                            setInfoMsg('All tasks must be finished to complete the Work Order.');
                            setOpenInfo(true);
                            setTargetTab(1);
                        }
                        else
                            // Checking materials Validation
                            if (res[1]?.content === null || res[2]?.content?.length === 0) {
                                setInfoMsg("You must enter at least one material before completing work order.");
                                setOpenInfo(true);
                                setLoading(false);
                                setTargetTab(2);
                            }

                            else {
                                // CHECKING LABOR VALIDATION WITH EMPTY RECORDS
                                if (res[1]?.content === null || res[1]?.content?.length === 0) {
                                    msg = 'You must enter duration for at least one labor resource before completing work order.\n';
                                    setInfoMsg(msg)
                                    setOpenInfo(true);
                                    setTargetTab(3);
                                }
                                else {
                                    // CHECKING LABOR WITH DURATION
                                    if (validateLabor(res[1]?.content)) {
                                        setInfoMsg('You must enter duration for at least one labor resource before completing work order.')
                                        setOpenInfo(true);
                                    } else {
                                        // CHECKING METER READING       
                                        if (res[4]?.assetNumber !== null && res[4]?.eamRequiredFlag === 'Y' && res[4]?.currentReading === 0) {
                                            setInfoMsg('Meter reading should not be 0.')
                                            setOpenInfo(true);
                                            setTargetTab(5);
                                        }
                                        else if (res[4]?.assetNumber !== null && res[4]?.eamRequiredFlag === 'Y' && validateMeterReading(res[0]?.content, currentReadingDate)) { //scheduledSatrt > currentReadingDate
                                            setInfoMsg('Please update the meter reading before closing the work order.');
                                            setOpenInfo(true);
                                            setTargetTab(5);
                                        }
                                        else {// CHECKING THE DOCUMENT DATA
                                            if (validateDocuments(res[2], workorderDetails, res[0])) {
                                                setInfoMsg('At least one document should be attached before completing work order.\n')
                                                setOpenInfo(true);
                                                setTargetTab(4);
                                            }
                                            else { // CHECKING THE FAILURE DATA
                                                if (res[4]?.failureCodeRequired !== null && res[4]?.failureCodeRequired === 'Y' && (res[4]?.failureCode === null || res[4]?.failureCode === '')) {
                                                    setInfoMsg('Failure data is required')
                                                    setOpenInfo(true);
                                                    setTargetTab(6);
                                                } else {
                                                    setConfirmMsg('Do you want complete the work order.');
                                                    setOpenConfirm(true);

                                                }
                                            }


                                        }
                                    }
                                }
                            }
                    } else {
                        msg += ' add atleat one task. \n';
                        setInfoMsg(msg);
                        setOpenInfo(true);
                    }
                }
                else {
                    setInfoMsg('Please select the system code to complete the work order');
                    setOpenInfo(true);
                    setTargetTab(0);
                }
            });
        }
    }
    const woCompleteStatusUpdate = (_statusForFilter) => {
        setLoading(true);
        const toastProps = { open: true, type: "success", message: "Completed  successfully", onClose: () => setToast({}) };
        setToast(toastProps);
        updateWorkOrderStatus(workorderDetails.createWorkOrderId, workorderDetails.workOrderId, workorderDetails.wipEntityId, user?.id).then(response => {

            if (workorderDetails?.statusType === 6) {
                window.location.reload()

            }
            else {
                navigate('/workorders');
            }
        }).catch((error) => {
            const toastProps = { open: true, type: "error", message: error, onClose: () => setToast({}) };
            setToast(toastProps);
            setLoading(false);
        });
    }

    const handleIconClick = () => {
        if (isSummaryOpen) {
            setIsSummaryOpen(false);
            setValue(previousTab);
        } else {
            setPreviousTab(value);
            setValue(0);
            setIsSummaryOpen(true);
        }
    };

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            <ConfirmDialog openConfirm={openConfirm} msg={confirmMsg}
                accept={() => {
                    woCompleteStatusUpdate(4);
                    setOpenConfirm(false);
                }}
                reject={() => setOpenConfirm(false)}
            />
            <InfoDialog openInfo={openInfo} msg={infoMsg}
                okAction={() => {
                    setOpenInfo(false);
                    setValue(targetTab);
                }}
            />
            <Box className={smallScreens ? 'mobileHomePageContentWrapper' : 'homePageContentWrapper'}>
                <Box className='homePageHeader' >

                    <IconButton sx={{ marginLeft: '-9px' }} onClick={() => navigate(-1,
                        { state: { ...dashboardPrams, status: "3", woDueType: "DUE_THIS_WEEK_WO" } }
                    )}>
                        {smallScreens ? <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '1rem' }} /> : <KeyboardBackspaceOutlinedIcon />}
                    </IconButton>
                    <Typography variant={'h5'} flexGrow={1}>
                        {window.innerWidth > 1200 ? (
                            <>
                                {workorderNumber} || {workorderDetails?.briefDescription} || {dateTimeFormat(workorderDetails?.releaseDate)} || {workOrderStatus?.find(status => status.id === workorderDetails.statusType)?.value}
                            </>
                        ) : (
                            workorderNumber
                        )}
                    </Typography>
                    <Stack direction={'row'} gap={smallScreens && '10px'}>
                        {!isLaptopResolution && smallScreens && value === 0 && (
                            <IconButton sx={{ padding: window.innerWidth < 899 ? '0px' : 'auto' }} onClick={handleIconClick}>
                                {isSummaryOpen ? <CloseIcon /> : <InfoOutlinedIcon />}
                            </IconButton>
                        )}
                        {workorderDetails.statusType === 3 && !isSummaryOpen && value === 0 ? (
                            <IconButton sx={{ padding: window.innerWidth < 899 ? '0px' : 'auto' }} onClick={() => clickCompleteWOStatus()}>
                                <TaskAltOutlinedIcon />
                            </IconButton>
                        ) : workorderDetails.statusType === 6 && !isSummaryOpen && value === 0 ? (
                            <Button variant="contained" style={{ backgroundColor: '#2BAA4F', color: '#FFFFFF' }} onClick={() => { clickCompleteWOStatus(); }}>
                                Release
                            </Button>
                        ) : null}

                        {/* Default icons for non-summary tabs */}
                        {value !== 0 && (
                            <>
                                {!isLaptopResolution && (
                                    <IconButton sx={{ padding: window.innerWidth < 899 ? '0px' : 'auto' }} onClick={handleIconClick}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                )}
                                <IconButton sx={{ padding: window.innerWidth < 899 ? '0px' : 'auto' }} onClick={() => clickCompleteWOStatus()}>
                                    <TaskAltOutlinedIcon />
                                </IconButton>
                            </>
                        )}
                    </Stack>
                </Box>
                <Box className='homePageTable' sx={{ '& .MuiTabPanel-root': { height: smallScreens ? window.innerHeight - 100 : window.innerHeight - 173, overflow: 'auto', padding: largeScreens ? '10px 0px' : '0px' }, mt: largeScreens ? 1 : 0 }} >
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            {largeScreens ?
                                <Box sx={{
                                    background: '#979797', borderBottom: 1, borderColor: 'divider',
                                    '& .Mui-selected': { background: '#2F80ED', color: 'white !important' },
                                    '& .MuiTabs-indicator': { display: 'none' },
                                    '& .MuiButtonBase-root': { color: 'white', flex: 1, flexDirection: 'row', padding: '0px 12px !important', alignItems: 'center', minHeight: '0px', height: '50px' },
                                    '& .MuiTab-iconWrapper': { marginBottom: '0px !important' },
                                }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        {
                                            tabsList.map((row, index) => (
                                                <Tab key={row.id} label={row.label} value={row.id} icon={<RadioButtonCheckedIcon />} />
                                            ))
                                        }
                                    </TabList>
                                </Box>
                                :
                                value !== 0 &&
                                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, background: 'black' }} elevation={3} >
                                    <BottomNavigation
                                        showLabels
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue(newValue);
                                        }}
                                        sx={{
                                            background: 'black',
                                            '& .Mui-selected': { fontSize: '0.7rem !important', background: '#2F80ED' },
                                            '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem', color: 'white !important' },
                                            '& .MuiSvgIcon-root': { color: 'white', fontSize: '1rem' },
                                            '& .MuiButtonBase-root': { gap: 0.5, minWidth: "16%", overflowX: 'auto', padding: '0px 0px !important' }
                                        }}
                                    >
                                        {
                                            tabsList.filter(item => item.id !== 0).map((row, index) => (
                                                <BottomNavigationAction key={row.id} value={row?.id} label={row?.label} icon={<img height={20} src={row?.icon} />} />
                                            ))
                                        }
                                    </BottomNavigation>
                                </Paper>
                            }
                            {
                                workorderDetails?.length !== 0 && (
                                    <>
                                        <TabPanel value={(1)}>
                                            <Operations clickedWorkOrder={workorderDetails} />
                                        </TabPanel>

                                        <TabPanel value={(0)}>
                                            <Suspense>
                                                <WoSummary clickedWorkOrder={workorderDetails} workOrderStatus={workOrderStatus} fetchWorkOrderDetailsByWorkOrderId={fetchWorkOrderDetailsByWorkOrderId} />
                                            </Suspense>
                                        </TabPanel>

                                        <TabPanel value={(2)}>
                                            <Suspense>
                                                <Materials clickedWorkOrder={workorderDetails} />
                                            </Suspense>
                                        </TabPanel>

                                        <TabPanel value={(3)}>
                                            <Suspense>
                                                <Labor clickedWorkOrder={workorderDetails} />
                                            </Suspense>
                                        </TabPanel>

                                        <TabPanel value={(4)}>
                                            <Suspense>
                                                <Documents clickedWorkOrder={workorderDetails} />
                                            </Suspense>
                                        </TabPanel>

                                        <TabPanel value={(5)}>
                                            <Suspense>
                                                <Meters clickedWorkOrder={workorderDetails} />
                                            </Suspense>
                                        </TabPanel>

                                        <TabPanel value={(6)}>
                                            <Suspense>
                                                <Failure clickedWorkOrder={workorderDetails} />
                                            </Suspense>
                                        </TabPanel>
                                    </>

                                )
                            }
                        </TabContext>
                    </Box>
                </Box>
            </Box>
            <ToastMessage toast={toast} />
        </Box >
    );
}
