import React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {

  },
  "& .MuiInputBase-input": {
    textAlign: "start",
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    backgroundColor: "white",
    fontSize: 14,
    padding: "8px 20px",
    color: "#696C7B",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "white",
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {

    backgroundColor: "#E2E4E8",

    fontSize: 14,
    padding: "7px 20px",
    color: "#696C7B",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
  },


  "& .MuiSvgIcon-root": {
    marginRight: "14px",
    fontSize: "30px"
  },

  "& .MuiSelect-select": {
    paddingRight: "38px !important"
  }

}));

export default function SelectComponent(props) {
  const { label, values, selectedvalue, disabled, width, error, errorText, parentselectcallback, placeholder } = props;
  const handleChange = (event) => {
    const newValue = event.target.value;
    props.parentselectcallback(newValue);
  };
  return (
    <FormControl className="ax-select-component" variant='standard' sx={{ width: width, marginBottom: "4px", marginTop: "8px" }}

    >
      {label && (
        <InputLabel
        >
          {label}
        </InputLabel>
      )}
      <Select
        disabled={disabled}
        labelId='demo-customized-select-label'
        id='demo-customized-select'
        value={selectedvalue || ''}
        onChange={handleChange}
        input={<BootstrapInput placeholder={placeholder} />}
      >
        {values?.map((value, index) => {
          return (
            <MenuItem key={index} value={value.id}>
              {value.value}
            </MenuItem>
          );
        })}
      </Select>
      {error && <span style={{ color: "red", marginTop: "5px" }}>{error}</span>}
    </FormControl>
  );
}
