export const AssetsExpand = [
  {
    field: "assetNumber",
    headerName: "ASSET",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "type",
    headerName: "TYPE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "description",
    headerName: "DESCRIPTION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "parentSerialNumber",
    headerName: "PARENT",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "location",
    headerName: "LOCATION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "manufacturersSerialNumber",
    headerName: "MANUFACTURER_SN",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
];
