import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';
export const getUserSitesById = async (token, userId) => {
    try {
        if (userId === undefined) {
            userId = 0;
        }
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_USER_SITES + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getEmployees = async (token, organizationId, departmentId, resourceId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_EMPLOYEES, { params: { organizationId: organizationId, departmentId: departmentId, resourceId: resourceId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}