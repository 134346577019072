import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';


export const getInventoryOrgsByOperatingUnit = async (token, page, size, operatingUnit) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_INVENTORY_ORGS_BY_OPERATINGUNIT + 'page=' + page + '&size=' + size + '&operatingUnit=' + operatingUnit).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getInventoryOrgsBySearch = async (token, page, size, keyword) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_INVENTORY_ORGS_SEARCH + 'page=' + page + '&size=' + size + '&keyword=' + keyword).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addSubInventories = async (token, page, size, orgs, userId) => {
    try {
        let query = '';
        if (Array.isArray(orgs)) {
            orgs.forEach(value => {
                query += '&orgs=' + value;
            });
        }
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_SUB_INVENTORIES_BY_ORGS + 'page=' + page + '&size=' + size + query + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addSubInventoriesBySearch = async (token, page, size, orgIds, keyword, userId) => {
    try {
        let query = '';
        if (Array.isArray(orgIds)) {
            orgIds.forEach(value => {
                query += '&orgIds=' + value;
            });
        }
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_SUB_INVENTORIES_BY_SEARCH + 'page=' + page + '&size=' + size + query + '&keyword=' + keyword + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addLocatorsByOrgsAndSubInventories = async (token, page, size, orgs, subInvemtories, userId) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_LOCATORS_BY_ORGS_AND_SUBINVENTORIES + 'page=' + page + '&size=' + size + '&orgs=' + orgs + '&subInventories=' + subInvemtories + '&userId=' + userId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addLocatorsBySearch = async (token, page, size, orgs, subInvemtories, keyword, userId) => {
    try {
        let query = '';
        if (Array.isArray(orgs)) {
            orgs.forEach(value => {
                query += '&orgs=' + value;
            });
        }
        if (Array.isArray(subInvemtories)) {
            subInvemtories.forEach(value => {
                query += '&subInventories=' + value;
            });
        }        
        if (keyword !== '') {
            query += '&keyword=' + keyword;
        }
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_LOCATORS_BY_SEARCH + 'page=' + page + '&size=' + size + query + '&userId=' + userId ).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateInventoryStatus = async (token, inventoryId, status, updatedBy) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.UPDATE_INVENTORY_STATUS + 'inventoryId=' + inventoryId + '&status=' + status + '&updatedBy=' + updatedBy).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getGenerateInventories = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.GET_GENERATE_INVENTORIES, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getInventoryAssignments = async (token, inventoryId, page, size) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.ASSIGNMENTS.GET_INVENTORY_ASSIGNMENTS + 'inventoryId=' + inventoryId + '&page=' + page + '&size=' + size).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteInventoryAssignment = async (token, assignmentId) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.ASSIGNMENTS.DELETE_INVENTORY_ASSIGNMENTS + 'assignmentId=' + assignmentId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const searchInventoryAssignment = async (token, employee, currentSite) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.ASSIGNMENTS.SEARCH_BY_EMPLOYEE, { params: { employee: employee, currentSite: currentSite } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addInventoryAssignment = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.GENERATE_INVENTORIES.ASSIGNMENTS.ADD_INVENTORY_ASSIGNMENTS, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getScheduleBatchProcesses = async (token, page, size, orgId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.GET_SCHEDULE_BATCH_PROCESSES
            + '&page=' + page + '&size=' + size + '&orgId=' + orgId).then(res => {
                return res?.data;
            });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteScheduleBatchProcesses = async (token, scheduleBatchProcessId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.DELETE_SCHEDULE_BATCH_PROCESSES + 'scheduleBatchProcessId=' + scheduleBatchProcessId).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addScheduleBatchprocesses = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.ADD_SCHEDULE_BATCH_PROCESSES, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const runScheduleBatchprocesses = async (_reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.RUN_NOW, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateScheduleBatchprocesses = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.UPDATE_SCHEDULE_BATCH_PROCESSES, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveOrUpdateScheduleParamsProcesses = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.SAVE_OR_UPDATE_SCHEDULE_PARAMS_PROCESS, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteScheduleParamsProcesses = async (token, scheduleId, parameterId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.SCHEDULE_BATCH_PROCESS.DELETE_SCHEDULE_PARAMS_PROCESSES, { params: { scheduleId: scheduleId, parameterId: parameterId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveThirdPartyPhysicalLocator = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.SAVE_PHYSICAL_LOCATOR, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        return error?.response?.data;
    }
}

export const saveThirdPartyPhysicalLocation = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.SAVE_PHYSICAL_LOCATION, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getLocatorsBySearch = async (token, keyword, page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.GET_LOCATORS_BY_SEARCH, { params: { keyword: keyword, page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getThirdPartyPhysicalLocators = async (token, page, size, physicalLocation, locator) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.GET_THIRDPARTY_PHYSICAL_LOCATORS, { params: { page: page, size: size, physicalLocation: physicalLocation, locator: locator } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getThirdPartyPhysicalLocations = async (token, page, size, search) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.GET_THIRDPARTY_PHYSICAL_LOCATIONS, { params: { page: page, size: size, search: search } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getThirdPartyPhysicalLocatorsData = async (token, page, size, physicalLocation) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.GET_THIRDPARTY_PHYSICAL_LOCATORS_DATA, { params: { page: page, size: size, physicalLocation: physicalLocation } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteThirdPartyPhysicalLocator = async (token, physicalLocatorId) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.BATCHPROCESS.THIRD_PARTY_LOCATORS.DELETE_THIRDPARTY_PHYSICAL_LOCATOR, { params: { physicalLocatorId: physicalLocatorId } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}




