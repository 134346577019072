
export const workOrderAccessLogColumns = [
   
    {
        field: "workOrder",
        headerName: "WORKORDER",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "type",
        headerName: "TYPE",
        width: 150,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "empName",
        headerName: "EMPLOYEE",
        width: 120,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "processStatus",
        headerName: "PROCESS_STATUS",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "processed",
        headerName: "PROCESSED",
        width: 100,
        headerClassName: "super-app-theme--header",
    },

    {
        field: "message",
        headerName: "MESSAGE",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
];