import { Box, Dialog, DialogTitle, Stack } from "@mui/material";
import { ButtonComponent } from "./common-field-components";
import { useTranslation } from "react-i18next";

export const ConfirmDialog = (props) => {
    const { openConfirm, accept, reject, msg, msg2, disable, hidebuttons } = props;
    const { t } = useTranslation();

    return (
        <Dialog className="wo-confirmation-popup" open={openConfirm}>
            <Box className="confirmation-popup-content" p={4}>
                <DialogTitle align="center">
                    {msg}
                    {msg2}
                </DialogTitle>
                <Box className="confirmation-popup-buttons" display="flex" justifyContent="center" mt={2}>
                    <Stack direction="row" spacing={2} alignItems="center">
                       
                            {!hidebuttons ? (
                                <ButtonComponent icon={false} label={t("NO")} color={"#F30C0C"} disabled={false} buttoncallBack={reject} />
                            ) : (
                                <ButtonComponent icon={false} label={t("CANCEL")} color={"#F30C0C"} disabled={false} buttoncallBack={reject} />
                            )}
                        
                        {!hidebuttons ? (
                            <ButtonComponent icon={false} label={t("YES")} disabled={disable} buttoncallBack={accept} />
                        ) : (
                            <ButtonComponent icon={false} label={t("ACCEPT")} disabled={disable} buttoncallBack={accept} />
                        )}
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    );
};
