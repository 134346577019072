import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';

export const getAllPermissons = async () => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.PERMISSIONS.GET_ALL_PERMISSIONS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAllPermissonMenus = async (page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.PERMISSIONS.GET_ALL_PERMISSION_MENUS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAllMenus = async () => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.PERMISSIONS.GET_ALL_MENUS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addNewPermission = async (newPermission) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.PERMISSIONS.POST_ADD_PERMISSION, JSON.stringify(newPermission)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const updatePermission = async (editedData) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.PERMISSIONS.GET_ALL_PERMISSION_MENUS, JSON.stringify(editedData)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const deletePermission = async (_permId) => {
    try {
        const result = await axios.get(APIENPOINTS.ADMIN.PERMISSIONS.DELETE_PERMISSION, { params: { permId: _permId } }).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getAllRoles = async (page = 0, size = 1000) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.ROLES.GET_ALL_ROLES, { params: { page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAllRolePermissions = async (page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.ROLES.GET_ROLE_PERMISSIONS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getAllDivisionPermissions = async (page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.ROLES.GET_ROLE_PERMISSIONS).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const addNewRole = async (token, newRole) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.ROLES.POST_ADD_ROLE, JSON.stringify(newRole)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const updateRolePermission = async (token, editedData) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.ROLES.PUT_ROLE_PERMISSIONS, JSON.stringify(editedData)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const deleteRole = async (_userId, _roleId) => {
    try {
        const result = await axios.get(APIENPOINTS.ADMIN.ROLES.DELETE_ROLE,
            { params: { userId: _userId, roleId: _roleId } }).then(res => {
                return res?.data;
            });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getUsers = async (page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.USERS.GET_ALL_USERS, { params: { page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getSearchUsers = async (page, size, keyword = "") => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.USERS.SEARCH_USERS, { params: { keyword: keyword, page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getUserByEmail = async (email) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.USERS.GET_USER_BY_EMAIL + email).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
        return error.response?.data;
    }
}

export const createNewUser = async (token, newUser) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.USERS.POST_ADD_USER, JSON.stringify(newUser)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const updateUser = async (editedUser) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.USERS.POST_UPDATE_USER, JSON.stringify(editedUser)).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const deleteUser = async (userId) => {
    try {
        const result = await axios.get(APIENPOINTS.ADMIN.USERS.DELETE_USER, { params: { userId: userId } }).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getAllLocations = async (page = 0, size = 1000) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.LOCATIONS.GET_ALL_LOCATIONS, { params: { page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAllDivisions = async (page = 0, size = 1000) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_ALL_DIVISIONS, { params: { page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getDivisionById = async (divisionId) => {
    try {
      const response = await axios.get(`${APIENPOINTS.MASTER.GET_ALL_DIVISIONS}/${divisionId}`);
      return response?.data;  
    } catch (error) {
      console.error('Error fetching division:', error);
      return null;
    }
  };
  
export const getAllDivisionsByDept = async (divisionId, page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.DIVISION.GET_DIVISION_BY_DEPT_ID, { params: { divisionId: divisionId, page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAllDepartments = async (divisionId, page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.DIVISION.GET_ALL_DEPARTMENTS, { params: { divisionId: divisionId, page: page, size: size } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteDivDept = async (id) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.DIVISION.DELETE_DIVISION_DEPT, { params: { deptId: id } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const deleteDiv = async (id) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ADMIN.DIVISION.DELETE_DIVISION, { params: { divisionId: id } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const saveDivision = async (_data) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.DIVISION.SAVE_DIVISION, _data).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}

export const saveDivisionDept = async (_data) => {
    try {
        const result = await axios.post(APIENPOINTS.ADMIN.DIVISION.SAVE_DIVISION_BY_DEPT, _data).then(res => {
            return res?.data;
        });
        return result;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}