import { Box, Button, IconButton, Typography, } from "@mui/material";
import React, { useState } from "react";
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OperationsIcon from "../../assets/images/WorkOrders/operations.svg";
import LaborIcons from "../../assets/images/WorkOrders/labor.svg";
import DocumentsIcons from "../../assets/images/WorkOrders/documents.svg";
import MetersIcons from "../../assets/images/WorkOrders/meters.svg";
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import AssetAssignmentForm from "./AssetAssignmentForm";
import AssetFinancialForm from "./AssetFinancialForm";
import AssetLocationForm from "./AssetLocationForm";
import AssetsMasterForm from "./AssetsMasterForm";
import AssetTransferForm from "./AssetTransferForm";
import AssetWarrantyForm from "./AssetWarrantyForm";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup'



export default function CreateAsset() {
    const theme = useTheme();
    const { oktaAuth, largeScreens, smallScreens } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [tabsList, setTabList] = useState([
        {
            id: 0,
            label: 'Assets Master Form',
            icon: "",
            status: false
        },
        {
            id: 1,
            label: 'Asset Location Form',
            icon: OperationsIcon,
            status: false
        },
        {
            id: 2,
            label: 'Asset Warranty Form',
            icon: LaborIcons,
            status: false
        },
        {
            id: 3,
            label: 'Asset Financial Form',
            icon: LaborIcons,
            status: false
        },
        {
            id: 4,
            label: 'Asset Assignment Form',
            icon: DocumentsIcons,
            status: false
        },
        {
            id: 5,
            label: 'Asset Transfer Form',
            icon: MetersIcons,
            status: false
        },
    ])

    const handleBack = () => {
        setValue((prevValue) => prevValue - 1);
    };

    const StepIndicator = ({ value }) => {
        return (
            <Box sx={{ borderRadius: "50%", background: 'white', width: '20px', height: '20px', color: 'black' }}>
                <Typography variant="body2">{value + 1}</Typography>
            </Box>

        );
    }

    const validationSchema = Yup.object({
        AssetsMaster: Yup.object({
            assetName: Yup.string()
                .required('Asset Name is required'),
            assetDescription: Yup.string()
                .required('Asset Description is required'),
            assetNumber: Yup.string()
                .required('Asset Number is required'),
            tagNumber: Yup.string()
                .required('Tag Number is required'),
            serialNumber: Yup.string()
                .required('serial Number is required'),
            category: Yup.string()
                .required('category Number is required'),
            assetType: Yup.string()
                .required('assetType is required'),
            assetKey: Yup.string()
                .required('assetKey is required'),
            unit: Yup.string()
                .required('Unit is required'),
            manufacturer: Yup.string()
                .required('Manufacturer is required'),
            model: Yup.string()
                .required('Model is required'),
            assetGroup: Yup.string()
                .required('Asset Group is required'),
            assetStatus: Yup.string()
                .required('Asset Status is required'),
            warrantyNumber: Yup.string()
                .required('Warranty Number is required'),
            leaseNumber: Yup.string()
                .required('Lease Number is required'),
            poNumber: Yup.string()
                .required('PO Number is required'),
            invoiceNumber: Yup.string()
                .required('Invoice Number is required'),
            organization: Yup.string()
                .required('Organization is required'),
            owningDepartment: Yup.string()
                .required('Owning Department is required'),
            parentAsset: Yup.string()
                .required('Parent Asset is required'),
            parentAssetDescription: Yup.string()
                .required('Parent Asset Description is required'),
            parentAssetGroup: Yup.string()
                .required('Parent Asset Group is required'),
            maintenable: Yup.string()
                .required('Maintenable is required'),
            accountingClass: Yup.string()
                .required('Accounting Class is required'),
            GISAsset: Yup.string()
                .required('GIS Asset is required'),
        }),
        AssetsLocation: Yup.object({
            location: Yup.string()
                .required('Location is required'),
            subInventory: Yup.string()
                .required('Sub Inventory is required'),
            locator: Yup.string()
                .required('Locator is required'),
            locationCode: Yup.string()
                .required('Tag Number is required'),
            address: Yup.string()
                .required('Address is required'),
            latitude: Yup.string()
                .required('Lattitude is required'),
            longitude: Yup.string()
                .required('Longitude is required'),

        }),
        AssetWarranty: Yup.object({
            warrantyNumber: Yup.string().required('Warranty Number is required'),
            description: Yup.string().required('Description is required'),
            type: Yup.string().required('Type is required'),
            startDate: Yup.date().required('Start Date is required'),
            endDate: Yup.date().required('End Date is required'),
            cost: Yup.number().required('Cost is required').positive('Cost must be a positive number'),
            renewable: Yup.string().required('Renewable status is required'),
            coverage: Yup.string().required('Coverage is required'),
            mileageLimit: Yup.number().required('Mileage Limit is required').positive('Mileage Limit must be a positive number'),
            warrantyExpiration: Yup.date().required('Warranty Expiration is required'),
            installationDate: Yup.date().required('Installation Date is required'),
        }),
        AssetFinancial: Yup.object({
            originalCost: Yup.string().required('originalCost is required'),
            currentValue: Yup.string().required('Current Value is required'),
            deprecationCost: Yup.string().required('Deprecation Cost is required'),
            vendor: Yup.string().required('Vendor is required'),
            dateOpened: Yup.date().required('Date Opened is required'),
            invoiceNumber: Yup.string().required('Invoice Number is required'),
            generalLedgerAccount: Yup.string().required('General Ledger Account is required'),
            requestLine: Yup.string().required('Request Line is required'),
            costCenter: Yup.string().required('Cost Center is required'),
            acquisitionNumber: Yup.string().required('Acquisition Number is required'),
            poNumber: Yup.string().required('Po Number is required'),
            method: Yup.string().required('Method is required'),
            lifeYears: Yup.number().required('Life Years is required'),
            lifeMonths: Yup.number().required('Life Months is required'),
            ltdDeprecation: Yup.string().required('Ltd Deprecation  is required'),
            ytdDeprecation: Yup.string().required('Ytd Deprecation is required'),
            accumulatedDeprecation: Yup.string().required('Accumulated Deprecation  is required'),
            deprecationLimit: Yup.string().required('Deprecation Limit is required'),
        }),
        AssetAssignment: Yup.object({
            employeeName: Yup.string().required('Employee Name is required'),
            employeeNumber: Yup.string().required('Employee Number is required'),
            location: Yup.string().required('Location is required'),
            expenseAccount: Yup.string().required('Expense Account is required'),
        }),
        AssetTransfer: Yup.object({
            organization: Yup.string().required('Organization is required'),
            sourceSystem: Yup.string().required('Source System is required'),
            consignee: Yup.string().required('Consignee is required'),
            shipMethod: Yup.string().required('Ship Method is required'),
            scheduledStartDate: Yup.string().required('Scheduled Start Date is required'),
            scheduledOrderDate: Yup.string().required('Scheduled Order Date is required'),
            orderType: Yup.string().required('Order Type is required'),
            status: Yup.string().required('Status is required'),
            item: Yup.string().required('Item is required'),
            shipmentPriority: Yup.string().required('Shipment Priority is required'),
            freight: Yup.string().required('Freight is required'),
            pickUpDate: Yup.string().required('Pick Up Date is required'),
            trackingNumber: Yup.string().required('Tracking Number is required'),
            freightTerms: Yup.string().required('Freight Terms is required'),
            servicelevel: Yup.string().required('Service Level is required'),
            carrier: Yup.string().required('Carrier is required'),
        }),


    });


    const formik = useFormik({
        initialValues: {
            AssetsMaster: {
                assetName: '',
                assetDescription: '',
                assetNumber: '',
                tagNumber: '',
                serialNumber: '',
                category: '',
                assetType: '',
                assetKey: '',
                unit: '',
                manufacturer: '',
                model: '',
                assetGroup: '',
                assetStatus: '',
                warrantyNumber: '',
                leaseNumber: '',
                poNumber: '',
                invoiceNumber: '',
                organization: '',
                owningDepartment: '',
                parentAsset: '',
                parentAssetDescription: '',
                parentAssetGroup: '',
                maintenable: '',
                accountingClass: '',
                GISAsset: '',
            },
            AssetsLocation: {
                location: '',
                subInventory: '',
                locator: '',
                locationCode: '',
                address: '',
                latitude: '',
                longitude: '',
            },
            AssetWarranty: {
                warrantyNumber: '',
                description: '',
                type: '',
                startDate: '',
                endDate: '',
                cost: '',
                renewable: '',
                coverage: '',
                mileageLimit: '',
                warrantyExpiration: '',
                installationDate: '',
            },
            AssetFinancial: {
                originalCost: '',
                currentValue: '',
                depreciationCost: '',
                vendor: '',
                dateOpened: '',
                requestLine: '',
                costCenter: '',
                mileageLimit: '',
                acquisitionNumber: '',
                poNumber: '',
                method: '',
                lifeYears: '',
                lifeMonths: '',
                ltdDeprecation: '',
                ytdDeprecation: '',
                accumulatedDeprecation: '',
                depreciationLimit: '',
            },
            AssetAssignment: {
                employeeName: '',
                employeeNumber: '',
                location: '',
                expenseAccount: '',
            },
            AssetTransfer: {
                organization: '',
                sourceSystem: '',
                consignee: '',
                shipMethod: '',
                scheduledStartDate: '',
                scheduledOrderDate: '',
                orderType: '',
                status: '',
                item: '',
                shipmentPriority: '',
                freight: '',
                pickUpDate: '',
                trackingNumber: '',
                freightTerms: '',
                servicelevel: '',
                carrier: '',
            },
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                // Perform any submission logic here (e.g., API calls)
                setLoading(true);
                // Simulating API call with timeout
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
                // Navigate to AssetHome page upon successful submission
                navigate('/Assets');
            } catch (error) {
                console.error('Submission error:', error);
                setLoading(false);
            }
        },
    });

    const stepKeys = [
        "AssetsMaster",
        "AssetsLocation",
        "AssetWarranty",
        "AssetFinancial",
        "AssetAssignment",
        "AssetTransfer",
    ];

    const OnNext = async () => {
        const currentStepKey = stepKeys[value];
        const currentStepSchema = validationSchema.fields[currentStepKey];
        const currentStepValues = formik.values[currentStepKey];
            setValue(value + 1);
    };

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            <Box className={smallScreens ? 'mobileHomePageContentWrapper' : 'homePageContentWrapper'}>
                <Box className='homePageHeader' sx={{ position: 'static', '& .MuiSvgIcon-root': { color: 'green' } }}>
                    <IconButton sx={{ marginLeft: '-9px' }} onClick={() => navigate('/Assets')}>
                        {smallScreens ? <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '1rem' }} /> : <KeyboardBackspaceOutlinedIcon />}
                    </IconButton>
                    <Typography variant={'h5'} flexGrow={1} display={'flex'} alignItems={'center'} gap={'10px'}>
                        {smallScreens ? (tabsList.find(tab => tab.id === value)?.label) : 'Create Asset'} {((tabsList.find(tab => tab.id === value)?.status && smallScreens) && <CheckCircleRoundedIcon />)}
                    </Typography>

                </Box>

                <Box className='homePageTable' sx={{ '& .MuiTabPanel-root': { height: smallScreens ? window.innerHeight - 153 : window.innerHeight - 234, overflow: 'auto', padding: largeScreens ? '10px 0px' : '0px' }, mt: largeScreens ? 1 : 0 }} >
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            {largeScreens ?
                                <Box sx={{
                                    background: '#979797', borderBottom: 1, borderColor: 'divider',
                                    '& .Mui-selected': { background: '#2F80ED', color: 'white !important' },
                                    '& .MuiTabs-indicator': { display: 'none' },
                                    '& .MuiButtonBase-root': { color: 'white', flex: 1, flexDirection: 'row', gap: 1, alignItems: 'center', minHeight: '0px', height: '50px' },
                                    '& .MuiTab-iconWrapper': { marginBottom: '0px !important' },
                                }}>
                                    <TabList aria-label="lab API tabs example">
                                        {
                                            tabsList.map((row, index) => (
                                                <Tab key={row.id} label={row.label} value={row.id} icon={row?.status ? <CheckCircleRoundedIcon /> : <StepIndicator value={row?.id} />} />
                                            ))
                                        }
                                    </TabList>
                                </Box>
                                :
                                <MobileStepper
                                    variant="text"
                                    steps={6}
                                    position="bottom"
                                    activeStep={value}
                                    sx={{ flexGrow: 1, fontSize: '1rem' }}
                                    nextButton={
                                        <Button onClick={OnNext} size="small" >
                                            {(value === tabsList.length - 1) ? "Submit" : "Next"}
                                            {(!(value === tabsList.length - 1)) && <KeyboardArrowRight />}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={value === 0}>
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                            Back
                                        </Button>
                                    }
                                />
                            }
                            <TabPanel value={0}>
                                <AssetsMasterForm formik={formik} />
                            </TabPanel>
                            <TabPanel value={1}>
                                <AssetLocationForm formik={formik} />
                            </TabPanel>
                            <TabPanel value={2}>
                                <AssetWarrantyForm formik={formik} />
                            </TabPanel>
                            <TabPanel value={3}>
                                <AssetFinancialForm formik={formik} />
                            </TabPanel>
                            <TabPanel value={4}>
                                <AssetAssignmentForm formik={formik} />
                            </TabPanel>
                            <TabPanel value={5}>
                                <AssetTransferForm formik={formik} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                    {!smallScreens && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, padding: '10px 0px' }}>
                            {value > 0 && (
                                <Button variant="contained" onClick={handleBack}>
                                    {<KeyboardArrowLeft />}
                                    Back
                                </Button>
                            )}
                            <Button id='v1' variant="contained" onClick={OnNext} size="small" >
                                {(value === tabsList.length - 1) ? "Submit" : "Next"}
                                {(!(value === tabsList.length - 1)) && <KeyboardArrowRight />}
                            </Button>
                        </Box>
                    )}

                </Box>
            </Box>
        </Box >
    );
}