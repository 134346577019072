// Validate the entered value should be number only
export const validateIsNumber = (value) => {
  var numberRegex = /^[0-9]+$/;
  return numberRegex.test(value);
};

// Validate the entered value should be alpha numeric only
export const validateAllowNumericandCharsOnly = (value) => {
  const alphanumericRegex = /^[-_a-zA-Z0-9.,]+$/;
  return alphanumericRegex.test(value);
};

// Validate the entered value should be valid email
export const validateEmail = (value) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return value.match(mailformat);
};
