import * as React from "react";
import { Grid, Typography} from '@mui/material'
import { InputComponent } from "../shared-components/common-field-components";



const AssetsLocationForm = (props) => {
    const {  formik,  } = props; 
  
    return (
        <Grid
        container
        spacing={2}
        sx={{
          padding: {
            xs: '4px',
            lg: '1px',
          },
        }}
      >
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Location</Typography>
          <InputComponent
            name="AssetsLocation.location"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.location && formik.errors.AssetsLocation?.location}
            value={formik.values.AssetsLocation.location}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Sub-Inventory</Typography>
          <InputComponent
            name="AssetsLocation.subInventory"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.subInventory &&formik.errors.AssetsLocation?.subInventory}
            value={formik.values.AssetsLocation.subInventory}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Locator</Typography>
          <InputComponent
            name="AssetsLocation.locator"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.locator &&formik.errors.AssetsLocation?.locator}
            value={formik.values.AssetsLocation.locator}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Location Code</Typography>
          <InputComponent
            name="AssetsLocation.locationCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.locationCode &&formik.errors.AssetsLocation?.locationCode}
            value={formik.values.AssetsLocation.locationCode}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Address</Typography>
          <InputComponent
            name="AssetsLocation.address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.address &&formik.errors.AssetsLocation?.address}
            value={formik.values.AssetsLocation.address}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Latitude</Typography>
          <InputComponent
            name="AssetsLocation.latitude"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.latitude &&formik.errors.AssetsLocation?.latitude}
            value={formik.values.AssetsLocation.latitude}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h6">Longitude</Typography>
          <InputComponent
            name="AssetsLocation.longitude"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.AssetsLocation?.longitude &&formik.errors.AssetsLocation?.longitude}
            value={formik.values.AssetsLocation.longitude}
            width="100%"
          />
        </Grid>
      </Grid>
    );
  };
export default AssetsLocationForm