export const userTableColumns = [
    {
        field: "userId",
        headerName: "USER_ID",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "name",
        headerName: "USER_NAME",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "email",
        headerName: "EMAIL_ADDRESS",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "phone",
        headerName: "PHONE_NUMBER",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "currentSite",
        headerName: "CURRENT_SITE",
        width: 100,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "delete",
        headerName: "",
        headerClassName: "super-app-theme--header",
        sortable: false,
    },   
];
