import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box, InputAdornment, TextField } from '@mui/material';
import { InputComponent, SelectComponent } from ".";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
    getCauseCodesByFailureCode,
    getResolutionCodesByFailureAndCauseAndSetId,
    saveFailure,
    updateFailure,
} from '../../../services/WorkOrderServices';
import ToastMessage from '../ToastMessage';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UserContext } from '../../../store/UserContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material'
import { BorderBottom, Padding } from '@mui/icons-material';
import '../index.css'


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const validationSchema = yup.object({
    failureCode: yup.string().trim().required("Required"),
    causeCode: yup.string().required("Required"),
    resolutionCode: yup.string().required("Required"),
    failureDate: yup.string().required(""),
    comments: yup.string(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        BorderBottom: 0,
    },
    "& td:first-of-type, & th:first-of-type": {
        borderLeft: '5px solid #006CEC !important',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px'
    },
}));
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}




export default function WoFailureTableComponent(props) {
    const { failureData, failures, failureDropdown, addRecord, user, } = props;
    const { t } = useTranslation();
    const d = new Date();
    const dateDefaultValue = dayjs(d, "DD-MM-YYYY");
    const { columns } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [causeDropdowns, setCauseDropdowns] = React.useState([{ id: 0, value: '--Select Cause--' }]);
    const [resolutionDropdowns, setResolutionDropdowns] = React.useState([{ id: 0, value: '--Select Resolution--' }]);
    const [toast, setToast] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('task');
    const { screenSize, appConstants } = useContext(UserContext);


    const visibleRows = React.useMemo(
        () =>
            stableSort(failureData, getComparator(order, orderBy)),
        [failureData, order, orderBy, page, rowsPerPage]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [errors, setErrors] = React.useState<any>({});
    const formik = useFormik({
        initialValues: {
            failureCode: '0',
            causeCode: '0',
            resolutionCode: '0',
            comments: null,
            failureDate: null,
            //TBU :names
            createdBy: props?.user.name,
            lastUpdatedBy: props?.user.name
        },
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
        },
    });



    const getCauseCodesBySelectedFailureCode = async (failureCode, setId) => {
        try {
            setLoading(true);
            const response = await getCauseCodesByFailureCode(user?.token, failureCode, setId);

            if (response && response?.statusCode === "200") {
                setCauseDropdowns([]);
                causeDropdowns[0] = {
                    id: 0,
                    value: '--Select Cause--',
                };

                setResolutionDropdowns([]);
                resolutionDropdowns[0] = {
                    id: 0,
                    value: '--Select Resolution--',
                };

                response?.data?.map((cause, index) => {
                    causeDropdowns[index + 1] = ({
                        id: cause.causeCode,
                        value: cause.description
                    });
                });
                setCauseDropdowns(causeDropdowns);
                setResolutionDropdowns(resolutionDropdowns);
                formik.setFieldValue('causeCode', 0, false);
                formik.setFieldValue('resolutionCode', 0, false);
                setLoading(false);
            }
        } catch (err) { console.log(err) };
    }

    const getResolutionCodesByFailureCodeAndCauseCodes = async (failureCode, causeCode, setId) => {
        try {
            setLoading(true);
            const response = await getResolutionCodesByFailureAndCauseAndSetId(user?.token, failureCode, causeCode, setId);
            if (response && response?.statusCode === "200") {
                setResolutionDropdowns([]);
                resolutionDropdowns[0] = {
                    id: 0,
                    value: '--Select Resolution--',
                };
                response?.data?.map((resolution, index) => {
                    resolutionDropdowns[index + 1] = ({
                        id: resolution.resolutionCode,
                        value: resolution.description
                    });
                });
                setResolutionDropdowns(resolutionDropdowns);
                setLoading(false);
            }
        } catch (err) { console.log(err) };
    }



    const handleResolutionCodesChange = (e) => {
        formik.setFieldValue('resolutionCode', e, false);
    }


    React.useEffect(() => {
        if (failureData?.length > 0 && addRecord) {
            const selectedFailure = failures?.find(x => x.failureCode === failureData[0].failureCode);
            const responses = Promise.all([getCauseCodesBySelectedFailureCode(failureData[0]?.failureCode, failures[0]?.setId), getResolutionCodesByFailureCodeAndCauseCodes(failureData[0]?.failureCode, failureData[0].causeCode, failures[0].setId)]);
            responses.then(res => {
                formik.setFieldValue('failureCode', failureData[0].failureCode, false);
                formik.setFieldValue('causeCode', failureData[0].causeCode, false);
                formik.setFieldValue('resolutionCode', failureData[0].resolutionCode, false);
                formik.setFieldValue('comments', failureData[0]?.comments);
                formik.setFieldValue('failureDate', failureData[0]?.failureDate);
            });
        }
    }, [failureData?.length > 0 && addRecord]);

    React.useEffect(() => {
        setCauseDropdowns(causeDropdowns);
    }, [causeDropdowns]);

    const validateFields = () => {
        var flag = false;
        if (formik.values.failureCode === '0' || formik.values.failureCode === '--Select Failure--' || parseInt(formik.values.failureCode) === 0) {
            formik.setFieldError('failureCode', 'Select Failue');
            flag = true;
        } else {
            formik.setFieldError('failureCode', '');
        }
        if (formik.values.causeCode === '0' || formik.values.causeCode === '--Select Cause--' || parseInt(formik.values.causeCode) === 0) {
            formik.setFieldError('causeCode', 'Select Cause');
            flag = true;
        } else {
            formik.setFieldError('causeCode', '');
        }
        if (formik.values.resolutionCode === '0' || formik.values.resolutionCode === '--Select Resolution--' || parseInt(formik.values.resolutionCode) === 0) {
            formik.setFieldError('resolutionCode', 'Select Resolution');
            flag = true;
        } else {
            formik.setFieldError('resolutionCode', '');
        }
        if (failureData && failureData?.length > 0) {
            let formattedDate = props?.clickedWorkOrder?.releaseDate;

            if (formatDate(formattedDate) > formatDate(formik?.values?.failureDate)) {
                formik.setFieldError('failureDate', 'failure date cant be before release date');
                flag = true;
            } else {
                formik.setFieldError('failureDate', '');
            }
        }
        else {
            if (formik.values.failureDate === null) {
                formik.setFieldError('failureDate', 'Select Failue Date');
                flag = true;
            } else {
                formik.setFieldError('failureDate', '');
            }
        }

        if (flag) {
            return false;
        } else {
            return true;
        }
    }

    function formatDateInsert(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}-${day}-${year}`;
    }

    React.useEffect(() => {
        if (props?.saveRecord) {
            props?.setSaveRecord(false);
            if (validateFields()) {
                if (failureData?.length === 0) {
                    const data = {
                        createWorkOrderId: props?.clickedWorkOrder?.createWorkOrderId,
                        workOrderId: props?.clickedWorkOrder?.workOrderId,
                        wipEntityId: props?.clickedWorkOrder?.wipEntityId,
                        organizationId: props?.clickedWorkOrder?.organizationId,
                        failureCode: formik.values.failureCode,
                        causeCode: formik.values.causeCode,
                        resolutionCode: formik.values.resolutionCode,
                        failureDate: formatDateInsert(formik.values.failureDate),
                        comments: formik.values.comments === null ? '' : formik.values.comments,
                        createdBy: user?.id,
                    }
                    saveFailureRecord(data);
                } else {
                    const data = {
                        createWorkOrderId: props?.clickedWorkOrder?.createWorkOrderId,
                        workOrderId: props?.clickedWorkOrder?.workOrderId,
                        wipEntityId: props?.clickedWorkOrder?.wipEntityId,
                        organizationId: props?.clickedWorkOrder?.organizationId,
                        failureCode: formik.values.failureCode,
                        causeCode: formik.values.causeCode,
                        resolutionCode: formik.values.resolutionCode,
                        failureDate: formatDateInsert(formik.values.failureDate),
                        comments: formik.values.comments,
                        modifiedBy: user?.id,
                    }
                    updateFailureRecord(data);
                }
            }
        }
    });
    const saveFailureRecord = async (data) => {
        setLoading(true);
        const res = await saveFailure(user?.token, data).then(res => {
            if (res?.statusCode === "200") {
                const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                formik.values.failureCode = '0';
                formik.values.causeCode = '0';
                formik.values.resolutionCode = '0';
                formik.values.failureDate = null;
                formik.values.comments = null;
                props?.getUpdatedFailureData();
                setLoading(false);
            }
            else {
                setLoading(false);
                const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                setToast(toastProps);

            }
        });
    }
    const updateFailureRecord = async (data) => {
        setLoading(true);
        const res = await updateFailure(user?.token, data).then(res => {
            if (res?.statusCode === "200") {
                const toastProps = { open: true, type: "success", message: "Updated successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                formik.values.failureCode = '0';
                formik.values.causeCode = '0';
                formik.values.resolutionCode = '0';
                formik.values.failureDate = null;
                formik.values.comments = null;
                props?.getUpdatedFailureData();
                setLoading(false);
            }
            else {
                setLoading(false);
                const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                setToast(toastProps);

            }
        });
    }
    const onKeyDown = (e) => {
        e.preventDefault();
    };



    return (
        <>
            <Box>
                {/* Web and Tablet UI */}
                <Box >
                    {loading &&
                        <div className="loading">Loading&#8230;</div>
                    }
                    <TableContainer sx={{ transition: "height 100ms ease-in-out", maxHeight: props.tableHeight, width: screenSize?.dynamicWidth < appConstants.MOBILE_RESOLUTION ? window.innerWidth : "100%" }} className='wo-operations-table-container'>
                        <Table sx={{
                            minWidth: 700,
                            '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                            '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                                color: "black", opacity: 100
                            }
                        }} stickyHeader size="small" aria-label="sticky table">
                            <TableHead>
                                <TableRow style={{ height: 10 }}>
                                    {columns.map((column, index) => {
                                        return <StyledTableCell key={index}>
                                            {t(column.headerName)}
                                        </StyledTableCell>
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {addRecord && <StyledTableRow className='add-failure-row'>
                                    <StyledTableCell>
                                        <SelectComponent
                                            values={failureDropdown}
                                            name={"failureCode"}
                                            selectedvalue={formik.values?.failureCode}
                                            disabled={false}
                                            width={"100%"}
                                            error={formik?.errors?.failureCode}
                                            parentselectcallback={(val) => {
                                                formik.setFieldValue('failureCode', val)
                                                    .then(async () => {
                                                        setCauseDropdowns([]);
                                                        setResolutionDropdowns([]);
                                                        causeDropdowns[0] = {
                                                            id: 0,
                                                            value: '--Select Cause--',
                                                        };
                                                        setCauseDropdowns(causeDropdowns);
                                                        formik.setFieldValue('causeCode', 0);
                                                        resolutionDropdowns[0] = {
                                                            id: 0,
                                                            value: '--Select Resolution--',
                                                        };
                                                        setResolutionDropdowns(resolutionDropdowns);
                                                        formik.setFieldValue('resolutionCode', 0);
                                                        getCauseCodesBySelectedFailureCode(val, props?.failures[0]?.setId);
                                                    })
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <SelectComponent
                                            values={causeDropdowns}
                                            name={"causeCode"}
                                            selectedvalue={formik.values?.causeCode}
                                            disabled={false}
                                            width={"100%"}
                                            error={formik?.errors?.causeCode}
                                            parentselectcallback={(val) => {
                                                formik.setFieldValue('causeCode', val)
                                                    .then(async () => {
                                                        setResolutionDropdowns([]);
                                                        resolutionDropdowns[0] = {
                                                            id: 0,
                                                            value: '--Select Resolution--',
                                                        };
                                                        setResolutionDropdowns(resolutionDropdowns);
                                                        formik.setFieldValue('resolutionCode', 0);
                                                        getResolutionCodesByFailureCodeAndCauseCodes(formik.values.failureCode, val, props?.failures[0]?.setId)
                                                    })
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <SelectComponent
                                            values={resolutionDropdowns}
                                            name={"resolutionCode"}
                                            selectedvalue={formik.values?.resolutionCode}
                                            disabled={false}
                                            width={"100%"}
                                            error={formik?.errors?.resolutionCode}
                                            parentselectcallback={handleResolutionCodesChange}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell className='datepicker-cell'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                defaultValue={(dateDefaultValue)}
                                                className='labor-dates'
                                                inputFormat={"MM-DD-YYYY"}
                                                onAccept={(e) => {

                                                }}
                                                onChange={(e) => {
                                                    try {
                                                        let date = new Date(e);
                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                        formik.setFieldValue("failureDate", formattedDate, false);
                                                    } catch (e) {
                                                        let date = new Date(e);
                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                        formik.setFieldValue("failureDate", formattedDate, false);
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Calendar />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            variant='outlined'
                                                            onKeyDown={onKeyDown}
                                                            size="small"
                                                            sx={{ fontSize: "14px !important", backgroundColor: "white", }}
                                                            {...params}
                                                        />
                                                        {formik?.errors?.failureDate && <span style={{ color: "red" }}>{formik?.errors?.failureDate}</span>}
                                                    </>
                                                )} value={formik?.values.failureDate}
                                                disableFuture={true} />
                                        </LocalizationProvider>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <InputComponent
                                            name={"comments"}
                                            value={formik?.values.comments}
                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                            width={"100%"}
                                            maxLength={"250"}
                                            error={errors?.comments} />
                                    </StyledTableCell>
                                </StyledTableRow>
                                }
                                {!addRecord && visibleRows && visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell >{row?.failureDescription}</StyledTableCell>
                                        <StyledTableCell>{row?.causeDescription}</StyledTableCell>
                                        <StyledTableCell>{row?.resolutionDescription}</StyledTableCell>
                                        <StyledTableCell>{formatDate(row?.failureDate)}</StyledTableCell>
                                        <StyledTableCell>
                                            {row?.comments}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={failureData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='customTablePagination'

                    />
                </Box>
                {screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION &&
                    <Dialog
                        open={addRecord}
                        onClose={() => props.onClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Add Document"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '0px' }}>
                            <Box className='mobile-card-content-main' >
                                <Box className='mobile-cards-content'>
                                    <Box className='card-content-wo'>
                                        <Card variant="outlined">
                                            <React.Fragment>
                                                <CardContent>
                                                    <TableContainer>
                                                        <Table className="horizontel-table mobile-table-card"

                                                        >
                                                            <TableBody key={'new-record'}>
                                                                <TableRow
                                                                >
                                                                    <TableCell component='th' scope='row' >
                                                                        {t('WO_FAILURE_LABEL')}
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <SelectComponent
                                                                            values={props?.failureDropdown}
                                                                            name={"failureCode"}
                                                                            selectedvalue={formik.values?.failureCode}
                                                                            disabled={false}
                                                                            width={"100%"}
                                                                            error={formik?.errors?.failureCode}
                                                                            parentselectcallback={(val) => {
                                                                                formik.setFieldValue('failureCode', val)
                                                                                    .then(async () => {
                                                                                        setCauseDropdowns([]);
                                                                                        setResolutionDropdowns([]);
                                                                                        causeDropdowns[0] = {
                                                                                            id: 0,
                                                                                            value: '--Select Cause--',
                                                                                        };
                                                                                        setCauseDropdowns(causeDropdowns);
                                                                                        formik.setFieldValue('causeCode', 0);
                                                                                        resolutionDropdowns[0] = {
                                                                                            id: 0,
                                                                                            value: '--Select Resolution--',
                                                                                        };
                                                                                        setResolutionDropdowns(resolutionDropdowns);
                                                                                        formik.setFieldValue('resolutionCode', 0);
                                                                                        getCauseCodesBySelectedFailureCode(val, props?.failures[0]?.setId);
                                                                                    })
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow

                                                                >
                                                                    <TableCell component='th' scope='row'>
                                                                        {t('WO_FAILURE_CAUSE')}
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <SelectComponent
                                                                            values={causeDropdowns}
                                                                            name={"causeCode"}
                                                                            selectedvalue={formik.values?.causeCode}
                                                                            disabled={false}
                                                                            width={"100%"}
                                                                            error={formik?.errors?.causeCode}
                                                                            parentselectcallback={(val) => {
                                                                                formik.setFieldValue('causeCode', val)
                                                                                    .then(async () => {
                                                                                        setResolutionDropdowns([]);
                                                                                        resolutionDropdowns[0] = {
                                                                                            id: 0,
                                                                                            value: '--Select Resolution--',
                                                                                        };
                                                                                        setResolutionDropdowns(resolutionDropdowns);
                                                                                        formik.setFieldValue('resolutionCode', 0);
                                                                                        getResolutionCodesByFailureCodeAndCauseCodes(formik.values.failureCode, val, props?.failures[0]?.setId)
                                                                                    })
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow

                                                                >
                                                                    <TableCell component='th' scope='row'>
                                                                        {t('WO_FAILURE_RESOLUTION')}
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <SelectComponent
                                                                            values={resolutionDropdowns}
                                                                            name={"resolutionCode"}
                                                                            selectedvalue={formik?.values?.resolutionCode}
                                                                            disabled={false}
                                                                            width={"100%"}
                                                                            error={formik?.errors?.resolutionCode}
                                                                            parentselectcallback={handleResolutionCodesChange}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow

                                                                >
                                                                    <TableCell component='th' scope='row'>
                                                                        {t('WO_FAILURE_FAILURE_DATE')}
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                defaultValue={(dateDefaultValue)}
                                                                                className='labor-dates'
                                                                                inputFormat={"MM-DD-YYYY"}
                                                                                onAccept={(e) => {

                                                                                }}
                                                                                onChange={(e) => {
                                                                                    try {
                                                                                        let date = new Date(e);
                                                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                                                        formik.setFieldValue("failureDate", formattedDate, false);
                                                                                    } catch (e) {
                                                                                        let date = new Date(e);
                                                                                        const formattedDate = moment(date).format("MM-DD-YYYY");
                                                                                        formik.setFieldValue("failureDate", formattedDate, false);
                                                                                    }
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <Calendar />
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                                renderInput={(params) => (
                                                                                    <>
                                                                                        <TextField
                                                                                            variant='outlined'
                                                                                            onKeyDown={onKeyDown}
                                                                                            size="small"
                                                                                            sx={{ fontSize: "14px !important", backgroundColor: "white", }}
                                                                                            {...params}
                                                                                        />
                                                                                        {(formik?.errors?.failureDate && formik?.values.failureDate === null) && <span style={{ color: "red" }}>{formik?.errors?.failureDate}</span>}
                                                                                    </>
                                                                                )} value={formik?.values.failureDate}
                                                                                disableFuture={true} />
                                                                        </LocalizationProvider>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow

                                                                >
                                                                    <TableCell component='th' scope='row'>
                                                                        {t('WO_FAILURE_COMMENTS')}
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        <InputComponent
                                                                            name={"comments"}
                                                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                                                            width={"100%"}
                                                                            value={formik.values.comments}
                                                                            maxLength={"250"}
                                                                            error={errors?.comments} />
                                                                    </TableCell>
                                                                </TableRow>


                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </CardContent>
                                            </React.Fragment>
                                        </Card>
                                    </Box>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => props.onClose()} autoFocus sx={{ color: '#FF0000' }}>
                                Cancel
                            </Button>
                            <Button onClick={() => props.saveFailureRecord()} autoFocus>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Box>
            <ToastMessage toast={toast} />
        </>
    );
}