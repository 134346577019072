import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from "../../store/UserContext";
import ToastMessage from "../shared-components/ToastMessage";

import '../styles/summary.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ButtonComponent,
} from "../shared-components/common-field-components";
import { updateAssetConfig, getAssetConfigurationsByAssetNumber, getAssetDetailsByAssetNumber, getAssetConfigByAttribute } from "../../services/AssetsServices";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMediaQuery } from '@mui/material';
import { Link } from "react-router-dom";


const validationSchema = yup.object({
  config1: yup.string().trim().required("Required"),
  config2: yup.string().required("Required"),
  config3: yup.string().required("Required"),
  config4: yup.date().required("Required"),
});

export default function Summary(props) {
  const { oktaAuth, appConstants, selectedSite, user } = React.useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();
  const savedAssetNumber = localStorage.getItem('assetNum');
  const [loading, setLoading] = React.useState(false);
  const [assetsConfig, setAssetConfig] = React.useState();
  const [assetDetails, setAssetDetails] = useState([]);
  const [attributes, setAttributes] = React.useState();
  const largeScreens = useMediaQuery('(min-width:600px)');
  const [configShow, setConfigShow] = React.useState(attributes?.length > 0 ? true : false)
  const [parentSummary, setParentSummary] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const assetNumber = useMemo(() => {
    return location?.state?.asset || savedAssetNumber
  }, [location, selectedSite]);
  const goBackTo = location?.state?.from;
  const [assetNumFromRoute, setAssetNumberFromRoute] = React.useState(assetNumber);
  const [config1, setConfig1] = React.useState([]);
  const [config2, setConfig2] = React.useState([{
    id: 0, value: 'Select'
  }]);
  const [config3, setConfig3] = React.useState([{
    id: 0, value: 'Select'
  }]);
  const [config4, setConfig4] = React.useState([{
    id: 0, value: 'Select'
  }]);
  const [toast, setToast] = useState({});
  const [selectedConfigParams, setSelectedConfigParams] = React.useState({ 'config1': 0, 'config2': 0, 'config3': 0, 'config4': 0 })
  const [configEditable, setconfigEditable] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      config1: '0',
      config2: '0',
      config3: '0',
      config4: '0',
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
    },
  });

  const updateState = () => {
    setConfig1([]);
    setConfig2([{}]);
    setConfig3([{}]);
    setConfig4([{}]);
    formik.setFieldValue('config1', 0, false);
    formik.setFieldValue('config2', 0, false);
    formik.setFieldValue('config3', 0, false);
    formik.setFieldValue('config4', 0, false);
  }
  const getAssetConfigByAssetAttribute = async () => {
    try {
      await updateState();
      let config1 = [];
      let config2 = [];
      let config3 = [];
      let config4 = [];
      await attributes[0]?.configuration1?.map((config, index) => {
        config1[index + 1] = {
          id: index + 1, value: config
        };
        if (assetsConfig?.configuration1 !== null && assetsConfig?.configuration1 === config) {
          formik.setFieldValue('config1', (index + 1), false);
        }
      });
      await attributes[0]?.configuration2?.map((config, index) => {
        config2[index + 1] = {
          id: index + 1, value: config
        };
        if (assetsConfig?.configuration2 !== null && assetsConfig.configuration2 === config) {
          formik.setFieldValue('config2', (index + 1), false);
        }
      });

      await attributes[0]?.configuration3?.map((config, index) => {
        config3[index + 1] = {
          id: index + 1, value: config
        };
        if (assetsConfig?.configuration3 !== null && assetsConfig.configuration3 === config) {
          formik.setFieldValue('config3', (index + 1), false);
        }
      });


      await attributes[0]?.configuration4?.map((config, index) => {
        config4[index + 1] = {
          id: index + 1, value: config
        };
        if (assetsConfig?.configuration4 !== null && assetsConfig.configuration4 === config) {
          formik.setFieldValue('config4', (index + 1), false);
        }
      });
      setConfig1(config1);
      setConfig2(config2);
      setConfig3(config3);
      setConfig4(config4);

      // }
    } catch (err) { console.log(err) };
  }
  React.useEffect(() => {
    if (assetsConfig && attributes?.length > 0) {
      if (configShow)
        setConfigShow(false)
      getAssetConfigByAssetAttribute();
    } else {
      if (configShow)
        setConfigShow(false)
      setconfigEditable(false);
      setConfig1([]);
      setConfig2([]);
      setConfig3([]);
      setConfig4([]);
      formik.setFieldValue('config1', 0, false);
      formik.setFieldValue('config2', 0, false);
      formik.setFieldValue('config3', 0, false);
      formik.setFieldValue('config4', 0, false);
    }
  }, [attributes]);

  const updateAssetConfigData = async () => {
    setLoading(true)
    const updatingData = {
      assetNumber: assetDetails?.assetNumber,
      attributeCategory: assetsConfig?.attributeCategory,
      configuration1: config1.find(x => x?.id === formik.values.config1)?.value,
      configuration2: config2.find(x => x?.id === formik.values.config2)?.value,
      configuration3: config3.find(x => x?.id === formik.values.config3)?.value,
      configuration4: config4.find(x => x?.id === formik.values.config4)?.value,
      modifiedBy: user.id,
    }
    try {
      const response = await updateAssetConfig('', updatingData);
      if (response && response?.statusCode === "200") {
        const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
        setToast(toastProps);
        setconfigEditable(!configEditable)
        setLoading(false)
        formik.setFieldValue('config1', (config1.find(x => x?.id === formik.values.config1)?.id), false);
        formik.setFieldValue('config2', (config2.find(x => x?.id === formik.values.config2)?.id), false);
        formik.setFieldValue('config3', (config3.find(x => x?.id === formik.values.config3)?.id), false);
        formik.setFieldValue('config4', (config4.find(x => x?.id === formik.values.config4)?.id), false);
      }
      else {
        const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
        setToast(toastProps);
        setLoading(false)
      }
    } catch (err) { console.log(err); setLoading(false) };


  }

  const getAssetConfigByAssetAttributeData = async (assetConfig, asset) => {
    try {
      setAttributes([]);
      const response = await getAssetConfigByAttribute('', assetConfig?.attributeCategory, asset?.assetGroup);
      if (response && response?.statusCode === "200") {
        setAttributes([response?.data]);
        setLoading(false);
      }
    } catch (err) { console.log(err) };
  }

  const fetchAssetsConfigLookUpData = async (asset) => {
    try {
      const response = await getAssetConfigurationsByAssetNumber('', asset?.assetNumber, selectedSite?.organizationId);
      if (response && response?.statusCode === "200") {
        setAssetConfig(response?.data);
        getAssetConfigByAssetAttributeData(response?.data, asset)
        getAssetConfigByAssetAttribute()
      }
    } catch (err) { console.log(err) };
  }

  function formatDate(timestamp) {
    if (timestamp === null || timestamp === 0) {
      return "-";
    } else {
      const date = new Date(timestamp);
      const year = date.getUTCFullYear(); 
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${month}-${day}-${year}`;
    }
  }
  

  const getAssetDetails = async (_assetNumber) => {
    setLoading(true);
    localStorage.setItem('assetNum', JSON.stringify(_assetNumber));
    const response = await getAssetDetailsByAssetNumber(accessToken, _assetNumber, selectedSite?.organizationId);
    if (response && response?.statusCode === "200") {
      setLoading(false);
      setAssetDetails(response?.data);
      fetchAssetsConfigLookUpData(response?.data);
    }
    else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (assetNumber && selectedSite)
      getAssetDetails(assetNumber);
  }, [assetNumber, selectedSite])

  const handleGetBackToChildAsset = (_assetNumber) => {
    getAssetDetails(_assetNumber)
    setParentSummary(false)
  }

  return (
    <Box className='assetSummaryMainBox'>
      {loading &&
        <div className="loading">
          Loading&#8230;</div>
      }
      <Box className='summaryHeader' sx={{ padding: window.innerWidth > appConstants.MOBILE_RESOLUTION ? '10px 24px' : '10px' }}>
        <Stack direction={'row'} alignItems={'center'} gap={window.innerWidth > appConstants.MOBILE_RESOLUTION ? '10px' : '0px'} marginLeft={'-10px'}>
          <IconButton onClick={() => parentSummary ? handleGetBackToChildAsset(assetNumFromRoute) : window.history.back()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography width={'100%'} variant={window.innerWidth > appConstants.MOBILE_RESOLUTION ? 'h5' : 'h6'} flexGrow={1}>
            {assetDetails.assetNumber || assetNumber}
          </Typography>
        </Stack>
        <ButtonComponent fullwidth={window.innerWidth < appConstants.MOBILE_RESOLUTION ? true : false} label={'Work Order History'}
          buttoncallBack={() => navigate('/assets/workorderhistory', { state: { asset: assetDetails?.assetNumber || assetNumber } })}>
        </ButtonComponent>
      </Box>
      <Box className='assetSummaryDesktop' sx={{ height: window.innerHeight - 124, padding: window.innerWidth > appConstants.MOBILE_RESOLUTION ? '10px 24px' : '10px' }}>
        <Grid container spacing={{ xs: 2, md: 5 }} sx={{ 'MuiGrid-root': { padding: '20px' } }}>
          <Grid item xs={12}>
            <Box flex={1} padding={'10px 0px'} borderBottom={'1px solid #9A9898'}>
              <Typography variant='boldBody' color={'#006CEC'}>Assets</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Site</Typography>
            <Typography variant='boldBody'>{assetDetails !== null ? selectedSite?.organizationCode + ' - ' + selectedSite?.organizationName : ""}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Asset</Typography>
            <Typography variant='boldBody'>{assetDetails?.assetNumber ? assetDetails?.assetNumber : '-'}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Description</Typography>
            <Typography variant='boldBody'>{assetDetails?.description ? assetDetails?.description : '-'}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Type</Typography>
            <Typography variant='boldBody'>{assetDetails?.type ? assetDetails?.type : '-'}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Category</Typography>
            <Typography variant='boldBody'>{assetDetails?.category ? assetDetails?.category : '-'}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Maintanable</Typography>
            <Typography variant='boldBody'>{assetDetails !== null ? assetDetails?.maintainable ? 'Yes' : 'No' : "-"}</Typography>
          </Grid>
          <Grid item xs={6} sm={3} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Parent Asset</Typography>
            {
              assetDetails?.parentInstanceNumber ?
                <Box sx={{ cursor: 'pointer', zIndex: 1000, position: 'relative' }} onClick={() => { getAssetDetails(assetDetails?.parentInstanceNumber); setParentSummary(true); }}>
                  <Link><Typography variant='boldBody'  >{assetDetails?.parentInstanceNumber}</Typography></Link>
                </Box> :
                <Typography variant='boldBody'  >{'-'}</Typography>
            }
          </Grid>
          <Grid item xs={6} sm={3} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
            <Typography >Manufacturer</Typography>
            <Typography variant='boldBody'>{assetDetails?.manufacturer ? assetDetails?.manufacturer : '-'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 5 }}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={{ xs: 2, md: 5 }}>
              <Grid item xs={12} mt={{ xs: 2, md: 5 }} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
                <Box flex={1} padding={'10px 0px'} borderBottom={'1px solid #9A9898'}>
                  <Typography variant='boldBody' color={'#006CEC'}>Tracking</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }} >
                <Typography >Area</Typography>
                <Typography variant='boldBody'>{assetDetails?.area ? assetDetails?.area : '-'}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
                <Typography >Asset Tag</Typography>
                <Typography variant='boldBody'>-</Typography>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
                <Typography >Department</Typography>
                <Typography variant='boldBody'>{assetDetails?.owningDepartment ? assetDetails?.owningDepartment : '-'}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
                <Typography >Location</Typography>
                <Typography variant='boldBody'>{assetDetails?.locatorName ? assetDetails?.locatorName : '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} mt={{ xs: 2, md: 5 }}>
            <Grid container spacing={{ xs: 2, md: 5 }}>
              <Grid item xs={12} sx={{ '&.MuiGrid-item': { paddingTop: window.innerWidth < 600 ? '0px !important' : '20px !important' } }}>
                <Box flex={1} padding={'10px 0px'} borderBottom={'1px solid #9A9898'}>
                  <Typography variant='boldBody' color={'#006CEC'}>Status</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ '&.MuiGrid-item': { paddingTop: window.innerWidth < 600 ? '0x' : '20px' } }}>
                <Typography >Status</Typography>
                <Typography variant='boldBody'>{(!assetDetails?.workingStatus || assetDetails?.workingStatus == " " || assetDetails?.workingStatus == "") ? '-' : assetDetails?.workingStatus}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} className="text-align" sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }} >
                <Typography >Install Date</Typography>
                <Typography variant='boldBody'>{assetDetails?.installDate ? formatDate(assetDetails?.installDate) : '-'}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ '&.MuiGrid-item': { paddingTop: '20px' } }}>
                <Typography >Modified Date</Typography>
                <Typography variant='boldBody'>{assetDetails?.modifiedDate ? formatDate(assetDetails?.modifiedDate) : '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container spacing={{ xs: 2, md: 2 }}>
          <Grid item xs={12} mt={'10px'}>
            <Box flex={1} className='configurationsHeader' mb={'-15px'}>
              <Typography variant='boldBody'>Configuration - {assetDetails?.assetNumber} {assetDetails?.description && '(' + assetDetails?.description + ')'}</Typography>
              <IconButton disabled={attributes?.length > 0 ? (attributes[0]?.configuration1?.length || attributes[0]?.configuration2?.length || attributes[0]?.configuration3?.length || attributes[0]?.configuration4?.length === 1) ? false : true : true}>
                <EditIcon sx={{ color: attributes?.length > 0 ? attributes[0]?.configuration1?.length || attributes[0]?.configuration2?.length || attributes[0]?.configuration3?.length || attributes[0]?.configuration4?.length === 1 ? "red !important" : "grey !important" : "grey !important" }}
                  onClick={() => { if (attributes?.length > 0 ? (attributes[0]?.configuration1?.length || attributes[0]?.configuration2?.length || attributes[0]?.configuration3?.length || attributes[0]?.configuration4?.length > 0) : null) { setconfigEditable(!configEditable); } }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} >
            <SelectComponent
              name='config1'
              values={config1}
              selectedvalue={formik.values.config1}
              disabled={!configEditable || (attributes[0]?.configuration1?.length === 0)}
              width={"100%"}
              parentselectcallback={(e) => {
                setSelectedConfigParams({ ...selectedConfigParams, ['config1']: e });
                formik.setFieldValue("config1", e, false);
              }}
            ></SelectComponent>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectComponent
              values={config2}
              name='config2'
              selectedvalue={formik.values?.config2}
              disabled={!configEditable || (attributes[0]?.configuration2?.length === 0)}
              width={"100%"}
              parentselectcallback={(e) => {
                setSelectedConfigParams({ ...selectedConfigParams, ['config2']: e });
                formik.setFieldValue("config2", e, false);
              }}
            ></SelectComponent>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectComponent
              values={config3}
              name='config3'
              selectedvalue={formik.values?.config3}
              disabled={!configEditable || (attributes[0]?.configuration3?.length === 0)}
              width={"100%"}
              parentselectcallback={(e) => {
                setSelectedConfigParams({ ...selectedConfigParams, ['config3']: e });
                formik.setFieldValue("config3", e, false);
              }}
            ></SelectComponent>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectComponent
              values={config4}
              name='config4'
              selectedvalue={formik.values?.config4}
              disabled={!configEditable || (attributes[0]?.configuration4?.length === 0)}
              width={"100%"}
              parentselectcallback={(e) => {
                formik.setFieldValue("config4", e, false);
                setSelectedConfigParams({ ...selectedConfigParams, ['config4']: e })
              }}
            ></SelectComponent>
          </Grid>
          <Grid item xs={12} sm={12} display={'flex'} gap={2} justifyContent={'flex-end'} alignContent={'end'}>
            <ButtonComponent icon={false} label={'Cancel'} color="#F30C0C" disabled={!configEditable} buttoncallBack={() => {
              setconfigEditable(!configEditable)
            }}></ButtonComponent>
            <ButtonComponent icon={false} label={'OK'} color="#2F80ED" disabled={!configEditable} buttoncallBack={() => { updateAssetConfigData() }}></ButtonComponent>
          </Grid>
        </Grid> */}
      </Box>
      <ToastMessage toast={toast} />
    </Box >
  );
}
