import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { addNewRole, getAllRolePermissions, updateRolePermission } from "../../services/AdminSecurityServices";
import { UserContext } from "../../store/UserContext";
import ToastMessage from "../shared-components/ToastMessage";
import { ButtonComponent, AxSecurityRolesTableComponent } from "../shared-components/common-field-components";
import "./admin.css";
import { useState } from "react";
import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const RolesAdmin = () => {
  const [rolesData, setRolesData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [showNewRole, setShowNewRole] = React.useState(false);
  const [sendData, setSendData] = React.useState(false);
  const [flagToGetUpdatedData, setFlagToGetUpdatedData] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = React.useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();

  const [toast, setToast] = React.useState({});

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);

  const fetchData = () => {
    setLoading(true);
    getAllRolePermissions(0, 10000).then(response => {
      if (response?.statusCode === "200") {
        setLoading(true);
        setRolesData(response?.data.content);
        setTotalElements(response.data.totalElements);
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }

  React.useEffect(() => { setLoading(true); fetchData() }, [refresh]);

  const saveRole = (_dataToSave, _action) => {
    setSendData(false);
    setLoading(true);
    if (_action === 'Create') {
      addNewRole(accessToken, _dataToSave)
        .then(response => {
          if (response.statusCode == 200) {
            const toastProps = { open: true, type: "success", message: "Role created succesfully", onClose: () => setToast({}) };
            setToast(toastProps);
            setShowNewRole(false);
            fetchData();
            setLoading(false);
          }
          else {
            const toastProps = { open: true, type: "error", message: response.message, onClose: () => setToast({}) };
            setToast(toastProps);
            setLoading(false);
          }
        })
        .catch(err => console.log(err));
    }
  }

  return (
    <Box className='admin-security-roles-main'>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      <ToastMessage toast={toast} />

      <Grid className='admin-security-content-page-header'>
        <Typography variant={'h5'} className='admin-content-header-tittle-text'>{t('ROLES_LIST')}</Typography>
        <Box >
          {showNewRole ? (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' },  }}>
              {window.innerWidth > 900 ? (
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, gap: 2 }}>
                  <ButtonComponent
                    label={t("CANCEL")}
                    color={"red"}
                    disabled={loading}
                    className='save-user-button'
                    buttoncallBack={() => setShowNewRole(false)}
                  ></ButtonComponent>
                  <ButtonComponent
                    label={t("SAVE")}
                    color={'#2BAA4F'}
                    disabled={loading}
                    className='save-user-button'
                    buttoncallBack={() => setSendData(true)}
                  >
                  </ButtonComponent>
                </Box>
              ) : (
                <>
                  <IconButton disabled={loading} onClick={() => setShowNewRole(false)} >
                    <Close />
                  </IconButton>

                  <IconButton disabled={loading} onClick={() => setSendData(true)}>
                    <SaveIcon />
                  </IconButton>

                </>
              )}
            </Box>
          ) :
            <Box className="add-button-pc">
              <ButtonComponent
                label={t("ADD_ROLE")}
                icon
                disabled={loading}
                className='add-roles-button'
                buttoncallBack={(e) => setShowNewRole(!showNewRole)}
              >
              </ButtonComponent>
              {change ?
                <Box className="save-button">
                  <ButtonComponent
                    label={t("SAVE")}
                    color={'#2BAA4F'}
                    disabled={loading}
                    buttoncallBack={() => {
                      setFlagToGetUpdatedData(true);
                    }}
                  >
                  </ButtonComponent>
                </Box> : null}
            </Box>
          }
          <Box className="add-icon-mobile add-button-mobile">

            {showNewRole ? (
              <Box>

              </Box>
            ) :
              <Box>
                <IconButton disabled={loading} onClick={(e) => setShowNewRole(!showNewRole)}>
                  <AddIcon />
                </IconButton>
                {change ?
                  <Box className="save-button">
                    <ButtonComponent
                      label={t("SAVE")}
                      color={'#2BAA4F'}
                      disabled={loading}
                      buttoncallBack={() => {
                        setFlagToGetUpdatedData(true);
                      }}
                    >
                    </ButtonComponent>
                  </Box> : null}
              </Box>
            }
          </Box>

        </Box>

      </Grid>
      <Box className='admin-security-tables'>
        <AxSecurityRolesTableComponent
          rolesData={rolesData}
          change={change}
          loading={loading}
          sendData={sendData}
          setSendData={setSendData}
          showNewRole={showNewRole}
          saveData={(_dataToSave) => { saveRole(_dataToSave, 'Create') }}
          setShowSaveButtonForEdit={setChange}
          sendEditedData={flagToGetUpdatedData}
          setSendEditedData={setFlagToGetUpdatedData}
          saveEditedData={(_data) => {
            updateRolePermission(accessToken, _data).then(res => {
              setLoading(true);
              if (res.statusCode == 200) {
                const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                fetchData();
                setLoading(false);
              }
              else {
                const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                setToast(toastProps);
                setLoading(false);
              }
            })
            setFlagToGetUpdatedData(false);
          }
          }
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalElements={totalElements}
          refresh={refresh}
          setRefresh={setRefresh}
        ></AxSecurityRolesTableComponent>

      </Box>
    </Box>
  );
};

export default RolesAdmin;
