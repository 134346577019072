import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { t } from "i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minWidth: '70px',
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function AxFailureAnaliysisTableComponent(props) {
    const { failureAnalysis, setFailureAnalysis } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const columns = [
        { id: 1, headerName: "Asset Number" },
        { id: 2, headerName: "Failure Count" },
        { id: 3, headerName: "MTBF (Days)" },
        { id: 4, headerName: "Frequency" },
        { id: 5, headerName: "MTTR (Hours)" },
        { id: 6, headerName: "Current Meter Reading" },
    ];



    return (
        <TableContainer className='table-container-wo-main' >
            <Table sx={{
                minWidth: 700,
                '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                    color: "black",
                    fontWeight: 600
                },
                "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                    color: "black",

                },
                "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                    color: "black"
                },
                "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                    color: "black", opacity: 100
                },
                "& .css-1enotpu-MuiTableRow-root:nth-of-type(odd)": {
                    backgroundColor: '#ffffff',
                }
            }} stickyHeader size="small" aria-label="sticky table">
                <TableHead>
                    <TableRow style={{ height: 10 }}>
                        {columns?.map((column, index) => {
                            return <StyledTableCell key={index}>{t(column.headerName)}</StyledTableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {failureAnalysis?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell>{row?.assetNumber}</StyledTableCell>
                            <StyledTableCell>{row?.failureCount}</StyledTableCell>
                            <StyledTableCell>{row?.mtbf}</StyledTableCell>
                            <StyledTableCell>{row?.frequency}</StyledTableCell>
                            <StyledTableCell>{row?.mttf}</StyledTableCell>
                            <StyledTableCell>{row?.meterReading}</StyledTableCell>
                        </StyledTableRow>
                    ))
                    }

                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={failureAnalysis?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
}
