export const TRANSLATIONS_ES = {
  welcome: "Welcome to the tutorial",
  signin: "iniciar sesion",
  password: "Contraseña",
  menudashboard: "Tablero",
  menumaintenance: "Mantenimiento",
  menuassets: "Activos",
  menusites: "Sitios",
  menuadmin: "Administración",
  menuprofile: "Perfil",
  menusettings: "Ajustes",
  menulogout: "Cerrar sesión",
  profilelanguage: "Idioma",
  profilesave: "Guardar",
  processedFrom: "Procesado desde",
  processedTo: "Procesado a",
  empfirstname: "Nombre",
  emplastname: "Apellido",
  empemail: "Correo electrónico",
  opensettings: "Abrir Configuración",
  NOTIFICATIONS: 'Notificaciones',
  MENU_WORK_ORDERS: 'Órdenes de trabajo',
  MENU_PLANNED_ASSET_INVENTORY: "Inventario de activos planificados",
  MENU_EXECUTE_INVENTORIES: "Ejecutar Inventarios",
  MENU_REPORTS: 'Informes',
  MENU_BATCH_PROCESS: 'Proceso por lotes',
  MY_ACCOUNT: 'Mi cuenta',

  INPUTLABEL: "Etiqueta de campo",

  WO_CREATE_WORKORDER: 'Crear orden de trabajo',
  WO_COMPLETE_WORKORDER: 'Orden de trabajo completa',
  WO_RELEASE_DATE: 'Fecha de lanzamiento',
  WO_CREATE_DATE: 'Fecha de Creación',
  WO_ACTUAL_START: 'Comienzo real',
  WO_ACTUAL_FINISH: 'Finalización real',
  WO_ADD_TASK: 'Agregar Falla',
  WO_OPERATIONS_LABEL: 'Operaciones',
  WO_OPERATIONS_TASK: 'Tarea',
  WO_OPERATIONS_DESCRIPTION: 'Descripción',
  WO_OPERATION_DEPARTMENT: 'Departamento',
  WO_OPERATIONS_RESOURCE: 'Recurso',
  WO_OPERATIONS_ACTUAL_FINISH: 'Finalización real',
  WO_OPERATIONS_CONDITION_VALUE: 'Valor de condición',
  WO_OPERATIONS_LAST_VALUE: 'Último valor',
  WO_OPERATIONS_CONDITION_NOTE: 'Nota Condicional',
  WO_OPERATIONS_COMPLETE_TASK: 'Completar tarea',

  WO_LABOR_LABEL: 'Mano de obra',
  WO_LABOR_ADD_LABEL: 'Añadir mano de obra',
  WO_FAILURE_EDIT_LABEL: 'Error de edición',
  WO_LABOR_TASK: 'Tarea',
  WO_LABOR_RESOURCE: 'Recurso',
  WO_LABOR_EMPLOYEE: 'Empleado',
  WO_LABOR_START: 'Comenzar',
  WO_LABOR_TRANSACTION_DATE: 'Fecha de Transacción',
  WO_LABOR_FINISH: 'Finalizar',
  WO_LABOR_DURATION: 'Duración (Horas)',
  WO_LABOR_STATUS: 'Estado',

  WO_DOCUMENTS_LABEL: 'Documentos',
  WO_DOCUMENTS_TYPE: 'Tipo',
  WO_DOCUMENTS_ATTACHMENT_FILE_NAME: 'Nombre del archivo adjunto',
  WO_DOCUMENTS_DESCRIPTION: 'Descripción',
  WO_DOCUMENTS_UPDATED_BY: 'Actualizado por',
  WO_DOCUMENTS_UPDATED_DATE: 'Fecha de actualización',
  WO_DOCUMENTS_DOWNLOAD: 'Descargar',

  WO_METERS_LABEL: 'Medidores',
  WO_METERS_REQUIREMENT: 'Requerimiento',
  WO_METERS_MEASUREMENT: 'Metro',
  WO_METERS_NEW_VALUE: 'Nuevo valor',
  WO_METERS_LAST_READING: 'Última lectura',
  WO_METERS_LATEST_VALUE: 'Último valor',
  WO_METERS_READING_DATE: 'Fecha de lectura',
  WO_METERS_VALUE_CHANGE: 'Cambio de valor',
  WO_METERS_NEW_READING: 'Nueva lectura',
  WO_METERS_INSPECTOR: 'Inspector',
  WO_METERS_RECORDED: 'Grabada',
  WO_METERS_VALUE: 'Valor',
  WO_METERS_UOM: 'UoM',



  WO_FAILURE_LABEL: 'Falla',
  WO_FAILURE_CAUSE: 'Causa',
  WO_FAILURE_RESOLUTION: 'Acción',
  WO_FAILURE_FAILURE_DATE: 'Fecha de falla',
  WO_FAILURE_COMMENTS: 'Comentarios',
  REALSE: "Liberar",
  WO_FILTERS_INPUTLABEL_WORKORDERS: "Orden de trabajo",
  WO_FILTERS_INPUTLABEL_ASSET: "Activo",
  WO_FILTERS_INPUTLABEL_ASSET_DESCRIPTION: "Descripción de activos",
  WO_FILTERS_INPUTLABEL_RIG_YARD: "Rig/Patio",
  WO_FILTERS_INPUTLABEL_STATUS: "Estado",
  WO_SUMMARY_INPUTLABEL_PARENT: "Asignado a",
  WO_SUMMARY_INPUTLABEL_ASSET_SERIAL_NUMBER: "Número de serie del activo",
  WO_SUMMARY_DATEPICKER_CREATE_DATE: "Fecha de Creación",
  WO_SUMMARY_DATEPICKER_ACTUAL_START: "Comienzo real",
  WO_SUMMARY_DATEPICKER_ACTUAL_FINISH: "Finalización real",
  WO_CREATE_WO_DROPDOWN_CATEGORY: "Categoría",
  WO_CREATE_WO_DATEPICKER_SCHEDULED_START: "Inicio programado",
  WO_CREATE_WO_DATEPICKER_SCHEDULED_FINISH: "Finalización programada",
  WO_CREATE_WO_DROPDOWN_SOURCE_ASSET_RIG: "Fuente de activo o Rig",
  WO_CREATE_WO_DROPDOWN_PRIORITY: "Prioridad",
  WO_CREATE_WO_INPUTLABEL_ASSET_GROUP: "Grupo de activos",
  WO_CREATE_WO_INPUTLABEL_EMPLOYEE_INITIALS: "Iniciales del empleado",
  WO_CREATE_WO_INPUTLABEL_MOVED_TO_RIG: "Movio a Rig",
  WO_CREATE_WO_DATEPICKER_DATE_OF_REMOVAL: "Fecha de eliminación",
  WO_CREATE_WO_INPUTLABEL_BRIEF_DESCRIPTION: "Breve descripción",
  WO_CREATE_WO_INPUTLABEL_ADDITIONAL_DESCRIPTION: "Descripción adicional",
  WO_GRID_WO_INPUTLABEL_WORKORDER: "Orden de trabajo",
  WO_GRID_WO_INPUTLABEL_ASSET: "Activo",
  WO_GRID_WO_INPUTLABEL_RIG: "Plataforma",
  WO_GRID_WO_SELECT_STATUS: "Estado",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG1: "Configuración 1",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG2: "Configuración 2",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG3: "Configuración 3",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG4: "Configuración 4",

  WO_RIG_NUMBER: 'Número del Rig',
  WO_PLAN_CREATION_STATUS: 'Estado de creación del plan',
  WO_PLAN: 'Plan',
  WO_CREATE_START: 'Crear Inicio',
  WO_CREATE_END: 'Crear fin',
  WO_ERROR: 'Error',
  Released: "Disponible",
  Complete: "Completada",
  OnHold: "En espera",

  MAINTENANCE_SUMMARY: "Resumen de mantenimiento",
  CAT_III_MAINTENANCE_SUMMARY: "Resumen de mantenimiento CAT III",
  INSPECTION_REPORT: "Reporte de inspección",
  THIRD_PARTY_SERVICE_TICKETS: "Tiquete de servicio de tercera compañía",
  PARTS_LIST: "Lista de partes",
  PHOTOS: "Fotos",
  CAT_III_CORRECTIVE_ACTION: "Acción correctiva CAT III",
  MATERIAL_CERT: "Certificación de material",
  ENGINEERING_WORK_PROCEDURE: "Procedimiento de trabajo de ingeniería",
  ORG: 'Org',


  Assets_leftbar_filter_inputlabel_manufacturer: "Número de serie del fabricante",
  Assets_leftbar_filter_inputlabel_keyword: "palabra clave",
  Assets_leftbar_filter_inputlabel_site: "sitio ",
  Assets_leftbar_filter_inputlabel_parent: "Asignado a",
  ADMIN_DEVICES_FILTER_INPUTLABEL_DEVICES: "Dispositivo",
  ADMIN_DEVICES_FILTER_DROPDOWN_DEVICES: "función del dispositivo",
  ADMIN_DEVICES_FILTER_DROPDOWN_TYPE: "tipo",

  DATEPICKER: "Selector de fechas",
  MTF_MATERIAL_TRANSFER_FORM: 'Formulario de transferencia de material',
  MTF_CREATE_MATERIAL_TRANSFER_FORM: 'Crear formulario de transferencia de material',
  MTF_FILTERS: "filtros",
  MTF_SEARCH: 'Buscar',
  MTF_EDIT: "Editar",
  MTF_SAVE: "Sauver",
  MTF_TRANSFER_DETAILS: 'Detalles de la transferencia',
  MTF_ASSET_INFORMATION: 'Información de activos',
  MTF_SHIPPING_INFORMATION: 'Información de envío',
  MTF_FORM: "Formato",
  MTF_REVISION: 'Revisión',
  MTF_EFFECTIVE_DATE: "Fecha efectiva",
  MTF_NEXT: 'Siguiente',
  MTF_BACK: 'Atrás',
  MTF_SUBMIT: 'Enviar',
  MTF_SHIPPING_COMPANY_NAME: 'Nombre de la empresa de envío',
  MTF_TRUCK_NUMBER: 'Numero de camion',
  MTF_PRINT_DRIVER_NAME: 'Nombre del conductor',
  MTF_PICK_UP_DATE_TIME: 'Fecha y hora de recogida',
  MTF_DELIVER_DATE_TIME: 'Fecha y hora de entrega',
  MTF_ITEM: "Artículo",
  MTF_QUANTITY: 'Cantidad',
  MTF_ID: 'Identificación',
  MTF_ASSET: 'Activo',
  MTF_SERIAL_NUMBER: 'Número de serie',
  MTF_DESCRIPTION: 'Descripción',
  MTF_MANUFACTURER: 'Fabricante',
  MTF_MODEL: 'Modelo',
  MTF_LENGTH_WIDTH_HEIGHT: 'Largo ancho alto',
  MTF_WEIGHT: 'Peso',
  MTF_LOAD_ASSIST_REQUIRED: '¿Se requiere asistencia de carga?',

  Mtf_leftbar_filter_inputlabel_FormID: "ID de formulario",
  Mtf_leftbar_filter_inputlabel_SerialNo: "Número de serie",
  Mtf_leftbar_filter_inputlabel_Asset: "Activo",
  Mtf_DATEPICKER_filter_inputlabel_From: "Desde la fecha",
  Mtf_DATEPICKER_filter_inputlabel_To: "Hasta la fecha",

  ADMIN_LOGS_EMPLOYEEACCESSLOGS_INPUTLABEL_KEYWORD: "Palabra clave",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_INPUTLABEL_NAME: "Nombre",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_INPUTLABEL_QUERYPARAM: "Parámetro de consulta",
  Mtf_Shipping_inputlabel_TransferIssuerName: "Nombre del emisor de la transferencia",
  Mtf_Shipping_inputlabel_AuthorizerName: "Nombre del autorizador",
  Mtf_Shipping_inputlabel_FromRigNumber: "Del número de plataforma o nombre de la ubicación",
  Mtf_Shipping_inputlabel_ToRigNumber: "Al número de plataforma o al nombre de la ubicación",
  MTF_RIG_PLACEHOLDER: "Incluir la ciudad y el estado más cercanos",
  Mtf_Shipping_inputlabel_DestinationPOC: "Nombre del contacto",
  Mtf_Shipping_inputlabel_POCPhoneNumber: "Número de telefono del contacto",
  Mtf_Shipping_inputlabel_ReasonForTransfer: "Razón para transferir",
  Mtf_Shipping_DATEPICKER_IssueDate: "Fecha de expedición",
  Mtf_Shipping_DATEPICKER_AuthorizationDate: "Fecha de autorización",
  Mtf_Shipping_DropDown_TransferesFrom: "Transferido desde",
  Mtf_Shipping_DropDown_TransferesTo: "Transferido a",
  Mtf_Shipping_DropDown_ReceiverName: "Nombre del destinatario",
  Mtf_Shipping_DropDown_ReceivedDate: "Fecha de recepción",
  Mtf_Shipping_inputlabel_POCEmailID: "Correo electrónico",

  PAI_ASSETS_DATEPICKER_INVENTORY_FROM: "Formulario de inventario",
  PAI_ASSETS_DATEPICKER_INVENTORY_TO: "Inventario hasta",
  PAI_ASSETS_PROCESS_STATUS: "Estado del proceso",
  PAI_ASSETS_INVENTORY_STATUS: "Estado del inventario",

  ADMIN_LOGS_EMPLOYEEACCESSLOGS_DATEPICKER_FROM: "De, Desde",
  ADMIN_LOGS_EMPLOYEEACCESSLOGS_DATEPICKER_TO: "A",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_STATUS: "Estado",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_TYPE: "Tipo",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_ADAPTER: "Adaptador",

  NEW_ASSET_TO_INVENTORY: "Nuevo activo al inventario",
  ASSET_NUMBER: "Número de activo",
  DATE_OF_LAST_ACTIVITY: "Fecha de la última actividad",
  DATE_INSERTED: "Fecha de inserción",
  WORKING_STATUS: "Situación laboral",

  DEPARTMENT: "Departamento",


  MTF_TRANSFER_INPUTLABEL_ISSUEREMAILID: "ID de correo electrónico del emisor",
  MTF_TRANSFER_INPUTLABEL_AUTHORIZEREMAILID: "ID de correo electrónico del autorizador",
  MTF_TRANSFER_INPUTLABEL_RECIEEVREMAILID: "ID de correo electrónico del destinatario",


  REPORTS_EMPLOYEE: "Nombre de empleado",
  REPORTS_FROMDATE: "Partir de la fecha",
  REPORTS_TODATE: "Hasta la fecha",
  REPORTS_WORKING_STATUS: "Estado de trabajo",

  LABOR_HOURS: "Horas laborales",
  INVENTRORY_REPORTS: "Informes de inventario",
  CONDITION_BASED_HISTORY: "Historial basado en condiciones",
  UPDATED_FROM: "Actualizado desde",
  UPDATED_TO: "Actualizado a",
  JOB_PLAN: "plan de trabajo",
  WORKORDER: 'Orden de trabajo',
  SITES: "Sitios",
  SECURITY: "Seguridad",
  DEVICES: "Dispositivo",
  LOGS: "Registros",
  OPERATING_UNIT: "Unidad Operativa",
  DESCRIPTION: "Descripción",
  LOCATION: "Ubicación",
  IS_OPERATING_UNIT: "Es unidad operativa",
  IS_INVENTORY_ORG: "Es un inventario de organización",
  ACTIVE: "Activo",
  USERS_LIST: "Lista de usuarios",
  USERS: 'Usuarios',
  USER_ID: "ID de usuario",
  VISIAL_DICTIONARY: "diccionario visual",
  USER_NAME: "Nombre de usuario",
  PHONE_NUMBER: "Número de teléfono",
  PERMISSION_LISTS: 'Listas de permisos',
  ADD_PERMISSION_LISTS: 'Agregar listas de permisos',
  CURRENT_SITE: "Sitio actual",
  ADD_USER: "Agregar usuario",
  ROLES: "roles",
  SITE: "Sitio",
  ACTIONS: "Acciones",
  SITES_LIST: "Lista de sitios",
  LOCATION_LIST: "Lista de ubicaciones",
  ROLES_LIST: "Lista de funciones",
  ROLE_ID: "Identificación del rol",
  ROLE_DESCRIPTION: "Descripción del rol",
  ADD_ROLE: "Agregar rol",
  PERMISSION_LIST: "Lista de permisos",
  PERMISSION_DESCRIPTION: "Descripción del permiso",
  MENU_NAME: "Nombre del menú",
  MENU_DESCRIPTION: "Descripción del menú",
  ACCESS_TYPE: "Tipo de acceso",
  PERMISSION_LIST_ID: "ID de lista de permisos",
  DEVICE: "Dispositivo",
  DEVICE_ROLE: "Rol del dispositivo",
  ADD_DEVICE: 'Añadir dispositivo',
  RESET_DATE: 'Restablecer fecha',
  RECOLONIZE: 'recolonizar',
  DECOLONIZE: 'Descolonizar',
  SOFT_RECOLONIZE: 'Recolonización suave',
  RESET_DATA: "Reiniciar datos",
  LAST_CONNECTED: "Última conexión",
  PENDING_DATA: "Datos pendientes",
  PENDING_APPLICATIONS: "Solicitudes pendientes",
  COLONIZATION: "Colonización",
  SAVE: "Guardar",
  BROADCAST_MESSAGES: "Mensajes de difusión",
  ADD_BROADCAST_MESSAGE: "Agregar mensaje de difusión",
  ADD_SCHEDULE_BATCH_PROCESS: "Agregar proceso por lotes programado",
  MESSAGE: "Mensaje",
  START_DATE: "Fecha de inicio",
  EXPIRATIONS_DATE: "Fecha de vencimiento",
  NOTIFICATION_TEMPLATE: "Plantilla de notificación",
  TEMPLATE: "Plantilla",
  ACCEPT: "Aceptar",
  SERIAL_NUM: "Número de serie",
  SCAN_RFID_TAG: 'Escanear una etiqueta RFID',
  AWAITING_SCAN: 'En espera de análisis',
  SENDER: "Remitente",
  EMAIL_ADDRESS: "Dirección de correo electrónico",
  ADD_TEMPLATE: "Agregar plantilla",
  MANUFACTURER_SN: "Número de serie del fabricante",
  PRIORITY: "Prioridad",
  INSTRUCTIONAL_TEXT: "Texto instructivo",
  SUBJECT: "Asunto",
  OPERATIONS: "Operaciones",
  LABOR: "Mano de obra",
  DOCUMENTS: "Documentos",
  METERS: "Medidores",
  FAILURE_: "Falla",
  WORK_ORDER_ACCESS_LOGS: "Accerder a los registros de órdenes de trabajo",
  WORK_ORDER: "Orden de trabajo",
  TYPE: "Tipo",
  OLD_VALUE: "Valor antiguo",
  PROCESS_STATUS: "Estado del proceso",
  PROCESSED: "Procesada",
  CREATED: "Creado",
  MESSAGE_TEXT: "Mensaje de texto",
  TASK: "Tarea",
  RESOURCE: "Recurso",
  ACTUAL_FINISH: "Finalización real",
  CONDITION_VALUE: "Valor de condición",
  LAST_VALUE: "Último valor",
  CONDITION_NOTE: "Nota Condicional",
  EMPLOYEE: "Empleado",
  START: "Comenzar",
  FINISH: "Finalizar",
  DURATION: "Duración",
  ACTUAL_START: "Comienzo real",
  ASSET_SERIAL_NUMBER: "Número de serie del activo",
  CREATE_DATE: "Fecha de Creación",
  ATTACHMENT_FILE_NAME: "Nombre del archivo adjunto",
  UPDATED_BY: "Actualizado por",
  UPDATED_DATE: "Fecha de actualización",
  REQUIREMENT: "Requerimiento",
  MEASUREMENT: "Medición",
  NEW_VALUE: "Nuevo valor",
  UNIT_OF_MEASURE: "Unidad de medida",
  INPUT_RANGE: "Rango de entrada",
  UPDATE: "Actualizar",
  LAST_READING: "Última lectura",
  NEW_READING: "Nueva lectura",
  FAILURE: "Falla",
  CAUSE: "Causa",
  RESOLUTION: "Acción",
  FAILURE_DATE: "Fecha de falla",
  COMMENTS: "Comentarios",
  EMPLOYEE_ACCESS_LOGS: "Registros de acceso de empleados",
  EXPORT: "Exportar",
  SCAN_INVENTORY: "Escanear inventario",
  NOTES: "Notas",
  EMPLOYEE_DESCRIPTION: "Descripción del empleado",
  LOGIN: "Iniciar sesión, Conectarse",
  LOG_DATA: "Datos de registro",
  APPLICATION_MESSAGE_LOGS: "Registros de mensajes de la aplicación",
  BATCH_PROCESS: "Proceso por lotes",
  PROCESS_SCHEDULER: "Programador de procesos",
  PROCESS_MONITOR: "Monitoreo de procesos",
  SCHEDULE_BATCH_PROCESS: "Programar proceso por lotes",
  WORKING_RIGS: "Rigs trabajando",
  GENERATE_INVENTORIES: "Generar Inventarios",
  THIRD_PARTY_LOCATORS: "Localizadores de terceros",
  ADD_PHYSICAL_LOCATION: 'Agregar ubicación física',
  ADD_THIRD_PARTY_PHYSICAL_LOCATION: 'Agregar ubicación física de terceros',
  JOBNAME: "Nombre del trabajo",
  CURRENT_STATUS: "Estado actual",
  FINISHED_TIME: "Tiempo finalizado",
  STATUS: "Estado",
  ENABLED: "Activado",
  NAME: "Nombre",
  ADAPTER: "Adaptador",
  ASSEMBLY: "Ensamblaje",
  QUERY_PARAMETER: "Parámetro de consulta",
  LAST_START: "Último inicio",
  LAST_FINISH: "último final",
  LAST_STATUS: "Último estado",
  ORDER: "Orden",
  RUN_NOW: "Ejecutar ahora",
  FIELD_NAME: "Nombre del campo",
  FIELD_TYPE: "Tipo de campo",
  VALUE: "Valor",
  FREQUENCY: "Frecuencia",
  LAST_RUN_TIME: "Hora de la última ejecución",
  NEXT_RUN_TIME: "Tiempo de ejecución siguiente",
  ENABLE_DISABLE: "Permitir/Desactivar",
  RIG_NUMBER: "Número del Rig",
  PLAN_CREATION_STATUS: "Estado de creación del plan",
  PLAN: "Plan",
  CREATE_START: "Crear Inicio",
  CREATE_END: "Crear fin",
  ERROR: "Error",
  ASSET_INVENTORY_PLAN: "Plan de inventario de activos",
  CREATED_BY: "Creado por",
  TOTAL_ASSET_COUNT: "Recuento total de activos",
  INVENTORY_ORGS: "Organizaciones de inventario",
  ASSET_COUNT: "Recuento de activos",
  INVENTORY_ORG: "Organización de inventario",
  SUB_INVENTORIES: "Subinventarios",
  LOCATORS: "Localizadores",
  LOCATOR: "Localizador",
  NO_RECORDS_FOUND: "No se encontraron registros",
  ASSET: "Activo",
  HANDHELD_SCAN: "Escaneado manual",
  VERIFED_BY_SCAN: 'Verificado por escaneo',
  VERIFIED_LOCATION: "Ubicación verificada",
  INVENTORY_BY: "Inventario por",
  VERIFIED_DATE: "Fecha verificada",
  MODEL_NUMBER: "Número de modelo",
  MANUFACTURER: "Fabricante",
  MANUFACTURER_NAME: "Nombre del fabricante",
  MODAL: "modelo",
  SERIAL_NUMBER: 'Número de serie',
  FULL_NAME: 'Nombre completo',
  APPROVED_BY: "Aprobado por",
  APPROVED_DATE: "Fecha de aprobación",
  ADD_INVENTORIES: "Agregar inventarios",
  ADD_SUB_INVENTORIES: 'Añadir subinventarios',
  ADD_INVENTORY_ORGS: 'Agregar organizaciones de inventario',
  ASSIGNMENTS: "Asignación de tareas",
  ADD_ASSIGNMENTS: "Agregar asignaciones",
  PLAN_STATUS: "Estado del plan",
  ASSIGNED_TO: "Asignado a",
  PLAN_DESCRIPTION: "Descripción del plan",
  ARCHIVED_ASSIGNED_TO: "Archivado Asignado a",
  ARCHIVED_EMPLOYEE: "Empleado archivado",
  PHYSICAL_LOCATION: "Localizacion fisica",
  INVENTORY_OWNER: "Propietario del inventario",
  SUB_INVENTORY: "Subinventario",
  CONFIRM: 'confirmar',
  PLANNED_ASSET_INVENTORY: "Inventario de activos planificados",
  COMPLETE_DATE: "Fecha de finalización",
  STARTED: "Iniciado",
  RESPONSIBLE: "responsable",
  INVENTORY_FROM: "Inventario desde",
  INVENTORY_TO: "Inventario a",
  INVENTORY_STATUS: "Estado del inventario",
  EXPORT_TO_EXCEL: "Exportar a Excel",
  ASSET_INVENTORY_ITEMS: "Elementos del inventario de activos",
  ASSET_SUMMARY: "Resumen de activos",
  VERIFIED_BY_SCAN: "Verificado por escaneo",
  VERIFIED_MANUALLY: "Verified By Scan",
  NOT_FOUND: "No verficado",
  NOT_VERIFIED: "No verificado",
  ADDED_IN_FIELD: "Agregado en el campo",
  COMPLETION: "Finalización (finish a task) Completamiento (Operation after perforation)",
  BREAKDOWN_BY_LOCATOR: "Desglose por localizador",
  FILTERS: "filtros",
  KEYWORD: "Palabra clave",
  PARENT: "Asignado a",
  SEARCH: "Buscar",
  ASSETS: "Activos",
  HIERARCHY: "Jerarquía",
  SUMMARY: "Resumen",
  PARENT_ASSET: "Activo principal",
  TRACKING: "Seguimiento",
  AREA: "Área",
  SUBINVENTORY: "Subinventario",
  INCLUDE_IN_INVENTORY: "Incluir en el inventario",
  CONFIGURATION: "Configuración",
  RFID: "RFID",
  SERIAL: 'Serial',
  MODEL: "Modelo",
  MANUFACTURERS_SERIAL_NUMBER: "Número de serie del fabricante",
  CATEGORY: "Categoría",
  MAINTAINABLE: "Mantenible?",
  INSTALL_DATE: "Fecha de instalación",
  MODIFIED_DATE: "Fecha de modificación",
  ATTACHMENTS: "Adjuntos",
  DOWNLOAD: "Descargar",
  OK: "OK",
  CANCEL: "Cancelar",
  YES: "Sí",
  NO: "No",
  ADD_LOCATORS: 'Agregar localizadores',
  GENERATE_ITEMS: "Generar items",
  SET_IN_PROGRESS: "Configuración en curso",
  COMPLETE_PLAN: 'Plan completado',
  ARCHIVE: 'Archivo',
  JOB_NAME: 'Nombre del trabajo',
  BATCH_PROCESS_ID: 'Identificación del proceso por lotes',
  PROCESS_ID: 'Identificacion de proceso',
  ORG_ID: 'ID de la organización',
  START_TIME: "Hora de inicio",
  SCEDULE_BATCH_PROCESS: 'Programar proceso por lotes',
  EXPIRATION_DATE: 'Fecha de vencimiento',
  JOB_NAME_DESCRIPTION_START_TIME: "Nombre del trabajo - Descripción - Hora de Inicio",
  JOB_NAME_DESCRIPTION_CURRENT_STATUS: "Nombre del trabajo - Fecha de Inicio - Estado actual",
  REMOVE_PLAN: 'Eliminar plan',
  Pai_Table_Description: "Description",
  Pai_Table_Status: "Status",
  Pai_Table_Type: "Type",
  Pai_Table_Started: "Iniciado",
  Pai_Table_CreatedBy: "Created By",
  Pai_Table_Created: "Created",
  ADD_THIRD_PARTY_LOCATION: 'Agregar ubicación de terceros',
  PHYSICAL_LOCATION_LOCATOR: 'Localización física del localizador',
  RIG_NUMBER_PLAN_CREATION_STATUS_PLAN: 'Número del Rig - Creación de Plan - Estado del plan',
  CREATED_DATE: 'Fecha de creación',
  keyword: "palabra clave",
  createby: "Creado por",
  completedate: "Fecha completa",
  status: "Estado",
  type: "Tipo",
  ADDED_IN_FEILD: 'Agregado en campo',

  ACCOUNT_DETAILS: 'detalles de la cuenta',
  ROWS_PER_PAGE: 'Filas por página',
  DELETE: 'Eliminar',
  VIEW: "Vista",
  EDIT: 'Editar',
  CLASS: 'Clase',
  FROM: 'De, Desde',
  TO: 'A',
  ALL: 'Todas',
  ADD_SELECTED: 'Agregar seleccionado',
  CLEAR: 'Limpiar',
  ARCHIVE_ASSIGNED_TO: 'Archivo asignado a',
  ARCHIVE_EMPLOYEE: 'Empleado de archivo',
  RIG: 'Aparejar',
  PLANNED_ASSET_INVENTORY_NAME: 'Nombre del inventario de activos planificado',
  SHOW_ARCHIVED: 'Mostrar archivado',
  VERIFIED_FROM: 'Formulario verificado',
  VERIFIED_TO: 'Verificado para',
  MAINTENANCE_ACTIVITY: 'Actividad de mantenimiento'
};
