import React, { useState, useRef, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImageIcon from '@mui/icons-material/Image';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './index.css';
import { getAssetAttachments } from '../../services/AssetsServices';

const Upload = ({
  fileName,
  accept,
  onUpload,
  disabled,
  getAttachments,
  isNewRow,
  row,
  rows = [], 
  setRows,
  clickedWorkOrder,
}) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState(fileName || (file && file?.name));

  useEffect(() => {
    setName(fileName);
  }, [fileName]);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setName(uploadedFile?.name);
    onUpload(uploadedFile);
  };

  const handleAddClick = () => {
    fileInputRef.current.click();
  };

  const handleRowUpdate = () => {
    const newRows = [...rows];
    const rowIndex = newRows.findIndex((x) => x?.id === row?.id);

    if (rowIndex !== -1) {
      newRows[rowIndex].isNew = true;
      setRows([...newRows]);
    } else {
      console.error("Row not found");
    }
  };

  return (
    <>
      {!!name && !!file ? (
        <div>
          <Tooltip
            title={
              <div className="tooltip" style={{ background: 'white', color: 'black' }}>
                <span>{name}</span>
                <span
                  style={{ display: 'flex' }}
                  onClick={() => {
                    setFile(null);
                    setName(null);
                  }}
                >
                  {!disabled && (
                    <CloseIcon
                      color="error"
                      fontSize="small"
                      onClick={handleRowUpdate}
                    />
                  )}
                </span>
              </div>
            }
          >
            <ImageIcon sx={{ cursor: 'pointer' }} onClick={() => getAssetAttachments()} />
          </Tooltip>
        </div>
      ) : (
        <>
          <input
            type="file"
            disabled={disabled}
            hidden
            accept={accept}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <IconButton
            onClick={handleAddClick}
            color="rgba(0, 0, 0, 0.54)"
            disabled={
              clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6
            }
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default Upload;
