import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, TableSortLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'black',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: 'nowrap',
        height: "20px",
        backgroundColor: '#E6E6E6',
    },
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'black',
        backgroundColor: '#E6E6E6',
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        height: "20px",
    },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    [`&.MuiTableSortLabel-root`]: {
        color: theme.palette.common.black,

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        BorderBottom: 0,
    },
    "& td:first-of-type, & th:first-of-type": {
        borderLeft: '5px solid #006CEC !important',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px'
    },

}));

/* Sorting Table Head */

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
    fetchAssetsByFilterData: any[],
    handleInputKeyPress: any[],
    searchFilter: any[],
    searchInputs: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        <StyledTableSortLabel
                            active={orderBy === headCell.field}
                            direction={orderBy === headCell.field ? order : 'asc'}
                            onClick={createSortHandler(headCell.field)}
                        >
                            {t(headCell.headerName)}
                            {orderBy === headCell.field ? (
                                <Box component="span">
                                </Box>
                            ) : null}
                        </StyledTableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

/* Sorting Table Head Ends */


export default function EAMAssetListingTableComponent(props) {
    const { smallScreens, columns, data, page, setPage, rowsPerPage, setRowsPerPage, search, setSearch, workOrderStatus, showFilterFields, fetchData } = props;
    const { screenSize, appConstants } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    /************** SORTING***********************************/
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<string>('createdDate');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSearch(true);
        props?.setSearchFilter({ ...props.searchFilter, sortBy: isAsc ? 'desc' : 'asc', parameter: property });
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [data, order, orderBy, page, rowsPerPage]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setSearch(true);
    };
    /*********************************************************/

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setSearch(true);
    };

    const dateFormat = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('MM-DD-YYYY');
    }

    return (
        <Box>
            <Box>
                <Box className='homePagePagination'>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component='div'
                        count={props.rowCount ? props.rowCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <Box className='table-box-assets'>
                <TableContainer sx={{ maxHeight: smallScreens ? window.innerHeight - 186 : showFilterFields ? window.innerHeight - 258 : window.innerHeight - 186 }}>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            searchFilter={props.searchFilter}
                            handleInputKeyPress={props.handleInputKeyPress}
                            orderBy={orderBy}
                            searchInputs={props.searchInputs}
                            onRequestSort={handleRequestSort}
                            headCells={columns}
                        />
                        <TableBody>
                            {visibleRows?.length && visibleRows?.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{row?.assetNumber || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.category || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.parentInstanceNumber || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.assetGroupDescription || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.description || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.manufacturer || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.model || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.manufacturerSerialNumber || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.department || "-"}</StyledTableCell>
                                    <StyledTableCell>{row?.locator || "-"}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
