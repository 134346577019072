import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserSecurityAdmin from "./UserSecurityAdmin";
import RolesAdmin from "./RolesAdmin";
import PermissionListAdmin from "./PermissionListAdmin";
import DivisionAdmin from "./DivisionAdmin";
import "./admin.css";
import { t } from "i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SecurityAdmin() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box className="admin-security-tab-main-page-pc" sx={{ width: "100%",  }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className="tabs-main-security"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ "& .MuiTabs-indicator": { height: "2px", backgroundColor: "#053EAD" }, "& .MuiTabs-scroller":{overflow:"auto !important"} }}
          >
            <Tab
              label={t('DIVISION')}
              {...a11yProps(0)}
              sx={{
                fontWeight: "bold",
                "&.Mui-selected": { color: "#053EAD" },
              }}
            />
             <Tab
              label={t('USERS')}
              {...a11yProps(1)}
              sx={{
                fontWeight: "bold",
                "&.Mui-selected": { color: "#053EAD" },
              }}
            />
            <Tab
              label={t('ROLES')}
              {...a11yProps(2)}
              sx={{
                fontWeight: "bold",
                "&.Mui-selected": { color: "#053EAD" },
              }}
            />
            <Tab
              label={t('PERMISSION_LISTS')}
              {...a11yProps(3)}
              sx={{
                fontWeight: "bold",
                "&.Mui-selected": { color: "#053EAD" },
              }}
            />
           
          </Tabs>
        </Box>
        <TabPanel className="admin-security-user-tab" value={value} index={0}>
          
          <DivisionAdmin />
        </TabPanel>
        <TabPanel value={value} index={1}>
        
          <UserSecurityAdmin />
        </TabPanel>
        <TabPanel value={value} index={2}>
        <RolesAdmin />
        </TabPanel>
        <TabPanel value={value} index={3}>
        <PermissionListAdmin />
       
        </TabPanel>
      </Box>
    </Box>
  );
}
