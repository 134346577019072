import { Typography, Box, IconButton, Container, Stack } from "@mui/material";
import { React } from "react";
import { useTranslation } from "react-i18next";
import {
    InputComponent,
    SelectComponent,
    ButtonComponent
} from "../shared-components/common-field-components";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

const DeviceFilters = (props) => {

    const { toggleDrawer, anchor, setState, state } = props;
    const onSearch = () => {
        props.onSearchDevices();
        setState({ ...state, right: false });

    }
    const { t } = useTranslation();
    const DropdownRole_label = t("ORG_ID");
    const Devices_label = t("ADMIN_DEVICES_FILTER_INPUTLABEL_DEVICES");
    return (
        <Box className="e-a-l-mobile-filters-main">
            <Container sx={{ width: '330px' }}>
                <Box className="e-a-l-mobile-filters-sub">
                    <Box className="filters-header">
                        <Box className="mobile-filters-tittle-and-icon">
                            <FilterAltIcon sx={{ color: '#696c7b' }} />
                            <Typography flexGrow={1}>{t('FILTERS')}</Typography>
                        </Box>
                        <IconButton onClick={toggleDrawer(anchor, false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className='e-a-l-mobile-filter-fields'>
                    <Stack width={'100%'}>
                    <Typography sx={{ color: 'black'}}>
                            {'Device'}
                        </Typography>
                        <InputComponent
                            value={props.device}
                            disabled={false}
                            onChange={(e) => {
                                props.setDevice(e.target.value);
                            }}
                           
                        ></InputComponent>
                    </Stack>
                    <Stack width={'100%'}>
                        <Typography sx={{ color: 'black' }}>
                            {'DeviceRole'}
                        </Typography>
                        <SelectComponent
                            values={[{ id: 0, value: "Please Select" }, ...props.sitesDataOptions]}
                            selectedvalue={props.deviceRole}
                            disabled={false}
                            parentselectcallback={(e) => props.setDeviceRole(e)}
                        ></SelectComponent>
                    </Stack>
                    <Box className='e-a-l-mobile-filters-seach-export-buttons-main deviecs-buttons-mobile'>
                        <Box className='save-button'>
                            <ButtonComponent
                                width={"100%"}
                                color={"#2BAA4F"}
                                icon={false}
                                label={t("CLEAR")}
                                disabled={false}
                                buttoncallBack={props.onClearData}
                            ></ButtonComponent>

                        </Box>
                        <ButtonComponent label={t("SEARCH")} className='search-button-e-a-l'
                            width={"100%"}
                            buttoncallBack={onSearch}>


                        </ButtonComponent>

                    </Box>
                </Box>

            </Container>
        </Box>
    );
};
export default DeviceFilters;