// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assetSummaryMainBox {
    width: 100%;
    overflow-y: auto;
}

.assetSummaryDesktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.configurationsHeader {
    padding: 10px 0px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.rightalign {
    text-align: 'right';
}

.summaryHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width : 0px) and (max-width : 599px) {
    .text-align {
        text-align: right;
    }

    .summaryHeader {
        flex-direction: column;
        align-items: start;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/summary.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".assetSummaryMainBox {\n    width: 100%;\n    overflow-y: auto;\n}\n\n.assetSummaryDesktop {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.configurationsHeader {\n    padding: 10px 0px;\n    display: flex;\n    gap: 20px;\n    justify-content: space-between;\n    flex-direction: row;\n    align-items: center;\n}\n\n.rightalign {\n    text-align: 'right';\n}\n\n.summaryHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n@media only screen and (min-width : 0px) and (max-width : 599px) {\n    .text-align {\n        text-align: right;\n    }\n\n    .summaryHeader {\n        flex-direction: column;\n        align-items: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
