import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';


export const getLaborHours = async (page, size, organizationId, fromDate, toDate, eName, selectedResource, sortBy, sortParameter) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.REPORTS.LABORHOURS.GET_LABORHOURS, { params: { page: page, size: size, organizationId: organizationId, fromDate: fromDate, toDate: toDate, empName: eName, resourceCode: selectedResource, sortBy: sortBy, sortParameter: sortParameter } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAssetListingReport = async (params) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.REPORTS.GET_ASSET_DETAILS_REPORT, { params }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getWoHistoryReport = async (page, size, orgId, status, priority, workOrderId, assetNumber, department, userId, sortBy, parameter, fromDate, toDate, labor) => {
    try {
        let query = '';

        if (workOrderId !== '') {
            query += '&workOrderId=' + workOrderId;
        }
        if (assetNumber) {
            query += '&assetNumber=' + assetNumber;
        }
        if (department !== '') {
            query += '&department=' + department;
        }
        if (Array.isArray(status)) {
            status.forEach(value => {
                query += '&status=' + value;
            });
        } else if (status !== '') {
            query += '&status=' + status;
        }
        if (Array.isArray(priority)) {
            priority.forEach(value => {
                query += '&priorities=' + value;
            });
        } else if (priority !== '') {
            query += '&priorities=' + priority;
        }
        if (labor !== '') {
            query += '&labor=' + labor;
        }
        if (toDate !== '') {
            query += '&toDate=' + toDate;
        }
        if (fromDate !== '') {
            query += '&fromDate=' + fromDate;
        }
        const fetchData = await axios.get(APIENPOINTS.REPORTS.GET_WO_REPORTS +
            'page=' + page +
            '&size=' + size +
            '&organizationId=' + orgId +
            query +
            '&userId=' + userId +
            '&sort=' + sortBy +
            '&parameter=' + parameter
        ).then(res => {
            return res?.data;
        });

        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getFailureAnalysis = async (page, size, organizationId, tableParams) => {
    const {
        assetNumber,
        assetGroup,
        failureCode,
        fromDate,
        toDate,
    } = tableParams;
    const params = {
        page: page,
        size: size,
        organizationId: organizationId,
        assetNumber: assetNumber,
        assetGroup: assetGroup,
        failureCode: failureCode,
        fromDate: fromDate,
        toDate: toDate
    }
    Object.keys(params).forEach(key => {
        if (params[key] === '' || params[key] === ',') {
            delete params[key];
        }
    });

    try {

        const fetchData = await axios.get(APIENPOINTS.REPORTS.FAILURE_ANALISIS, { params: params }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getConditionBasedHistory = async (token, page, size, organizationId, fromDate, toDate, asset, parentAsset, workOrder, task, eName, maintenanceActivity) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.REPORTS.CONDITIONBASEDHISTORY.GET_CONDITION_BASED_HISTORY, { params: { page: page, size: size, organizationId: organizationId, fromDate: fromDate, toDate: toDate, asset: asset, parentAsset: parentAsset, workOrder: workOrder, task: task, empName: eName, maintenanceActivity: maintenanceActivity } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}


export const getInventoryHours = async (token, page, size, orgId, rig, paiName, asset, status, handleScan, archived, verifiedFrom, verifiedTo, createdFrom, createdTo) => {
    try {

        const fetchData = await axios.get(APIENPOINTS.REPORTS.INVENTORY_REPORTS, {
            params: {
                page: page, size: size, organizationId: orgId, rig: rig, paiName: paiName, asset: asset, status: status,
                handhledScan: handleScan, archived: archived, verifiedFrom: verifiedFrom, verifiedTo: verifiedTo, createdFrom: createdFrom, createdTo: createdTo
            }
        }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getWorkOrderAccessLogs = async (page, size, workOrderId, empName) => {
    try {
        const response = await axios.get(APIENPOINTS.WORKORDERACCESSLOGS.GET_WOACCESSLOGS, {
            params: {
                page: page,
                size: size,
                workOrder: workOrderId,
                empName: empName,

            }
        });
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}




