import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Box, TableSortLabel } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    width: "10%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <StyledTableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              {t(headCell.headerName)}
              {orderBy === headCell.field ? (
                <Box component="span">
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default function AxEmployeeAccessLogsTableComponent(props) {
  const { data, page, setPage, rowsPerPage, setRowsPerPage, } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('assetNumber');
  const { t } = useTranslation();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data?.content, getComparator(order, orderBy)),
    [data?.content, order, orderBy, page, rowsPerPage]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      field: "employeeDescription",
      headerName: t("EMPLOYEE_DESCRIPTION"),
      headerClassName: "super-app-theme--header",
      className: "",
      sortable: false
    },
    {
      field: "login",
      headerName: t("LOGIN"),
      headerClassName: "super-app-theme--header",
      className: "",
      sortable: false
    },
    {
      field: "logDate",
      headerName: t("LOG_DATA"),
      headerClassName: "super-app-theme--header",
      className: "",
      sortable: false
    },
    
  ];

  return (
    <Box>
      <TableContainer className='table-container-e-a-l'>
        <Table
          sx={{
            minWidth: 700,
            "& .MuiTableCell-head": {
             backgroundColor: "#E6E6E6 !important", color: "black !important"
            },
            "& .css-cokgfw-MuiTableCell-root.MuiTableCell-head": { width: "50px !important" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          stickyHeader
          size='small'
          aria-label='sticky table'
        >
        
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          <TableBody>
            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.employeeDescription}</StyledTableCell>
                <StyledTableCell>{row.login}</StyledTableCell>
                <StyledTableCell>{row.logDate}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

  );
}
