import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2.5),
    color: 'rgba(0, 0, 0, 0.6)'
  },
  "& .MuiInputBase-input": {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    backgroundColor: "white",
    fontSize: "14px",
    color: "black",
    padding: "10px 20px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {

    backgroundColor: "#E2E4E8",
    padding: "10px 20px",
    color: "#696C7B",

    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
  },
}));

export default function InputComponent(props) {
  const { label, defaultValue, disabled, width, error, onChange, autoFocus, placeholder } = props;
  return (
    <FormControl className="ax-input-component" variant='standard' sx={{
      width: width, marginBottom: "4px", marginTop: "8px"
    }}>
      {label && (
        <InputLabel htmlFor='bootstrap-input'>
          {label}
        </InputLabel>
      )}
      <BootstrapInput
        defaultValue={defaultValue}
        disabled={disabled}
        {...props}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
        error={error}      
      />
      {error && <Typography  variant='body2' style={{ color: "red", marginTop: "5px" }}>{error}</Typography>}
    </FormControl>
  );
}
