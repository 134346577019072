import { Box, Grid, IconButton, Paper, Stack, TablePagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WorkOrderHomePageTableComponent } from "../shared-components/common-field-components";
import { UserContext } from "../../store/UserContext";
import { workOrderGridColumns } from "../constants/workOrders";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkOrderStatus, getWorkOrdersBySearchAndSorting } from "../../services/WorkOrderServices";
import { exportToExcel } from "../../services/ExcelExportService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function AssetWorkorderHistory() {
    const { user, selectedSite, smallScreens, largeScreens } = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const assetNumber = location?.state?.asset;
    const priorities = JSON.parse(localStorage?.getItem('woPriorities'))?.filter(item => item.value != 'Please Select')?.map(item => item.value);
    const [searchFilter, setSearchFilter] = useState({ workOrderId: '', assetNumber: assetNumber, rig: '', status: location?.state?.status || 3, sortBy: '', parameter: '' })
    const [showFilterFields, setShowFilterFields] = useState(false);
    const [workordersData, setWorkordersData] = React.useState();
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false);
    const [workOrderStatus, setWorkOrderStatus] = useState([]);
    const [doSearch, setDoSearch] = useState(false);


    const fetchWorkOrdersByFilterData = () => {
        try {
            setLoading(true);
            getWorkOrdersBySearchAndSorting(
                page,
                rowsPerPage,
                selectedSite?.organizationId,
                searchFilter?.status,
                searchFilter.workOrderId,
                searchFilter?.assetNumber || assetNumber,
                searchFilter.rig,
                user?.id,
                searchFilter?.sortBy,
                searchFilter?.parameter,
                priorities || '',
                '',
                '',
                JSON.parse(localStorage.getItem("divisions"))?.map((item) => item?.id)
            ).then(response => {
                if (response && response?.statusCode === "200") {
                    setWorkordersData(response?.data?.content);
                    setTotalElements(response?.data?.totalElements);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });

        } catch (err) { console.log(err); setLoading(false); };
    }

    const fetchWorkOrderStatus = () => {
        getWorkOrderStatus()
            .then((response, i) => {
                if (response?.statusCode === "200") {
                    const data = response.data.map((res) => {
                        return { id: res.statusId, value: res.workOrderStatus }
                    })
                    const ids = data
                        .filter(item => item.value === 'On Hold' || item.value === 'Released')
                        .map(item => item.id);

                    data.unshift({ id: [3, 4, 6], value: "All" });
                    setWorkOrderStatus(data);
                }
            })
            .catch((err) => console.log(err));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        fetchWorkOrderStatus();
    }, [selectedSite])

    useEffect(() => {
        if (selectedSite && assetNumber)
            fetchWorkOrdersByFilterData();
    }, [page, rowsPerPage, selectedSite, assetNumber, path, searchFilter.sortBy, searchFilter.parameter]);

    const findStatus = (status) => {
        return workOrderStatus?.filter(function (val) { return val.id === status })[0]?.value;
    }

    const excelExport = () => {
        const arr = ['/excelExport/exportWorkOrders?'];
        arr.push(`organizationId=${selectedSite.organizationId}`);
        arr.push(`status=${searchFilter?.status === 0 ? [3, 4, 6] : searchFilter?.status ? searchFilter?.status : 3}`);
        if (searchFilter?.workOrderId && searchFilter?.workOrderId !== "") {
            arr.push(`workOrderId=${searchFilter?.workOrderId}`)
        }
        if (searchFilter?.assetNumber && searchFilter?.assetNumber !== "") {
            arr.push(`assetNumber=${searchFilter?.assetNumber}`);
        }
        if (searchFilter?.rig && searchFilter?.rig !== "") {
            arr.push(`rig=${searchFilter?.rig}`);
        }
        if (user?.id) {
            arr.push(`userId=${user?.id}`);
        }
        exportToExcel(arr.join("&"));
    }



    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                (largeScreens) && (
                    <Box className='homePageContentWrapper'>
                        <Box className='homePageHeader'>
                            <Stack direction={'row'} alignItems={'center'} gap={'10px'} marginLeft={'-10px'}>
                                <IconButton onClick={() => window.history.back()}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography variant='h5' flexGrow={1}>
                                    Asset Work Order History - {assetNumber}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box className='homePageTable'>
                            {
                                (workordersData && workordersData?.length !== 0) ? (
                                    <WorkOrderHomePageTableComponent
                                        rowCount={totalElements}
                                        columns={workOrderGridColumns}
                                        page={page}
                                        setSearchFilter={setSearchFilter}
                                        searchFilter={searchFilter}
                                        showFilterFields={showFilterFields}
                                        setPage={(e) => setPage(e)}
                                        rowsPerPage={rowsPerPage}
                                        workOrderStatus={workOrderStatus}
                                        setRowsPerPage={(e) => setRowsPerPage(e)}
                                        data={workordersData}
                                        doSearch={doSearch}
                                        setDoSearch={setDoSearch}
                                        fetchData={fetchWorkOrdersByFilterData}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        selectedSite={selectedSite}
                                    ></WorkOrderHomePageTableComponent>
                                ) : (
                                    <Typography variant="h6" align="center" style={{ marginTop: '16px', display: 'block' }}>No Work Orders Found</Typography>
                                )
                            }
                        </Box>
                    </Box>
                )
            }
            {//mobile resolution
                smallScreens && (
                    <Box className='mobileHomePageContentWrapper'>
                        <Box className='homePageHeader'>
                            <Stack direction={'row'} alignItems={'center'} gap={'5px'} marginLeft={'-10px'}>
                                <IconButton onClick={() => window.history.back()}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography width={'100%'} sx={{ wordBreak: 'break-word' }} variant='h6' flexGrow={1}>
                                    {assetNumber}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box className='homePagePagination'>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component='div'
                                count={totalElements ? totalElements : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                        <Box className='homePageMobileTableContent' sx={{ height: window.innerHeight - 166 }}>
                            {workordersData?.length === 0 && <Typography variant="h6">No data found</Typography>}
                            {workordersData && workordersData?.map((row, index) => (
                                <Grid key={index} onClick={() => { navigate('/workorders/details', { state: row?.workOrderId }); localStorage.setItem('woNumber', row?.workOrderId) }} className="mobileHomePageListCard">
                                    <Paper elevation={0} >
                                        <Box alignItems='start' flex={1}>
                                            <Typography variant="h6">{row?.workOrderId}</Typography>
                                            <Typography >{row?.assetNumber}</Typography>
                                            <Typography><Typography variant="h6" component={'span'} >Parent: </Typography>{row.currentSubinventoryCode}</Typography>
                                        </Box>
                                        <Box textAlign='end' flex={1}>
                                            <Typography variant="h6" >{(row.briefDescription === "null" || row.briefDescription === "Null" || row.briefDescription === null) ? "" : row.briefDescription}</Typography>
                                            <Typography><Typography variant="h6" component={'span'}>Status: </Typography>{row?.statusType && findStatus(row?.statusType)}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}