import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Broadcast from "./Broadcast";
import "./admin.css";
import { t } from "i18next";


export default function NotificationsBroadcast() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {/* Mobile  */}
      </Box>
      {/* <Typography variant={'h5'} paddingLeft="10px" paddingTop="10px">
        {t("BROADCAST_MESSAGES")}
      </Typography>
       */}
      <Broadcast />
    </Box>
  );
}
