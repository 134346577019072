import { useState } from "react";
import {
  Grid,
  Box,
  Paper,
  Avatar,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import bg from "../../assets/images/bg.jpg";
import { useOktaAuth } from "@okta/okta-react";
import AimxcelLogo from "../../assets/images/aimxcel_logo.png";
import { InfoDialog } from "../shared-components/InfoDialog";

export default function SignIn() {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const { username, password } = state;
  const changeHandler = (e) => {
    setState((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };
  const [openInfo, setOpenInfo] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    oktaAuth
      .signInWithCredentials(state)
      .then((res) => {
        const sessionToken = res.sessionToken;
        oktaAuth.signInWithRedirect({
          originalUri: "/dashboard",
          sessionToken: sessionToken,
        });
      }).catch((err) => {
        setOpenInfo(true)
      });



  };
  return (

    <Box className='header-main' component='nav' sx={{ flexGrow: 1, height: '100dvh' }}>
      {/* <AppBar
        component={Box}

        position='static'
        elevation={0}
        sx={{
          "&.MuiAppBar-root": { backgroundColor: 'linear-gradient(90deg, #303b42 10%, #0d2f4b 90%)' }
        }}
      >
        <Toolbar>
          <Box
            component="img"
            sx={{ height: 20 }}
            alt="Logo"
            src={AimxcelLogo}
          />
        </Toolbar>
      </AppBar> */}
      <Grid container className="login-main" sx={{ '&.MuiGrid-root': { justifyContent: 'center !important' } , height: 'calc(100% - 0px)'}} component='main' >
        <CssBaseline />
        {window.innerWidth > 1200 && <Grid
          className='signin-bg-image'
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            //display: window.innerWidth <= 1200 && 'none'
          }}
        />}

        <Grid className="signin-fields-main" sx={{ '&.MuiGrid-root': { justifyContent: 'center' } }} item xs={12} sm={8} md={window.innerWidth <= 1200 ? 12 : 5} component={Paper} elevation={0} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box component='form' onSubmit={submitHandler} sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                fullWidth={true}
                id='username'
                label='Username'
                name='username'
                autoFocus
                autoComplete='off'
                value={username}
                onChange={changeHandler}
              />
              <TextField
                margin='normal'
                fullWidth={true}
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={changeHandler}
              />

              <Button type='submit' fullWidth={true} variant='contained' sx={{ mt: 3, mb: 2, backgroundColor: "#1D66A2" }}>
                Sign In
              </Button>
              <InfoDialog openInfo={openInfo} msg={'"Username" or "Password" is wrong. Please try again'}
                okAction={async () => { setOpenInfo(false) }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>

  );
}
