import { Box, Button, Drawer, IconButton, Stack, Toolbar, Typography, } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { WorkOrderHomePageTableComponent, DatePickerComponent, InputComponent, SelectComponent } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import { workOrderGridColumns } from "../constants/workOrders";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkOrderStatus, } from "../../services/WorkOrderServices";
import { exportToExcel } from "../../services/ExcelExportService";
import { getWoHistoryReport } from "../../services/ReportServices";
import IosShareIcon from '@mui/icons-material/IosShare';

export default function AssetsHomePage() {
    const { user, selectedSite, oktaAuth, smallScreens, largeScreens } = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    let localParams = JSON.parse(localStorage?.getItem('woHistory'));
    const priorities = JSON.parse(localStorage?.getItem('woPriorities'))?.filter(item => item.value != 'Please Select')?.map(item => item.value);
    const [searchFilter, setSearchFilter] = useState({ workOrderId: localParams?.workOrderId || '', assetNumber: localParams?.assetNumber || '', department: localParams?.department || '', status: location?.state?.status || localParams?.status || 3, labor: localParams?.labor || 'A', sortBy: localParams?.sortBy || '', parameter: localParams?.parameter || '', fromDate: localParams?.fromDate || '', toDate: localParams?.toDate || '' })
    const [showFilterFields, setShowFilterFields] = useState(location?.state || true);
    const [workordersData, setWorkordersData] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [workOrderStatus, setWorkOrderStatus] = useState([]);
    const isClear = useRef(false);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [labor, setLabor] = useState(0); const path = location.pathname;
    const [doSearch, setDoSearch] = useState(false);
    const [allIds, setAllIds] = React.useState([]);


    const fetchWorkOrdersByFilterData = (_page) => {
        try {
            setLoading(true);
            getWoHistoryReport(
                _page ? _page : page,
                rowsPerPage,
                selectedSite?.organizationId,
                searchFilter?.status,
                priorities,
                searchFilter.workOrderId,
                searchFilter?.assetNumber,
                searchFilter.department,
                user?.id,
                searchFilter?.sortBy,
                searchFilter?.parameter,
                searchFilter?.fromDate || '',
                searchFilter?.toDate || '',
                searchFilter?.labor === 'A' ? '' : searchFilter?.labor,

            ).then(response => {
                if (response && response?.statusCode === "200") {
                    setWorkordersData(response?.data?.content);
                    setTotalElements(response?.data?.totalElements);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });

        } catch (err) { console.log(err); setLoading(false); };
    }
    const fetchWorkOrderStatus = () => {
        getWorkOrderStatus()
            .then((response, i) => {
                if (response?.statusCode === "200") {
                    const data = response.data.map((res) => {
                        return { id: res.statusId, value: res.workOrderStatus }
                    })
                    const ids = data
                        .filter(item => item.value === 'On Hold' || item.value === 'Released')
                        .map(item => item.id);

                    data.unshift({ id: '[3, 4, 6]', value: "All" });
                    setWorkOrderStatus(data);
                }
            })
            .catch((err) => console.log(err));
    };
    const handleChangePage = (event, newPage) => {
        setDoSearch(true);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setDoSearch(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
        }
    };
    const clearFilters = async () => {
        isClear.current = true;
        setPage(0);
        setRowsPerPage(25);
        setSearchFilter({ workOrderId: '', assetNumber: '', status: 3, labor: 0, sortBy: null, parameter: null, fromDate: null, toDate: null });
    }
    useEffect(() => {
        if (isClear.current === true) {
            setShowSideBar(!showSideBar);
            isClear.current = false;
        }
    }, [searchFilter]);

    useEffect(() => {
        fetchWorkOrderStatus();
    }, [selectedSite])

    useEffect(() => {
        if (doSearch) {
            setDoSearch(false)
            fetchWorkOrdersByFilterData();
        }

    }, [doSearch, searchFilter?.sortBy, searchFilter?.parameter, page, rowsPerPage])




    const findStatus = (status) => {
        return workOrderStatus?.filter(function (val) { return val.id === status })[0]?.value;
    }
    const excelExport = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('organizationId', selectedSite?.organizationId);
        const statusParams = JSON.parse(searchFilter?.status || [])
        if (Array.isArray(statusParams)) {
            statusParams?.forEach(value => {
                urlParams.append('status', value);
            });
        } else if (searchFilter?.status !== '') {
            urlParams.append('status', statusParams);
        }
        if (searchFilter?.workOrderId && searchFilter?.workOrderId !== "") {
            urlParams.append('workOrderId', searchFilter?.workOrderId);
        }
        if (searchFilter?.assetNumber && searchFilter?.assetNumber !== "") {
            urlParams.append('assetNumber', searchFilter?.assetNumber);
        }
        if (searchFilter?.department && searchFilter?.department !== "") {
            urlParams.append('department', searchFilter?.department);
        }
        if (user?.id) {
            urlParams.append('userId', user?.id);
        }
        if (searchFilter?.sortBy && searchFilter?.sortBy !== "") {
            urlParams.append('sort', searchFilter?.sortBy);
        }
        if (searchFilter?.parameter && searchFilter?.parameter !== "") {
            urlParams.append('parameter', searchFilter?.parameter);
        }
        if (searchFilter?.fromDate && searchFilter?.fromDate !== "") {
            urlParams.append('fromDate', searchFilter?.fromDate);
        }
        if (searchFilter?.toDate && searchFilter?.toDate !== "") {
            urlParams.append('toDate', searchFilter?.toDate);
        }
        if (searchFilter?.labor && searchFilter?.labor !== "") {
            urlParams.append('labor', searchFilter?.labor);
        }
        if (Array.isArray(priorities)) {
            priorities.forEach(value => {
                urlParams.append('priorities', value);
            });
        } else if (priorities !== '') {
            urlParams.append('priorities', priorities);
        }

        const url = `/excelExport/exportWOHistoryReport?${urlParams.toString()}`;
        exportToExcel(url);
    };
    const LaborFilterOptions = [
        { id: 'A', value: 'All' },
        {
            id: 'Y', value: 'Yes'
        },
        { id: 'N', value: 'No' }
    ]
    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet

                <Box className={largeScreens ? 'homePageContentWrapper' : 'mobileHomePageContentWrapper'}>
                    <Box className='homePageHeader'>
                        <Typography variant='h4' flexGrow={1}>
                            Work Order History
                        </Typography>
                        <Stack direction={'row'}>

                            <IconButton onClick={() => largeScreens ? setShowFilterFields(!showFilterFields) : setShowSideBar(!showSideBar)}>
                                {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                            </IconButton>
                            <IconButton onClick={excelExport}>
                                <IosShareIcon style={{ fontSize: "20" }} />
                            </IconButton>
                        </Stack>
                    </Box>
                    {
                        smallScreens ?
                            <Drawer
                                anchor="right"
                                open={showSideBar}
                                onClose={() => setShowSideBar(!showSideBar)}
                                PaperProps={{ style: { width: '300px' } }} >
                                <Box className='drawerContent' padding={'10px 15px'}>
                                    <Toolbar />

                                    <Stack direction={'column'} gap={'10px'}>
                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Workorder'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                name="workorderId"
                                                value={searchFilter?.workOrderId}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                                }

                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Asset '}
                                            </Typography>
                                            <InputComponent
                                                name="assetNumber"
                                                width="100%"
                                                value={searchFilter?.assetNumber}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                                }

                                                }
                                            ></InputComponent>
                                        </Stack>

                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Status'}
                                            </Typography>
                                            <SelectComponent
                                                values={workOrderStatus}
                                                onKeyPress={handleInputKeyPress}
                                                selectedvalue={searchFilter?.status}
                                                disabled={false}
                                                width='100%'
                                                parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['status']: e })}
                                            ></SelectComponent>
                                        </Stack>
                                        {searchFilter?.status == 3 &&
                                            <Stack width="100%" >
                                                <Typography >
                                                    {'Labor Assigned'}
                                                </Typography>
                                                <SelectComponent
                                                    width="100%"
                                                    values={LaborFilterOptions}
                                                    selectedvalue={searchFilter?.labor}
                                                    onKeyPress={handleInputKeyPress}
                                                    parentselectcallback={(e) => {
                                                        setSearchFilter({ ...searchFilter, ['labor']: e.target.value });
                                                    }}
                                                    disabled={false}
                                                ></SelectComponent>
                                            </Stack>
                                        }
                                        {<Stack width="100%" >
                                            <Typography >
                                                {searchFilter?.status !== 4 ? 'From Date (Released)' : 'From Date (Actual)'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>}
                                        {
                                            <Stack width="100%" >
                                                <Typography >
                                                    {searchFilter?.status !== 4 ? 'To Date (Released)' : 'To Date (Actual)'}
                                                </Typography>
                                                <DatePickerComponent
                                                    width="100%"
                                                    disabled={false}
                                                    disableFuture={true}
                                                    dateDefaultValue={searchFilter?.toDate}
                                                    onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                                ></DatePickerComponent>
                                            </Stack>
                                        }
                                        <Button variant='contained' onClick={() => { fetchWorkOrdersByFilterData(0); setPage(0); setShowSideBar(!showSideBar) }}>Search</Button>
                                        <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                    </Stack>
                                </Box>
                            </Drawer>
                            :
                            <Box className='homePageFilters'>
                                {showFilterFields && (
                                    <Box className='filters-lg'>
                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Workorder'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                name="workorderId"
                                                value={searchFilter?.workOrderId}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                                }

                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Asset '}
                                            </Typography>
                                            <InputComponent
                                                name="assetNumber"
                                                width="100%"
                                                value={searchFilter?.assetNumber}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                                }

                                                }
                                            ></InputComponent>
                                        </Stack>

                                        <Stack width="100%">
                                            <Typography color={'black !important'}>
                                                {'Status'}
                                            </Typography>
                                            <SelectComponent
                                                values={workOrderStatus}
                                                onKeyPress={handleInputKeyPress}
                                                selectedvalue={searchFilter?.status}
                                                disabled={false}
                                                width='100%'
                                                parentselectcallback={(e) => setSearchFilter({ ...searchFilter, ['status']: e })}
                                            ></SelectComponent>
                                        </Stack>
                                        {searchFilter?.status == 3 &&
                                            <Stack width="100%" >
                                                <Typography >
                                                    {'Labor Assigned'}
                                                </Typography>
                                                <SelectComponent
                                                    width="100%"
                                                    values={LaborFilterOptions}
                                                    selectedvalue={searchFilter?.labor}
                                                    onKeyPress={handleInputKeyPress}
                                                    parentselectcallback={(e) => {
                                                        setSearchFilter({ ...searchFilter, ['labor']: e });
                                                    }}
                                                    disabled={false}
                                                ></SelectComponent>
                                            </Stack>
                                        }
                                        {<Stack width="100%" >
                                            <Typography >
                                                {searchFilter?.status !== 4 ? 'From Date (Released)' : 'From Date (Actual)'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>}
                                        {
                                            <Stack width="100%" >
                                                <Typography >
                                                    {searchFilter?.status !== 4 ? 'To Date (Released)' : 'To Date (Actual)'}
                                                </Typography>
                                                <DatePickerComponent
                                                    width="100%"
                                                    disabled={false}
                                                    disableFuture={true}
                                                    dateDefaultValue={searchFilter?.toDate}
                                                    onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                                ></DatePickerComponent>
                                            </Stack>
                                        }
                                        <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                            <IconButton
                                                aria-label="search"
                                                onClick={() => { fetchWorkOrdersByFilterData(0); setPage(0) }}
                                                edge="start"
                                            >
                                                <SearchIcon />
                                            </IconButton>

                                            <IconButton onClick={clearFilters}
                                                aria-label="search"
                                                edge="start"
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )}
                            </Box>
                    }
                    <Box className='homePageTable'>
                        {
                            workordersData && workordersData.length > 0 ? (
                                <WorkOrderHomePageTableComponent
                                    rowCount={totalElements}
                                    columns={workOrderGridColumns}
                                    page={page}
                                    doSearch={doSearch}
                                    setDoSearch={setDoSearch}
                                    setSearchFilter={setSearchFilter}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    searchFilter={searchFilter}
                                    showFilterFields={showFilterFields}
                                    setPage={(e) => setPage(e)}
                                    rowsPerPage={rowsPerPage}
                                    workOrderStatus={workOrderStatus}
                                    setRowsPerPage={(e) => setRowsPerPage(e)}
                                    data={workordersData}
                                    fetchData={fetchWorkOrdersByFilterData}
                                    selectedSite={selectedSite}
                                />
                            ) : (
                                <Typography variant="h6" align="center" style={{ marginTop: '16px', display: 'block' }}>No data found</Typography>
                            )
                        }
                    </Box>
                </Box>

            }


        </Box>
    );
}