import AddIcon from '@mui/icons-material/Add';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Box, CircularProgress, Grid, IconButton, Typography, } from "@mui/material";
import { t } from "i18next";
import React, { useContext, useState, useEffect } from "react";
import { createNewUser, updateUser } from "../../services/AdminSecurityServices";
import { UserContext } from "../../store/UserContext";
import ToastMessage from "../shared-components/ToastMessage";
import { ButtonComponent, AxSecurityUsersTableComponent, InputComponent } from "../shared-components/common-field-components";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import "./admin.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const UserSecurityAdmin = () => {
  const [change, setChange] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);
  const [reload, setReload] = useState(false);
  const [showNewRole, setShowNewRole] = useState(false);
  const [addUserData, setAddUserData] = useState(false);
  const [showSaveButtonForEdit, setShowSaveButtonForEdit] = useState(false);
  const [enableBackButton, setEnableBackButton] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [flagToGetNewUserData, setFlagToGetNewUserData] = useState(false);
  const [sendEditedData, setSendEditedData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState(false);
  const { oktaAuth, smallScreens, largeScreens } = useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();
  const [toast, setToast] = useState({});

  const navigateBack = () => {
    setAddUserData(false);
    setEnableBackButton(false);
    setViewData(false);
    setShowNewRole(false);
  };

  const createUser = (_data) => {
    setLoading(true);
    createNewUser(accessToken, _data)
      .then(response => {
        setLoading(false);
        if (response?.statusCode == '200') {
          const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
          setToast(toastProps);
          setShowNewUser(false);
          setReload(!reload);
          setShowNewRole(false);
          setFlagToGetNewUserData(false);
        }
        else {
          const toastProps = { open: true, type: "error", message: response?.message, onClose: () => setToast({}) };
          setToast(toastProps);
          setFlagToGetNewUserData(false);
        }
      })
      .catch(err => console.log(err));
  };

  const saveUser = (_data) => {
    setLoading(true);
    //setSendEditedData(false);
    setChange(false);
    updateUser(_data)
      .then(response => {
        setLoading(false);
        if (response?.statusCode === '200') {
          const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
          setToast(toastProps);
          setReload(!reload);
          setShowNewUser(false);
        }
        else {
          const toastProps = { open: true, type: "error", message: response?.message, onClose: () => setToast({}) };
          setToast(toastProps);

        }
      })
      .catch(err => console.log(err));
  };

  // useEffect(() => {
  //   if (sendEditedData) {
  //     console.log("Sending edited data...");
  //     saveUser();
  //   }
  // }, [sendEditedData]);

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilter(true);
    }
  };

  return (
    <Box className='admin-security-user-main'>
      <Grid className='admin-security-content-page-header' mb={0.5}>
        <Box className="tittle-and-backbutton">
          {enableBackButton && (
            <ArrowCircleLeftOutlinedIcon
              onClick={navigateBack}
              className="back-button-mobile-sites"
              fontSize="medium"
            />
          )}
          <Box
            className='users-search-pc'
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <Typography variant={'h6'} className='admin-content-header-tittle-text'>{t('USERS_LIST')}</Typography>
              <Box marginLeft={"10px"}>
                <InputComponent
                  label={""}
                  value={keyword}
                  disabled={false}
                  onKeyPress={(e) => { if (e.key === 'Enter') setFilter(true); }}
                  onChange={(e) => { setKeyword(e.target.value); }}
                />
              </Box>
              <Box>
                <ButtonComponent
                  icon={false}
                  label={t("SEARCH")}
                  disabled={loading}
                  buttoncallBack={() => setFilter(true)}
                />
              </Box>
              <Box className='clear-button' display="flex">
                <ButtonComponent
                  color={'#2BAA4F'}
                  label={t("CLEAR")}
                  disabled={loading}
                  buttoncallBack={() => {
                    if (keyword !== "") {
                      setKeyword("");
                      setFilter(true);
                    }
                  }}
                >
                  {t('CLEAR')}
                </ButtonComponent>
              </Box>
            </Box>
          </Box>
          <Box
            width={"100%"}
            justifyContent="space-between"
            className='users-search-mobile'
            display={{ xs: 'flex', md: 'none' }}
            flexDirection={"row"}
          >
            <Box className="search-main" display="flex" alignItems="center" gap="10px">
              <div>
                <Box width="100%" className="search-field">
                  <InputBase
                    value={keyword}
                    inputProps={{ maxLength: 16 }}
                    autoFocus
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyPress={(e) => { if (e.key === 'Enter') setFilter(true); }}
                    placeholder="Search Users"
                  />
                </Box>
              </div>

              <IconButton
                className='icon-button'
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                disabled={loading}
                onClick={() => setFilter(true)}
              >
                <SearchIcon color="aliceblue" fontSize='large' />
              </IconButton>
            </Box>
            <Box className="clear-button" display="flex">
              <IconButton
                onClick={() => {
                  if (keyword !== "") {
                    setKeyword("");
                    setFilter(true);
                  }
                }}
                disabled={loading}
                aria-label="search"
                edge="start"
              >
                <RefreshIcon color="gray" fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between' }}>
          {showNewUser ? (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, }}>
              {window.innerWidth > 900 ? (
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, gap: 2 }}>
                  <ButtonComponent
                    label={t("CANCEL")}
                    color={"red"}
                    disabled={loading}
                    buttoncallBack={() => setShowNewUser(!showNewUser)}
                  />
                  <ButtonComponent
                    label={t("SAVE")}
                    color={'#2BAA4F'}
                    disabled={loading}
                    buttoncallBack={() => {
                      setFlagToGetNewUserData(true);
                    }}
                  />
                </Box>
              ) : (
                <>
                  <IconButton disabled={loading} onClick={() => setShowNewUser(!showNewUser)} >
                    <Close />
                  </IconButton>

                  <IconButton disabled={loading} onClick={() => {
                    setFlagToGetNewUserData(true);
                  }}>
                    <SaveIcon />
                  </IconButton>
                </>
              )}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <IconButton
                disabled={loading}
                sx={{ display: { xs: 'block', sm: 'none' } }}
                onClick={() => setShowNewUser(!showNewUser)} >
                <AddIcon />
              </IconButton>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, gap: 2 }}>
                {change &&
                  (
                    <IconButton  disabled={loading} onClick={() => {

                      setSendEditedData(true);
                    }}>
                      <SaveIcon />
                    </IconButton>
                  )}
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
                <ButtonComponent
                  label={t("ADD_USER")}
                  disabled={loading}
                  icon buttoncallBack={() => setShowNewUser(!showNewUser)}
                  sx={{ display: { xs: 'block', sm: 'none' } }}
                />
                {change &&
                  (
                    <ButtonComponent
                      label={t("SAVE")}
                      color={'#2BAA4F'}
                      disabled={loading}
                      buttoncallBack={() => {

                        setSendEditedData(true);
                      }}
                    />
                  )}

              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      <Box className='admin-security-tables'>
        {loading ?
          <CircularProgress />
          :
          <AxSecurityUsersTableComponent
            change={change}
            setChange={setChange}
            filter={filter}
            setFilter={(e) => setFilter(e)}
            setKeyword={(e) => setKeyword(e)}
            keyword={keyword}
            showNewUser={showNewUser}
            flagToSaveNewUser={flagToGetNewUserData}
            setSaveNewUser={setFlagToGetNewUserData}
            saveNewUser={(_data) => {
              createUser(_data);

            }}
            sendEditedData={sendEditedData}
            setSendEditedData={setSendEditedData}
            saveEditedData={saveUser}
            reload={reload} />
        }
      </Box>
      <ToastMessage toast={toast} />
    </Box>
  );
};
export default UserSecurityAdmin; 