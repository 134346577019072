import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useContext } from "react";
import { getAllDivisions, saveDivision, updateRolePermission } from "../../services/AdminSecurityServices";
import { UserContext } from "../../store/UserContext";
import ToastMessage from "../shared-components/ToastMessage";
import { ButtonComponent, AxSecurityDivisionsTableComponent } from "../shared-components/common-field-components";
import "./admin.css";
import { useState } from "react";
import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const DivisionAdmin = () => {
  const { selectedSite, user } = useContext(UserContext);
  const [divisionsData, setDivisionsData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [showNewRole, setShowNewRole] = React.useState(false);
  const [sendData, setSendData] = React.useState(false);
  const [flagToGetUpdatedData, setFlagToGetUpdatedData] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = React.useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();
  const [toast, setToast] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);

  const fetchData = () => {
    setLoading(true);
    getAllDivisions(page, rowsPerPage).then(response => {
      if (response?.statusCode === "200") {
        setLoading(true);
        setDivisionsData(response?.data.content);
        setTotalElements(response.data.totalElements);
        setLoading(false);
      }
    })
      .catch(err => console.log(err));
    setLoading(false);
  }



  React.useEffect(() => { setLoading(true); fetchData() }, [refresh]);

  const saveNewDivision = (_dataToSave,) => {
    setSendData(false);
    setLoading(true);
    saveDivision(_dataToSave)
      .then(response => {
        if (response.statusCode == 200) {
          const toastProps = { open: true, type: "success", message: "Division created succesfully", onClose: () => setToast({}) };
          setToast(toastProps);
          setShowNewRole(false);
          fetchData();
          setLoading(false);
        }
        else {
          const toastProps = { open: true, type: "error", message: response.message, onClose: () => setToast({}) };
          setToast(toastProps);
          setLoading(false);
        }
      })
      .catch(err => console.log(err));
  }

  return (
    <Box className='admin-security-roles-main'>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      <ToastMessage toast={toast} />

      <Grid className="admin-security-content-page-header">
        <Typography variant={'h5'} className="admin-content-header-tittle-text">
          {t('DIVISION_LIST')}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between' }}>
          {showNewRole ? (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, }}>
              {window.innerWidth > 900 ? (
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, gap: 2 }}>
                  <ButtonComponent
                    label={t("CANCEL")}
                    color={"red"}
                    buttoncallBack={() => setShowNewRole(false)}
                  />
                  <ButtonComponent
                    label={t("SAVE")}
                    color={'#2BAA4F'}
                    buttoncallBack={() => setSendData(true)}
                  />
                </Box>
              ) : (
                <>
                  <IconButton onClick={() => setShowNewRole(false)} >
                    <Close />
                  </IconButton>

                  <IconButton onClick={() => setSendData(true)}>
                    <SaveIcon />
                  </IconButton>

                </>
              )}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

              <IconButton
                sx={{ display: { xs: 'block', sm: 'none' } }}
                onClick={() => setShowNewRole(!showNewRole)}
              >
                <AddIcon />
              </IconButton>

              <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
                <ButtonComponent
                  label={t("ADD_DIVISION")}
                  icon
                  buttoncallBack={() => setShowNewRole(!showNewRole)}
                  sx={{ display: { xs: 'block', sm: 'none' } }}
                />
                {change && (
                  <ButtonComponent
                    label={t("SAVE")}
                    color={'#2BAA4F'}
                    buttoncallBack={() => setSendData(true)}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      <Box className='admin-security-tables'>
        <AxSecurityDivisionsTableComponent
          divisionsData={divisionsData}
          change={change}
          selectedSite={selectedSite}
          sendData={sendData}
          setSendData={setSendData}
          showNewRole={showNewRole}
          saveData={(_dataToSave) => { saveNewDivision(_dataToSave) }}
          setShowSaveButtonForEdit={setChange}
          sendEditedData={flagToGetUpdatedData}
          setSendEditedData={setFlagToGetUpdatedData}
          fetchData={fetchData}
          saveEditedData={(_data) => {
            updateRolePermission(accessToken, _data).then(res => {
              setLoading(true);
              if (res.statusCode == 200) {
                const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                fetchData();
                setLoading(false);
              }
              else {
                const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                setToast(toastProps);
                setLoading(false);
              }
            })
            setFlagToGetUpdatedData(false);
          }
          }
          user={user}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalElements={totalElements}
          refresh={refresh}
          setRefresh={setRefresh}
        ></AxSecurityDivisionsTableComponent>

      </Box>
    </Box>
  );
};

export default DivisionAdmin;
