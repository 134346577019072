import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { InputComponent, SelectComponent } from ".";
import { red } from "@mui/material/colors";
import { Box, TableSortLabel } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../ConfirmDialog";
import '../index.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",


  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  "&:last-child td, &:last-child th": {
    BorderBottom: 0,
  },
  "& td:first-of-type, & th:first-of-type": {
    borderLeft: '5px solid #006CEC !important',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  },
}));



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <StyledTableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AxDevicestableComponent(props) {
  const {
    columns,
    clearDataToSave,
    isNewDevice,
    data,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    isAddDeviceSave,
    setIsAddDeviceSave,
    onAdd,
    sitesData,
    toggleColonizeButtons
  } = props;

  const [editingDevices, setEditingDevices] = useState<any>([]);
  const [devicesData, setDevicesData] = useState<any>([...data?.content]);
  const [openCancel, setOpenCancel] = useState(false);
  const [getDeviceId, setGetDeviceId] = useState(null);

  useEffect(() => {
    setDevicesData([...data?.content]);
  }, [data]);

  const TimeDisplay = ({ dateString }) => {
    const [date, time] = dateString.split(' ');

    return (
      <div>
        <span>{date}</span> <span>{time.slice(0, 8)}</span>
      </div>
    );
  };

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('orgCode');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(devicesData || [], getComparator(order, orderBy)),
    [devicesData, order, orderBy,]
  );

  /*********************************************************/

  useEffect(() => {
    if (clearDataToSave) setEditingDevices([]);
  }, [clearDataToSave]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    props.setDataToSave(editingDevices);
  }, [editingDevices]);

  const onDeviceCellEdit = useCallback(
    (editedRow) => {
      const index = editingDevices.findIndex((item) => item.deviceId === editedRow.deviceId);
      let newEditiedDevices = [...editingDevices];
      newEditiedDevices[index] = editedRow;
      setEditingDevices(newEditiedDevices);
    },
    [editingDevices],
  );

  const getTableCells = useCallback(
    (row) => {
      const selectedRow = editingDevices.find((item) => item.deviceId === row.deviceId);
      return (
        <>
          {selectedRow?.deviceId ? (
            <>
              <StyledTableCell >
                <InputComponent
                  value={selectedRow.device}
                  onChange={(e) => onDeviceCellEdit({ ...selectedRow, device: e.target.value })}
                />
              </StyledTableCell>

              <StyledTableCell>
                <InputComponent
                  value={selectedRow.deviceDescription}
                  onChange={(e) =>
                    onDeviceCellEdit({ ...selectedRow, deviceDescription: e.target.value })
                  }
                />
              </StyledTableCell>
            </>
          ) : (
            <>
              <StyledTableCell>{row.device}</StyledTableCell>
              <StyledTableCell>{row.deviceDescription}</StyledTableCell>
            </>
          )}
          <StyledTableCell>{row.deviceRole}</StyledTableCell>
          <StyledTableCell>{row.resetData ? "True" : "False"}</StyledTableCell>
          <StyledTableCell>
            {row.lastConnected !== null
              ? new Date(row.lastConnected).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })
              : row.lastConnected}
          </StyledTableCell>

          <StyledTableCell>{row.pendingData ? "True" : "False"}</StyledTableCell>
          <StyledTableCell>{row.pendingApplication ? "True" : "False"}</StyledTableCell>
          <StyledTableCell>{row.colonization}</StyledTableCell>
        </>
      );
    },
    [editingDevices],
  );

  useEffect(() => {
    let selectedDeviceIndex = devicesData.findIndex(x => x.selected);
    if (selectedDeviceIndex > -1) {
      toggleColonizeButtons(false);
    }
    else {
      toggleColonizeButtons(true);
    }
  }, [devicesData]);



  return (
    <Box>
      <TableContainer className='table-container-devices'>
        <Table
          className='devices-table-main'
          sx={{
            minWidth: 700,
            "& .MuiTableCell-head": {
              backgroundColor: "#E6E6E6 !important",
              color: "black !important",
            },
            "& .css-cokgfw-MuiTableCell-root.MuiTableCell-head": { width: "1px !important" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          stickyHeader
          size='small'
          aria-label='sticky table'
        >

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          <TableBody>
            <ConfirmDialog sx={{ width: 600 }} openConfirm={openCancel}
              hidebuttons={false}
              msg={`Are you sure?`}
              accept={() => { props.onDelete(getDeviceId); setOpenCancel(false); }}
              reject={() => { setOpenCancel(false); }}
            />
            {isNewDevice && (
              <AddDeviceForm
                isAddDeviceSave={isAddDeviceSave}
                setIsAddDeviceSave={setIsAddDeviceSave}
                onAdd={onAdd}
                sitesData={sitesData}
              />
            )}
            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align='center' className='checkbox-cell'>
                  {" "}
                  <Checkbox checked={row.selected}
                    onChange={(event) => {
                      let tempData = [...devicesData];
                      let ind = tempData.findIndex(x => x.selected);
                      if (ind > -1)
                        tempData[ind].selected = false;
                      tempData[tempData.findIndex(x => x.deviceId === row.deviceId)].selected = event.target.checked;
                      setDevicesData([...tempData]);
                    }}
                  />
                </StyledTableCell>

                {getTableCells(row)}
                <StyledTableCell align='center'>
                  <Box className='action-buttons'>
                    <ModeEditOutlineOutlinedIcon
                      fontSize='medium'
                      color='info'
                      onClick={() => {
                        props.onShowSave();
                        setEditingDevices([...editingDevices, row]);
                      }}
                    ></ModeEditOutlineOutlinedIcon>
                    <IndeterminateCheckBoxIcon
                      fontSize='medium'
                      sx={{ color: red[500] }}
                      onClick={() => {
                        setOpenCancel(true);
                        setGetDeviceId(row.deviceId);
                      }}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
    </Box>

  );
}

const validationSchema = yup.object({
  device: yup.string().required("Required"),
  deviceDescription: yup.string().required("Required"),
  deviceRole: yup.string().required("Required"),
});

const resetDateValues = [
  {
    id: 1,
    value: "True",
  },
  {
    id: 2,
    value: "False",
  },
];

function AddDeviceForm(props) {
  const { setIsAddDeviceSave, isAddDeviceSave, onAdd, sitesData } = props;
  const [errors, setErrors] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      device: null,
      deviceDescription: null,
      deviceRole: null,
      resetDate: false,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });



  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value, false);
  };

  useEffect(() => {
    if (isAddDeviceSave)
      formik.validateForm().then((result) => {
        setErrors({ ...result });
        setIsAddDeviceSave(!isAddDeviceSave);
        if (Object.keys(result).length === 0) {
          onAdd(formik.values);
        }
      });

  }, [isAddDeviceSave]);

  return (
    <StyledTableRow>
      <StyledTableCell>{""}</StyledTableCell>

      <StyledTableCell>
        <InputComponent
          name={"device"}
          disabled={false}
          width={"100%"}
          onChange={handleChange}
          error={errors?.device}
        />
      </StyledTableCell>
      <StyledTableCell>
        <InputComponent
          name={"deviceDescription"}
          disabled={false}
          width={"100%"}
          onChange={handleChange}
          error={errors?.deviceDescription}
        />
      </StyledTableCell>
      <StyledTableCell>
        <SelectComponent
          width='100%'
          name={"deviceRole"}
          values={sitesData}
          disabled={false}
          selectedvalue={formik.values.deviceRole}
          parentselectcallback={(val) => {

            formik.setFieldValue("deviceRole", val, false);
          }}
          error={errors?.deviceRole}
        />
      </StyledTableCell>
      <StyledTableCell className="dropdown-deivces">
        <SelectComponent
          width='100%'
          name={"resetDate"}
          values={resetDateValues}
          disabled={false}
          selectedvalue={formik.values.resetDate}
          parentselectcallback={(val) => formik.setFieldValue("resetDate", val)}
          error={errors?.resetDate}
        />
      </StyledTableCell>
      <StyledTableCell>{""}</StyledTableCell>
      <StyledTableCell>{""}</StyledTableCell>
      <StyledTableCell>{""}</StyledTableCell>
      <StyledTableCell>{""}</StyledTableCell><StyledTableCell>{""}</StyledTableCell>
    </StyledTableRow>
  );
}
