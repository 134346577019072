import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box, TableSortLabel, IconButton, Typography, Stack } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ButtonComponent, DatePickerComponent, InputComponent, SelectComponent } from '.';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { downloadDocumentRecord } from '../../../services/WorkOrderServices';
import Upload from '../ImageUpload';
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { ConfirmDialog } from "../ConfirmDialog";
import { UserContext } from '../../../store/UserContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material'
import '../index.css'




export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        textOverflow: 'ellipsis',
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        height: '20px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    },
    '&:last-child td, &:last-child th': {
        BorderBottom: 0,
    },
    "& td:first-of-type, & th:first-of-type": {
        borderLeft: '5px solid #006CEC !important',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px'
    },
}));


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        {headCell.field !== "buttons" &&
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {t(headCell.headerName)}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export default function WoDocumentsTableComponent(props) {
    const { t } = useTranslation();
    const { clickedWorkOrder, columns, data, setPage, page, rowsPerPage, setRowsPerPage,
        saveRecord, deleteRecord, formik, errors, setErrors,
        addRecord, setAddRecord, dateDefaultValue, setAttachments, loading, type, setType,
        fileName, setFileName } = props;
    const { screenSize, appConstants, largeScreens } = useContext(UserContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('task');
    const [getDocId, setGetDocId] = useState(null);
    const [openDeleteCnfrm, setOpenDeleteCnfrm] = React.useState(false);
    const isWorkOrderDisabled = clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6;
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [data, order, orderBy, page, rowsPerPage]
    );
    const handleChange = (e) => {
        if (typeof e === 'number') {
            const selectedvalue = typeDropdownValues.find(dropdown => dropdown.id === e);
            formik.setFieldValue("type", selectedvalue?.value, false);
        } else {
            formik.setFieldValue(e.target.name, e.target.value, false);
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const downloadDocument = async (name) => {
        const trimmedFileName = name.trim();
        const encodedFileName = encodeURIComponent(trimmedFileName);
        await downloadDocumentRecord(encodedFileName);
    }
    const handleFileUpload = (file) => {
        const updatedFile = new File([file], file?.name, { type: file?.type });
        setAttachments([...[updatedFile]]);
        setFileName(file?.name);
        formik.setFieldValue("documentName", file?.name, false)
    }

    const dateFormat = (date) => {
        const dateDefaultValue = dayjs(date);
        return dateDefaultValue.format('MM-DD-YYYY');
    }

    const typeDropdownValues = [
        { id: 1, value: t("MAINTENANCE_SUMMARY") },
        { id: 2, value: t("INSPECTION_REPORT") },
        { id: 3, value: t("THIRD_PARTY_SERVICE_TICKETS") },
        { id: 4, value: t("PARTS_LIST") },
        { id: 5, value: t("PHOTOS") },
        { id: 6, value: t("MATERIAL_CERT") },
        { id: 7, value: t("ENGINEERING_WORK_PROCEDURE") },
    ];

    const onClose = () => {
        setErrors({})
        setAddRecord(false)
    }

    return (
        <Box>
            {loading &&
                <div className="loading">Loading&#8230;</div>
            }
            <ConfirmDialog sx={{ width: 600 }} openConfirm={openDeleteCnfrm}
                hidebuttons={false}
                msg={`Are you sure?`}
                accept={() => { deleteRecord(getDocId); setOpenDeleteCnfrm(false); }}
                reject={() => { setOpenDeleteCnfrm(false); }}
            />
            {/* Web and Tablet UI */}
            <Box >
                <TableContainer sx={{ width: screenSize?.dynamicWidth < appConstants.MOBILE_RESOLUTION ? window.innerWidth : "100%", transition: "height 100ms ease-in-out", maxHeight: props.tableHeight }} className='wo-doc-table-container'>
                    <Table sx={{
                        minWidth: 700,
                        '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                        '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                            color: "black"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                            color: "black"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                            color: "black"
                        },
                        "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                            color: "black", opacity: 100
                        },
                    }} stickyHeader size="small" aria-label="sticky table">

                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={columns}
                        />
                        <TableBody className='add-documents'>

                            {(
                                (addRecord || data?.length === 0) &&
                                screenSize?.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION &&
                                !isWorkOrderDisabled
                            ) && <StyledTableRow >
                                    <StyledTableCell align='center' className='wo-doc-tablecell1' >
                                        <SelectComponent
                                            values={typeDropdownValues}
                                            name={"type"}
                                            selectedvalue={type}
                                            disabled={false}
                                            width={"100%"}

                                            error={errors?.type}
                                            parentselectcallback={(e) => {
                                                setType(e);
                                                handleChange(e);
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align='center' className='wo-doc-tablecell2'>
                                        <Box display='flex' alignItems='center' sx={{ gap: 1 }}>
                                            <Upload clickedWorkOrder={clickedWorkOrder} accept="*/*" fileName={fileName} onUpload={(file) => handleFileUpload(file)}
                                            />
                                            <InputComponent
                                                name={"documentName"}
                                                value={fileName}
                                                onChange={(e) => {
                                                    setFileName(e.target.value)
                                                    formik.setFieldValue(e.target.name, e.target.value, false)
                                                }}
                                                width={"100%"}
                                                maxLength={"250"}
                                                error={errors?.documentName} />
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' className='wo-doc-tablecell2'>
                                        <InputComponent
                                            name={"description"}
                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                            width={"100%"}
                                            maxLength={"250"}
                                            error={errors?.description} />
                                    </StyledTableCell>
                                    <StyledTableCell align='left' className='wo-doc-tablecell1'>
                                        <InputComponent
                                            name={"modifiedBy"}
                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                            disabled={true}
                                            value={formik?.values.modifiedBy}
                                            width={"100%"}
                                            maxLength={"250"}
                                            error={errors?.modifiedBy} />
                                    </StyledTableCell>
                                    <StyledTableCell className='wo-doc-tablecell1'>
                                        <DatePickerComponent
                                            disabled={true}
                                            name={"modifiedDate"}
                                            dateDefaultValue={dateDefaultValue}
                                            onDateSelect={(date) => formik.setFieldValue("modifiedDate", date, false)}
                                            error={errors?.modifiedDate}
                                            value={formik?.values.modifiedDate} />
                                    </StyledTableCell>
                                    <StyledTableCell className='broadcasttablecell3' align='center'>
                                        <Stack direction={'row'} gap={1} marginRight={'-16px'}>
                                            <IconButton
                                                onClick={saveRecord}
                                                color='rgba(0, 0, 0, 0.54)'
                                                disabled={((clickedWorkOrder?.statusType === 4) || (clickedWorkOrder?.statusType === 6)) ? true : false}
                                            >
                                                <SaveIcon />

                                            </IconButton>
                                            <IconButton

                                                onClick={() => {
                                                    setErrors({})
                                                    setAddRecord(false)
                                                }}
                                                color='rgba(0, 0, 0, 0.54)'
                                                disabled={((clickedWorkOrder?.statusType === 4) || (clickedWorkOrder?.statusType === 6)) ? true : false}
                                            >
                                                <CancelIcon />
                                            </IconButton>

                                        </Stack >
                                    </StyledTableCell>
                                </StyledTableRow>}

                            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <StyledTableRow key={row.documentId}  >
                                    <StyledTableCell >{row.type}</StyledTableCell >
                                    <StyledTableCell>

                                        <Box display='flex' alignItems='center' sx={{ gap: 1 }}>
                                            {largeScreens ?
                                                <Box className='wo-doc-download-button-pc'>
                                                    <ButtonComponent
                                                        buttoncallBack={() => downloadDocument(row.documentName)}
                                                        icon={false}
                                                        label={t("WO_DOCUMENTS_DOWNLOAD")}
                                                        disabled={false}
                                                    />
                                                </Box>
                                                :
                                                <Box className='wo-doc-download-button'>
                                                    <IconButton sx={{ color: '#2f80ed' }} onClick={() => downloadDocument(row.documentName)}>
                                                        <SaveAltIcon />
                                                    </IconButton>
                                                </Box>
                                            }
                                            <Typography>
                                                {`${row.type}, ${clickedWorkOrder.assetNumber.slice(3)}, ${dateFormat(row.createdDate)}, ${row.documentName} `}
                                            </Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.description}</StyledTableCell>
                                    <StyledTableCell>{row.modifiedBy}</StyledTableCell>
                                    <StyledTableCell>{dateFormat(row.modifiedDate)}</StyledTableCell>
                                    <StyledTableCell className="action-button-cell" align='center'>
                                        <Stack direction={'row'} gap={1} >
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    color: (clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6) ? 'disabled' : 'rgba(0, 0, 0, 0.54)',
                                                    pointerEvents: (clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6) ? "none" : "pointer",
                                                    cursor: (clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6) ? "not-allowed" : "pointer"
                                                }}
                                                color='primary'
                                                onClick={() => setAddRecord(true)} />

                                            <RemoveCircleOutlineIcon
                                                sx={{
                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                    pointerEvents: (clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6) ? "none" : "pointer",
                                                    cursor: (clickedWorkOrder?.statusType === 4 || clickedWorkOrder?.statusType === 6) ? "not-allowed" : "pointer"
                                                }}
                                                onClick={() => { setGetDocId(row.documentId); setOpenDeleteCnfrm(true); }} />
                                        </Stack>

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='customTablePagination'


                />
            </Box>
            {screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION &&
                <Dialog
                    open={addRecord}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Add Document"}
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px' }}>
                        <Box className='mobile-card-content-main'>
                            <Box className='mobile-cards-content'>
                                <Box className='card-content-wo'>
                                    <Card variant="outlined">
                                        <React.Fragment>
                                            <CardContent>
                                                {/* Container for Form Fields */}
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                                                    {/* Document Type Field */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                            {t('WO_DOCUMENTS_TYPE')}
                                                        </Typography>
                                                        <SelectComponent
                                                            values={typeDropdownValues}
                                                            name={"type"}
                                                            selectedvalue={type}
                                                            disabled={false}
                                                            width={"100%"}
                                                            error={errors?.type}
                                                            parentselectcallback={(e) => {
                                                                setType(e);
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </Box>

                                                    {/* Attachment File Name Field */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                            Attachment File Name
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", gap: '10px' }}>
                                                            <Upload accept="*/*" fileName={fileName} onUpload={(file) => handleFileUpload(file)} />
                                                            <InputComponent
                                                                name={"documentName"}
                                                                value={fileName}
                                                                onChange={(e) => {
                                                                    setFileName(e.target.value);
                                                                    formik.setFieldValue(e.target.name, e.target.value, false);
                                                                }}
                                                                width={"100%"}
                                                                error={errors?.documentName}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    {/* Description Field */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                            {t('WO_DOCUMENTS_DESCRIPTION')}
                                                        </Typography>
                                                        <InputComponent
                                                            name={"description"}
                                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                                            width={"100%"}
                                                            maxLength={"250"}
                                                            error={errors?.description}
                                                        />
                                                    </Box>

                                                    {/* Updated By Field */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                            {t('WO_DOCUMENTS_UPDATED_BY')}
                                                        </Typography>
                                                        <InputComponent
                                                            name={"modifiedBy"}
                                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                                            value={formik?.values.modifiedBy}
                                                            disabled={true}
                                                            width={"100%"}
                                                            maxLength={"250"}
                                                            error={errors?.modifiedBy}
                                                        />
                                                    </Box>

                                                    {/* Updated Date Field */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                            {t('WO_DOCUMENTS_UPDATED_DATE')}
                                                        </Typography>
                                                        <DatePickerComponent
                                                            disabled={true}
                                                            name={"modifiedDate"}
                                                            dateDefaultValue={dateDefaultValue}
                                                            onDateSelect={(date) => formik.setFieldValue("modifiedDate", date, false)}
                                                            error={errors?.modifiedDate}
                                                            value={formik?.values.modifiedDate}
                                                        />
                                                    </Box>

                                                </Box>
                                            </CardContent>
                                        </React.Fragment>
                                    </Card>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button variant='contained' color="error" onClick={onClose} autoFocus sx={{ color: '#FFFFFF' }}>
                            Cancel
                        </Button>
                        <Button variant='contained' color="success" onClick={saveRecord} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            }



        </Box >
    );
}