// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerCss {
  background: linear-gradient(90deg, #2a3547 6.83%, #2f80ed 81.92%);
}

.orgDropdown .MuiFormControl-root {
  margin: 0px;
}

.drawer-main .MuiPaper-root.MuiDrawer-paper {
  background-color: black;
}

.mainLayoutComponent .MuiPaper-root.MuiDrawer-paper .MuiTypography-root {
  color: white !important;
}

.MuiPaper-root.MuiDrawer-paper .MuiButtonBase-root.MuiListItemButton-root:hover {
  background: #2C2C2C;
}

.MuiPaper-root.MuiDrawer-paper .child-menu-list .MuiButtonBase-root.MuiListItemButton-root:hover {
  background: #5d5d5d;
}

.sideBarOverLayMobile {
  background: #00000042;
  z-index: 100;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/header/styles.css"],"names":[],"mappings":"AAAA;EACE,iEAAiE;AACnE;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".headerCss {\n  background: linear-gradient(90deg, #2a3547 6.83%, #2f80ed 81.92%);\n}\n\n.orgDropdown .MuiFormControl-root {\n  margin: 0px;\n}\n\n.drawer-main .MuiPaper-root.MuiDrawer-paper {\n  background-color: black;\n}\n\n.mainLayoutComponent .MuiPaper-root.MuiDrawer-paper .MuiTypography-root {\n  color: white !important;\n}\n\n.MuiPaper-root.MuiDrawer-paper .MuiButtonBase-root.MuiListItemButton-root:hover {\n  background: #2C2C2C;\n}\n\n.MuiPaper-root.MuiDrawer-paper .child-menu-list .MuiButtonBase-root.MuiListItemButton-root:hover {\n  background: #5d5d5d;\n}\n\n.sideBarOverLayMobile {\n  background: #00000042;\n  z-index: 100;\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
