import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from "@mui/material/Checkbox";
import { Box, useMediaQuery, useTheme, TablePagination } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import '../index.css'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {

    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

function formatDate(dateString) {
    const date = new Date(dateString);
  
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2,   
   '0');
    const year = date.getFullYear();
  
    return `${month}-${day}-${year}`;   
  
  }

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                <StyledTableCell>
                    Work Order
                </StyledTableCell>
                <StyledTableCell>
                    Start Date
                </StyledTableCell>
                <StyledTableCell>
                    Due Date
                </StyledTableCell>

            </TableRow>
        </TableHead>
    );
}


export default function AxDashboardTable(props) {

    const { t } = useTranslation();
    const { columns, data, handleChangePage, handleChangeRowsPerPage, rowCount, page, rowsPerPage } = props;

    const navigate = useNavigate();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('assetNumber');
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(data?.content, getComparator(order, orderBy)),
        [data?.content, order, orderBy, page, rowsPerPage]
    );

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));

    function formatDate(timestamp) {
        if (timestamp === null || timestamp === 0) {
            return "-";
        } else {
            // Split the input date string into day, month, and year
            const [day, month, year] = timestamp.split("/");

            // Return the date in mm/dd/yyyy format
            return `${month}/${day}/${year}`;
        }
    }


    return (
        <Box>
            {/* Web and Tablet UI */}
            <Box>
                <Box className='homePagePagination'>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component='div'
                        count={rowCount ? rowCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='customTablePagination'


                    />
                </Box>
            </Box>
            <TableContainer className='attachments-table' sx={{ maxHeight: '491px' }}>
                <Table sx={{

                    '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "#000000 !important" },
                    '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                    "& .css-cokgfw-MuiTableCell-root.MuiTableCell-head": { width: "50px !important" },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                        color: "black"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                        color: "black"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                        color: "white"
                    },
                    "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                        color: "white", opacity: 100
                    }
                }} stickyHeader size="small" aria-label="sticky table">

                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={columns}
                    />
                    <TableBody>
                        {(data).map((row, index) => (
                            <StyledTableRow key={index}  >
                                <StyledTableCell sx={{ '&.MuiTableCell-root': { padding: " 6px 10px !important" } }} onClick={() => { navigate('/workorders/details', { state: row.workOrderId }); localStorage.setItem('woNumber', JSON.stringify(row.workOrderId)) }}><Link >{row.workOrderId}</Link></StyledTableCell>
                                <StyledTableCell sx={{ '&.MuiTableCell-root': { padding: " 6px 10px !important" } }} >{formatDate(row?.scheduledStartDate)}</StyledTableCell>
                                <StyledTableCell sx={{ '&.MuiTableCell-root': { padding: " 6px 10px !important" } }}>{formatDate(row?.scheduledCompletionDate)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}