// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-buttons svg {
  cursor: pointer;
}

/* 
.MuiTableCell-root {
  padding: 8px 16px !important;
} */

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}
.MuiTableHead-root {
  height: 43px !important;
}
.checkbox-cell {
  width: 4%;
}

.checkbox-cell1 {
  width: 10%;
}
.checkbox-cell2 {
  width: 2%;
}
.checkbox-cell3 {
  width: 8%;
}
::-webkit-scrollbar-track {
  background-color: #E2E4E8;
}

::-webkit-scrollbar-thumb {
  background-color: #999EA8;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;;;GAGG;;AAEH;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,uBAAuB;AACzB;AACA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;AACA;EACE,SAAS;AACX;AACA;EACE,SAAS;AACX;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".action-buttons svg {\n  cursor: pointer;\n}\n\n/* \n.MuiTableCell-root {\n  padding: 8px 16px !important;\n} */\n\n::-webkit-scrollbar {\n  width: 0.4em;\n  height: 0.4em;\n}\n.MuiTableHead-root {\n  height: 43px !important;\n}\n.checkbox-cell {\n  width: 4%;\n}\n\n.checkbox-cell1 {\n  width: 10%;\n}\n.checkbox-cell2 {\n  width: 2%;\n}\n.checkbox-cell3 {\n  width: 8%;\n}\n::-webkit-scrollbar-track {\n  background-color: #E2E4E8;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #999EA8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
