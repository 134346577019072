import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';



export const getAssetsBySearch = async (token, page, size, site, keyword, manufacturersSerialNumber, parent, userId, sortBy, parameter) => {
    try {
        let fetchData = [];
        if (userId) {
            fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSETS_BY_SEARCH_SORTING, { params: { page: page, size: size, site: site, keyword: keyword, manufacturersSerialNumber: manufacturersSerialNumber, parent: parent, userId: userId, sort: sortBy, parameter: parameter } }).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAssetsByParent = async (token, parent) => {
    try {
        let fetchData = [];
        if (parent) {
            fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSETS_BY_PARENT, { params: { parent: parent } }).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAssetDetailsByAssetNumber = async (token, assetNumber, organizationId) => {
    try {
        let fetchData = []
        fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSET_DETAILS_ASSET_NUMBER, { params: { assetNumber: assetNumber, organizationId: organizationId } }).then(res => {
            return res?.data;
        });

        return fetchData;
    } catch (error) {
        console.log(error);
    }
}


export const getAllAssets = async (token, page, size) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ALL_ASSETS + '?page=' + page + '&size=' + size).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getAssetConfigurationsByAssetNumber = async (token, serialNumber, organizationId) => {
    try {
        let fetchData = [];
        if (serialNumber) {
            fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSET_CoNFIG, { params: { assetNumber: serialNumber, organizationId: organizationId } }).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}


export const getAssetHierarchyByAssetNumber = async (token, assetNumber) => {
    try {
        let fetchData = [];
        if (assetNumber) {
            fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSET_HIERARCHY + assetNumber).then(res => {
                return res?.data;
            });
        }
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getAssetConfigByAttribute = async (token, assetAttribute, assetGroup) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.MASTER.GET_ASSET_CONFIGURATIONS, { params: { attributeCategory: assetAttribute, assetGroup: assetGroup } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateAssetConfig = async (token, _data) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.ASSETS.UPDATE_ASSET_CONFIG, _data).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}
export const getAssetAttachments = async (token, assetNumber) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ASSETS.GET_ASSET_ATTACHMENTS, { params: { assetNumber: assetNumber } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const getUpdateRfidByAssetNumber = async (token, assetNumber, organizationId, assetsId, rfid) => {
    try {
        const fetchData = await axios.get(APIENPOINTS.ASSETS.UPDATE_RFID_BY_ASSETNUMBER, { params: { assetNumber: assetNumber, organizationId: organizationId, assetsId: assetsId, rfid: rfid } }).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}