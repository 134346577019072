import React from "react";
import { Alert, Snackbar } from "@mui/material";


const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ToastMessage(props) {
    const { toast } = props;
    return (
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={toast.onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <CustomAlert onClose={toast.onClose} severity={toast.type} sx={{ width: '100%' }}>
                {toast.message}
            </CustomAlert>
        </Snackbar>
    )
}