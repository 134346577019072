import axios from 'axios';
import { APIENPOINTS } from '../components/constants/APIEndpoints';
export const getAllSites = async (page = 0, size = 10) => {
    try {
        const fetchData = await axios.get(`${APIENPOINTS.ADMIN.SITES.GET_ALL_SITES}?page=${page}&size=${size}`).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

export const updateSites = async (token, _reqBody) => {
    try {
        const fetchData = await axios.post(APIENPOINTS.ADMIN.SITES.PUT_UPDATE_SITES, _reqBody).then(res => {
            return res?.data;
        });
        return fetchData;
    } catch (error) {
        console.log(error);
    }
}

