import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { red } from "@mui/material/colors";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { InputComponent, SelectComponent } from ".";
import { deleteRole, getAllPermissons } from "../../../services/AdminSecurityServices";
import ToastMessage from "../ToastMessage";
import { Delete } from "@mui/icons-material";
import { TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../ConfirmDialog";


const roleTableColumns = [
  {
    field: "roleName",
    headerName: "ROLE_ID",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "roleDesc",
    headerName: "ROLE_DESCRIPTION",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "delete",
    headerName: "",
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}

          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, setSelectedRole, setOpenConfirm, loading } = props;
  const [currentRow, setCurrentRow] = React.useState({ ...row });
  const [permissions, setPermissions] = useState([...props.permissions]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = useState({});

  useEffect(() => {
    setCurrentRow({ ...row });
    setPermissions([...props.permissions])
    setOpen(false);
  }, [row.roleId])

  useEffect(() => {
    if (open)
      props.setEditedRoleId(row.roleId);
  }, [open])

  useEffect(() => {
    if (props.sendEditedData && props.editedRoleId === row.roleId) {
      let dataToSave = {
        roleId: row.roleId,
        permissionIdsToAdd: [...permissions.map(x => x.permId).filter(x => x !== 0)],
        permissionIdsToDelete: [...row.permissions.map(x => {
          if (!permissions.find(cp => cp.permId === x.permId)) {
            return x.permId;
          }
        }).filter(x => x !== undefined)]
      }
      props.saveEditedData(dataToSave);
      props.setSendEditedData(false);
      props.setShowSaveButtonForEdit(false);
      setOpen(false);
    }
  }, [props.sendEditedData])

  const handleChange = (_perm, _index) => {
    let _permissions = [...permissions];
    const temp = { ..._permissions[_index] };
    temp.permId = _perm;
    temp.permName = props.allPermissions.find(x => x.permId == _perm)?.permName;
    temp.permDesc = props.allPermissions.find(x => x.permId == _perm)?.description;
    _permissions[_index] = { ...temp };
    setPermissions([..._permissions]);
    if (row.permissions.length !== _permissions.length) {
      props.setShowSaveButtonForEdit(true);
      return;
    }
    else {
      for (let i = 0; i < _permissions.length; i++) {
        if (row.permissions.find(x => x.permId == _permissions[i].permId)) {
          props.setShowSaveButtonForEdit(false);
        } else {
          props.setShowSaveButtonForEdit(true);
          return;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow
        className='admin-security-roles-table-row'
        sx={{
          "& > *": { borderBottom: open ? "inherit" : "unset" }, background: open ? "#F3F4F5" : "white", borderBottom: open ? "1px solid rgba(224, 224, 224, 1)" : "none",

        }}
      >
        <TableCell component='th' scope='row' sx={{
          borderLeft: '5px solid #006CEC !important', borderBottom: '0', borderBottomLeftRadius: '5px',
          borderTopLeftRadius: '5px'
        }}>
          {currentRow.roleName}
        </TableCell>
        <TableCell align='right'>{currentRow.roleDesc}</TableCell>
        <TableCell sx={{ display: "flex" }} >
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => {
            setSelectedRole(currentRow); setOpenConfirm(true);
          }}>
            <Delete />
          </IconButton>
          <IconButton disabled={loading} aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, background: open ? "#F3F4F5" : "white", }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box className='collapsable-table-box-main'>
              <Box className='collapsable-table-box-sub'>
                <Box className='roles-grid-main'>
                  <Table className='admin-security-roles-table' size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('PERMISSION_LIST')}</TableCell>
                        <TableCell>{t('PERMISSION_DESCRIPTION')}</TableCell>
                        <TableCell align='right'>{t('ACTIONS')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {permissions.map((rowPer, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component='th'
                            scope='row'
                            className='table-cell-with-dropdown'
                          >
                            <SelectComponent
                              label=''
                              values={props.allPermissions}
                              selectedvalue={rowPer.permId}
                              disabled={false}
                              width='100%'
                              parentselectcallback={(_perm) => handleChange(_perm, index)}
                            ></SelectComponent>
                          </TableCell>
                          <TableCell>{rowPer.permDesc}</TableCell>
                          <TableCell  className="action-button-cell" align='center'>
                            <Box className='action-buttons'>
                              <IconButton
                                disabled={loading || rowPer.id === 0}
                                onClick={() => {
                                  let tempPermissions = [...permissions];
                                  tempPermissions = [{ ...props.allPermissions[0] }, ...permissions];
                                  setPermissions([...tempPermissions]);
                                }}
                                color={rowPer.id !== 0 ? 'primary' : 'disabled'}
                              >
                                <AddBoxIcon />
                              </IconButton>
                              <IconButton
                                disabled={loading || permissions.length === 1}
                                onClick={() => {
                                  let tempPermissions = [...permissions];
                                  tempPermissions.splice(index, 1);
                                  setPermissions([...tempPermissions]);
                                  if (tempPermissions.length !== row.permissions.length) {
                                    props.setShowSaveButtonForEdit(true);
                                  } else {
                                    for (let i = 0; i < tempPermissions.length; i++) {
                                      if (row.permissions.find(x => x.permId === tempPermissions[i].permId)) {
                                        props.setShowSaveButtonForEdit(false);
                                      } else {
                                        props.setShowSaveButtonForEdit(true);
                                        return;
                                      }
                                    }
                                  }
                                }}
                                sx={permissions.length !== 1 ? { color: red[500] } : null}
                              >
                                <IndeterminateCheckBoxIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ToastMessage toast={toast} />
    </React.Fragment >
  );
}

export default function AxSecurityRolesTableComponent(props) {
  const { rolesData, showNewRole, totalElements } = props;
  const [currentPageData, setCurrentPageData] = useState([...rolesData.slice(0, 10)]);
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [roleError, setRoleError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [allPermissions, setAllPermissions] = useState<any>([]);
  const [selectedPermissions, setSelectedPermission] = useState<any>([]);
  const [editedRoleId, setEditedRoleId] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const [toast, setToast] = useState({});

  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('userId');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(currentPageData || [], getComparator(order, orderBy)),
    [currentPageData, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/

  useEffect(() => {
    setCurrentPageData([...rolesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [rolesData]);

  useEffect(() => {
    setCurrentPageData([...rolesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [page, rowsPerPage])

  const fetchAllPermissions = () => {
    setLoading(true);
    getAllPermissons()
      .then(response => {
        if (response?.statusCode === "200") {
          response.data.forEach(x => {
            x.id = x.permId;
            x.value = x.permName;
          });
          response.data.unshift({ id: 0, value: 'Select Permission', permName: 'Select Permission' });
          setAllPermissions(response?.data);
          setSelectedPermission([{ id: 0, value: 'Select Permission', permName: 'Select Permission' }]);
          setLoading(false);
        }
      })
      .catch(err => console.log(err));
    setLoading(false);
  }

  useEffect(() => {
    fetchAllPermissions();
  }, [])

  const handleSelectPermission = (_permName, _index) => {
    let tempSelectedPermissions = selectedPermissions;
    let permDetails = allPermissions.find(x => x.id === _permName);
    tempSelectedPermissions[_index].id = permDetails.id;
    tempSelectedPermissions[_index].value = permDetails.value;
    tempSelectedPermissions[_index].description = permDetails.description;
    setSelectedPermission([...tempSelectedPermissions]);
  }



  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  };

  // Validation

  const checkIsExist = (list, val) => {
    return list.find(x => x === val);
  }

  const validateData = () => {
    const regEx = /^[0-9a-zA-Z ]+$/;
    let valid = false;
    if (checkIsExist(rolesData.map(x => x.roleName), role)) {
      setRoleError("Entered role name already exists");
      valid = false;
    }
    else {
      setRoleError(null);
      valid = true;
    }

    if (checkIsExist(rolesData.map(x => x.roleDesc), description)) {
      setDescriptionError("Entered description already exists");
      valid = false;
    }
    else {
      setDescriptionError(null);
      valid = true;
    }
    props.setSendData(false);
    return valid;
  }

  const validateRolePermissions = () => {
    let valid = false;
    let tempRolesData = [...rolesData];
    let currentSelectedPermissions = selectedPermissions.filter(x => x.id !== 0);
    if (currentSelectedPermissions.length === 0) {
      const toastProps = { open: true, type: "error", message: "Selected atleast one permissions", onClose: () => setToast({}) };
      setToast(toastProps);
      props.setSendData(false);

      return valid;
    }
    else {

      valid = true;
    }

    return valid;
  }

  useEffect(() => {
    if (props.sendData) {
      if (validateData() && validateRolePermissions()) {
        let dataToSave = {
          roleName: role,
          roleDescription: description,
          permissionIds: [...selectedPermissions.filter(x => x.id !== 0).map(x => x.id)],
          createdBy: '',
          updatedBy: ''
        }
        props.saveData(dataToSave);
        setRole('');
        setDescription('');
        setSelectedPermission([{ id: 0 }]);
      }
    }
  }, [props.sendData])

  useEffect(() => {
    if (showNewRole) {
      setRole("");
      setDescription("");
    }
  }, [showNewRole])

  const deleteSelectedRole = (_role) => {
    setLoading(true);
    deleteRole(1, _role)

      .then(res => {
        if (res.statusCode == 200) {
          setPage(0);
          setOpenConfirm(false);
          props.setRefresh(!props.refresh);
          const toastProps = { open: true, type: "success", message: res.message, onClose: () => setToast({}) };
          setToast(toastProps);
          setLoading(false);
        }
        else {
          const toastProps = { open: true, type: "error", message: res.message, onClose: () => setToast({}) };
          setToast(toastProps);
          setLoading(false);
        }
      }).catch((err) => {
        const toastProps = { open: true, type: "error", message: "Some error occured. Please contact admin.", onClose: () => setToast({}) };
        setToast(toastProps);
        setLoading(false);
      })
  }

  return (
    <Box>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }
      <ToastMessage toast={toast} />
      <TableContainer className="table-container-roles">

        <Table stickyHeader className='admin-roles-main-table'
          sx={{
            "& th": { padding: "0px 16px", textAlign: "left" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          aria-label='collapsible table'>

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={roleTableColumns}
          />
          <TableBody>
            {showNewRole && (
              <>
                <TableRow key={"addnewuser"}>
                  <TableCell>
                    <InputComponent
                      label={""}
                      value={role}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => setRole(e.target.value)}
                      error={roleError}
                    ></InputComponent>
                  </TableCell>
                  <TableCell>
                    <InputComponent
                      label={""}
                      value={description}
                      disabled={false}
                      width={"100%"}
                      onChange={(e) => setDescription(e.target.value)}
                      error={descriptionError}
                    ></InputComponent>
                  </TableCell>

                  <TableCell align='center' className='table-cell-with-dropdown'></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0, background: true ? "#F3F4F5" : "white", }} colSpan={12}>
                    <Collapse in={true} timeout='auto' unmountOnExit>
                      <Box className='collapsable-table-box-main'>
                        <Box className='collapsable-table-box-sub'>
                          <Box className='roles-grid-main'>
                            <Table className='admin-security-roles-table' size='small' aria-label='purchases'>
                              <TableHead sx={{ whiteSpace: 'nowrap' }}>
                                <TableRow>
                                  <TableCell>{t('PERMISSION_LIST')}</TableCell>
                                  <TableCell>{t('PERMISSION_DESCRIPTION')}</TableCell>
                                  <TableCell align='right'>{t('ACTIONS')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedPermissions.map((rowPer, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                      className='table-cell-with-dropdown'
                                    >
                                      <SelectComponent
                                        label=''
                                        values={allPermissions}
                                        selectedvalue={rowPer.id}
                                        disabled={false}
                                        width='100%'
                                        parentselectcallback={(e) => handleSelectPermission(e, index)}
                                      ></SelectComponent>
                                    </TableCell>
                                    <TableCell>{rowPer.description}</TableCell>
                                    <TableCell className="action-button-cell" align='center'>
                                      <Box className='action-buttons'>
                                        <IconButton disabled={loading || rowPer.id === 0}>
                                          <AddBoxIcon onClick={() => {
                                            setSelectedPermission([{ ...allPermissions[0] }, ...selectedPermissions])
                                          }}
                                            color={rowPer.id !== 0 ? 'primary' : 'disabled'}
                                          ></AddBoxIcon>
                                        </IconButton>
                                        <IconButton disabled={loading || selectedPermissions.length === 1}>
                                          <IndeterminateCheckBoxIcon onClick={() => {
                                            let tempSelectedPermissions = selectedPermissions;
                                            tempSelectedPermissions.splice(index, 1);
                                            setSelectedPermission([...tempSelectedPermissions]);
                                          }}
                                            sx={selectedPermissions.length !== 1 ? { color: red[500] } : null}
                                          ></IndeterminateCheckBoxIcon>
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            )}
            {visibleRows?.map((row, index) => (
              <Row
                key={index}
                row={row}
                permissions={row.permissions}
                allPermissions={allPermissions}
                setEditedRoleId={setEditedRoleId}
                setOpenConfirm={(e) => setOpenConfirm(e)}
                setSelectedRole={(e) => setSelectedRole(e)}
                editedRoleId={editedRoleId}
                setShowSaveButtonForEdit={props.setShowSaveButtonForEdit}
                sendEditedData={props.sendEditedData}
                setSendEditedData={props.setSendEditedData}
                saveEditedData={props.saveEditedData}
                rolesData={rolesData}
                refresh={props.refresh}
                setRefresh={props.setRefresh}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
      <ConfirmDialog openConfirm={openConfirm}
        hidebuttons={true}
        msg={`Do you want to delete ${selectedRole?.roleName}?`}
        accept={() => {
          deleteSelectedRole(selectedRole?.roleId);
        }}
        reject={() => { setOpenConfirm(false); }}
      />
    </Box>

  );
}
