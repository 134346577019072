import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { useState, useContext } from 'react';
import { SelectComponent, ButtonComponent } from '../shared-components/common-field-components';
import { UserContext } from '../../store/UserContext';
import { updateSites } from '../../services/AdminSitesServices';
import ToastMessage from '../shared-components/ToastMessage';
import { t } from "i18next";

export default function MobileTableCardComponentSites(props) {

  const { siteData } = props;
  const { oktaAuth } = useContext(UserContext);
  const accessToken = oktaAuth?.oktaAuth?.getAccessToken();
  const [isEditing, setIsEditing] = useState(false);
  const [updatedSite, setUpdatedSite] = useState(siteData);
  const [toast, setToast] = useState({});

  const saveSiteData = () => {
    updateSites(accessToken, [updatedSite]).then(response => {
      if (response.statusCode == 200) {
        const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
        setToast(toastProps);
      }
      else {
        const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
        setToast(toastProps);
      }
    }).catch(() => {
      const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
      setToast(toastProps);
    });
    setIsEditing(!isEditing)
  }

  return (
    <Box className='card-content-main'>
      <Box className='card-content'>
        <Card sx={{ minWidth: 275 }} variant="outlined">
          <React.Fragment>
            <CardContent>
              <div className="lists-container">
                <div>
                  <dl>
                    <>
                      <>
                        <dt >
                          {t('SITES')}
                        </dt>

                        <dd>{siteData.orgCode}</dd>
                      </>

                      <>
                        <dt>
                          {t('OPERATING_UNIT')}
                        </dt>

                        <dd>{siteData.operatingUnit}</dd>

                      </>
                      <>
                        <dt>
                          {t('DESCRIPTION')}
                        </dt>

                        <dd>{siteData.orgName}</dd>

                      </>
                      <>
                        <dt>
                          {t('LOCATION')}
                        </dt>

                        <dd>{siteData.location}</dd>

                      </>
                      <>
                        <dt>
                          {t('IS_OPERATING_UNIT')}
                        </dt>

                        <dd>{!isEditing ? siteData.isOperatingUnit : <SelectComponent
                          values={[{ id: 1, value: "Yes" }, { id: 2, value: "No" }]}
                          selectedvalue={siteData.isOperatingUnit === "No" ? 2 : 1}
                          disabled={false}
                          width={"100%"}
                          parentselectcallback={(_val) => {
                            _val = _val === 1 ? "Yes" : "No";
                            setUpdatedSite({ ...updatedSite, isOperatingUnit: _val });
                          }}
                        />}</dd>

                      </>
                      <>
                        <dt>
                          {t('IS_INVENTORY_ORG')}
                        </dt>

                        <dd>{!isEditing ? siteData.isInventoryOrg : <SelectComponent
                          values={[{ id: 1, value: "Yes" }, { id: 2, value: "No" }]}
                          selectedvalue={siteData.isInventoryOrg === "No" ? 2 : 1}
                          disabled={false}
                          width={"100%"}
                          parentselectcallback={(_val) => {
                            _val = _val === 1 ? "Yes" : "No";
                            setUpdatedSite({ ...updatedSite, isInventoryOrg: _val });
                          }}
                        />}</dd>

                      </>
                      <>
                        <dt>
                          {t('ACTIVE')}
                        </dt>

                        <dd>{!isEditing ? siteData.active : <SelectComponent
                          values={[{ id: 1, value: "True" }, { id: 2, value: "False" }]}
                          selectedvalue={siteData.active === "f" ? 2 : 1}
                          disabled={false}
                          width={"100%"}
                          parentselectcallback={(_val) => {
                            _val = _val === 1 ? "True" : "False";
                            setUpdatedSite({ ...updatedSite, active: _val });
                          }}
                        />}</dd>

                      </>

                    </>
                  </dl>
                </div>

              </div>
            </CardContent>
            <CardActions className='card-actions'>
              {isEditing ? <ButtonComponent label={t("SAVE")} color={'#2BAA4F'}
                buttoncallBack={saveSiteData}
              ></ButtonComponent> :
                <Box className='edit-button'>
                  <Button size="small">
                    <IconButton onClick={() => setIsEditing(!isEditing)}>
                      <Edit />
                    </IconButton>
                  </Button>
                </Box>

              }
            </CardActions>
          </React.Fragment>
        </Card>
      </Box>
      <ToastMessage toast={toast} />
    </Box>

  );
}