import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


import { t } from "i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white", 
    color: '#2f80ed',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    width: "10%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {

  },
  "&:last-child td, &:last-child th": {
    border: 0,

  },
}));

export default function AxExecuteinventoriestable(props) {
  const { columns, data } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);



  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table sx={{ minWidth: 700 }} stickyHeader size='small' aria-label='sticky table'>
        <TableHead>
          <TableRow style={{ height: 10 }}>
            {columns?.map((column, index) => {
              return <StyledTableCell key={index}>{t(column.headerName)}</StyledTableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow >
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.VerifiedByScan}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.VerifiedManually}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.NotFound ? props.exInfoTableData?.assetSummary?.NotFound : 0}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.NotVerified}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.AddedInField ? props.exInfoTableData?.assetSummary?.AddedInField : 0}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.TotalAssetCount}</StyledTableCell>
            <StyledTableCell>{props.exInfoTableData?.assetSummary?.Completion + "%"}</StyledTableCell>

          </StyledTableRow>

        </TableBody>
      </Table>

    </TableContainer>
  );
}
