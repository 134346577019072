import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WorkordersOperationsTableComponent } from "../shared-components/common-field-components";
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import ToastMessage from "../shared-components/ToastMessage";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { getDepartmentsByOrgId, getOperationsByWipEntityId, deleteOperationById, updateActualFinish } from "../../services/WorkOrderServices";

export default function Operations(props) {
    const { clickedWorkOrder } = props;
    const { user, oktaAuth, smallScreens } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const [loading, setLoading] = useState(false);
    const [operationsData, setOperationsData] = useState();
    const [deptData, setDeptData] = useState();
    const [toast, setToast] = useState({});
    const [saveRecord, setSaveRecord] = useState(false);

    const [addRecord, setAddRecord] = useState(false);

    const WorkOrderOperationsTableColumns = [
        {
            field: "task",
            headerName: "Task",
            width: 100,
            headerClassName: "super-app-theme--header",
            disablePadding: false,
        },
        {
            field: "description",
            headerName: "Description",
            width: 100,
            headerClassName: "super-app-theme--header",
            disablePadding: false,
        },
        {
            field: "departmentCode",
            headerName: "Department",
            width: 100,
            headerClassName: "super-app-theme--header",
            disablePadding: false,
        },
        {
            field: "actualFinish",
            headerName: "Actual Finish",
            width: 100,
            headerClassName: "super-app-theme--header",
            disablePadding: false,
        },
        {
            field: "buttons",
            headerName: "",
            width: 100,
            headerClassName: "super-app-theme--header",
            disablePadding: true,
        },


    ];
    const saveOperationRecord = () => {
        setSaveRecord(!saveRecord);
    }

    const fetchData = React.useCallback(() => {
        setLoading(true);
        const response = Promise.all([getOperationsByWipEntityId(user?.token, 0, 10000, clickedWorkOrder?.wipEntityId, clickedWorkOrder?.assetActivity), getDepartmentsByOrgId(user?.token, clickedWorkOrder?.organizationId, user?.id)]);
        response.then(async (res) => {
            setLoading(false);
            await res[1]?.data?.unshift({ id: 0, value: 'Select Department' });
            await res[1]?.data?.forEach(x => {
                x.id = x.departmentId;
                x.value = x.departmentCode;
            });
            setDeptData([...res[1]?.data]);
            setOperationsData([...res[0]?.data?.content]?.sort((a, b) => a.task - b.task));
        })
    }, []);

    useEffect(() => {
        if (clickedWorkOrder?.wipEntityId) {
            fetchData();
        }
    }, [])

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            <Box>
                <Box className='homePageHeader' sx={{ padding: smallScreens ? '8px 0px' : "0px" }}>
                    <Typography variant={'h5'} flexGrow={1}>
                        Operations
                    </Typography>
                    <Stack direction={'row'} >
                        <IconButton sx={{ padding: smallScreens ? '0px' : 'auto' }} onClick={() => { setAddRecord(true); }}
                            disabled={((clickedWorkOrder?.statusType === 4) || (clickedWorkOrder?.statusType === 6)) ? true : loading}>
                            <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Stack>
                </Box>
                <Box className='homePageTable' mt={'10px'}>
                    {
                        operationsData && (
                            <WorkordersOperationsTableComponent
                                addRecord={addRecord}
                                setAddRecord={setAddRecord}
                                columns={WorkOrderOperationsTableColumns}
                                operationsData={operationsData}
                                deptData={deptData}
                                saveRecord={saveRecord}
                                setSaveRecord={setSaveRecord}
                                saveOperationRecord={saveOperationRecord}
                                addOperation={saveOperationRecord}
                                clickedWorkOrder={clickedWorkOrder}
                                getUpdatedOperationsData={fetchData}
                                updateActualFinish={(_data) => {
                                    setLoading(true);
                                    updateActualFinish(user?.token, _data, user?.id).then(res => {
                                        if (res?.statusCode === "200") {
                                            const toastProps = { open: true, type: "success", message: "Updated actual finish successfully", onClose: () => setToast({}) };
                                            setToast(toastProps);
                                            setLoading(false);
                                            fetchData();
                                        }
                                        else {
                                            const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                                            setToast(toastProps);
                                            setLoading(false);
                                        }
                                    })
                                }}
                                deleteOperationRecord={(operationId) => {
                                    deleteOperationById(user?.token, operationId).then((response) => {
                                        if (response?.statusCode === "200") {
                                            const toastProps = { open: true, type: "success", message: response?.message, onClose: () => setToast({}) };
                                            setToast(toastProps);
                                            fetchData();
                                        }
                                        else {
                                            const toastProps = { open: true, type: "error", message: "Delete Failed.", onClose: () => setToast({}) };
                                            setToast(toastProps);
                                        }
                                    }).catch((err) => {
                                        console.log("error", err);
                                    })
                                }}

                            />
                        )
                    }
                </Box>
            </Box>
            <ToastMessage toast={toast} />
        </Box>
    );
}