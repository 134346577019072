export const exportToExcel = async (url) => {
    try {
        const link = document.createElement('a');
        link.href = process.env.REACT_APP_AX_SERVICES_URL + url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    } catch (error) {
        console.log(error);
    }
}