import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { t } from "i18next";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AxDevicesMobileTableComponent(props) {
  const { columns, data } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { viewData, setViewData,  setEnableBackButton } = props;

  const viewTableRow = () => {
    setViewData(!viewData);
    setEnableBackButton(!viewData);
  }

  return (
    <Box>
      <TableContainer className='table-container-shared-mobile-device'>
        <Table
          sx={{

            "& .MuiTableCell-head": {
              backgroundColor: "#2A3547 !important",
              color: "white !important",
            },

          }}
          stickyHeader
          size='small'
          aria-label='sticky table'
        >
          <TableHead>
            <TableRow style={{ height: 10, background: "#2A3547" }}>
              {columns.map((column, index) => {
                return <StyledTableCell key={index}>{t(column.headerName)}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) && data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.device}</StyledTableCell>
                <StyledTableCell>{row.description}</StyledTableCell>
                <StyledTableCell>{row.deviceRole}</StyledTableCell>
                <StyledTableCell align='center' className="view-icon-cell">
                  <ArrowCircleRightOutlinedIcon fontSize="medium" onClick={viewTableRow}></ArrowCircleRightOutlinedIcon>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
