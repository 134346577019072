import { Box, Button, Drawer, IconButton, Stack, Toolbar, Typography, } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { DatePickerComponent, FailureAnalysisTableComponent, InputComponent, } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { getFailureAnalysis } from "../../services/ReportServices";
import { exportToExcel } from "../../services/ExcelExportService";
import IosShareIcon from '@mui/icons-material/IosShare';

const failureAnalysisColumns = [
    {
        field: "assetNumber",
        headerName: "Asset Number",
        width: 120,
        headerClassName: "super-app-theme--header MuiDataGrid-pinnedColumns--left-header",
    },
    {
        field: "failureCount",
        headerName: "Failure Count",
        width: 200,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "mtbf",
        headerName: "MTBF (Days)",
        width: 75,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "frequency",
        headerName: "Frequency",
        width: 75,
        headerClassName: "super-app-theme--header"
    },
    {
        field: "mttf",
        headerName: "MTTR (Hours)",
        width: 150,
        headerClassName: "super-app-theme--header",
    },
    {
        field: "meterReading",
        headerName: "Current Meter Reading",
        width: 75,
        headerClassName: "super-app-theme--header",
    }
];


export default function FailureAnalysis() {
    const { user, selectedSite, oktaAuth, smallScreens, largeScreens } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const [searchFilter, setSearchFilter] = useState({ assetNumber: '', assetGroup: '', failureCode: '', fromDate: '', toDate: '' })
    const [showFilterFields, setShowFilterFields] = useState(true);
    const [tableData, setTableData] = React.useState();
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const isClear = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchFailureData = async () => {
        setLoading(true);
        setTableData([]);
        const response = await getFailureAnalysis(page, rowsPerPage, selectedSite?.organizationId, searchFilter);
        setLoading(false);
        if (response?.statusCode === '200') {
            setTableData(response?.data?.content);
            setTotalElements(response?.data?.totalElements);
        }
    }

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
        }
    };

    const clearFilters = async () => {
        isClear.current = true;
        setPage(0);
        setRowsPerPage(25);
        setSearchFilter({ assetNumber: '', failureCode: '', fromDate: '', toDate: '' });
    }

    useEffect(() => {
        if (isClear.current === true) {
            fetchFailureData();
            setShowSideBar(!showSideBar)
            isClear.current = false;

        }
    }, [searchFilter])

    useEffect(() => {
        if (selectedSite && searchFilter)
            fetchFailureData();
    }, [page, rowsPerPage, selectedSite, location, searchFilter.sortBy, searchFilter.parameter]);

    const excelExport = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('organizationId', selectedSite?.organizationId);

        if (searchFilter?.assetNumber && searchFilter?.assetNumber !== "") {
            urlParams.append('assetNumber', searchFilter?.assetNumber);
        }
        if (searchFilter?.assetGroup && searchFilter?.assetGroup !== "") {
            urlParams.append('assetGroup', searchFilter?.assetGroup);
        }
        if (searchFilter?.failureCode && searchFilter?.failureCode !== "") {
            urlParams.append('failureCode', searchFilter?.failureCode);
        }
        if (searchFilter?.fromDate && searchFilter?.fromDate !== "") {
            urlParams.append('fromDate', searchFilter?.fromDate);
        }
        if (searchFilter?.toDate && searchFilter?.toDate !== "") {
            urlParams.append('toDate', searchFilter?.toDate);
        }

        const url = `/excelExport/exportFailureAnalysisReport?${urlParams.toString()}`;
        exportToExcel(url);
    };

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                <Box className={largeScreens ? 'homePageContentWrapper' : 'mobileHomePageContentWrapper'}>
                    <Box className='homePageHeader'>
                        <Typography variant='h5' flexGrow={1}>
                            Failure Analysis
                        </Typography>
                        <Stack direction={'row'}>
                            <IconButton onClick={() => largeScreens ? setShowFilterFields(!showFilterFields) : setShowSideBar(!showSideBar)}>
                                {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon  />}
                            </IconButton>
                            <IconButton onClick={excelExport}>
                                <IosShareIcon style={{ fontSize: "20" }} />
                            </IconButton >
                        </Stack>
                    </Box>
                    {
                        smallScreens ?
                            <Drawer
                                anchor="right"
                                open={showSideBar}
                                onClose={() => setShowSideBar(!showSideBar)}
                                PaperProps={{ style: { width: '300px' } }} >
                                <Box className='drawerContent' padding={'10px 15px'}>
                                    <Toolbar />
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                        <Typography variant='boldBody' color={'black !important'}>Filters</Typography>
                                        <Box sx={{display:'flex', flexDirection:'row', gap:'10px'}}>
                                            <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                                                <FilterAltOffOutlinedIcon />
                                            </IconButton>
                                            <IconButton onClick={excelExport}>
                                                <IosShareIcon style={{ fontSize: "20" }} />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <Stack direction={'column'} gap={'10px'}>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Asset Number'}
                                            </Typography>
                                            <InputComponent
                                                name="assetNumber"
                                                width="100%"
                                                value={searchFilter?.assetNumber}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                                }
                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Asset Group'}
                                            </Typography>
                                            <InputComponent
                                                name="assetGroup"
                                                width="100%"
                                                value={searchFilter?.assetGroup}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetGroup']: e.target.value });
                                                }
                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'Failure Code'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                value={searchFilter?.failureCode}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['failureCode']: e.target.value });
                                                }}
                                                disabled={false}
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'From Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'To Date'}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'To Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'From Date'}
                                                dateDefaultValue={searchFilter?.toDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Button variant='contained' onClick={() => { fetchFailureData(); setShowSideBar(!showSideBar); setPage(0); }}>Search</Button>
                                        <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                    </Stack>
                                </Box>
                            </Drawer>
                            :
                            <Box className='homePageFilters'>
                                {showFilterFields && (
                                    <Box className='filters-lg'>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Asset Number'}
                                            </Typography>
                                            <InputComponent
                                                name="assetNumber"
                                                width="100%"
                                                value={searchFilter?.assetNumber}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetNumber']: e.target.value });
                                                }
                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Asset Group'}
                                            </Typography>
                                            <InputComponent
                                                name="assetGroup"
                                                width="100%"
                                                value={searchFilter?.assetGroup}
                                                disabled={false}
                                                onKeyPress={handleInputKeyPress}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['assetGroup']: e.target.value });
                                                }
                                                }
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'Failure Code'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                value={searchFilter?.failureCode}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['failureCode']: e.target.value });
                                                }}
                                                disabled={false}
                                            ></InputComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'From Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'To Date'}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'To Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'From Date'}
                                                dateDefaultValue={searchFilter?.toDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                            <IconButton
                                                aria-label="search"
                                                onClick={() => {fetchFailureData(); setPage(0);}}
                                                edge="start"
                                            >
                                                <SearchIcon />
                                            </IconButton>

                                            <IconButton onClick={clearFilters}
                                                aria-label="search"
                                                edge="start"
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )}
                            </Box>
                    }

                    <Box className='homePageTable'>
                        {
                            tableData && (
                                <FailureAnalysisTableComponent
                                    rowCount={totalElements}
                                    columns={failureAnalysisColumns}
                                    page={page}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}
                                    showFilterFields={showFilterFields}
                                    setPage={(e) => setPage(e)}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={(e) => setRowsPerPage(e)}
                                    data={tableData}
                                    fetchData={fetchFailureData}
                                    selectedSite={selectedSite}
                                    smallScreens={smallScreens}
                                ></FailureAnalysisTableComponent>
                            )
                        }
                    </Box>
                </Box>
            }

        </Box>
    );
}