import React, { useContext, useEffect } from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SelectComponent, InputComponent } from "../shared-components/common-field-components";
import { UserContext } from "../../store/UserContext";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
const theme = createTheme();

function MyAccount() {
    const [language, setLanguage] = React.useState(i18n?.language);
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const [selected, setSelected] = React.useState();
    const languages = [
        { id: 1, value: "English", language: "en" },
        { id: 2, value: "Spanish", language: "es" }
    ];
    useEffect(() => {
        i18n.changeLanguage(language);
        const selectedLang = languages.find(x => x.language === language);
        setSelected(selectedLang?.id);
    }, [selected]);
    return (
        <ThemeProvider theme={theme}>
            <Paper
                elevation={0}
                className='mtf-home-header'
                style={{ textAlign: 'center' }}
            >
                <Toolbar>
                    <Typography component='div'>
                        {t('ACCOUNT_DETAILS')}
                    </Typography>
                </Toolbar>
            </Paper>
            <Grid className='acct-overflow'>
                <Container component='main' className="acct_box" >
                    <Paper
                        elevation={0}
                        id='mtf-create-form-paper'
                        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                    >
                        <Box className="acct_main">
                            <Box className="acct_data">

                                <InputComponent
                                    label={t('empfirstname')}
                                    name={"firstName"}
                                    value={user?.given_name}
                                    disabled={false}
                                    width='100%'
                                ></InputComponent>

                                <InputComponent
                                    label={t('emplastname')}
                                    name={"lastName"}
                                    value={user?.family_name}
                                    disabled={false}
                                    width='100%'
                                ></InputComponent>

                            </Box>
                            <Box className="acct_data">

                                <InputComponent
                                    label={t('empemail')}
                                    name={"email"}
                                    value={user?.email}
                                    disabled={false}
                                    width='100%'
                                ></InputComponent>

                                {selected &&
                                    <SelectComponent
                                        label={t('profilelanguage')}
                                        values={languages}
                                        name={"transferredFrom"}
                                        selectedvalue={selected}
                                        disabled={false}
                                        width='100%'
                                        parentselectcallback={(e) => {
                                            const selectedLang = languages.find(x => x.id === e);
                                            setSelected(selectedLang.id);
                                            setLanguage(selectedLang.language);
                                            i18n.changeLanguage(selectedLang.language);
                                        }}
                                    ></SelectComponent>
                                }

                            </Box>

                        </Box>
                    </Paper>
                </Container>
            </Grid>

        </ThemeProvider>
    );
}

export default MyAccount;
