export const BroadcastTableColumns = [
  {
    field: "message",
    headerName: "MESSAGE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "startDate",
    headerName: "START_DATE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "expirationsDate",
    headerName: "EXPIRATIONS_DATE",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "",
    headerName: "",
    width: 100,
    headerClassName: "super-app-theme--header",
    sortable: false
  },
];
