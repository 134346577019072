import { Box, Button, Drawer, IconButton, Stack, TextField, Toolbar, Typography, Autocomplete, } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { DatePickerComponent, LaborHoursTableComponent, InputComponent, } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { getLaborHours } from "../../services/ReportServices";
import { getOperationLaborResourcesByOrgId } from "../../services/WorkOrderServices";
import { exportToExcel } from "../../services/ExcelExportService";
import IosShareIcon from '@mui/icons-material/IosShare';

const laborHoursColumns = [
    {
        field: "orgName",
        headerName: "Org",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },

    {
        field: "workOrderId",
        headerName: "Work Order",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "task",
        headerName: "Task",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "asset",
        headerName: "Asset",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "parentAsset",
        headerName: "Parent Asset",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "assetGroupDescription",
        headerName: "Asset Group Description",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "resourceCode",
        headerName: "Resource Description",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "hours",
        headerName: "Hours",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "empName",
        headerName: "Employee Name",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
    {
        field: "transactionDate",
        headerName: "Transaction Date",
        width: 100,
        headerClassName: "super-app-theme--header",
        sortable: true
    },
];

export default function LaborHours() {
    const { userSitesData, selectedSite, oktaAuth, smallScreens, largeScreens } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const getSelectedOrg = userSitesData?.find(item => item?.orgId == selectedSite?.organizationId);
    const [searchFilter, setSearchFilter] = useState({ fromDate: null, toDate: null, selectedResource: '', selectedResourceInput: '', Org: getSelectedOrg && getSelectedOrg, eName: '', sortBy: 'asc', sortParameter: 'task' })
    const [showFilterFields, setShowFilterFields] = useState(true);
    const [tableData, setTableData] = React.useState();
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const isClear = useRef(false);
    const navigate = useNavigate();
    const [search, setSearch] = useState(false);
    const location = useLocation();
    const [resourceDescriptionValues, setResourceDescriptionValues] = useState(false);


    const fetchFailureData = async () => {
        setLoading(true);
        setTableData([]);
        const response = await getLaborHours(page, rowsPerPage, searchFilter?.Org?.orgId || selectedSite?.organizationId, searchFilter?.fromDate, searchFilter?.toDate, searchFilter?.eName, searchFilter?.selectedResource?.resourceCode, searchFilter?.sortBy, searchFilter?.sortParameter);
        setLoading(false);
        if (response?.statusCode === '200') {
            setTableData(response?.data?.content);
            setTotalElements(response?.data?.totalElements);
        }
    }
    const excelExport = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('organizationId', selectedSite.organizationId);

        if (searchFilter?.fromDate && searchFilter?.fromDate !== "") {
            urlParams.append('fromDate', searchFilter?.fromDate);
        }
        if (searchFilter?.toDate && searchFilter?.toDate !== "") {
            urlParams.append('toDate', searchFilter?.toDate);
        }
        if (searchFilter?.eName && searchFilter?.eName !== "") {
            urlParams.append('empName', searchFilter?.eName);
        }
        if (searchFilter?.selectedResource?.resourceCode && searchFilter?.selectedResource?.resourceCode !== "") {
            urlParams.append('resourceCode', searchFilter?.selectedResource?.resourceCode);
        }
        if (searchFilter?.sortBy && searchFilter?.sortBy !== "") {
            urlParams.append('sortBy', searchFilter?.sortBy);
        }
        if (searchFilter?.sortParameter && searchFilter?.sortParameter !== "") {
            urlParams.append('sortParameter', searchFilter?.sortParameter);
        }

        const url = `/excelExport/exportLaborHoursReport?${urlParams.toString()}`;
        exportToExcel(url);
    };
    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
        }
    };

    const clearFilters = async () => {
        isClear.current = true;
        setSearch(false);
        setTableData([]);
        setPage(0);
        setRowsPerPage(25);
        setSearchFilter({ fromDate: null, toDate: null, selectedResource: '', selectedResourceInput: '', Org: getSelectedOrg && getSelectedOrg, eName: '', sortBy: 'asc', sortParameter: 'task' });
    }

    useEffect(() => {
        if (isClear.current === true) {
            setShowSideBar(!showSideBar)
            isClear.current = false;

        }
    }, [searchFilter])

    useEffect(() => {
        if (selectedSite && searchFilter && search) {
            setSearch(false);
            fetchFailureData();
        }
    }, [page, rowsPerPage, searchFilter.sortBy, searchFilter.parameter]);

    const fetchLaborResources = async (filter) => {
        try {
            const resources = await getOperationLaborResourcesByOrgId(page, 50, selectedSite?.organizationId, filter);
            const uniqueResources = resources?.data?.reduce((acc, current) => {
                const duplicate = acc.find(item => item.resourceId === current.resourceId);
                if (!duplicate) acc.push(current);
                return acc;
            }, []);
            setResourceDescriptionValues(uniqueResources);
        } catch (error) {
            console.error('Error fetching labor resources:', error);
        }
    };


    useEffect(() => {
        fetchLaborResources();
    }, [selectedSite?.organizationId]);


    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                <Box className={largeScreens ? 'homePageContentWrapper' : 'mobileHomePageContentWrapper'}>
                    <Box className='homePageHeader'>
                        <Typography variant='h5' flexGrow={1}>
                            Labor Hours
                        </Typography>
                        <Stack direction={'row'}>
                            <IconButton onClick={() => largeScreens ? setShowFilterFields(!showFilterFields) : setShowSideBar(!showSideBar)}>
                                {showFilterFields ? <FilterAltOutlinedIcon /> : <FilterAltOutlinedIcon />}
                            </IconButton>
                            <IconButton onClick={excelExport}>
                                <IosShareIcon style={{ fontSize: "20" }} />
                            </IconButton>
                        </Stack>
                    </Box>
                    {
                        smallScreens ?
                            <Drawer
                                anchor="right"
                                open={showSideBar}
                                onClose={() => setShowSideBar(!showSideBar)}
                                PaperProps={{ style: { width: '300px' } }} >
                                <Box className='drawerContent' padding={'10px 15px'}>
                                    <Toolbar />
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                        <Typography variant='boldBody' color={'black !important'}>Filterss</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                            <IconButton sx={{ marginRight: '-10px' }} onClick={() => setShowSideBar(!showSideBar)}>
                                                <FilterAltOffOutlinedIcon />
                                            </IconButton>
                                            <IconButton onClick={excelExport}>
                                                <IosShareIcon />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <Stack direction={'column'} gap={'10px'}>

                                        <Stack width="100%" >
                                            <Typography >
                                                {'From Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'To Date'}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'To Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'From Date'}
                                                dateDefaultValue={searchFilter?.toDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Resource Description'}
                                            </Typography>
                                            <Autocomplete
                                                value={searchFilter?.selectedResource || null}
                                                onChange={(event, newValue) => {
                                                    setSearchFilter({ ...searchFilter, selectedResource: newValue });
                                                }}
                                                inputValue={searchFilter?.selectedResourceInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, selectedResourceInput: newInputValue });
                                                    if (newInputValue?.length > 3) {
                                                        fetchLaborResources(newInputValue);
                                                    }
                                                    else {
                                                        fetchLaborResources();
                                                    }
                                                }}
                                                limitTags={1}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option?.resourceCode || ''}
                                                options={resourceDescriptionValues && resourceDescriptionValues?.sort((a, b) => a?.resourceCode.localeCompare(b?.resourceCode))}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        {userSitesData && userSitesData.length > 0 && (
                                            <Stack width="100%">
                                                <Typography>
                                                    {'Org'}
                                                </Typography>
                                                <Autocomplete
                                                    value={searchFilter?.Org || null}
                                                    onChange={(event, newValue) => {
                                                        setSearchFilter({ ...searchFilter, Org: newValue });
                                                    }}
                                                    inputValue={searchFilter?.OrgInput || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setSearchFilter({ ...searchFilter, OrgInput: newInputValue });
                                                    }}
                                                    limitTags={1}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.orgName || ''}
                                                    options={
                                                        userSitesData
                                                            .filter(item => item.id !== 0)
                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                    }
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disabled={false}
                                                />
                                            </Stack>
                                        )}
                                        <Stack width="100%" >
                                            <Typography >
                                                {'Employee Name'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                value={searchFilter?.eName}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['eName']: e.target.value });
                                                }}
                                                disabled={false}
                                            ></InputComponent>
                                        </Stack>
                                        <Button variant='contained' onClick={() => { fetchFailureData(); setShowSideBar(!showSideBar); setPage(0); }}>Search</Button>
                                        <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                    </Stack>
                                </Box>
                            </Drawer>
                            :
                            <Box className='homePageFilters'>
                                {showFilterFields && (
                                    <Box className='filters-lg'>

                                        <Stack width="100%" >
                                            <Typography >
                                                {'From Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'To Date'}
                                                dateDefaultValue={searchFilter?.fromDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['fromDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%" >
                                            <Typography >
                                                {'To Date'}
                                            </Typography>
                                            <DatePickerComponent
                                                width="100%"
                                                disabled={false}
                                                disableFuture={true}
                                                label={'From Date'}
                                                dateDefaultValue={searchFilter?.toDate}
                                                onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['toDate']: e }); }}
                                            ></DatePickerComponent>
                                        </Stack>
                                        <Stack width="100%">
                                            <Typography >
                                                {'Resource Description'}
                                            </Typography>
                                            <Autocomplete
                                                value={searchFilter?.selectedResource || null}
                                                onChange={(event, newValue) => {
                                                    setSearchFilter({ ...searchFilter, selectedResource: newValue });
                                                }}
                                                inputValue={searchFilter?.selectedResourceInput || ''}
                                                onInputChange={(event, newInputValue) => {
                                                    setSearchFilter({ ...searchFilter, selectedResourceInput: newInputValue });
                                                    if (newInputValue?.length > 3) {
                                                        fetchLaborResources(newInputValue);
                                                    }
                                                    else {
                                                        fetchLaborResources();
                                                    }
                                                }}
                                                limitTags={1}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option?.resourceCode || ''}
                                                options={(resourceDescriptionValues || []).sort((a, b) => a?.resourceCode.localeCompare(b?.resourceCode))}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                disabled={false}
                                            />
                                        </Stack>
                                        {userSitesData && userSitesData.length > 0 && (
                                            <Stack width="100%">
                                                <Typography>
                                                    {'Org'}
                                                </Typography>
                                                <Autocomplete
                                                    value={searchFilter?.Org || null}
                                                    onChange={(event, newValue) => {
                                                        setSearchFilter({ ...searchFilter, Org: newValue });
                                                    }}
                                                    inputValue={searchFilter?.OrgInput || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setSearchFilter({ ...searchFilter, OrgInput: newInputValue });
                                                    }}
                                                    limitTags={1}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.orgName || ''}
                                                    options={
                                                        userSitesData
                                                            .filter(item => item.id !== 0)
                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                    }
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disabled={false}
                                                />
                                            </Stack>
                                        )}
                                        <Stack width="100%" >
                                            <Typography >
                                                {'Employee Name'}
                                            </Typography>
                                            <InputComponent
                                                width="100%"
                                                value={searchFilter?.eName}
                                                onChange={(e) => {
                                                    setSearchFilter({ ...searchFilter, ['eName']: e.target.value });
                                                }}
                                                disabled={false}
                                            ></InputComponent>
                                        </Stack>
                                        <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                            <IconButton
                                                aria-label="search"
                                                onClick={() => {fetchFailureData(); setPage(0);}}
                                                edge="start"
                                            >
                                                <SearchIcon />
                                            </IconButton>

                                            <IconButton onClick={clearFilters}
                                                aria-label="search"
                                                edge="start"
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                )}
                            </Box>
                    }

                    <Box className='homePageTable'>
                        <LaborHoursTableComponent
                            rowCount={totalElements}
                            columns={laborHoursColumns}
                            page={page}
                            setSearchFilter={setSearchFilter}
                            searchFilter={searchFilter}
                            showFilterFields={showFilterFields}
                            setPage={(e) => setPage(e)}
                            rowsPerPage={rowsPerPage}
                            search={search}
                            setSearch={setSearch}
                            setRowsPerPage={(e) => setRowsPerPage(e)}
                            data={tableData || []}
                            fetchData={fetchFailureData}
                            selectedSite={selectedSite}
                            smallScreens={smallScreens}
                        ></LaborHoursTableComponent>
                    </Box>
                </Box>
            }

        </Box>
    );
}