import { Box, Button, Drawer, Grid, IconButton, Paper, Stack, TablePagination, Toolbar, Typography, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';

import { AssetHomePageTableComponent, InputComponent } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import { AssetsExpand } from "../constants/assetsExpandColumnData";
import { getAssetsBySearch } from "../../services/AssetsServices";
import '../styles/homepage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { exportToExcel } from "../../services/ExcelExportService";



export default function AssetsHomePage() {
    const { user, selectedSite, oktaAuth, smallScreens, largeScreens } = React.useContext(UserContext);
    const accessToken = oktaAuth?.okaAuth?.getAccessToken();
    const localFetchParams = JSON.parse(localStorage?.getItem('assetLocalFetchParams'));
    const [searchFilter, setSearchFilter] = useState(
        {
            keyword: localFetchParams?.searchFilters?.keyword || '',
            manufacturersSerialNumber: localFetchParams?.searchFilters?.manufacturersSerialNumber || '',
            site: localFetchParams?.searchFilters?.site || '',
            parent: localFetchParams?.searchFilters?.parent || '',
            sortBy: localFetchParams?.searchFilters?.sortBy || '',
            parameter: localFetchParams?.searchFilters?.parameter || ''
        }
    )
    const [showFilterFields, setShowFilterFields] = useState(false);
    const [assetsData, setAssetsData] = React.useState();
    const [totalElements, setTotalElements] = React.useState();
    const [page, setPage] = React.useState(localFetchParams?.page || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localFetchParams?.rowsPerPage || 25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const isClear = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const H6Span = styled('span')(({ theme }) => ({
        ...theme.typography.h6,
    }));

    const fetchAssetsByFilterData = () => {
        try {
            setLoading(true);
            const localSetSearchFilters = { ...localFetchParams, searchFilters: searchFilter }
            localStorage.setItem('assetLocalFetchParams', JSON.stringify(localSetSearchFilters));
            getAssetsBySearch(accessToken, page, rowsPerPage, selectedSite?.organizationId, searchFilter.keyword === "" ? null : searchFilter.keyword?.toUpperCase(), searchFilter.manufacturer === "" ? null : searchFilter.manufacturer, searchFilter.parent === "" ? null : searchFilter.parent?.toUpperCase(),
                user?.id, searchFilter?.sortBy, searchFilter?.parameter).then(response => {
                    if (response && response?.statusCode === "200") {
                        setAssetsData(response?.data?.content);
                        setTotalElements(response?.data?.totalElements);
                        setLoading(false);
                        setSearchFilter(searchFilter);
                        localStorage.setItem("assetsearchfilter", JSON.stringify({ keyword: searchFilter.keyword ? searchFilter.keyword : '', manufacturer: searchFilter.manufacturer ? searchFilter.manufacturer : '', parent: searchFilter.parent ? searchFilter.parent : '', sortBy: '', parameter: '' }));
                    } else {
                        setLoading(false);
                    }
                });

        } catch (err) { console.log(err); setLoading(false); };
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        const savedParams = JSON.parse(localStorage.getItem('assetLocalFetchParams'));
        const savePage = { ...savedParams, page: newPage };
        localStorage.setItem('assetLocalFetchParams', JSON.stringify(savePage));
    };
    /*********************************************************/

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        const savedParams = JSON.parse(localStorage.getItem('assetLocalFetchParams'));
        const savePage = { ...savedParams, rowsPerPage: +event.target.value };
        localStorage.setItem('assetLocalFetchParams', JSON.stringify(savePage));
        /*  setPage(0); */
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchAssetsByFilterData();
        }
    };

    const clearFilters = async () => {
        isClear.current = true;
        setPage(0);
        setRowsPerPage(25);
        setSearchFilter({ ...searchFilter, keyword: '', parent: '', manufacturer: '', sortBy: '', parameter: '' });
    }

    const excelExport = () => {
        const urlParams = new URLSearchParams();
      
        if (selectedSite?.organizationId) {
          urlParams.append('site', selectedSite.organizationId);
        }
        if (searchFilter.keyword && searchFilter.keyword !== "") {
          urlParams.append('keyword', searchFilter.keyword);
        }
        if (searchFilter.manufacturer && searchFilter.manufacturer !== "") {
          urlParams.append('manufacturersSerialNumber', searchFilter.manufacturer);
        }
        if (searchFilter.parent && searchFilter.parent !== "") {
          urlParams.append('parent', searchFilter.parent);
        }
        if (user?.id) {
          urlParams.append('userId', user.id);
        }
        if (searchFilter?.sortBy && searchFilter?.sortBy !== "") {
          urlParams.append('sort', searchFilter?.sortBy);
        }
        if (searchFilter?.parameter && searchFilter?.parameter !== "") {
          urlParams.append('parameter', searchFilter?.parameter);
        }      
        const url = `/excelExport/assets?${urlParams.toString()}`;
        exportToExcel(url);
      };

    useEffect(() => {
        if (isClear.current === true) {
            fetchAssetsByFilterData();
            setShowSideBar(!showSideBar)
            isClear.current = false;

        }
    }, [searchFilter])

    useEffect(() => {
        if (selectedSite)
            fetchAssetsByFilterData();
    }, [page, rowsPerPage, path, selectedSite, searchFilter.sortBy, searchFilter.parameter]);


    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;</div>
            }
            {//desktop, laptop, tablet
                (largeScreens) && (
                    <Box className='homePageContentWrapper'>
                        <Box className='homePageHeader'>
                            <Typography variant='h5' flexGrow={1}>
                                Assets
                            </Typography>
                            <Stack direction={'row'}>

                                <IconButton onClick={() => setShowFilterFields(!showFilterFields)}>
                                    {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                                </IconButton>
                                <IconButton onClick={excelExport}>
                                <IosShareIcon style={{ fontSize:"20" }} />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Box className='homePageFilters'>
                            {showFilterFields && (
                                <Box className='filters-lg'>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Keyword'}
                                        </Typography>
                                        <InputComponent

                                            width="100%"
                                            name="keyword"
                                            value={searchFilter?.keyword}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['keyword']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            Manufacturer's S/N
                                        </Typography>
                                        <InputComponent
                                            name="manufacturer"
                                            width="100%"
                                            value={searchFilter?.manufacturer}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['manufacturer']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Site'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            value={selectedSite ? selectedSite?.organizationCode + ' - ' + (selectedSite?.organizationName == 'Seattle Maintenance' ? 'Jacksonville Maintenance' : selectedSite?.organizationName) : ''}
                                            disabled={true}
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography >
                                            {'Parent'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="parent"
                                            value={searchFilter?.parent}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['parent']: e.target.value });
                                            }
                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => {fetchAssetsByFilterData(); setPage(0);}}
                                            edge="start"
                                        >
                                            <SearchIcon />
                                        </IconButton>

                                        <IconButton onClick={clearFilters}
                                            aria-label="search"
                                            edge="start"
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                        <Box className='homePageTable'>
                            {
                                assetsData && (
                                    <AssetHomePageTableComponent
                                        rowCount={totalElements}
                                        columns={AssetsExpand}
                                        page={page}
                                        showFilterFields={showFilterFields}
                                        setPage={(e) => setPage(e)}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={(e) => setRowsPerPage(e)}
                                        data={assetsData}
                                        fetchData={fetchAssetsByFilterData}
                                        selectedSite={selectedSite}
                                        searchFilter={searchFilter}
                                        setSearchFilter={setSearchFilter}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    ></AssetHomePageTableComponent>
                                )
                            }
                        </Box>
                    </Box>
                )
            }
            {//mobile resolution
                smallScreens && (
                    <Box className='mobileHomePageContentWrapper'>
                        <Drawer
                            anchor="right"
                            open={showSideBar}
                            onClose={() => setShowSideBar(!showSideBar)}
                            PaperProps={{ style: { width: '300px' } }} >
                            <Box className='drawerContent' padding={'10px 15px'}>
                                <Toolbar />
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='boldBody' color={'black !important'}>Filters</Typography>
                                    <IconButton sx={{ marginRight: '-10px' }} onClick={() => setShowSideBar(!showSideBar)}>
                                        <FilterAltOffOutlinedIcon />
                                    </IconButton>
                                </Stack>
                                <Stack direction={'column'} gap={'10px'}>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Keyword'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="keyword"
                                            value={searchFilter?.keyword}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['keyword']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            Manufacturer's S/N
                                        </Typography>
                                        <InputComponent
                                            name="manufacturer"
                                            width="100%"
                                            value={searchFilter?.manufacturer}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['manufacturer']: e.target.value });
                                            }

                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Site'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            value={selectedSite ? selectedSite?.organizationCode + ' - ' + selectedSite?.organizationName : ''}
                                            disabled={true}
                                        ></InputComponent>
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Parent'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="parent"
                                            value={searchFilter?.parent}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['parent']: e.target.value });
                                            }
                                            }
                                        ></InputComponent>
                                    </Stack>
                                    <Button variant='contained' onClick={() => {setPage(0); fetchAssetsByFilterData(); setShowSideBar(!showSideBar) }}>Search</Button>
                                    <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                </Stack>
                            </Box>
                        </Drawer>
                        <Box className='homePageHeader'>
                            <Typography variant='boldBody' flexGrow={1}>
                                Assets
                            </Typography>
                            <Stack direction={'row'} >

                                <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                                    {showSideBar ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                                </IconButton>
                                <IconButton onClick={excelExport}>
                                    <IosShareIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Box className='homePagePagination'>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component='div'
                                count={totalElements ? totalElements : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                        <Box className='homePageMobileTableContent' sx={{ height: window.innerHeight - 166 }}>
                            {assetsData?.length === 0 && <Typography variant="h6">No data found</Typography>}
                            {assetsData && assetsData?.map((row, index) => (
                                <Grid key={index} onClick={() => navigate('/assets/summary', { state: { asset: row?.assetNumber, from: location?.pathname } })} className="mobileHomePageListCard">
                                    <Paper elevation={0} >
                                        <Box alignItems='start' flex={1}>

                                            <Typography variant="subtitle2">{row.assetNumber}</Typography>

                                            <Typography variant="body2"><Typography variant="boldBody" component={'span'} >Ctg: </Typography>{row.category}</Typography>
                                            <Typography variant="body2"><Typography variant="boldBody" component={'span'} >Parent: </Typography>{row.parent ? row.parent : '-'}</Typography>
                                        </Box>
                                        <Box textAlign='end' flex={1}>

                                            <Typography variant="body2" ><Typography variant="boldBody" component={'span'}>Type: </Typography>{row.type ? (row.type.length > 10 ? row.type.slice(0, 10) + '...' : row.type) : '-'}</Typography>
                                            <Typography variant="body2"> <Typography variant="boldBody" component={'span'}>Desc: </Typography>{row.description ? (row.description.length > 10 ? row.description.slice(0, 25) + '...' : row.description) : '-'} </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}