import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { useState, useContext } from "react";
import SignIn from "../../components/signin/SignIn";
import Dashboard from "../../components/dashboard/Dashboard";
import MyAccount from "../../components/myaccount/MyAccout";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';

import { getUserSitesById } from "../../services/MasterServices";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { UserContext } from "../../store/UserContext";
import Header from "./header/Header";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import WorkOrdersIcon from '../../assets/images/newLayoutIcons/workOrders.png';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import laborHoursIcon from '../../assets/images/reports/LabborHourIcon.svg'
import AssetsIcon from '../../assets/images/newLayoutIcons/Assets.png'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from "react-router-dom";

import NotificationsIcon from '../../assets/images/admin/Bell_pin.svg';
import SecurityIcon from '../../assets/images/admin/Chield.svg';
import WOACLOG from '../../assets/images/reports/WOACLOG.png';

import EAMAssetListingIcon from "../../assets/images/reports/EAMAssetListing.svg";
import DevicesIcon from '../../assets/images/admin/Tabletdevices-admin.svg';
import SitesIcon from '../../assets/images/admin/world_fill.svg';
import woReports from "../../assets/images/reports/woReports.svg"
import Sites from "../admin/Sites";
import SecurityAdmin from "../admin/SecurityAdmin";
import NotificationsBroadcast from "../admin/NotificationsBroadcast";
import Devices from "../admin/Devices";
import './mainLayout.css'
import Summary from "../assets/Summary";
import WorkOrdersHomePage from "../workorders/WorkOrdersHomePage";
import AssetsHomePage from "../assets/AssetsHomePage";
import WorkOrderDetails from "../workorders/WorkOrderDetails";
import CreateAsset from "../assets/CreateAsset";
import CreateWorkOrder from "../workorders/CreateWorkOrder";
import FailureAnalysis from "../reports/FailureAnalysis";
import WorkOrderHistory from "../reports/WorkOrderHistory";
import LaborHours from "../reports/LaborHours";
import EAMAssetListing from "../reports/EAMAssetListing";
import AssetWorkorderHistory from "../assets/AssetWorkorderHistory";
import WorkOrderAccessLog from "../admin/WorkOrderAccessLog";

const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down("md")]: {
        display: 'none',
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: 'black',
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    background: 'black',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
        position: 'absolute'
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MainLayout(props) {
    const theme = useTheme();
    const { error } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const { permissions } = useContext(UserContext);
    const { updateSelectedSite, defaultSiteId } = React.useContext(UserContext);

    const handleDrawerOpen = () => {
        if (!clickedMenuIcon) {
            setOpen(true);
        }
    };

    const openDrawer = () => {
        setOpen(!open);
        setClickedMenuIcon(!clickedMenuIcon)
    };

    const handleDrawerClose = () => {
        if (!clickedMenuIcon) {
            setOpen(false);
        }

    };

    const { user, screenSize, appConstants, selectedSite } = React.useContext(UserContext);

    const accessToken = user?.token;
    const [allSitesData, setAllSitesData] = React.useState();
    const [clickedMenuIcon, setClickedMenuIcon] = React.useState(false);
    const [getToggledMenu, setGetToggledMenu] = React.useState(null);

    const pages = [
        { name: "Dashboard", paths: "/dashboard", icon: <DashboardIcon />, hasAccess: true, },
        { name: t("MENU_WORK_ORDERS"), paths: "/workorders", hasAccess: permissions.workOrders, icon: <img width={24} height={24} src={WorkOrdersIcon} alt="Work Orders Icon"></img> },
        { name: t("menuassets"), paths: "/assets/", hasAccess: permissions.assets, icon: <img width={24} height={24} src={AssetsIcon} alt="Assets Icon"></img> },
        {
            name: t("MENU_REPORTS"),
            children: [
                { name: t("FAILURE_ANALYSIS"), paths: "/reports/failure-analysis", icon: <QueryStatsOutlinedIcon /> },
                { name: t("LABOR_HOURS"), paths: "/reports/labor-hours", icon: <img width={22} height={22} src={laborHoursIcon}></img> },
                { name: t("WORKORDER_HISTORY"), paths: "/reports/workorder-history", icon: <img width={22} height={22} src={woReports}></img> },
                { name: "Asset Details", paths: "/reports/eamasset-listing", icon: <img width={20} height={20} src={EAMAssetListingIcon}></img> },
            ],
            hasAccess: permissions.reports, icon: <BarChartIcon />
        },
        {
            name: t("menuadmin"),
            children: [
                { name: t("SITES"), paths: "/admin/sites", icon: <img width={22} height={22} src={SitesIcon}></img> },
                { name: t("SECURITY"), paths: "/admin/security", icon: <img width={22} height={22} src={SecurityIcon}></img> },
                { name: t("DEVICES"), paths: "/admin/devices", icon: <img width={22} height={22} src={DevicesIcon}></img> },
                // { name: t("NOTIFICATIONS"), paths: "/admin/notifications", icon: <img width={22} height={22} src={NotificationsIcon}></img> },
                { name: t("Work Orders Access Logs"), paths: "/admin/workorder-access-log", icon: <ManageSearchOutlinedIcon /> }

            ],
            hasAccess: permissions.admin, icon: <PersonIcon />
        }
    ];



    const fetchData = () => {
        getUserSitesById(accessToken, user?.id).then(response => {
            let sites = []
            if (response?.statusCode === "200") {
                response?.data?.forEach(x => { // BINDING ID, VALUE TO EXISITNG LIST
                    x.id = x.organizationId;
                    x.value = x.organizationCode + '-' + x.organizationName;
                });
                updateSelectedSite({ ...response.data?.find(x => x.siteId == defaultSiteId) });
                setAllSitesData(response?.data);

            }
        })
            .catch(err => console.log(err));
    }

    React.useEffect(() => {
        fetchData()
    }, []);

    const [openMenu, setOpenMenu] = useState(false);

    const handleToggleClick = (_page) => {
        setOpenMenu(!openMenu);
        setGetToggledMenu(_page)
    };

    const handlePageClick = (path) => {
        setOpen(false);
    };

    const renderSubMenu = (page) => {
        return (
            <List component="div" disablePadding sx={{ backgroundColor: "#2C2C2C" }} className="child-menu-list">
                {page.children.map((childPage, index) => (
                    <ListItem key={index} disablePadding sx={{ display: "block" }}>
                        <Link
                            to={childPage.paths !== location.pathname && childPage.paths}
                            style={{ textDecoration: 'none', width: "100%" }}
                            onClick={() => handlePageClick(childPage.paths)}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 53,
                                    px: open ? 4 : 2.5,
                                    backgroundColor: childPage.paths === location?.pathname ? '#5d5d5d' : 'none'
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 0, color: 'white', mr: open ? 2 : 0 }}>
                                    {childPage.icon}
                                </ListItemIcon>
                                {
                                    open &&
                                    <ListItemText color="white" primary={childPage.name} />
                                }
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
        );
    };


    React.useEffect(() => {
        if (!['/workorders', '/workorders/details', '/workorders/createWorkOrder'].includes(location.pathname)) {
            localStorage.removeItem('woLocalFetchParams')
        }
        else if (!['/assets'].includes(location.pathname)) {
            localStorage.removeItem('assetLocalFetchParams');
        }
        if (!['/workorders', '/workorders/details', '/workorders/createWorkOrder', '/assets/summary'].includes(location.pathname)) {
            localStorage.removeItem('dashParams');
            localStorage.removeItem('woLocalFetchParams')
        }
    }, [location])

    return (
        <Box className="mainLayoutComponent" sx={{ display: "flex", width: window.innerWidth, height: window.innerHeight }}>
            <CssBaseline />
            {location.pathname !== "/" && location.pathname !== "/login" &&
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <>
                            {location.pathname !== "/" &&
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={openDrawer}
                                    edge="start"
                                    sx={{
                                        marginRight: screenSize.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION && '20px',
                                        marginLeft: screenSize.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION ? '0px' : '-12px'
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            }
                        </>
                        <Header />
                    </Toolbar>
                </AppBar>
            }
            {(location.pathname !== "/" && location.pathname !== "/login") &&
                < Drawer variant="permanent" open={open} onClose={handleDrawerClose} className="drawer-main"
                    onMouseEnter={handleDrawerOpen}
                    onMouseLeave={handleDrawerClose}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <List sx={{ py: 0 }}>
                        {pages?.filter(item => item.hasAccess)?.map((page, index) => (
                            <div key={index}>
                                <ListItem disablePadding onClick={() => !page.children && handlePageClick()} >
                                    <Link to={page.paths !== location.pathname && !page.children && page.paths} style={{ textDecoration: 'none', width: "100%" }}>
                                        <ListItemButton
                                            aria-label="list-item-nav"
                                            onClick={() => { page.children && handleToggleClick(page.name) }}
                                            sx={{
                                                minHeight: 53,
                                                px: 2.5,
                                                backgroundColor: page.paths === location.pathname ? '#5d5d5d' : (page.children && (openMenu && (getToggledMenu === page.name))) || (page.children && (page.children.some(child => child.paths === location.pathname))) ? '#2C2C2C' : 'none'
                                            }}
                                        >
                                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 0, color: 'white' }}>
                                                {page.icon}
                                            </ListItemIcon>
                                            {open &&
                                                <>
                                                    <ListItemText color="white" primary={page.name} />
                                                    {page.children && (openMenu && (getToggledMenu === page.name) ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />)}
                                                </>

                                            }
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                                {(page.children) && (
                                    <Collapse in={openMenu && (getToggledMenu === page.name)} timeout="auto" unmountOnExit>
                                        {renderSubMenu(page)}
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>
                </Drawer>
            }
            {open && screenSize.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION && <Box className='sideBarOverLayMobile' sx={{ width: window.innerWidth, height: window.innerHeight }} onClick={() => setOpen(false)}></Box>}
            <Box component="main" sx={{ flexGrow: 1 }}>
                {location.pathname !== "/" && location.pathname !== "/login" && <DrawerHeader />}
                <Box sx={{ width: location.pathname !== "/" && location.pathname !== "/login" ? screenSize?.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION ? open ? window.innerWidth - 260 : window.innerWidth - 65 : window.innerWidth : window.innerWidth, height: window.innerHeight - 64 }}>
                    <Routes>
                        <Route path='/' exact={true} element={<SignIn />} />
                        <Route path='login' element={<SignIn />} />
                        <Route path='dashboard' element={<Dashboard error={error} />} />
                        <Route path='myAccount' element={<MyAccount />} />
                        <Route path="/login/callback" element={<LoginCallback />} />
                        {
                            selectedSite &&
                            <>
                                <Route path='/assets' element={<AssetsHomePage />} />
                                <Route path='/assets/createAsset' element={<CreateAsset />} />
                                <Route path='/workorders' element={<WorkOrdersHomePage />} />
                                <Route path='/workorders/details' element={<WorkOrderDetails />} />
                                <Route path='/workorders/createWorkOrder' element={<CreateWorkOrder />} />
                                <Route path='/assets/summary' element={<Summary />} />
                                <Route path='/assets/workorderhistory' element={<AssetWorkorderHistory />} />
                                <Route path='/admin/sites' element={<Sites />} />
                                <Route path='/admin/security' element={<SecurityAdmin />} />
                                <Route path='/admin/devices' element={<Devices />} />
                                {/* <Route path='/admin/notifications' element={<NotificationsBroadcast />} /> */}
                                <Route path='/reports/failure-analysis' element={<FailureAnalysis />} />
                                <Route path='/reports/labor-hours' element={<LaborHours />} />
                                <Route path='/reports/workorder-history' element={<WorkOrderHistory />} />
                                <Route path='/reports/eamAsset-Listing' element={<EAMAssetListing />} />
                                <Route path='/admin/workorder-access-log' element={<WorkOrderAccessLog />} />
                            </>
                        }
                    </Routes>
                </Box>
            </Box>
        </Box >
    );
}
