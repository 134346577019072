import React, { useContext, useEffect } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Link as MuiLink
} from "@mui/material";
import { SelectComponent } from "../../shared-components/common-field-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { deepOrange } from '@mui/material/colors';
import Logout from '@mui/icons-material/Logout';
import "./header.css"
import { UserContext } from "../../../store/UserContext";
import "./styles.css";
import AimxcelLogo from "../../../assets/images/aimxcel_logo.png";
import AimxcelLogoMobile from '../../../assets/images/aimxcel_logo_mobile.png';
import { useTranslation } from "react-i18next";
import { getUserSitesById } from "../../../services/MasterServices";
import { useOktaAuth } from "@okta/okta-react";
const Header = (props) => {
  const { t } = useTranslation();
  const { permissions } = useContext(UserContext);
  const { updateSelectedSite, defaultSiteId, userSitesData } = React.useContext(UserContext);
  const navigate = useNavigate();
  const pages = [
    { name: t("MENU_WORK_ORDERS"), paths: "/workorders", hasAccess: permissions.workOrders },
    { name: t("menuassets"), paths: "/assets", hasAccess: permissions.assets },
    { name: t("MENU_REPORTS"), paths: "/reports", hasAccess: permissions.reports },
    { name: t("menuadmin"), paths: "/admin", hasAccess: permissions.admin }
  ];

  const dropDownValues = [
    { id: 1, value: "MID - Midland IO" },
    { id: 2, value: "OKC - Oklahoma City IO " },
    { id: 3, value: "DIC - Dickinson IO" },
    { id: 4, value: "All" },
  ];
  const { oktaAuth, } = useOktaAuth();
  const { user, screenSize, appConstants } = React.useContext(UserContext);
  oktaAuth?.isAuthenticated().then(res => {
    if (window.location.pathname !== '/login/callback' && !res)
      oktaAuth.signOut();
  })

  const accessToken = user?.token;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [load, setLoad] = React.useState(false);
  const [allSitesData, setAllSitesData] = React.useState();
  const [site, setSite] = React.useState(defaultSiteId);
  const location = useLocation();

  useEffect(() => {
    if (defaultSiteId)
      localStorage.setItem("currentSiteId", defaultSiteId);
    setSite(defaultSiteId)
  }, [defaultSiteId])

  useEffect(() => {
    if (site && localStorage.getItem("currentSiteId") != site) {
      navigate(location.pathname);
      localStorage.setItem("currentSiteId", site);
    }
  }, [site])

  const fetchData = () => {
    getUserSitesById(accessToken, user?.id).then(response => {
      let sites = []
      if (response?.statusCode === "200") {
        response?.data?.forEach(x => { // BINDING ID, VALUE TO EXISITNG LIST
          x.id = x.organizationId;
          x.value = x.organizationCode + '-' + x.organizationName;
        });
        updateSelectedSite({ ...response.data.find(x => x.siteId == defaultSiteId) });
        setAllSitesData(response?.data);
      }
    })
      .catch(err => console.log(err));
  }

  React.useEffect(() => {
    fetchData()
  }, [accessToken]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
    }
  };
  const logOut = async () => {
    localStorage.removeItem("currentSiteId");
    await oktaAuth.signOut();
  }

  let currentPath = location.pathname;
  pages.map((page) => {
    if (currentPath.includes(page.paths)) {
      currentPath = page.paths;
    }
  });


  let currentPathName = location.pathname;
  pages.map((page) => {
    if (currentPathName.includes(page.paths)) {
      currentPathName = page.name;
    }
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const axSelectSite = (e) => {
    setSite(e); // UPDATING SELECTED ORGID TO DROPDOWN

    const site = userSitesData?.find(x => x.id === e); // FINSING ORG DETAILS BY ORGID

    updateSelectedSite({ ...allSitesData.find(x => x.siteId == e) });
  };

  const sortedUserSitesData = userSitesData?.slice().sort((a, b) => a?.orgName?.localeCompare(b?.orgName));

  return (
    <Box className='header-main' sx={{ flexGrow: 1 }}>
      <Box
        flex={1}
        display='flex'
        justifyContent='space-between'
        sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "space-between" }}
        className="logo-menu-dropdown-main-box"
      >
        <Box className="logo-menu-icon-parent-box">
          <Box className="logo" display='flex' alignItems='center' sx={{ gap: "10px" }}>
            <Box sx={{ display: { xs: "none", md: "none", lg: "flex", xl: "flex" } }}>
              <MuiLink
                component={Link}
                to={"/dashboard"}
                underline='none'
              >
                <Box
                  component='img'
                  title='logo'
                  sx={{ marginRight: "1em", height: 20 }}
                  src={AimxcelLogo}
                >
                </Box>
              </MuiLink>
            </Box>
            <Box className="logo" display='flex' alignItems='center' sx={{ display: { xs: "flex", md: "flex", lg: "none", xl: "none" } }}>
              <MuiLink
                component={Link}
                to={"/dashboard"}
                underline='none'
              >
                <Box
                  component='img'
                  title='logo'
                  sx={{ height: 24 }}
                  src={AimxcelLogoMobile}

                />
              </MuiLink>
            </Box>
          </Box>
          <Box className="current-page">
            <Typography >{currentPathName}</Typography>
          </Box>
        </Box>

        <Box className="select-site-header">
          {userSitesData && site &&
            <SelectComponent
              label=''
              values={sortedUserSitesData}
              selectedvalue={site}
              disabled={false}
              width={220}
              parentselectcallback={(e) => axSelectSite(e)}
              aria-label="User Sites Dropdown"
            ></SelectComponent>
          }
          <Box className="user-dp" alignContent="center" alignItems="center">
            <Avatar onClick={handleClick} sx={{ bgcolor: deepOrange[500], cursor: 'pointer', width: "30px", height: "30px" }}>{(user?.name.match(/\b(\w)/g))?.join('')}</Avatar>
          </Box>
        </Box>

        <Box className='menuItem'>
          <Box display='flex' alignItems='center' justifyContent={'flex-end'}>
            <Avatar onClick={handleClick} sx={{ bgcolor: "#FF0000", cursor: 'pointer', fontSize: '1rem' }}>{(user?.name.match(/\b(\w)/g))?.join('')}</Avatar>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {screenSize?.dynamicWidth < appConstants.MOBILE_RESOLUTION &&
              <MenuItem>
                {userSitesData && site &&
                  <SelectComponent
                    label=''
                    values={sortedUserSitesData}
                    selectedvalue={site}
                    disabled={false}
                    width={220}
                    parentselectcallback={(e) => axSelectSite(e)}
                  ></SelectComponent>
                }
              </MenuItem>
            }
            <MenuItem component={Link}
              to={'/myAccount'}
              underline='none' >
              {t('MY_ACCOUNT')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={logOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t('menulogout')}
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
          justifyContent: "space-between",
        }}
      >
        <Box className="logo-menu-parent-box">
          
          <Box className="logo" display='flex' alignItems='center'>
            <MuiLink
              component={Link}
              to={"/dashboard"}
              underline='none'
            >
              <Box
                component='img'
                title='logo'
                sx={{ height: 30 }}
                src={AimxcelLogoMobile}
              />
            </MuiLink>
          </Box>
          {location.pathname !== "/dashboard" && (
            <Box className="current-page">
              <Typography >{currentPathName}</Typography>
            </Box>
          )}
        </Box>
        <Box className="user-dp-mobile" display='flex' alignContent="center" alignItems="center" padding="10px">
          <Avatar onClick={handleClick} sx={{ bgcolor: deepOrange[500], cursor: 'pointer', width: "30px", height: "30px", fontSize: '0.8rem' }}>{(user?.name.match(/\b(\w)/g))?.join('')}</Avatar>
        </Box>
      </Box>
    </Box >
  );
};

export default Header;
