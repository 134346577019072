export const TRANSLATIONS_EN = {
  welcome: "Welcome to the tutorial",
  signin: "Sign In",
  password: "Password",
  menudashboard: "Dashboard",
  menumaintenance: "Maintenance",
  menuassets: "Assets",
  NOTIFICATIONS: 'Notifications',
  MENU_WORK_ORDERS: 'Work Orders',
  MENU_PLANNED_ASSET_INVENTORY: "Planned Asset Inventory",
  MENU_EXECUTE_INVENTORIES: "Execute Inventories",
  MENU_REPORTS: 'Reports',
  MENU_BATCH_PROCESS: 'Batch Process',
  MY_ACCOUNT: 'My Account',
  processedFrom: "Processed From",
  processedTo: "Processed To",
  menusites: "Sites",
  menusecurity: "Security",
  menudevices: "Devices",
  menunotifications: "Notifications",
  menulogs: "Logs",
  menuadmin: "Admin",
  menuprofile: "Profile",
  menusettings: "Settings",
  menulogout: "Logout",
  profilelanguage: "Language",
  profilesave: "Save",
  empfirstname: "First Name",
  emplastname: "Last Name",
  empemail: "Email",
  opensettings: "Open Settings",
  INPUTLABEL: "Field-Label",
  REPOST: 'Repost',
  REALSE: "Release",
  WO_CREATE_WORKORDER: 'Create Work Order',
  WO_COMPLETE_WORKORDER: 'Complete Work Order',
  WO_RELEASE_DATE: 'Release Date',
  WO_CREATE_DATE: 'Create Date',
  WO_ACTUAL_START: 'Actual Start',
  WO_ACTUAL_FINISH: 'Actual Finish',
  WO_ADD_TASK: 'Add Task',
  WO_OPERATIONS_LABEL: 'Operations',
  WO_OPERATIONS_TASK: 'Task',
  WO_OPERATIONS_DATE: "Date Required",
  WO_OPERATIONS_ITEM: 'Item',
  WO_OPERATIONS_DESCRIPTION: 'Description',
  WO_OPERATIONS_QUANTITY: 'Quantity',
  WO_OPERATIONS_DESCRIPTION: 'Description',
  WO_OPERATION_DEPARTMENT: 'Department',
  WO_OPERATIONS_RESOURCE: 'Resource',
  WO_OPERATIONS_ACTUAL_FINISH: 'Actual Finish',
  WO_OPERATIONS_CONDITION_VALUE: 'Condition Value',
  WO_OPERATIONS_LAST_VALUE: 'Last Value',
  WO_OPERATIONS_CONDITION_NOTE: 'Condition Note',
  WO_OPERATIONS_COMPLETE_TASK: 'Complete Task',

  WO_LABOR_LABEL: 'Labor',
  WO_LABOR_ADD_LABEL: 'Add Labor',
  WO_LABOR_TASK: 'Task',
  WO_LABOR_RESOURCE: 'Resource',
  WO_LABOR_EMPLOYEE: 'Employee',
  WO_LABOR_START: 'Start',
  WO_LABOR_TRANSACTION_DATE: 'Transaction Date',
  WO_LABOR_FINISH: 'Finish',
  WO_LABOR_DURATION: 'Duration (Hours)',
  WO_LABOR_STATUS: 'Status',

  WO_DOCUMENTS_LABEL: 'Documents',
  WO_DOCUMENTS_TYPE: 'Type',
  WO_DOCUMENTS_ATTACHMENT_FILE_NAME: 'Attachment File Name',
  WO_DOCUMENTS_DESCRIPTION: 'Description',
  WO_DOCUMENTS_UPDATED_BY: 'Updated By',
  WO_DOCUMENTS_UPDATED_DATE: 'Updated Date',
  WO_DOCUMENTS_DOWNLOAD: 'Download',
  WO_DOCUMENTS_ATTACH: 'Attach',

  WO_METERS_LABEL: 'Meters',
  WO_METERS_REQUIREMENT: 'Required',
  WO_METERS_MEASUREMENT: 'Meter',
  WO_METERS_NEW_VALUE: 'New Value',
  WO_METERS_LAST_READING: 'Last Reading',
  WO_METERS_LATEST_VALUE: 'Latest Value',
  WO_METERS_READING_DATE: 'Reading Date',
  WO_METERS_VALUE_CHANGE: 'Value Change',
  WO_METERS_NEW_READING: 'New Reading',
  WO_METERS_INSPECTOR: 'Inspector',
  WO_METERS_RECORDED: 'Recorded',
  WO_METERS_VALUE: 'Value',
  WO_METERS_UOM: 'UoM',



  WO_FAILURE_LABEL: 'Failure',
  WO_FAILURE_ADD_LABEL: 'Add Failure',
  WO_FAILURE_EDIT_LABEL: 'Edit Failure',
  WO_FAILURE_CAUSE: 'Cause',
  WO_FAILURE_RESOLUTION: 'Resolution',
  WO_FAILURE_FAILURE_DATE: 'Failure Date',
  WO_FAILURE_COMMENTS: 'Comments',


  WO_FILTERS_INPUTLABEL_WORKORDERS: "Work Order",
  WO_FILTERS_INPUTLABEL_ISMETE: "Is M & TE",
  WO_FILTERS_INPUTLABEL_LINEAR: "Linear",
  WO_FILTERS_INPUTLABEL_TYPE: "Type",
  WO_FILTERS_INPUTLABEL_LOCATION: "Location",
  WO_FILTERS_INPUTLABEL_LOOP: 'Loop Location',
  WO_FILTERS_INPUTLABEL_PARENT: 'Parent',
  WO_FILTERS_INPUTLABEL_ROTATING: "Rotating Item",
  WO_FILTERS_INPUTLABEL_ASSET: "Asset",
  WO_FILTERS_INPUTLABEL_ASSET_DESCRIPTION: "Asset Description",
  WO_FILTERS_INPUTLABEL_RIG_YARD: "Rig/Yard",
  WO_FILTERS_INPUTLABEL_STATUS: "Status",
  WO_SUMMARY_INPUTLABEL_PARENT: "Parent",
  WO_SUMMARY_INPUTLABEL_ASSET_SERIAL_NUMBER: "Asset Serial Number",
  WO_SUMMARY_DATEPICKER_CREATE_DATE: "Create Date",
  WO_SUMMARY_DATEPICKER_ACTUAL_START: "Actual Start",
  WO_SUMMARY_DATEPICKER_ACTUAL_FINISH: "Actual Finish",
  WO_CREATE_WO_DROPDOWN_CATEGORY: "Category",
  WO_CREATE_WO_DROPDOWN_CLASS_CODES: "Class Codes",
  WO_CREATE_WO_DATEPICKER_SCHEDULED_START: "Scheduled Start",
  WO_CREATE_WO_DATEPICKER_SCHEDULED_FINISH: "Scheduled Finish",
  WO_CREATE_WO_DROPDOWN_SOURCE_ASSET_RIG: "Source Asset or Rig",
  WO_CREATE_WO_DROPDOWN_PRIORITY: "Priority",
  WO_CREATE_WO_INPUTLABEL_ASSET_GROUP: "Asset Group",
  WO_CREATE_WO_INPUTLABEL_EMPLOYEE_INITIALS: "Employee Initials",
  WO_CREATE_WO_INPUTLABEL_MOVED_TO_RIG: "Moved To Rig",
  WO_CREATE_WO_DATEPICKER_DATE_OF_REMOVAL: "Date of Removal",
  WO_CREATE_WO_INPUTLABEL_BRIEF_DESCRIPTION: "Brief Description",
  WO_CREATE_WO_INPUTLABEL_ADDITIONAL_DESCRIPTION: "Additional Description",
  WO_GRID_WO_INPUTLABEL_WORKORDER: "Work Order",
  WO_GRID_WO_INPUTLABEL_ASSET: "Asset",
  WO_GRID_WO_INPUTLABEL_RIG: "Rig",
  WO_GRID_WO_SELECT_STATUS: "Status",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG1: "Configuration 1",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG2: "Configuration 2",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG3: "Configuration 3",
  WO_ASSET_SUMMARY_SELECTLABEL_CONFIG4: "Configuration 4",

  WO_RIG_NUMBER: 'Rig Number',
  WO_PLAN_CREATION_STATUS: 'Plan Creation Status',
  WO_PLAN: 'Plan',
  WO_CREATE_START: 'Create Start',
  WO_CREATE_END: 'Create End',
  WO_ERROR: 'Error',

  MTF_MATERIAL_TRANSFER_FORM: 'Material Transfer Form',
  MTF_CREATE_MATERIAL_TRANSFER_FORM: 'Create Material Transfer Form',
  MTF_FILTERS: "Filters",
  MTF_SEARCH: 'Search',
  MTF_EDIT: "Edit",
  MTF_SAVE: "Save",
  MTF_TRANSFER_DETAILS: 'Transfer Details',
  MTF_ASSET_INFORMATION: 'Asset Information',
  MTF_SHIPPING_INFORMATION: 'Shipping Information',
  MTF_FORM: "Form",
  MTF_REVISION: 'Revision',
  MTF_EFFECTIVE_DATE: "Effective Date",
  MTF_NEXT: 'Next',
  MTF_BACK: 'Back',
  MTF_SUBMIT: 'Submit',
  MTF_SHIPPING_COMPANY_NAME: 'Shipping Company Name',
  MTF_TRUCK_NUMBER: 'Truck Number',
  MTF_PRINT_DRIVER_NAME: 'Print Driver Name',
  MTF_PICK_UP_DATE_TIME: 'Pick Up Date and Time',
  MTF_DELIVER_DATE_TIME: 'Delivery Date and Time',
  MTF_ITEM: "Item",
  MTF_QUANTITY: 'Quantity',
  MTF_ASSET: 'Asset',
  MTF_SERIAL_NUMBER: 'Serial Number',
  MTF_DESCRIPTION: 'Description',
  MTF_MANUFACTURER: 'Manufacturer',
  MTF_MODEL: 'Model',
  MTF_LENGTH_WIDTH_HEIGHT: 'Length, Width & Height',
  MTF_WEIGHT: 'Weight',
  MTF_LOAD_ASSIST_REQUIRED: 'Load Assist Required?',
  Mtf_leftbar_filter_inputlabel_FormID: "Form ID",
  Mtf_leftbar_filter_inputlabel_SerialNo: "Serial Number",
  Mtf_leftbar_filter_inputlabel_Asset: "Asset",
  Mtf_DATEPICKER_filter_inputlabel_From: "From Date",
  Mtf_DATEPICKER_filter_inputlabel_To: "To Date",
  Assets_leftbar_filter_inputlabel_Asset: "Asset",
  Assets_leftbar_filter_inputlabel_keyword: "Keyword",
  Assets_leftbar_filter_inputlabel_Type: "Type",
  Assets_leftbar_filter_inputlabel_Description: "Description",
  Assets_leftbar_filter_inputlabel_Parent: "Parent",
  Assets_leftbar_filter_inputlabel_Locator: "Locator",
  Assets_leftbar_filter_inputlabel_Category: "Category",
  Assets_leftbar_filter_inputlabel_Manufcature: "Manufacture's S/N",
  Assets_leftbar_filter_inputlabel_RFID: 'RFID',



  Assets_leftbar_filter_inputlabel_manufacturer: "Manufacturer's Serial Number",
  Assets_leftbar_filter_inputlabel_site: "Site",
  Assets_leftbar_filter_inputlabel_parent: "Parent",
  ADMIN_LOGS_EMPLOYEEACCESSLOGS_INPUTLABEL_KEYWORD: "Keyword",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_INPUTLABEL_NAME: "Name",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_INPUTLABEL_QUERYPARAM: "Query Param",
  DATEPICKER: "Date Picker",
  Mtf_Shipping_inputlabel_TransferIssuerName: "Transfer Issuer Name",
  Mtf_Shipping_inputlabel_AuthorizerName: "Authorizer Name",
  Mtf_Shipping_inputlabel_FromRigNumber: "From Rig Number or Location Name",
  Mtf_Shipping_inputlabel_ToRigNumber: "To Rig Number or Location Name",
  MTF_RIG_PLACEHOLDER: "include nearest city and state",
  Mtf_Shipping_inputlabel_DestinationPOC: "Destination Point of Contact Name",
  Mtf_Shipping_inputlabel_POCPhoneNumber: "Point of Contact Phone Number",
  Mtf_Shipping_inputlabel_ReasonForTransfer: "Reason for Transfer",
  Mtf_Shipping_DATEPICKER_IssueDate: "Issue Date",
  Mtf_Shipping_DATEPICKER_AuthorizationDate: "Authorization Date",
  Mtf_Shipping_DropDown_TransferesFrom: "Transferred From",
  Mtf_Shipping_DropDown_TransferesTo: "Transferred To",
  Mtf_Shipping_DropDown_ReceiverName: "Receiver Name",
  Mtf_Shipping_DropDown_ReceivedDate: "Received Date",
  Mtf_Shipping_inputlabel_POCEmailID: "Email ID",

  REPORTS_EMPLOYEE: "Employee Name",
  REPORTS_FROMDATE: "From Date",
  REPORTS_TODATE: " To Date",
  REPORTS_WORKING_STATUS: "Wroking Status",
  FAILURE_ANALYSIS: 'Failure Analysis',
  ASSET_HISTORY: "Asset History",
  WORKORDER_HISTORY: "WorkOrder History",
  EAMASSET_LISTING: "EAM Asset Listing",

  LABOR_HOURS: "Labor Hours",
  INVENTRORY_REPORTS: "Inventory Reports",
  CONDITION_BASED_HISTORY: "Condition Based History",
  UPDATED_FROM: "Updated From",
  UPDATED_TO: "Updated To",

  SITES: "Sites",
  SECURITY: "Security",
  DEVICES: "Devices",
  ADD_DEVICE: 'Add Device',
  RESET_DATE: 'Reset Date',
  LOGS: "Logs",
  SITE: "Site",
  WORKORDER: 'Work Order',
  OPERATING_UNIT: "Operating Unit",
  DESCRIPTION: "Description",
  LOCATION: "Location",
  IS_OPERATING_UNIT: "Is Operating Unit",
  IS_INVENTORY_ORG: "Is Inventory Org",
  ACTIVE: "Active",
  USERS_LIST: "Users List",
  USERS: 'Users',
  PERMISSION_LISTS: 'Permision Lists',
  DIVISION:'Division',
  ADD_PERMISSION_LISTS: 'Add Permision Lists',
  USER_ID: "User ID",
  USER_NAME: "User Name",
  EMAIL_ADDRESS: "Email Address",
  CONFIRM: 'Confirm',
  PHONE_NUMBER: "Phone Number",
  CURRENT_SITE: "Current Site",
  ADD_USER: "Add User",
  ROLES: "Roles",
  DIVISIONS: "Divisions",
  ACTIONS: "Actions",
  SITES_LIST: "Sites List",
  LOCATION_LIST: "Location List",
  ROLES_LIST: "Roles List",
  DIVISION_LIST: "Division List",
  ROLE_ID: "Role ID",
  DIVISION_ID: "Divison Id",
  DIVISION_DESCRIPTION:  "Division Description",
  ROLE_DESCRIPTION: "Role Description",
  ADD_ROLE: "Add Role",
  ADD_DIVISION: "Add Division",
  PERMISSION_LIST: "Permission List",
  PERMISSION_DESCRIPTION: "Permission Description",
  MENU_NAME: "Menu Name",
  MENU_DESCRIPTION: "Menu Description",
  ACCESS_TYPE: "Access Type",
  PERMISSION_LIST_ID: "Permission List ID",
  DEVICE: "Device",
  DEVICE_ROLE: "Device Role",
  RESET_DATA: "Reset Data",
  LAST_CONNECTED: "Last Connected",
  PENDING_DATA: "Pending Data",
  PENDING_APPLICATIONS: "Pending Applications",
  COLONIZATION: "Colonization",
  SAVE: "Save",
  BROADCAST_MESSAGES: "Broadcast Messages",
  ADD_BROADCAST_MESSAGE: "Add Broadcast Message",
  ADD_SCHEDULE_BATCH_PROCESS: "Add Schedule Batch Process",
  MESSAGE: "Message",
  START_DATE: "Start Date",
  EXPIRATIONS_DATE: "Expirations Date",
  NOTIFICATION_TEMPLATE: "Notification Template",
  TEMPLATE: "Template",
  SENDER: "Sender",
  ADD_TEMPLATE: "Add Template",
  PRIORITY: "Priority",
  INSTRUCTIONAL_TEXT: "Instructional Text",
  SUBJECT: "Subject",
  MESSAGE_TEXT: "Message Text",
  OPERATIONS: "Operations",
  LABOR: "Labor",
  DOCUMENTS: "Documents",
  METERS: "Meters",
  FAILURE_: "Failure ",
  WORK_ORDER_ACCESS_LOGS: "Work Order Access Logs",
  WORK_ORDER: "Work Order",

  TYPE: "Type",
  OLD_VALUE: "Old Value",
  PROCESS_STATUS: "Process Status",
  EMPLOYEE:"Employee",

  ACCEPT: "Accept",
  SERIAL_NUM: "Serial Number",
  SCAN_RFID_TAG: 'Scan a RFID Tag',
  AWAITING_SCAN: 'Awaiting Scan',

  WO_OPERATIONS_DEPARTMENT: 'Department',
  WO_LABOR_DEPARTMENT: 'Department',

  NEW_ASSET_TO_INVENTORY: "New Asset To Inventory",
  ASSET_NUMBER: "Asset Number",
  DATE_OF_LAST_ACTIVITY: "Date of Last Activity",
  DATE_INSERTED: "Date Inserted",
  WORKING_STATUS: "Working Status",
  PROCESSED: "Processed",
  CREATED: "Created",
  TASK: "Task",
  RESOURCE: "Resource",
  ACTUAL_FINISH: "Actual Finish",
  CONDITION_VALUE: "Condition Value",
  LAST_VALUE: "Last Value",
  CONDITION_NOTE: "Condition Note",
  EMPLOYEE: "Employee",
  START: "Start",
  FINISH: "Finish",
  DURATION: "Duration",
  ACTUAL_START: "Actual Start",
  ASSET_SERIAL_NUMBER: "Asset Serial Number",
  CREATE_DATE: "Create Date",
  ATTACHMENT_FILE_NAME: "Attachment File Name",
  UPDATED_BY: "Updated By",
  UPDATED_DATE: "Updated Date",
  REQUIREMENT: "Requirement",
  MEASUREMENT: "Measurement",
  NEW_VALUE: "New Value",
  VISIAL_DICTIONARY: "Visual Dictionary",
  UNIT_OF_MEASURE: "Unit of Measure",
  INPUT_RANGE: "Input range",
  NOTES: "Notes",
  UPDATE: "Update",
  LAST_READING: "Last Reading",
  NEW_READING: "New Reading",
  FAILURE: "Failure",
  CAUSE: "Cause",
  RESOLUTION: "Resolution",
  FAILURE_DATE: "Failure Date",
  COMMENTS: "Comments",
  EMPLOYEE_ACCESS_LOGS: "Employee Access Logs",
  EXPORT: "Export",
  EMPLOYEE_DESCRIPTION: "Employee Description",
  LOGIN: "Login",
  LOG_DATA: "Log Data",
  APPLICATION_MESSAGE_LOGS: "Application Message Logs",
  BATCH_PROCESS: "Batch Process",
  PROCESS_SCHEDULER: "Process Scheduler",
  PROCESS_MONITOR: "Process Monitor",
  SCHEDULE_BATCH_PROCESS: "Schedule Batch Process",
  WORKING_RIGS: "Working Rigs",
  GENERATE_INVENTORIES: "Generate Inventories",
  THIRD_PARTY_LOCATORS: "Third Party Locators",
  ADD_PHYSICAL_LOCATION: 'Add Physical Location',
  ADD_THIRD_PARTY_PHYSICAL_LOCATION: 'Add Third Party Physical Location',
  JOBNAME: "Jobname",
  CURRENT_STATUS: "Current Status",
  START_TIME: "Start Time",
  FINISHED_TIME: "Finished Time",
  STATUS: "Status",
  ENABLED: "Enabled",
  NAME: "Name",
  ADAPTER: "Adapter",
  ASSEMBLY: "Assembly",
  QUERY_PARAMETER: "Query Parameter",
  LAST_START: "Last Start",
  LAST_FINISH: "Last Finish",
  LAST_STATUS: "Last Status",
  ORDER: "Order",
  RUN_NOW: "Run Now",
  SCAN_INVENTORY: "Scan Inventory",
  FIELD_NAME: "Field Name",
  FIELD_TYPE: "Field Type",
  VALUE: "Value",
  FREQUENCY: "Frequency",
  LAST_RUN_TIME: "Last Run Time",
  NEXT_RUN_TIME: "Next Run Time",
  ENABLE_DISABLE: "Enable/Disable",
  RIG_NUMBER: "Rig Number",
  PLAN_CREATION_STATUS: "Plan Creation Status",
  PLAN: "Plan",
  CREATE_START: "Create Start",
  CREATE_END: "Create End",
  ERROR: "Error",
  ASSET_INVENTORY_PLAN: "Asset Inventory Plan",
  CREATED_BY: "Created By",
  TOTAL_ASSET_COUNT: "Total Asset Count",
  INVENTORY_ORGS: "Inventory Orgs",
  ASSET_COUNT: "Asset Count",
  INVENTORY_ORG: "Inventory Org",
  SUB_INVENTORIES: "Sub Inventories",
  DIVISION: "Division",
  LOCATORS: "Locators",
  LOCATOR: "Locator",
  LOCATION: "Location",
  RECOLONIZE: 'Recolonize',
  DECOLONIZE: 'De-Colonize',
  SOFT_RECOLONIZE: 'Soft Recolonize',
  NO_RECORDS_FOUND: "No records found",
  ASSET: "Asset",
  HIERARCHY: "Hierarchy",
  HANDHELD_SCAN: "Handheld Scan",
  VERIFED_BY_SCAN: 'Verified By Scan',
  CREATED_DATE: 'Created Date',
  VERIFIED_LOCATION: "Verified Location",
  INVENTORY_BY: "Inventory By",
  VERIFIED_DATE: "Verified Date",
  MODEL_NUMBER: "Model Number",
  MANUFACTURER: "Manufacturer",
  MANUFACTURER_NAME: "Manufacturer Name",
  MANUFACTURER_SN: "Manufacturer's S/N",
  MODAL: "Model",
  SERIAL_NUMBER: 'Serial Number',
  FULL_NAME: 'Full Name',
  APPROVED_BY: "Approved By",
  APPROVED_DATE: "Approved Date",
  ADD_INVENTORIES: "Add Inventories",
  ADDED_IN_FEILD: 'Added in Field',
  ADD_INVENTORY_ORGS: 'Add Inventory Orgs',
  ASSIGNMENTS: "Assignments",
  ADD_SUB_INVENTORIES: 'Add Sub Inventories',
  ADD_ASSIGNMENTS: "Add Assignments",
  PLAN_STATUS: "Plan Status",
  ASSIGNED_TO: "Assigned To",
  PLAN_DESCRIPTION: "Plan Description",
  ARCHIVED_ASSIGNED_TO: "Archived Assigned to",
  ARCHIVED_EMPLOYEE: "Archived Employee",
  PHYSICAL_LOCATION: "Physical Location",
  INVENTORY_OWNER: "Inventory Owner",
  SUB_INVENTORY: "Sub Inventory",
  PLANNED_ASSET_INVENTORY: "Planned Asset Inventory",
  COMPLETE_DATE: "Complete Date",
  STARTED: "Started",
  RESPONSIBLE: "Responsible",
  INVENTORY_FROM: "Inventory From",
  INVENTORY_TO: "Inventory to",
  EXPORT_TO_EXCEL: "Export to Excel",
  ASSET_INVENTORY_ITEMS: "Asset Inventory Items",
  ASSET_SUMMARY: "Asset Summary",
  VERIFIED_BY_SCAN: "Verified By Scan",
  VERIFIED_MANUALLY: "Verified Manually",
  NOT_FOUND: "Not Found",
  NOT_VERIFIED: "Not Verified",
  ADDED_IN_FIELD: "Added in field",
  MTF_ID: 'Id',
  COMPLETION: "Completion",
  BREAKDOWN_BY_LOCATOR: "Breakdown by Locator",
  FILTERS: "Filters",
  KEYWORD: "Keyword",
  Released: "Released",
  Complete: "Complete",
  On_Hold: "On Hold",

  RIG_YARD: "Rig Yard",
  ASSET_DESCRIPTION: "Asset Description",
  RELEASE_DATE: "Release Date",

  PARENT: "Parent",
  SEARCH: "Search",
  ASSETS: "Assets",
  SUMMARY: "Summary",
  PARENT_ASSET: "Parent Asset",
  TRACKING: "Tracking",
  AREA: "Area",
  SUBINVENTORY: "SubInventory",
  INCLUDE_IN_INVENTORY: "Include in Inventory",
  CONFIGURATION: "Configuration",
  RFID: "RFID",
  SERIAL: 'Serial',
  MODEL: "Model",
  MANUFACTURERS_SERIAL_NUMBER: "Manufacturer's Serial Number",
  CATEGORY: "Category",
  MAINTAINABLE: "Maintainable?",
  INSTALL_DATE: "Install Date",
  MODIFIED_DATE: "Modified Date",
  ATTACHMENTS: "Attachments",
  DOWNLOAD: "Download",
  OK: "OK",
  CANCEL: "Cancel",
  YES: "Yes",
  NO: "No",
  ADD_LOCATORS: 'Add Locators',
  GENERATE_ITEMS: "Generate Items",
  SET_IN_PROGRESS: "Set In Progress",
  COMPLETE_PLAN: 'Complete Plan',
  ARCHIVE: 'Archive',
  JOB_NAME: 'Job Name',
  BATCH_PROCESS_ID: 'Batch Process Id',
  PROCESS_ID: 'Process Id',
  ORG_ID: 'Org Id',
  ORG: 'Org',
  JOB_PLAN: "Job plan",
  SCEDULE_BATCH_PROCESS: 'Schedule Batch Process',
  EXPIRATION_DATE: 'Expiration Date',
  PAI_ASSETS_DATEPICKER_INVENTORY_FROM: "Inventory From",
  PAI_ASSETS_DATEPICKER_INVENTORY_TO: "Inventory To",
  PAI_ASSETS_PROCESS_STATUS: "Process Status",
  PAI_ASSETS_INVENTORY_STATUS: "Inventory Status",
  REMOVE_PLAN: 'Remove Plan',
  JOB_NAME_DESCRIPTION_START_TIME: "Job Name, Description, Start Time",
  JOB_NAME_DESCRIPTION_CURRENT_STATUS: "Job Name, Start Date, Current Status",
  ADMIN_LOGS_EMPLOYEEACCESSLOGS_DATEPICKER_FROM: "From",
  ADMIN_LOGS_EMPLOYEEACCESSLOGS_DATEPICKER_TO: "To",
  ADD_THIRD_PARTY_LOCATION: 'Add Third Party Location',
  PHYSICAL_LOCATION_LOCATOR: 'Physical Location, Locator',
  RIG_NUMBER_PLAN_CREATION_STATUS_PLAN: 'Rig Number, Plan Creation Status, Plan',
  ADMIN_DEVICES_FILTER_INPUTLABEL_DEVICES: "Device",
  ADMIN_DEVICES_FILTER_DROPDOWN_DEVICES: "Device Role",
  ADMIN_DEVICES_FILTER_DROPDOWN_TYPE: "Type",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_STATUS: "Status",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_TYPE: "Type",
  BATCHPROCESS_PROCESSMONITOR_PROCESSMONITOR_DROPDOWN_ADAPTER: "Adapter",
  DEPARTMENT: "Department",

  MAINTENANCE_SUMMARY: "Maintenance Summary",
  CAT_III_MAINTENANCE_SUMMARY: "CAT III Maintenance Summary",
  INSPECTION_REPORT: "Inspection Report",
  THIRD_PARTY_SERVICE_TICKETS: "Third party service tickets",
  PARTS_LIST: "Parts list",
  PHOTOS: "Photos",
  CAT_III_CORRECTIVE_ACTION: "CAT III corrective action",
  MATERIAL_CERT: "Material Cert",
  ENGINEERING_WORK_PROCEDURE: "Engineering Work Procedure",



  MTF_TRANSFER_INPUTLABEL_ISSUEREMAILID: "Issuer's Email ID",
  MTF_TRANSFER_INPUTLABEL_AUTHORIZEREMAILID: "Authorizer's Email ID",
  MTF_TRANSFER_INPUTLABEL_RECIEEVREMAILID: "Receiver's Email ID",

  Pai_Table_Description: "Description",
  Pai_Table_Status: "Status",
  Pai_Table_Type: "Type",
  Pai_Table_Started: "Started",
  Pai_Table_CreatedBy: "Created By",
  Pai_Table_Created: "Created",

  keyword: "Key Word",
  createby: "Created By",
  completedate: "Complete Date",
  status: "Status",
  type: "Type",

  ACCOUNT_DETAILS: 'Account Details',
  ROWS_PER_PAGE: 'Rows per page',
  DELETE: 'Delete',
  VIEW: "View",
  EDIT: 'Edit',
  CLASS: 'Class',
  FROM: 'From',
  TO: 'To',
  ALL: 'All',
  ADD_SELECTED: 'Add Selected',
  CLEAR: 'Clear',
  ARCHIVE_ASSIGNED_TO: 'Archive Assigned To',
  ARCHIVE_EMPLOYEE: 'Archive Employee',
  RIG: 'Rig',
  PLANNED_ASSET_INVENTORY_NAME: 'Planned Asset Inventory Name',
  INVENTORY_STATUS: 'Inventory Status',
  SHOW_ARCHIVED: 'Show Archived',
  VERIFIED_FROM: 'Verified From',
  VERIFIED_TO: 'Verified To',
  MAINTENANCE_ACTIVITY: 'Maintenance Activity'


};
