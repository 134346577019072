import * as React from "react";
import { Grid, Box, Typography, } from '@mui/material';
import { DatePickerComponent, InputComponent } from "../shared-components/common-field-components";


const AssetTransferForm = (props) => {
    
    const { formik} = props;

    return (
        <Box sx={{
            padding: {
                xs: '4px',
                lg: '1px',
            }
        }} >
            <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={2} rowSpacing={2} >
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Organization</Typography>
                        <InputComponent
                            name="AssetTransfer.organization"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.organization && formik.errors.AssetTransfer?.organization}
                            value={formik.values.AssetTransfer?.organization || ''}
                        />
                    </Grid>




                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Source System</Typography>
                        <InputComponent
                            name="AssetTransfer.sourceSystem"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.sourceSystem && formik.errors.AssetTransfer?.sourceSystem}
                            value={formik.values.AssetTransfer?.sourceSystem || ''}

                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Consignee</Typography>
                        <InputComponent
                            name="AssetTransfer.consignee"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.consignee && formik.errors.AssetTransfer?.consignee}
                            value={formik.values.AssetTransfer?.consignee || ''}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Ship Method</Typography>
                        <InputComponent
                            name="AssetTransfer.shipMethod"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.shipMethod && formik.errors.AssetTransfer?.shipMethod}
                            value={formik.values.AssetTransfer?.shipMethod || ''}
                        />


                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Scheduled Start Date</Typography>
                        <DatePickerComponent
                            name="scheduledStartDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onDateSelect={(date) => formik.setFieldValue("AssetTransfer.scheduledStartDate", date, true)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetTransfer?.scheduledStartDate && formik.errors.AssetTransfer?.scheduledStartDate}
                            value={formik.values.AssetTransfer?.scheduledStartDate || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Scheduled Order Date</Typography>
                        <DatePickerComponent
                            name="scheduledOrderDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onDateSelect={(date) => formik.setFieldValue("AssetTransfer.scheduledOrderDate", date, true)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetTransfer?.scheduledOrderDate && formik.errors.AssetTransfer?.scheduledOrderDate}
                            value={formik.values.AssetTransfer?.scheduledOrderDate || ''}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">OrderType</Typography>
                        <InputComponent
                            name="AssetTransfer.orderType"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.orderType && formik.errors.AssetTransfer?.orderType}
                            value={formik.values.AssetTransfer?.orderType || ''}
                        />


                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Status</Typography>
                        <InputComponent
                            name="AssetTransfer.status"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.status && formik.errors.AssetTransfer?.status}
                            value={formik.values.AssetTransfer?.status || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Item</Typography>
                        <InputComponent
                            name="AssetTransfer.item"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.item && formik.errors.AssetTransfer?.item}
                            value={formik.values.AssetTransfer?.item || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Shipment Priority</Typography>
                        <InputComponent
                            name="AssetTransfer.shipmentPriority"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.shipmentPriority && formik.errors.AssetTransfer?.shipmentPriority}
                            value={formik.values.AssetTransfer?.shipmentPriority || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Freight</Typography>
                        <InputComponent
                            name="AssetTransfer.freight"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.freight && formik.errors.AssetTransfer?.freight}
                            value={formik.values.AssetTransfer?.freight || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6"> Pick Up Date</Typography>
                        <DatePickerComponent
                            name="pickUpDate"
                            width="100%"
                            type="date"
                            disabled={false}
                            onDateSelect={(date) => formik.setFieldValue("AssetTransfer.pickUpDate", date, true)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetTransfer?.pickUpDate && formik.touched.pickUpDate && formik.errors.pickUpDate}
                            value={formik.values.AssetTransfer?.pickUpDate || ''}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Tracking Number</Typography>
                        <InputComponent
                            name="AssetTransfer.trackingNumber"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.trackingNumber && formik.errors.AssetTransfer?.trackingNumber}
                            value={formik.values.AssetTransfer?.trackingNumber || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Freight Terms</Typography>
                        <InputComponent
                            name="AssetTransfer.freightTerms"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.freightTerms && formik.errors.AssetTransfer?.freightTerms}
                            value={formik.values.AssetTransfer?.freightTerms || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Service Level</Typography>
                        <InputComponent
                            name="AssetTransfer.servicelevel"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.servicelevel && formik.errors.AssetTransfer?.servicelevel}
                            value={formik.values.AssetTransfer?.servicelevel || ''}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6">Carrier</Typography>
                        <InputComponent
                            name="AssetTransfer.carrier"
                            width="100%"
                            onChange={formik.handleChange}
                            error={formik.touched.AssetTransfer?.carrier && formik.errors.AssetTransfer?.carrier}
                            value={formik.values.AssetTransfer?.carrier || ''}
                        />


                    </Grid>

                </Grid>
            </form>
        </Box>
    );
};

export default AssetTransferForm;
