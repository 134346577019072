import React, { useEffect, useState, useContext, useCallback } from "react";
import { Grid, Box, Typography, IconButton, Stack } from "@mui/material";
import "./admin.css";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  InputComponent,
  AxDevicestableComponent,
  ButtonComponent,
  SelectComponent,
} from "../shared-components/common-field-components";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/material/Drawer";
import { deviceTableColumns } from "../constants/DevicesTable";
import DeviceFilters from "./DeviceFilters";
import {
  addDevice, decolonizeDevice,
  deleteDevice,
  getAllDevices, recolonizeDevice,
  searchDevices,
  updateDevices,
} from "../../services/AdminDevicesServices";
import { UserContext } from "../../store/UserContext";

import { t } from "i18next";
import ToastMessage from "../shared-components/ToastMessage";
import { getAllSites } from "../../services/AdminSitesServices";
import { ConfirmDialog } from "../shared-components/ConfirmDialog";

const Devices = (props) => {
  const DropdownRole_label = t("ORG_ID");
  const Devices_label = t("ADMIN_DEVICES_FILTER_INPUTLABEL_DEVICES");
  const [state, setState] = useState({
    right: false,
  });
  const [device, setDevice] = useState("");
  const [deviceRole, setDeviceRole] = useState(0);
  const [addDeviceData, setaddDeviceData] = useState(false);
  const [isAddDeviceSave, setIsAddDeviceSave] = useState(false);
  const [enableBackButton, setEnableBackButton] = useState(false);
  const [showFilterFields, setShowFilterFields] = useState(false);

  const { user } = useContext(UserContext);
  const [openCancel, setOpenCancel] = useState(false);
  const [devicesData, setDevicesData] = useState();
  const [sitesDataOptions, setSitesDataOptions] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [clearDataToSave, setClearDataToSave] = useState(false);
  const [dataToSave, setDataToSave] = useState([]);
  const [newDeviceData, setNewDeviceData] = useState({});
  const [isNewDevice, setIsNewDevice] = useState();
  const [toast, setToast] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [disableColonizeButtons, setDisableColonizeButtons] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [totalSitesData, setTotalSitesData] = useState([]);
  const fetchData = () => {
    try {
      setLoading(true);
      getAllDevices(0, 10000).then((response) => {
        if (response?.statusCode === "200") {
          response?.data?.content?.forEach(x => {
            x.selected = false;
          });
          setDevicesData(response?.data);
          setClearDataToSave(false);
          setShowSave(false);
          setLoading(false);
        }
      });
    } catch (error) {
    }
  };

  const fetchSiteData = () => {
    try {
      setLoading(true);
      getAllSites(0, 10000).then((response) => {
        if (response?.statusCode === "200") {
          setTotalSitesData(response?.data?.content);
          setSitesDataOptions(
            response?.data?.content?.map((item) => ({ id: item.orgCode, value: item.orgName })),
            setLoading(false)
          );
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSiteData();
  }, []);


  const setToastMessages = (type, message) => {
    const toastProps = {
      open: true,
      type,
      message,
      onClose: () => setToast({}),
    };
    setToast(toastProps);
  };

  const onAddDevice = useCallback(
    (newDeviceData) => {
      newDeviceData.createdBy = user?.name;
      addDevice(newDeviceData)
        .then((response) => {
          setLoading(true)
          if (response.statusCode === "200") {
            setToastMessages("success", "Added successfully");
            fetchData();
            setIsNewDevice(false);
            setClearDataToSave(true);
            setLoading(false);
          } else {
            if (response.data.message === "DUPLICATE KEY") {
              setToastMessages("error", `Device ${newDeviceData.device} already exist`);
              setLoading(false);
            }
            else {
              setToastMessages("error", "Some error occured")
              setLoading(false);
            }
          }
        })
        .catch((e) => {
          setToastMessages("error", "Some error occured");
          setLoading(false);
        });
    },
    [newDeviceData],
  );

  const saveDevices = useCallback(() => {
    updateDevices(dataToSave)
      .then((response) => {
        setLoading(true);
        if (response.statusCode === "200") {
          setToastMessages("success", "Saved successfully");
          fetchData();
          setClearDataToSave(true);
          setLoading(false)
        } else {
          if (response.message === "DUPLICATE KEY") {
            setToastMessages("error", `Device ${dataToSave.device} already exist`);
            setLoading(false)
          }
          else {
            setToastMessages("error", "Some error occured")
            setLoading(false)
          }
        };
      })
      .catch(() => {
        setToastMessages("error", "Some error occured");
        setLoading(false)
      });
  }, [dataToSave]);

  const onDelete = (deviceId) => {
    deleteDevice(deviceId)
      .then((response) => {
        setLoading(true);
        if (response.statusCode === "200") {
          setToastMessages("success", "Deleted successfully");
          fetchData();
          setLoading(false);
        } else setToastMessages("error", "Some error occured");
      })
      .catch(() => {
        setToastMessages("error", "Some error occured");
        setLoading(false);
      });
  };

  const onRecolonize = () => {
    let selectedDevice = devicesData.content.find(x => x.selected);
    const organizationCode = totalSitesData?.filter(item => item?.orgCode === selectedDevice?.deviceRole)[0]?.orgId;
    recolonizeDevice(organizationCode, selectedDevice.device).then((response) => {
      setLoading(true);
      if (response.statusCode === "200") {
        const result = response.data;
        if (result === "RUNNING") {
          setToastMessages("warning", "Recolonization Task is Processing, Please try it after sometime.")
          setLoading(false);
        } else if (result === "STARTED") {
          setToastMessages("success", "Recolonization Task has started processing");
          setLoading(false);
        }
        fetchData();
      } else setToastMessages("error", "Some error occured");
      setLoading(false);
    })
      .catch(() => {
        setToastMessages("error", "Some error occured");
        setLoading(false);
      });
  }
  const onDecolonize = () => {
    let selectedDevice = devicesData.content.find(x => x.selected);
    decolonizeDevice(selectedDevice.device).then((response) => {
      setLoading(true);
      if (response.statusCode === "200") {
        setToastMessages("success", `De-Colonize of Device ${selectedDevice.device} done successfully`);
        fetchData();
        setLoading(false);
      } else setToastMessages("error", "Some error occured");
      setLoading(false);
    })
      .catch(() => {
        setToastMessages("error", "Some error occured");
        setLoading(false);
      });
  }
  const onClearData = () => {
    setDevice('');
    setDeviceRole(0);
    setLoadData(true);
  }

  useEffect(() => {
    if (loadData === true) {
      onSearchDevices();
    }
  }, [loadData])

  const onSearchDevices = () => {
    setLoading(true);
    searchDevices({ device: (device === '' ? null : device), deviceRole: (deviceRole === 0 ? null : deviceRole), page: 0, size: 10000 })
      .then((response) => {
        setIsNewDevice(false);
        response?.data?.content?.forEach(x => {
          x.selected = false;
        });
        if (response.statusCode === "200") { setDevicesData(response?.data); setLoadData(false) }
        else setToastMessages("error", "Some error occured");
        setLoading(false);
      })
      .catch(() => {
        setToastMessages("error", "Some error occured");
        setLoading(false);
      });
  };

  const navigateBack = () => {
    setaddDeviceData(false);
    setEnableBackButton(false);
    setViewData(false);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const filter = (anchor) => (
    <Box role='presentation'>
      <DeviceFilters toggleDrawer={toggleDrawer} state={state} setState={setState} anchor={anchor} sitesDataOptions={sitesDataOptions} onClearData={onClearData} device={device} setDevice={setDevice} deviceRole={deviceRole} onSearchDevices={() => onSearchDevices()} setDeviceRole={(e) => setDeviceRole(e)} />
    </Box>
  );
  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearchDevices();
    }
  };

  return (
    <Box className='admin-devices-main'>
      {loading &&
        <div className="loading">Loading&#8230;</div>
      }

      <Box className='webheader'>
        <Typography variant='h5' flexGrow={1}>
          Devices
        </Typography>
        <Stack direction={'row'}>
          <IconButton onClick={() => {
            setShowSave(true);
            setIsNewDevice(true);
          }}>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => setShowFilterFields(!showFilterFields)}>
            {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>

        </Stack>
      </Box>
      {showFilterFields && (
        <Box className='headers-filters' >
          <Box className='filters'>
            <Stack width="100%">
              <Typography>
                {'Device'}
              </Typography>
              <InputComponent

                value={device}
                disabled={false}
                onKeyPress={handleInputKeyPress}
                onChange={(e) => setDevice(e.target.value)}
                width="100%"
              />
            </Stack>

            <Stack width="100%" >
              <Typography>
                {'Device Role'}
              </Typography>
              <SelectComponent

                values={[{ id: 0, value: "Please Select" }, ...sitesDataOptions]}
                selectedvalue={deviceRole}
                disabled={false}
                parentselectcallback={(e) => setDeviceRole(e)}
                width="100%"
              />
            </Stack>
            <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
              <IconButton
                aria-label="search"
                onClick={onSearchDevices}
                edge="start"
              >
                <SearchIcon />
              </IconButton>

              <IconButton onClick={onClearData}
                aria-label="search"
                edge="start"
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      )}

      <Grid className='admin-devices-content-page-header-mobile'>
        <Box className='tittle-and-backbutton admin-headers'>
          {enableBackButton && (
            <ArrowCircleLeftOutlinedIcon
              onClick={navigateBack}
              className='back-button-mobile-sites'
              fontSize='medium'
            />
          )}
          <Typography variant={'h5'} className='admin-content-header-tittle-text'>{t("DEVICES")}</Typography>

        </Box>

        <Box>
          <Box className='add-button-mobile'>
            {!addDeviceData && (
              <Box className='devices-icons'>

                {!isNewDevice && (
                  <IconButton onClick={() => {
                    setShowSave(true);
                    setIsNewDevice(true);
                  }} >
                    <AddIcon />
                  </IconButton>
                )}
                {showSave && (
                  <>
                    <ButtonComponent
                      label={t("SAVE")}
                      color={"#2BAA4F"}
                      className='device-height-buttons'
                      buttoncallBack={() => {
                        isNewDevice ? setIsAddDeviceSave(true) : saveDevices();
                      }}
                    />
                    <ButtonComponent
                      label={t("CANCEL")}
                      color={"#ff0500"}
                      className='device-height-buttons'
                      buttoncallBack={() => {
                        setShowSave(false);
                        setClearDataToSave(true);
                        isNewDevice && setIsNewDevice(false);
                      }}
                    />
                  </>
                )}

                <Box className='filter-button-mobile'>
                  <React.Fragment key={"right"}>
                    <IconButton onClick={toggleDrawer("right", true)}>
                      <FilterAltIcon />
                    </IconButton>
                    <Drawer
                      anchor={"right"}
                      open={state["right"]}
                      onClose={toggleDrawer("right", false)}
                      width={"311px"}
                    >
                      {filter("right")}
                    </Drawer>
                  </React.Fragment>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Box className='admin-device-table'>
        <Box className='deivce-all-bt'>
          <Box className='device-buttons'>
            <ButtonComponent
              icon={false}
              label={t("RECOLONIZE")}
              disabled={disableColonizeButtons}
              className='device-height-buttons'
              buttoncallBack={() => onRecolonize()}
            ></ButtonComponent>
            <ButtonComponent
              icon={false}
              label={t("DECOLONIZE")}
              disabled={disableColonizeButtons}
              buttoncallBack={() => setOpenCancel(true)}
            ></ButtonComponent>
            <ButtonComponent
              icon={false}
              label={"Refresh"}
              buttoncallBack={() => fetchData()}
            ></ButtonComponent>
          </Box>
          <Box className='device-buttons-add'>
            {showSave && (
              <>
                <ButtonComponent
                  label={t("SAVE")}
                  color={"#2BAA4F"}
                  className='device-height-buttons'
                  buttoncallBack={() => {
                    isNewDevice ? setIsAddDeviceSave(true) : saveDevices();
                  }}
                />
                <ButtonComponent
                  label={t("CANCEL")}
                  color={"#ff0500"}
                  className='device-height-buttons'
                  buttoncallBack={() => {
                    setShowSave(false);
                    setClearDataToSave(true);
                    isNewDevice && setIsNewDevice(false);
                  }}
                />
              </>
            )}
          </Box>
        </Box>
        <Box >
          <AxDevicestableComponent
            columns={deviceTableColumns}
            data={devicesData || { content: [] }}
            page={page}
            setPage={(e) => setPage(e)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(e) => setRowsPerPage(e)}
            onShowSave={() => setShowSave(true)}
            showSave={showSave}
            onDelete={onDelete}
            onAdd={onAddDevice}
            isAddDeviceSave={isAddDeviceSave}
            setIsAddDeviceSave={setIsAddDeviceSave}
            sitesData={sitesDataOptions}
            isNewDevice={isNewDevice}
            setDataToSave={(_dataToSave) => {
              const newData = _dataToSave.map((item) => ({ ...item, resetDate: 1 ? true : false, lastUpdatedBy: user?.name }));
              setDataToSave(newData);
            }}
            clearDataToSave={clearDataToSave}
            toggleColonizeButtons={(_disable) => { setDisableColonizeButtons(_disable) }}
          ></AxDevicestableComponent>
        </Box>
      </Box>
      <ConfirmDialog sx={{ width: 600 }} openConfirm={openCancel}
        hidebuttons={false}
        msg={`Are you sure you want to proceed with De-Colonization?`}
        accept={() => { onDecolonize(); setOpenCancel(false); }}
        reject={() => { setOpenCancel(false); fetchData() }}
      />
      <ToastMessage toast={toast} />
    </Box>
  );
};

export default Devices;
