import { Box, Button, Drawer, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { DatePickerComponent, InputComponent, WorkOrderAccessLogsTableComponent } from "../shared-components/common-field-components";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../store/UserContext";
import '../styles/homepage.css';
import { useLocation } from "react-router-dom";
import { getWorkOrderAccessLogs } from "../../services/ReportServices";
import { workOrderAccessLogColumns } from "../constants/WorkOrderAccessLogColumnsData";
export default function WorkOrderAccessLog(props) {
    const { user, selectedSite, smallScreens, largeScreens } = React.useContext(UserContext);
    const location = useLocation();
    const [searchFilter, setSearchFilter] = useState({
        workOrderId: '',
        empName: '',
        processedFrom: '',
        processedTo: ''
    });

    const [showFilterFields, setShowFilterFields] = useState(true);
    const [workordersData, setWorkordersData] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const isClear = useRef(false);
    const [doSearch, setDoSearch] = useState(false);

    // Update the fetch function to use getWorkOrderAccessLogs
    const fetchWorkOrdersByFilterData = async (_page) => {
        try {
            setLoading(true);
            const response = await getWorkOrderAccessLogs(
                _page !== undefined ? _page : page,
                rowsPerPage,
                searchFilter?.workOrderId || null,
                searchFilter?.empName || null,

            );
            setLoading(false);
            if (response && response?.statusCode === "200") {
                setWorkordersData(response?.data?.content);
                setTotalElements(response?.data?.totalElements);
            } else {
                setWorkordersData([]);
                setTotalElements(0);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleChangePage = (event, newPage) => {
        setDoSearch(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setDoSearch(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            setDoSearch(true);
            setPage(0);
        }
    };

    const clearFilters = async () => {
        isClear.current = true;
        setSearchFilter({ workOrderId: '', empName: '', processedFrom: '', processedTo: '' });
        setDoSearch(true);
        setPage(0);
    }

    useEffect(() => {
        if (isClear.current === true) {
            setShowSideBar(!showSideBar);
            isClear.current = false;
        }
    }, [searchFilter]);

    useEffect(() => {
        if (doSearch) {
            setDoSearch(false);
            fetchWorkOrdersByFilterData();
        }
    }, [doSearch, page, rowsPerPage]);

    return (
        <Box className='homePageMain'>
            {loading &&
                <div className="loading">
                    Loading&#8230;
                </div>
            }
            <Box className={largeScreens ? 'homePageContentWrapper' : 'mobileHomePageContentWrapper'}>
                <Box className='homePageHeader'>
                    <Typography variant='h5' flexGrow={1}>
                        Work Order Access Logs
                    </Typography>
                    <Stack direction={'row'}>
                        <IconButton onClick={() => largeScreens ? setShowFilterFields(!showFilterFields) : setShowSideBar(!showSideBar)}>
                            {showFilterFields ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
                        </IconButton>
                    </Stack>
                </Box>
                {
                    smallScreens ?
                        <Drawer
                            anchor="right"
                            open={showSideBar}
                            onClose={() => setShowSideBar(!showSideBar)}
                            PaperProps={{ style: { width: '300px' } }} >
                            <Box className='drawerContent' padding={'10px 15px'}>
                                <Toolbar />
                                <Stack direction={'column'} gap={'10px'}>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Work Order ID'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="workOrderId"
                                            value={searchFilter?.workOrderId}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                            }}
                                        />
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Employee Name'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="empName"
                                            value={searchFilter?.empName}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['empName']: e.target.value });
                                            }}
                                        />
                                    </Stack>

                                    <Stack width="100%" >
                                        <Typography >
                                            {'Processed From'}
                                        </Typography>
                                        <DatePickerComponent
                                            width="100%"
                                            disabled={false}
                                            disableFuture={true}
                                            dateDefaultValue={searchFilter?.processedFrom}
                                            onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['processedFrom']: e }); }}
                                        />
                                    </Stack>
                                    <Stack width="100%" >
                                        <Typography >
                                            {'Processed To'}
                                        </Typography>
                                        <DatePickerComponent
                                            width="100%"
                                            disabled={false}
                                            disableFuture={true}
                                            dateDefaultValue={searchFilter?.processedTo}
                                            onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['processedTo']: e }); }}
                                        />
                                    </Stack>
                                    <Button variant='contained' onClick={() => { setDoSearch(true); setPage(0); setShowSideBar(!showSideBar); }}>Search</Button>
                                    <Button variant='contained' color="success" onClick={clearFilters}>Clear</Button>
                                </Stack>
                            </Box>
                        </Drawer>
                        :
                        <Box className='homePageFilters'>
                            {showFilterFields && (
                                <Box className='filters-lg'>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Work Order ID'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="workOrderId"
                                            value={searchFilter?.workOrderId}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['workOrderId']: e.target.value });
                                            }}
                                        />
                                    </Stack>
                                    <Stack width="100%">
                                        <Typography color={'black !important'}>
                                            {'Employee Name'}
                                        </Typography>
                                        <InputComponent
                                            width="100%"
                                            name="empName"
                                            value={searchFilter?.empName}
                                            disabled={false}
                                            onKeyPress={handleInputKeyPress}
                                            onChange={(e) => {
                                                setSearchFilter({ ...searchFilter, ['empName']: e.target.value });
                                            }}
                                        />
                                    </Stack>

                                    <Stack width="100%" >
                                        <Typography >
                                            {'Processed From'}
                                        </Typography>
                                        <DatePickerComponent
                                            width="100%"
                                            disabled={false}
                                            disableFuture={true}
                                            dateDefaultValue={searchFilter?.processedFrom}
                                            onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['processedFrom']: e }); }}
                                        />
                                    </Stack>
                                    <Stack width="100%" >
                                        <Typography >
                                            {'Processed To'}
                                        </Typography>
                                        <DatePickerComponent
                                            width="100%"
                                            disabled={false}
                                            disableFuture={true}
                                            dateDefaultValue={searchFilter?.processedTo}
                                            onDateSelect={(e) => { setSearchFilter({ ...searchFilter, ['processedTo']: e }); }}
                                        />
                                    </Stack>
                                    <Stack className="filtersIconStack" gap={'10px'} alignItems={'center'} direction={'row'}>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => { setDoSearch(true); setPage(0); }}
                                            edge="start"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                        <IconButton onClick={clearFilters}
                                            aria-label="clear"
                                            edge="start"
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                }
                <Box className='homePageTable'>
                    <WorkOrderAccessLogsTableComponent
                        columns={workOrderAccessLogColumns}
                        rowCount={totalElements}
                        page={page}
                        selectedSite={selectedSite}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={rowsPerPage}
                        data={workordersData}
                        loading={loading}
                        
                    />
                </Box>
            </Box>
        </Box>
    );
}
