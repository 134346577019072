import React, { useState, } from 'react';
import { Grid, Typography, Box, } from '@mui/material';
import { InputComponent } from "../shared-components/common-field-components";

const AssetFinancialForm = (props) => {
    const { formik, } = props;
    return (
        <Box>
            <Box sx={{
                padding: {
                    xs: '4px',
                    lg: '1px',
                }
            }} >
                <Grid container rowSpacing={0.5} columnSpacing={2}

                >
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Original Cost</Typography>
                        <InputComponent
                            name="AssetFinancial.originalCost"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.originalCost && formik.errors.AssetFinancial?.originalCost}
                            value={formik.values.AssetFinancial?.originalCost}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Current Value</Typography>
                        <InputComponent
                            name="AssetFinancial.currentValue"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.currentValue && formik.errors.AssetFinancial?.currentValue}
                            value={formik.values.AssetFinancial?.currentValue}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Deprecation Cost</Typography>
                        <InputComponent
                            name="AssetFinancial.deprecationCost"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.deprecationCost && formik.errors.AssetFinancial?.deprecationCost}
                            value={formik.values.AssetFinancial?.deprecationCost}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Vendor</Typography>
                        <InputComponent
                            name="AssetFinancial.vendor"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.vendor && formik.errors.AssetFinancial?.vendor}
                            value={formik.values.AssetFinancial?.vendor}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Date Opened</Typography>
                        <InputComponent
                            name="AssetFinancial.dateOpened"
                            width="100%"
                            type="date"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.dateOpened && formik.errors.AssetFinancial?.dateOpened}
                            value={formik.values.AssetFinancial?.dateOpened}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Invoice Number</Typography>
                        <InputComponent
                            name="AssetFinancial.invoiceNumber"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.invoiceNumber && formik.errors.AssetFinancial?.invoiceNumber}
                            value={formik.values.AssetFinancial?.invoiceNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>General Ledger Account</Typography>
                        <InputComponent
                            name="AssetFinancial.generalLedgerAccount"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.generalLedgerAccount && formik.errors.AssetFinancial?.generalLedgerAccount}
                            value={formik.values.AssetFinancial?.generalLedgerAccount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Request Line</Typography>
                        <InputComponent
                            name="AssetFinancial.requestLine"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.requestLine && formik.errors.AssetFinancial?.requestLine}
                            value={formik.values.AssetFinancial?.requestLine}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Cost Center</Typography>
                        <InputComponent
                            name="AssetFinancial.costCenter"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.costCenter && formik.errors.AssetFinancial?.costCenter}
                            value={formik.values.AssetFinancial?.costCenter}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Acquisition Number</Typography>
                        <InputComponent
                            name="AssetFinancial.acquisitionNumber"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.acquisitionNumber && formik.errors.AssetFinancial?.acquisitionNumber}
                            value={formik.values.AssetFinancial?.acquisitionNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Po Number</Typography>
                        <InputComponent
                            name="AssetFinancial.poNumber"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.poNumber && formik.errors.AssetFinancial?.poNumber}
                            value={formik.values.AssetFinancial?.poNumber}
                        />
                    </Grid>


                </Grid>

            </Box>
            <Box sx={{
                padding: {
                    xs: '4px',
                    lg: '1px',
                }
            }} >
                <Typography variant='h5' sx={{ marginBottom: 1 }} >Deprecation</Typography>

                <Grid container rowSpacing={0.5} columnSpacing={2}

                >
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Method</Typography>
                        <InputComponent
                            name="AssetFinancial.method"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.method && formik.errors.AssetFinancial?.method}
                            value={formik.values.AssetFinancial?.method}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Life Years</Typography>
                        <InputComponent
                            name="AssetFinancial.lifeYears"
                            width="100%"
                            type="number"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.lifeYears && formik.errors.AssetFinancial?.lifeYears}
                            value={formik.values.AssetFinancial?.lifeYears}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Life Months</Typography>
                        <InputComponent
                            name="AssetFinancial.lifeMonths"
                            width="100%"
                            type="number"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.lifeYears && formik.errors.AssetFinancial?.lifeMonths}
                            value={formik.values.AssetFinancial?.lifeMonths}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Ltd Deprecation</Typography>
                        <InputComponent
                            name="AssetFinancial.ltdDeprecation"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.ltdDeprecation && formik.errors.AssetFinancial?.ltdDeprecation}
                            value={formik.values.AssetFinancial?.ltdDeprecation}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Ytd Deprecation</Typography>
                        <InputComponent
                            name="AssetFinancial.ytdDeprecation"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.ytdDeprecation && formik.errors.AssetFinancial?.ytdDeprecation}
                            value={formik.values.AssetFinancial?.ytdDeprecation}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Accumulated Deprecation</Typography>
                        <InputComponent
                            name="AssetFinancial.accumulatedDeprecation"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.accumulatedDeprecation && formik.errors.AssetFinancial?.accumulatedDeprecation}
                            value={formik.values.AssetFinancial?.accumulatedDeprecation}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography>Deprecation Limit</Typography>
                        <InputComponent
                            name="AssetFinancial.deprecationLimit"
                            width="100%"
                            disabled={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.AssetFinancial?.deprecationLimit && formik.errors.AssetFinancial?.deprecationLimit}
                            value={formik.values.AssetFinancial?.deprecationLimit}
                        />
                    </Grid>


                </Grid>



            </Box>
        </Box>
    );
};

export default AssetFinancialForm;
