import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Box } from "@mui/system";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { red } from "@mui/material/colors";
import SaveIcon from '@mui/icons-material/Save';
import { InputComponent, DatePickerComponent } from ".";
import { TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../ConfirmDialog";
import '../index.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
  },
  "&:last-child td, &:last-child th": {
    BorderBottom: 0,
  },
  "& td:first-of-type, & th:first-of-type": {
    borderLeft: '5px solid #006CEC !important',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow style={{ height: 10 }}>
        {headCells?.map((headCell) => (
          <StyledTableCell
            key={headCell.field}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {headCell.sortable === false ? null :
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}
              >
                {t(headCell.headerName)}
                {orderBy === headCell.field ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AxBroadcastTableComponent(props) {
  const { columns, data, page, setPage, rowsPerPage, setRowsPerPage,
    saveRecord, deleteRecord, formik, errors, setErrors, addRecord, setAddRecord, dateDefaultValue } = props;


  /************** SORTING***********************************/
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('orgCode');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data?.content || [], getComparator(order, orderBy)),
    [data, order, orderBy, page, rowsPerPage]
  );

  /*********************************************************/

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [broadcastMessagesId, setBroadcastMessageId] = useState(null);
  const [openDeleteCnfrm, setOpenDeleteCnfrm] = useState(false);

  return (
    <Box>
      <ConfirmDialog sx={{ width: 600 }} openConfirm={openDeleteCnfrm}
        hidebuttons={false}
        msg={`Are you sure?`}
        accept={() => { deleteRecord(broadcastMessagesId); setOpenDeleteCnfrm(false); }}
        reject={() => { setOpenDeleteCnfrm(false); }}
      />
      <TableContainer className='table-container-shared' sx={{ maxHeight: data?.content?.length == 0 ? "calc(100dvh - 231px) !important" : "calc(100dvh - 189px) !important" }}>
        <Table
          sx={{
            minWidth: 700,
            "& .MuiTableCell-head": {
              backgroundColor: "#E6E6E6 !important",
              color: "black !important",
            },
            "& .css-cokgfw-MuiTableCell-root.MuiTableCell-head": { width: "50px !important" },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
              color: "black"
            },
            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
              color: "black", opacity: 100
            }
          }}
          stickyHeader
          size='small'
          aria-label='sticky table'
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns}
          />
          {/* Add new record  */}
          <TableBody>
            {addRecord && <StyledTableRow>
              <StyledTableCell align='center' className='broadcasttablecell1'>
                <InputComponent
                  name={"message"}
                  onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                  width={"100%"}
                  maxLength={"250"}
                  error={errors?.message} />
              </StyledTableCell>
              <StyledTableCell className='broadcasttablecell2'>
                <DatePickerComponent
                  name={"startDate"}
                  dateDefaultValue={dateDefaultValue}
                  onDateSelect={(date) => formik.setFieldValue("startDate", date, false)}
                  error={errors?.startDate}
                  value={formik?.values.startDate} />
              </StyledTableCell>
              <StyledTableCell className='broadcasttablecell2'>
                <DatePickerComponent
                  name={"expirationsDate"}
                  dateDefaultValue={dateDefaultValue}
                  onDateSelect={(date) => formik.setFieldValue("expirationsDate", date, false)}
                  error={errors?.expirationsDate}
                  value={formik?.values.expirationsDate} />
              </StyledTableCell>
              <StyledTableCell className='broadcasttablecell3' align='center'>
                <Box className='action-buttons'>
                  <SaveIcon color='success' onClick={saveRecord} />
                  <IndeterminateCheckBoxIcon sx={{ color: red[500] }} onClick={() => {
                    setErrors({})
                    setAddRecord(false)
                  }} />
                </Box>
              </StyledTableCell>
            </StyledTableRow>}

            {/* list  */}
            {visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.broadcastMessagesId} >
                <StyledTableCell className='broadcasttablecell1' >
                  {row.message}
                </StyledTableCell>
                <StyledTableCell className='broadcasttablecell2'>
                  {row.startDate
                    ? new Date(row.startDate).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }).slice(0, 10)
                    : "-"
                  }
                </StyledTableCell>

                <StyledTableCell className='broadcasttablecell2'>
                  {row.expirationsDate
                    ? new Date(row.expirationsDate).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }).slice(0, 10)
                    : "-"
                  }
                </StyledTableCell>

                <StyledTableCell className='broadcasttablecell3' align='center'>
                  <Box className='action-buttons'>
                    <AddBoxIcon color='primary' onClick={() => setAddRecord(true)} />
                    <IndeterminateCheckBoxIcon
                      sx={{ color: red[500] }}
                      onClick={() => { setBroadcastMessageId(row.broadcastMessagesId); setOpenDeleteCnfrm(true) }}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={data?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='customTablePagination'
      />
    </Box>

  );
}
