import React, { useState } from 'react'
import { Grid, Typography, Input, } from '@mui/material'
import { InputComponent, SelectComponent } from "../shared-components/common-field-components";
import AttachFileIcon from '@mui/icons-material/AttachFile';



const AssetsMasterForm = (props) => {
    const { siteData, formik, } = props;
    const [updatedSite, setUpdatedSite] = useState(siteData);
    const [attachments, setAttachments] = useState([]);
        
    const handleFileUpload = (event) => {
        const files = event.target.files;
        setAttachments(files);
    };

    return (

        <Grid container spacing={2} sx={{
            padding: {
                xs: '4px',
                lg: '1px',
            },
        }} >

            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">Asset Name</Typography>
                <InputComponent
                    name="AssetsMaster.assetName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.assetName &&formik.errors.AssetsMaster?.assetName}
                    helperText={formik.errors.AssetsMaster?.assetName}
                    value={formik.values.AssetsMaster.assetName}
                    width="100%"
                />
            </Grid>


            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Asset Description
                </Typography>
                <InputComponent
                    name="AssetsMaster.assetDescription"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.assetDescription &&formik.errors.AssetsMaster?.assetDescription}
                    helperText={formik.errors.AssetsMaster?.assetDescription}
                    value={formik.values.AssetsMaster.assetDescription}
                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Asset Number
                </Typography>
                <InputComponent onChange={formik.handleChange}
                    name="AssetsMaster.assetNumber"
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.assetNumber &&formik.errors.AssetsMaster?.assetNumber}
                    helperText={formik.errors.AssetsMaster?.assetNumber}
                    value={formik.values.AssetsMaster.assetNumber}
                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Tag Number
                </Typography>
                <InputComponent onChange={formik.handleChange}
                    name="AssetsMaster.tagNumber"
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.tagNumber &&formik.errors.AssetsMaster?.tagNumber}
                    helperText={formik.errors.AssetsMaster?.tagNumber}
                    value={formik.values.AssetsMaster.tagNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Serial Number
                </Typography>
                <InputComponent onChange={formik.handleChange}
                    name="AssetsMaster.serialNumber"
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.serialNumber &&formik.errors.AssetsMaster?.serialNumber}
                    helperText={formik.errors.AssetsMaster?.serialNumber}
                    value={formik.values.AssetsMaster.serialNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">
                    Category
                </Typography>
                <InputComponent
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="AssetsMaster.category"
                    error={formik.touched.AssetsMaster?.category &&formik.errors.AssetsMaster?.category}
                    helperText={formik.errors.AssetsMaster?.category}
                    value={formik.values.AssetsMaster.category}
                    width={"100%"}
                />
            </Grid>


            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">
                    Asset Type
                </Typography>
                <SelectComponent
                    name="AssetsMaster.assetType"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.assetType &&formik.errors.AssetsMaster?.assetType}
                    helperText={formik.errors.AssetsMaster?.assetType}
                    value={formik.values.AssetsMaster.assetType}
                    values={[
                        { id: 1, value: "CAPITALIZED" },
                        { id: 2, value: "EXPENSED" },
                        { id: 3, value: "GROUP" }
                    ]}
                    disabled={false}
                    width={"100%"}
                    parentselectcallback={(_val) => {
                        let value;
                        switch (_val) {
                            case 1:
                                value = "CAPITALIZED";
                                break;
                            case 2:
                                value = "EXPENSED";
                                break;
                            case 3:
                                value = "GROUP";
                                break;
                            default:
                                value = "";
                                break;
                        }
                        setUpdatedSite({ ...updatedSite, isOperatingUnit: value });
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">
                    Asset Key
                </Typography>
                <InputComponent
                    name="AssetsMaster.assetKey"
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.assetKey &&formik.errors.AssetsMaster?.assetKey}
                    helperText={formik.errors.AssetsMaster?.assetKey}
                    value={formik.values.AssetsMaster.assetKey}
                    onChange={formik.handleChange}
                    width="100%"
                />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Unit
                </Typography>
                <InputComponent
                    name="AssetsMaster.unit"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.unit &&formik.errors.AssetsMaster?.unit}
                    helperText={formik.errors.AssetsMaster?.unit}
                    value={formik.values.AssetsMaster.unit}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Manufacturer
                </Typography>
                <InputComponent
                    name="AssetsMaster.manufacturer"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.manufacturer &&formik.errors.AssetsMaster?.manufacturer}
                    helperText={formik.errors.AssetsMaster?.manufacturer}
                    value={formik.values.AssetsMaster.manufacturer}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Model
                </Typography>
                <InputComponent
                    name="AssetsMaster.model"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.model &&formik.errors.AssetsMaster?.model}
                    helperText={formik.errors.AssetsMaster?.model}
                    value={formik.values.AssetsMaster.model}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Asset Group
                </Typography>
                <InputComponent
                    name="AssetsMaster.assetGroup"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.assetGroup &&formik.errors.AssetsMaster?.assetGroup}
                    helperText={formik.errors.AssetsMaster?.assetGroup}
                    value={formik.values.AssetsMaster.assetGroup}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Asset Status
                </Typography>
                <InputComponent
                    name="AssetsMaster.assetStatus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.assetStatus &&formik.errors.AssetsMaster?.assetStatus}
                    helperText={formik.errors.AssetsMaster?.assetStatus}
                    value={formik.values.AssetsMaster.assetStatus}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Warranty Number
                </Typography>
                <InputComponent
                    name="AssetsMaster.warrantyNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.warrantyNumber &&formik.errors.AssetsMaster?.warrantyNumber}
                    helperText={formik.errors.AssetsMaster?.warrantyNumber}
                    value={formik.values.AssetsMaster.warrantyNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Lease Number
                </Typography>
                <InputComponent
                    name="AssetsMaster.leaseNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.leaseNumber &&formik.errors.AssetsMaster?.leaseNumber}
                    helperText={formik.errors.AssetsMaster?.leaseNumber}
                    value={formik.values.AssetsMaster.leaseNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    PO Number
                </Typography>
                <InputComponent
                    name="AssetsMaster.poNumber"
                    onBlur={formik.handleBlur}
                    error={formik.touched.AssetsMaster?.poNumber &&formik.errors.AssetsMaster?.poNumber}
                    onChange={formik.handleChange}
                    helperText={formik.errors.AssetsMaster?.poNumber}
                    value={formik.values.AssetsMaster.poNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Invoice Number
                </Typography>
                <InputComponent
                    name="AssetsMaster.invoiceNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.invoiceNumber &&formik.errors.AssetsMaster?.invoiceNumber}
                    helperText={formik.errors.AssetsMaster?.invoiceNumber}
                    value={formik.values.AssetsMaster.invoiceNumber}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Organization
                </Typography>
                <InputComponent
                    name="AssetsMaster.organization"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.organization &&formik.errors.AssetsMaster?.organization}
                    helperText={formik.errors.AssetsMaster?.organization}
                    value={formik.values.AssetsMaster.organization}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Owning Department
                </Typography>
                <InputComponent
                    name="AssetsMaster.owningDepartment"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.owningDepartment &&formik.errors.AssetsMaster?.owningDepartment}
                    helperText={formik.errors.AssetsMaster?.owningDepartment}
                    value={formik.values.AssetsMaster.owningDepartment}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Parent Asset
                </Typography>
                <InputComponent
                    name="AssetsMaster.parentAsset"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.parentAsset &&formik.errors.AssetsMaster?.parentAsset}
                    helperText={formik.errors.AssetsMaster?.parentAsset}
                    value={formik.values.AssetsMaster.parentAsset}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Parent Asset Description
                </Typography>
                <InputComponent
                    name="AssetsMaster.parentAssetDescription"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.parentAssetDescription &&formik.errors.AssetsMaster?.parentAssetDescription}
                    helperText={formik.errors.AssetsMaster?.parentAssetDescription}
                    value={formik.values.AssetsMaster.parentAssetDescription}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Parent Asset Group
                </Typography>
                <InputComponent
                    name="AssetsMaster.parentAssetGroup"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.parentAssetGroup &&formik.errors.AssetsMaster?.parentAssetGroup}
                    helperText={formik.errors.AssetsMaster?.parentAssetGroup}
                    value={formik.values.AssetsMaster.parentAssetGroup}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Maintenable
                </Typography>
                <InputComponent
                    name="AssetsMaster.maintenable"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.maintenable &&formik.errors.AssetsMaster?.maintenable}
                    helperText={formik.errors.AssetsMaster?.maintenable}
                    value={formik.values.AssetsMaster.maintenable}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    Accounting Class
                </Typography>
                <InputComponent
                    name="AssetsMaster.accountingClass"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.accountingClass &&formik.errors.AssetsMaster?.accountingClass}
                    helperText={formik.errors.AssetsMaster?.accountingClass}
                    value={formik.values.AssetsMaster.accountingClass}

                    width={"100%"}>
                </InputComponent>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" >
                    GIS Asset
                </Typography>
                <SelectComponent
                    name="AssetsMaster.GISAsset"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.AssetsMaster?.GISAsset &&formik.errors.AssetsMaster?.GISAsset}
                    helperText={formik.errors.AssetsMaster?.GISAsset}
                    value={formik.values.AssetsMaster.GISAsset}

                    values={[{ id: 1, value: "Yes" }, { id: 2, value: "No" }]}
                    disabled={false}
                    width={"100%"}
                    parentselectcallback={(_val) => {
                        _val = _val === 1 ? "Yes" : "No";
                        setUpdatedSite({ ...updatedSite, isOperatingUnit: _val });
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">
                    Attachments
                </Typography>
                <Input
                    type="file"
                    onChange={handleFileUpload}
                    multiple
                    inputProps={{ accept: '.pdf,.doc,.docx,.xls,.xlsx' }}
                    style={{ display: 'none' }}
                    id="fileInput"
                />
                <label htmlFor="fileInput">
                    <AttachFileIcon variant="outlined" />
                </label>
                {attachments.length > 0 && (
                    <Typography variant="body1">
                        {attachments.length} file(s) selected
                    </Typography>
                )}
            </Grid>

        </Grid>




    )
}

export default AssetsMasterForm