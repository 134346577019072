// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fieldset {
    position: absolute;
    border: none; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.MuiInputBase-root.MuiOutlinedInput-root {
    border-color: transparent !important; 
    border-radius: 0; 
}

.MuiOutlinedInput-notchedOutline {
    border: none !important; 
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 3px solid #9fbfdf !important; /* Set the border color to #1976d2 on focus */
}
`, "",{"version":3,"sources":["webpack://./src/components/shared-components/common-field-components/DatePicker.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC,EAAE,6CAA6C;AACvF","sourcesContent":["fieldset {\n    position: absolute;\n    border: none; \n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);\n}\n\n.MuiInputBase-root.MuiOutlinedInput-root {\n    border-color: transparent !important; \n    border-radius: 0; \n}\n\n.MuiOutlinedInput-notchedOutline {\n    border: none !important; \n}\n\n.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n    border: 3px solid #9fbfdf !important; /* Set the border color to #1976d2 on focus */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
