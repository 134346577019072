import React, { useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { ButtonComponent } from '.';
import { Box, Typography, TableSortLabel, Stack } from '@mui/material';
import { InputComponent, SelectComponent } from ".";
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material'
import {
    saveOperation,
    updateOperation,
    getLaborsByWipEntityId,
    getOperationResourcesByOrgId,
} from '../../../services/WorkOrderServices';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ToastMessage from '../ToastMessage';
import { ConfirmDialog } from '../ConfirmDialog';
import { InfoDialog } from '../InfoDialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { any } from 'joi';
import { APIENPOINTS } from '../../constants/APIEndpoints';
import { exportToExcel } from '../../../services/ExcelExportService';
import { UserContext } from "../../../store/UserContext";
import '../index.css'
import EditIcon from '@mui/icons-material/Edit';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}



const validationSchema = yup.object({
    task: yup.string().trim().required("Required"),
    description: yup.string().required("Required"),
    department: yup.string().required("Required"),
    resourceCode: yup.string(),
    actualFinish: yup.string().required("Required"),
    conditionValue: yup.string(),
    lastValue: yup.string(),
    conditionNote: yup.string(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '20px',
        minWidth: '70px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
    '&:last-child td, &:last-child th': {
        BorderBottom: 0,

    },
    "& td:first-of-type, & th:first-of-type": {
        borderLeft: '5px solid #006CEC !important',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px'
    },

}));
function formatDate(dateString) {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2,
        '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;

}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string
    ) => void;
    order: Order;
    orderBy: string;
    headCells: any[],
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow style={{ height: 10 }}>
                {headCells?.map((headCell) => (
                    <StyledTableCell
                        key={headCell.field}
                        align={'left'}
                        width='25%'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        {headCell.field !== "buttons" &&
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {headCell.headerName}
                                {orderBy === headCell.field ? (
                                    <Box component="span">
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function WorkordersOperationsTableComponent(props) {
    const { user, screenSize, appConstants, } = useContext(UserContext);
    const { operationsData, addRecord, loader, columns, addOperation } = props;
    let now = dayjs();
    const [resourceData, setResourceData] = React.useState();
    const [resourceDropdown, setResourceDropdown] = React.useState([{ id: 0, value: 'Select Employee' }]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [toast, setToast] = React.useState({});
    const [nextTask, setNextTask] = React.useState();
    const [editRecord, setEditRecord] = React.useState(false);
    const [editData, setEditData] = React.useState({});
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [infoMsg, setInfoMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [openVisualDictionary, setOpenVisualDictionary] = React.useState(false);
    const [vd, setVd] = React.useState({ task: 0, taskImage: '', data: any });
    const [record, setRecord] = React.useState([]);
    const [operationId, setOperationId] = React.useState("");
    const [openConfirmTask, setOpenConfirmTask] = React.useState(false);


    const handleCloseVD = () => {
        setOpenVisualDictionary(false);
    }

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('id');
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            stableSort(operationsData, getComparator(order, orderBy)),
        [operationsData, order, orderBy, page, rowsPerPage]
    );

    const d = new Date();
    const dateDefaultValue = dayjs(d, "DD-MM-YYYY");
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let selectedvalue = 0;
    const [errors, setErrors] = React.useState<any>({});
    const formik = useFormik({
        initialValues: {
            task: null,
            description: null,
            department: '0',
            resourceCode: '0',
            actualFinish: null,
            conditionValue: null,
            lastValue: null,
            conditionNote: null,
            createdBy: user?.name,
            lastUpdatedBy: user?.name
        },
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
        },
    });

    const handleDepartmentChange = (e) => {
        formik.setFieldValue('department', e, false);
    }
    const handleResourceCodesChange = (e) => {
        selectedvalue = e;
        formik.values.resourceCode = e;
        formik.setFieldValue('resourceCode', e, false)
    }
    const validateFields = () => {
        var flag = false;
        if (formik.values.description === null || formik.values.description === '') {
            formik.setFieldError('description', 'Enter Description');
            flag = true;
        } else {
            formik.setFieldError('description', '');
        }
        if (formik.values.department === '0' || formik.values.department === 'Select Department') {
            formik.setFieldError('department', 'Select Department');
            flag = true;
        } else {
            formik.setFieldError('department', '');
        }

        if (flag) {
            return false;
        }
        else {
            formik.setFieldError('description', '');
            formik.setFieldError('resourceCode', '');
            return true;
        }
    }
    const getResources = async (organizationId, owningDepartment) => {
        try {
            const response = await getOperationResourcesByOrgId(user?.token, organizationId, owningDepartment);
            if (response && response?.statusCode === "200") {
                setResourceData(response?.data);
                let resourceDropdown = [{ id: 0, value: 'Select Resource' }];
                await response?.data?.map((resource, index) => {
                    resourceDropdown.push({
                        id: resource.resourceId,
                        value: resource.resourceCode
                    })
                });
                setResourceDropdown(resourceDropdown);
            }
        } catch (err) { setLoading(false); console.log(err) };
    }
    useEffect(() => {
        setNextTask(operationsData && operationsData?.length === 0 ? 10 : operationsData[operationsData?.length - 1].task + 10);
        if (props.saveRecord) {
            props.setSaveRecord(false);
            if (validateFields()) {
                if (editRecord && addRecord) {
                    updateRecordData();
                } else {
                    setLoading(true);
                    const dept = props?.deptData?.find(item => item.id === formik.values.department);
                    const data = {
                        createWorkOrderId: 0,
                        workOrderId: props?.clickedWorkOrder?.workOrderId,
                        wipEntityId: props?.clickedWorkOrder?.wipEntityId,
                        task: nextTask,
                        taskImage: "string",
                        description: formik.values.description,
                        departmentId: formik.values.department,
                        departmentCode: dept?.value,
                        resourceId: 11023,
                        resourceCode: 'FieldTech',
                        actualFinish: formik.values.actualFinish,
                        conditionalValue: formik.values.conditionValue,
                        lastValue: formik.values.lastValue,
                        conditionalNote: formik.values.conditionNote,
                        organizationId: props?.clickedWorkOrder?.organizationId,
                        range: "string",
                        unitOfMeasure: "string",
                        createdBy: user?.id,
                        modifiedBy: user?.id,
                    }
                    saveOperationRecord(data);
                }
            }
        }
    });
    useEffect(() => {
        if (props?.addRecord) {
            refreshFields();
            formik.setFieldValue('task', nextTask, false);
        }
    }, [props?.addRecord])

    const saveOperationRecord = async (data) => {
        const res = await saveOperation(user?.token, data).then(res => {
            if (res?.statusCode === "200") {
                const toastProps = { open: true, type: "success", message: "Saved successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                formik.values.description = null;
                formik.values.actualFinish = null;
                formik.values.conditionValue = null;
                formik.values.lastValue = null;
                formik.values.conditionNote = null;
                formik.values.resourceCode = '0';
                // refreshFields();
                onClose();
                setTimeout(() => {
                    props?.getUpdatedOperationsData();
                    setLoading(false);
                }, 3000);
            }
            else {
                setLoading(false);
                if (res?.message.includes('exists')) {
                    const toastProps = { open: true, type: "error", message: "Task number already exists", onClose: () => setToast({}) };
                    setToast(toastProps);
                } else {
                    const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                    setToast(toastProps);
                }
            }
        });
    }
    const actualFinish = (row, operationId) => {

        const data = {
            operationId: operationId,
            completedDate: now.format("YYYY-MM-DD hh:mm:ss a"),
        }
        if (row.longDescription && row.longDescription !== null && row.longDescription != "" && row.conditionalValue === null) {
            const toastProps = { open: true, type: "error", message: "Please enter a conditional value before completing a task", onClose: () => setToast({}) };
            setToast(toastProps);
        }
        else {
            props?.updateActualFinish(data);
        }

    }
    const fetchLaborData = async (operationId) => {
        try {
            setLoading(true);
            const deleteOp = operationsData.filter(x => x.operationId === operationId);
            const response = await getLaborsByWipEntityId(user?.token, 0, 25, props?.clickedWorkOrder?.wipEntityId);
            if (response && response?.statusCode === "200") {
                const laborData = response?.data?.content;
                const exists = laborData.some(m => m.task === deleteOp.task);
                if (exists) {
                    setOpenInfo(true);
                    setLoading(false);
                    setInfoMsg('Task dependent of labor');
                } else {
                    setOpenConfirm(true);
                    setLoading(false);
                }
            }
        } catch (err) { setLoading(false); console.log(err) };
    }
    const deleteRecord = (operationId) => {
        setDeleteId(operationId);
        fetchLaborData(operationId);
    }
    const editRecordData = (row) => {
        setEditRecord(true);
        setEditData(row);
        const dept = props?.deptData?.find(item => item.id === formik.values.department);
        formik.setFieldValue('task', row.task, false)
        formik.setFieldValue('description', row.description, false)
        formik.setFieldValue('actualFinish', row.actualFinish, false)
        formik.setFieldValue('conditionalValue', row.conditionalValue, false)
        formik.setFieldValue('lastValue', row.lastValue, false)
        formik.setFieldValue('conditionalNote', row.conditionalNote, false)
        formik.setFieldValue('department', row?.departmentId ?? '0', false);
    }
    React.useEffect(() => {
        setEditData(editData);
    }, [editData])
    const updateRecordData = () => {
        let data = { ...editData };
        setLoading(true);
        const dept = props?.deptData?.find(item => item.id === formik.values.department);
        const departmentId = formik.values.department;
        const departmentCode = dept?.value;
        const resourceId = 11023;
        const resourceCode = 'FieldTech';
        const description = formik.values.description;
        const actualFinish = formik.values.actualFinish;
        const conditionValue = formik.values.conditionValue;
        const lastValue = formik.values.lastValue;
        const conditionNote = formik.values.conditionNote;
        data = { ...data, departmentId };
        data = { ...data, departmentCode };
        data = { ...data, resourceId };
        data = { ...data, resourceCode };
        data = { ...data, description };
        data = { ...data, actualFinish };
        data = { ...data, conditionValue };
        data = { ...data, lastValue };
        data = { ...data, conditionNote };
        updateOperationRecord(data);
    }
    const updateOperationRecord = async (data) => {
        updateOperation(user?.token, data).then(res => {
            if (res?.statusCode === "200") {

                const toastProps = { open: true, type: "success", message: "Updated successfully", onClose: () => setToast({}) };
                setToast(toastProps);
                refreshFields()
                setEditRecord(false);
                setEditData({});
                onClose();
                setTimeout(() => {
                    props?.getUpdatedOperationsData();
                    setLoading(false);
                }, 3000);
            }
            else {
                setLoading(false);
                if (res?.message.includes('exists')) {
                    const toastProps = { open: true, type: "error", message: "Task number already exists", onClose: () => setToast({}) };
                    setToast(toastProps);
                } else {
                    const toastProps = { open: true, type: "error", message: "Some error occured", onClose: () => setToast({}) };
                    setToast(toastProps);
                }
            }
        });
    }
    const refreshFields = () => {
        formik.setFieldValue('description', null, false)
        formik.setFieldValue('actualFinish', null, false)
        formik.setFieldValue('conditionValue', null, false)
        formik.setFieldValue('lastValue', null, false)
        formik.setFieldValue('conditionNote', null, false)
        formik.setFieldValue('resourceCode', '0', false)
        formik.setFieldValue('department', '0', false)
    }

    const clickVisualDictionary = async (row) => {
        exportToExcel(APIENPOINTS.ASSETS.TASK_VISUAL_DICTIONALY + '?attachmentName=' + row?.taskImage);
    }

    const updateHttpLink = (description) => {
        if (description !== null && description !== '') {
            let descArray = description.split(' ');
            let updatedDesc = '';
            if (descArray.length > 0) {
                descArray.forEach(desc => {
                    if (desc.includes('http')) {
                        updatedDesc += ' <a href="' + desc + '" target="_blank"> ' + desc + ' </a> ';
                    } else {
                        updatedDesc += desc;
                    }
                });
                description = updatedDesc;
            }
        }
        return description;
    }

    const onClose = () => {
        setEditRecord(false);
        props?.setAddRecord(false);
        refreshFields();
        setEditData({});
    }

    return (
        <Box>
            <Box>

                <Box >
                    {loading &&
                        <div className="loading">
                            Loading&#8230;
                        </div>
                    }
                    <ConfirmDialog openConfirm={openConfirm} msg={'Do you want delete the operation?'}
                        accept={() => {
                            props.deleteOperationRecord(deleteId);
                            setOpenConfirm(false);
                        }}
                        reject={() => setOpenConfirm(false)}
                    />
                    <ConfirmDialog openConfirm={openConfirmTask} disable={loader} msg={'Are you sure?'}
                        accept={() => {
                            actualFinish(record, operationId);
                            setOpenConfirmTask(false);
                        }}
                        reject={() => setOpenConfirmTask(false)}
                    />
                    <InfoDialog openInfo={openInfo} msg={infoMsg}
                        okAction={() => {
                            setOpenInfo(false);
                        }}
                    />
                    {/* MODAEL POPUP */}

                    <div>
                        <BootstrapDialog
                            onClose={handleCloseVD}
                            aria-labelledby="customized-dialog-title"
                            open={openVisualDictionary}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseVD}>
                                {"VISIAL_DICTIONARY"} {vd?.task}
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Box className="summary-asset-data ">
                                    <Box className="summary-asset-data" sx={{ minWidth: 400 }} key={0 + 'summary'}>
                                        <embed
                                            src={vd.data}
                                            id="displayFile"

                                            width="100%"
                                            height="99%"
                                            style={{ borderStyle: "solid" }}
                                            type="application/pdf"
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                        </BootstrapDialog>
                    </div>
                    {/*VISUAL DICTIONARY MODAEL POPUP */}

                    <TableContainer sx={{ transition: "height 100ms ease-in-out", maxHeight: window.innerHeight - 288, width: "100%" }} className='wo-operations-table-container' >
                        <Table sx={{
                            minWidth: 700,
                            '& .MuiTableCell-head': { backgroundColor: "#E6E6E6 !important", color: "black !important" },
                            '& .css-cokgfw-MuiTableCell-root.MuiTableCell-head': { width: "50px !important" },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                                color: "black"
                            },
                            "& .MuiButtonBase-root.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                                color: "black", opacity: 100
                            }
                        }} stickyHeader size="small" aria-label="sticky table">

                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={columns}
                            />
                            <TableBody>

                                {((addRecord || editRecord) && screenSize?.dynamicWidth > appConstants.TABLET_POTRAIT_RESOLUTION) && <StyledTableRow className='add-row-wo'>

                                    <StyledTableCell className='wo-op-tablecell1' >
                                        <InputComponent
                                            name={"task"}
                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                            width={"100%"}
                                            defaultValue={formik.values.task}
                                            value={formik.values.task}
                                            disabled={true}
                                            maxLength={"10"}
                                            error={errors?.task} />
                                    </StyledTableCell>
                                    <StyledTableCell className='wo-op-tablecell2'>
                                        <InputComponent
                                            name={"description"}
                                            onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                            width={"100%"}
                                            maxLength={"250"}
                                            placeholder={'Enter Description'}
                                            value={formik.values.description}
                                            error={formik?.errors?.description} />
                                    </StyledTableCell>
                                    <StyledTableCell className='wo-op-tablecell2'>
                                        <SelectComponent
                                            values={props?.deptData}
                                            name={"department"}
                                            width={"100%"}
                                            selectedvalue={formik.values?.department}
                                            /* disabled={editRecord} */
                                            value={formik.values.department}
                                            error={formik?.errors?.department}
                                            parentselectcallback={handleDepartmentChange}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell />
                                    <StyledTableCell className="action-button-cell" align='center' >
                                        <Box className='action-buttons'>
                                            <Stack direction={"row"} gap={'10px'} >
                                                <SaveIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                                    onClick={() => {
                                                        editRecord ? updateRecordData() : addOperation()
                                                    }
                                                    }
                                                ></SaveIcon>
                                                <CancelIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                                    onClick={() => {
                                                        setEditRecord(false);
                                                        props?.setAddRecord(false);
                                                        refreshFields();
                                                        setEditData({});

                                                    }}
                                                >
                                                </CancelIcon>
                                            </Stack>
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                                }


                                {visibleRows && visibleRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    editData && editData?.operationsId !== row?.operationsId &&
                                    <StyledTableRow key={index} >
                                        <StyledTableCell sx={{ borderLeft: '5px solid #006CEC !important' }}>
                                            {(row?.taskImage === undefined || row?.taskImage === null || row?.taskImage === '') ?
                                                <Typography>
                                                    {row.task}
                                                </Typography> :
                                                <Typography className='operation-last-value' onClick={() => clickVisualDictionary(row)}>
                                                    {row.task}
                                                </Typography>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell className='wo-operations-desc-cell'>

                                            {row?.description && row?.description?.includes('https') ? <div dangerouslySetInnerHTML={{ __html: updateHttpLink(row.description) }}></div> : row.description}
                                        </StyledTableCell>
                                        <StyledTableCell>{row?.departmentCode}</StyledTableCell>

                                        <StyledTableCell className='complete-task-column'>
                                            {row?.actualFinish ? (formatDate(row.actualFinish.slice(0, 10))) :
                                                <Box className='wo-complete-task-button'>
                                                    <ButtonComponent
                                                        icon={false}
                                                        label={"Complete Task"}
                                                        disabled={(props?.clickedWorkOrder?.statusType === 4 || props?.clickedWorkOrder?.statusType === 6) ? true : loading}
                                                        buttoncallBack={() => { setRecord(row); setOperationId(row?.operationsId); setOpenConfirmTask(true); }}
                                                    ></ButtonComponent>
                                                </Box>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell className="action-button-cell" align='center'>
                                            {(row?.actualFinish === null || row?.actualFinish === '') &&
                                                <Stack direction={"row"} gap={'10px'} className='action-buttons-wo' >
                                                    <IconButton onClick={() => {
                                                        editRecordData(row)
                                                    }}
                                                        disabled={(props?.clickedWorkOrder?.statusType === 4 || props?.clickedWorkOrder?.statusType === 6) ? true : loading}
                                                        color='rgba(0, 0, 0, 0.54)'
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                </Stack>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                ))}

                            </TableBody>
                        </Table>

                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={operationsData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='customTablePagination'
                    />
                </Box>
                {screenSize?.dynamicWidth < appConstants.TABLET_POTRAIT_RESOLUTION &&
                    <Dialog
                        open={addRecord || editRecord}
                        onClose={onClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Create Task"}
                        </DialogTitle>
                        <DialogContent sx={{ padding: '0px' }}>
                            <Box className='mobile-card-content-main'>
                                <Box className='mobile-cards-content'>
                                    <Box className='card-content-wo'>
                                        <Card variant="outlined">
                                            <React.Fragment>
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                                        {/* Task Field */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                Task
                                                            </Typography>
                                                            <InputComponent
                                                                name={"task"}
                                                                value={props.addRecord ? nextTask : formik.values.task}
                                                                onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                                                width={"100%"}
                                                                maxLength={"50"}
                                                                disabled={true}
                                                                error={errors?.task}
                                                            />
                                                        </Box>

                                                        {/* Description Field */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                Description
                                                            </Typography>
                                                            <InputComponent
                                                                name={"description"}
                                                                value={formik.values.description}
                                                                onChange={(e) => formik.setFieldValue(e.target.name, e.target.value, false)}
                                                                width={"100%"}
                                                                maxLength={"250"}
                                                                placeholder={'Enter Description'}
                                                                error={formik?.errors?.description}
                                                            />
                                                        </Box>

                                                        {/* Department Field */}
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                                Department
                                                            </Typography>
                                                            <SelectComponent
                                                                values={props?.deptData}
                                                                name={"department"}
                                                                selectedvalue={formik.values?.department}
                                                                /* disabled={editRecord} */
                                                                width={"100%"}
                                                                value={formik.values.department}
                                                                error={formik?.errors?.department}
                                                                parentselectcallback={handleDepartmentChange}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </React.Fragment>
                                        </Card>
                                    </Box>
                                </Box>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant='contained' color="error" disabled={loading} onClick={onClose} autoFocus sx={{ color: '#FFFFFF' }}>
                                Cancel
                            </Button>
                            <Button variant='contained' color="success" disabled={loading} onClick={() => editRecord ? updateRecordData() : props.saveOperationRecord()} autoFocus>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Box>
            <ToastMessage toast={toast} />
        </Box>
    );
}